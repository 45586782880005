import { useState } from "react";
import {Modal} from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import {
   setAddNodeModalOpen,
   setObjectiveArr,
   setDeadline,
   createPlanningDuration,
   setIsAddTreeModalOpen,
   addTreeToANode
} from "../../store/actions";

import {Formik} from "formik";
import {RootState} from "../../store/store";
import { toast } from "react-toastify";
import { NewButton } from "../Buttons/NewButton";

const input = ``;

export function AddTreeModal() {
  const dispatch:any = useDispatch();
  const isAddTreeModalOpen = useSelector((state: RootState) => state.globalStates.isAddTreeModalOpen);
  const parentId = useSelector((state: RootState) => state.globalStates.parentId);
  const [startTime] = useState(new Date());
  const [isSubmiting, setIsSubmiting] = useState(false);


  const handleSubmit = async (values:any,e:any) => { 
        // Example usage
        try {
          const stringChildren = JSON.stringify(parseIndentedString(values.treeData), null, 2);
          const children = JSON.parse(stringChildren);
          dispatch(addTreeToANode(parentId as string,children,setIsSubmiting))
        } 
        catch (error:any) {
          toast.error(error.message);
        }
  };

  const onEscape = async () => {
    dispatch(setObjectiveArr(null));
    dispatch(setDeadline(null));
    dispatch(setAddNodeModalOpen(false));
    if(parentId){
      await createPlanningDuration({startTime,endTime: new Date(),node: parentId});
    }
  }


  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={isAddTreeModalOpen}
      setIsOpen={(bool)=>dispatch(setIsAddTreeModalOpen(bool))}
      hideBtns={true}
      onEscape={onEscape}
      header
    >
      <Formik
      initialValues={{treeData: input}}
      validate={()=>{}}
      onSubmit={handleSubmit}
      >
        {(e:any)=>(
          <div>
            <div className="flex flex-col">
              <label htmlFor="treeData" className="font-bold text-gray-400">Tree Data</label>
              <textarea value={e.values.treeData} onChange={e.handleChange} name="treeData" id="treeData" className="border rounded-md p-2" placeholder="Add Tree Data.."/>
            </div>


            <div className="mt-10">

              <NewButton disabled={isSubmiting? true:false} label={isSubmiting? "Saving..":"Add"} onClick={e.handleSubmit} full/>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
}



export function parseIndentedString(input:string) {
  const lines = input.split('\n'); // Split the string into lines
  const root:any = [];
  const stack = [{ level: -1, children: root }]; // Use a stack to manage hierarchy

  for (const line of lines) {
    if (!line.trim()) continue; // Skip empty lines
    
    
    const newNode:any = {
      objective: "",
      type: "",
      estimatedTaskDuration: 1,
      deadline: null,
      color: "",
      taskOrder: 0,
      recurring: {
        days: [0,1,2,3,4,5,6],
        frequency: ""
      },
      isRecurringTask: false,
      objectiveDynamicProperties: {
        title: ""
      }
    };

    line.split(",").forEach(str => {
      const key = str.split(":")[0].trim();
      const value = str.split(":")[1].trim();

      if(key === "NODE_TYPE"){
        newNode.type = value;        
      }
      if(key === "ESTIMATED_DURATION"){
        newNode.estimatedTaskDuration = Number(value);        
      }
      if(key === "NODE_TITLE"){
        newNode.objectiveDynamicProperties.title = value;        
      }
      if(key === "OBJECTIVE"){
        newNode.objective = value;        
      }
      if(key === "DEADLINE"){
        newNode.deadline = new Date(value);        
      }
      if(key === "TASK_ORDER"){
        newNode.taskOrder = Number(value);        
      }
      if(key === "COLOR"){
        newNode.color = value;        
      }
      if(key === "RECURRING"){
        newNode.isRecurringTask = value === "Yes"? true:false
      }
      if(key === "FREQUENCY" && newNode.isRecurringTask){
        if(value === "WEEKLY"){
          newNode.recurring.frequency = "weekly";
        }
        else {
          newNode.recurring.frequency = "daily";
        }
      }
      if(key === "DAYS" && newNode.isRecurringTask){
        const fb = value.replace("[","").replace("]","");
        newNode.recurring.days = fb.split("-").map(v => Number(v));
      }
    });


    const level = line.search(/\S/); // Count leading spaces to determine depth

    const node =  {...newNode,children: []}


    while (stack.length > 0 && stack[stack.length - 1].level >= level) {
      stack.pop(); // Pop stack until finding the correct parent level
    }

    const parent = stack[stack.length - 1];
    parent.children.push(node); // Add the node to its parent's children
    stack.push({ level, children: node.children }); // Push the current node onto the stack
  }

  return root;
}







