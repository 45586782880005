
import { RootState } from "@store/store";
import { useSelector } from "react-redux";
import { AddNodeModal } from "./Modal/AddNodeModal";
import { UpdateNodeModal } from "./Modal/UpdateNodeModal";
import { CreateCognipulseModal } from "./Modal/CreateCognipulseModal";
import { CreateImpulseModal } from "./Modal/CreateImpulseModal";
import { AddTreeModal } from "./Modal/AddTreeModal";
import { CreatePhySelfie } from "./Modal/CreatePhySelfieModal";
import { DeeperInsightsModal } from "./Modal/DeeperInsightsModal";
import { UnlockPotentialNowModal } from "./Modal/UnlockPotentialNowModal";
import { DelegateNodeModal } from "./Modal/DelegateNodeModal";
import { TimeAlertModal } from "./Modal/TimeAlertModal";
import { UpCommingTaskModal } from "./Modal/UpCommingTaskModal";
import { ParentTaskCompleteAlertModal } from "./Modal/ParentTaskCompleteAlertModal";
import { CreateImpulseAfterTaskComplete } from "@components/Modal/CreateImpulseAfterTaskComplete";
import { PauseModal } from "./Modal/PauseModal";
import { RatingAfterCompleteModal } from "./Modal/RatingAfterComplete";



export function Modals() {
    const isUpdateNodeModalOpen = useSelector((state:RootState) => state.globalStates.isUpdateNodeModalOpen);
    const isAddNodeModalOpen = useSelector((state:RootState) => state.globalStates.isAddNodeModalOpen);
    const isCreateCognipulseModalOpen = useSelector((state:RootState) => state.globalStates.isCreateCognipulseModalOpen);
    const isCreateImpulseModalOpen = useSelector((state:RootState) => state.globalStates.isCreateImpulseModalOpen);
    const isAddTreeModalOpen = useSelector((state:RootState) => state.globalStates.isAddTreeModalOpen);
    const isPhySelfieModalOpen = useSelector((state:RootState) => state.globalStates.isPhySelfieModalOpen);
    const isShowDeeperInsights = useSelector((state:RootState) => state.globalStates.isShowDeeperInsights);
    const isShowUnlockPotentialNow = useSelector((state:RootState) => state.globalStates.isShowUnlockPotentialNow);
    const isDelegateNodeModalOpen = useSelector((state:RootState) => state.globalStates.isDelegateNodeModalOpen);
    const isTimingAlertModalOpen = useSelector((state:RootState) => state.globalStates.isTimingAlertModalOpen);
    const isUpcomingTaskModalOpen = useSelector((state:RootState) => state.globalStates.isUpcomingTaskModalOpen);
    const isParentTaskCompleteAlertModalOpen = useSelector((state:RootState) => state.globalStates.isParentTaskCompleteAlertModalOpen);
    const isCreateImpulseAfterTaskCompleteModalOpen = useSelector((state:RootState) => state.globalStates.isCreateImpulseAfterTaskCompleteModalOpen);
    const isPauseModalOpen = useSelector((state:RootState) => state.globalStates.isPauseModalOpen);
    const isRatingAfterCompleteModalOpen = useSelector((state:RootState) => state.globalStates.isRatingAfterCompleteModalOpen);

  

    return (
      <>
        {
          isAddNodeModalOpen && <AddNodeModal />
        }
        {
          isUpdateNodeModalOpen && <UpdateNodeModal />
        }
        {
          isCreateCognipulseModalOpen && <CreateCognipulseModal/>
        }
        {
          isCreateImpulseModalOpen && <CreateImpulseModal/>
        }
        {
          isAddTreeModalOpen && <AddTreeModal/>
        }
        {
          isPhySelfieModalOpen && <CreatePhySelfie/>
        }
        {
          isShowDeeperInsights && <DeeperInsightsModal/>
        }
        {
          isShowUnlockPotentialNow && <UnlockPotentialNowModal/>
        }
        {
          isDelegateNodeModalOpen && <DelegateNodeModal/>
        }
        {
          isTimingAlertModalOpen && <TimeAlertModal/>
        }
        {
          isUpcomingTaskModalOpen && <UpCommingTaskModal/>
        }
        {
          isParentTaskCompleteAlertModalOpen && <ParentTaskCompleteAlertModal/>
        }
        {
          isCreateImpulseAfterTaskCompleteModalOpen && <CreateImpulseAfterTaskComplete/>
        }
        {
          isPauseModalOpen && <PauseModal/>
        }
        {
          isRatingAfterCompleteModalOpen && <RatingAfterCompleteModal/>
        }
      </>
    );
}
