import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { setCurrentTask, setIsCurrentTaskRunning, setIsFullViewOn, setNavigatePointerNodeId } from "../../../store/actions";
import { RemaningTime } from "./RemaningTime";
import { ActionButtons } from "./ActionButtons";
import { toast } from "react-toastify";

interface CurrentTaskProps {
}

const CurrentTask: React.FC<CurrentTaskProps> = () => {
	const dispatch:any = useDispatch();
	const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
	const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents);
	const currentTaskContainer = useRef<HTMLDivElement>(null);


	
	const handleCurrentTask = () => {
		if (!currentTask) return;
		dispatch(setIsFullViewOn(false));
		dispatch(setNavigatePointerNodeId(currentTask?._id));
	};

	const handlePrevOrNextClick = (isNext:boolean) => {
		const currentTaskParent = currentTasksParents && currentTasksParents[currentTasksParents.length-1];

		if(!currentTaskParent){
			return;
		}
		if(currentTask?.isSomeOneWorkingOnThis || currentTask?.startTime){
			toast.error("You can't move the running task!");
			return;
		}

		const siblings = currentTaskParent.children.filter((child:any) => child.children?.length === 0);
		const index = siblings.findIndex((child:any) => child._id === currentTask._id);

		if(isNext){
			if(siblings[index+1]){
				dispatch(setCurrentTask(siblings[index+1]))
			}
			else {
				toast.error("This is the last sibling.");
			}
			return;
		}
		if(siblings[index-1]){
			dispatch(setCurrentTask(siblings[index-1]));
		}
		else {
			toast.error("This is the first sibling.");
		}
	}


	useEffect(() => {
		if (currentTask?.isSomeOneWorkingOnThis) {
			dispatch(setIsCurrentTaskRunning(true));
		}
		if(!currentTask?.isSomeOneWorkingOnThis){
			dispatch(setIsCurrentTaskRunning(false));
		}
	}, [currentTask?.isSomeOneWorkingOnThis,dispatch]);


	useEffect(() => {
		const container = currentTaskContainer?.current; // ✅ Capture the ref value

    	if (!container) return;

		const handlePrevOrNextClickInner = (isNext:boolean) => {
			const currentTaskParent = currentTasksParents && currentTasksParents[currentTasksParents.length-1];
	
			if(!currentTaskParent){	
				return;
			}
			if(currentTask?.isSomeOneWorkingOnThis || currentTask.startTime){
				toast.error("You can't move the running task!");
				return;
			}

			const siblings = currentTaskParent.children.filter((child:any) => child.children?.length === 0);
			const index = siblings.findIndex((child:any) => child._id === currentTask._id);
	
			if(isNext){
				if(siblings[index+1]){
					dispatch(setCurrentTask(siblings[index+1]))
				}
				else {
					toast.error("This is the last sibling.");
				}
				return;
			}
			if(siblings[index-1]){
				dispatch(setCurrentTask(siblings[index-1]));
			}
			else {
				toast.error("This is the first sibling.");
			}
		}

		// ✅ Define handlers
		const handleTouchMove = (e: any) => {
			e.preventDefault();
		}
		const handleSwipedLeft = (e: any) => {
			e.preventDefault();
			handlePrevOrNextClickInner(true);
		};
		const handleSwipedRight = (e: any) => {
			e.preventDefault();
			handlePrevOrNextClickInner(false);
		};

		container.addEventListener("touchmove", handleTouchMove);
		container.addEventListener("swiped-left", handleSwipedLeft);
		container.addEventListener("swiped-right", handleSwipedRight);
	
		return () => {
			container.removeEventListener("touchmove", handleTouchMove);
			container.removeEventListener("swiped-left", handleSwipedLeft);
			container.removeEventListener("swiped-right", handleSwipedRight);
		};
	}, [dispatch,currentTask?._id,currentTasksParents,currentTask?.isSomeOneWorkingOnThis,currentTask?.startTime]);


	return (
		<div className='border-top w-full'>
			<h5 className='mt-3'>Current task</h5>
			{/* Text area */}
			{/* <div onClick={handleCurrentTask} className="bg-gray-100 rounded border border-gray-400 leading-normal w-full py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white">
				{currentTask ? currentTask.objective : "Please schedule a Category of Improvement"}
			</div> */}
			<div 
				className="w-full md:w-full mb-1 mt-2 flex items-center"
				ref={currentTaskContainer}
			>
				<span onClick={() => handlePrevOrNextClick(false)} className='p-1 cursor-pointer'>{"<"}</span>
				<div  onClick={handleCurrentTask} className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-auto py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white">
					{currentTask ? currentTask.objective : "Please schedule a Category of Improvement"}
				</div>
				<span onClick={() => handlePrevOrNextClick(true)} className='p-1 cursor-pointer'>{">"}</span>
			</div>

			<RemaningTime/>

			{/* Action buttons */}
			<div className='flex justify-between flex-wrap mt-3 border-bottom pb-4'>
				<ActionButtons/>
			</div>
		</div>
	);
};

export {CurrentTask};



