import React, { useEffect, useState } from 'react'
import { Modal } from '@components/Modal/Index';
import { Triangle } from '@components/Triangle';
import { useStartEndTime } from '@components/Modal/hooks/useStartEndTime';
import { ParentNodeObjective } from '@components/Modal/nodeAddingGuides/TaskModal';
import { NewButton } from '@components/Buttons/NewButton';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { getActivityById } from '@store/actions';
import { toast } from 'react-toastify';
import { useCreateOrUpdateNode } from '@components/Modal/hooks/useCreateOrUpdateNode';
import { PreView } from '../components';


interface IMissionModal {
  setIsOpen: any;
  setNodeObj: any;
  objectiveDynamicProperties?: any;
  isUpdating?: boolean;
}

const keyList = ["actionVerbs","specificFocuses","highImpactOutcomesObjective","timeFrames"];
const screenNames = ['WELCOME',"PROJECT_TITLE","WHERE","WHAT","WHO","WHEN","WOW"] as const;
type TScreenName = typeof screenNames[number];

interface ITimeSpent {
  startTime: Date,
  endTime: Date,
  screenName: TScreenName;
}

interface Idata {
  specificFocuses: string;
  specificFocusesFillerWord: string;
  actionVerbs: string;
  actionVerbsFillerWord: string;
  highImpactOutcomesObjective: string;
  highImpactOutcomesObjectiveFillerWord: string;
  timeFrames: string;
  rating: number;
  timeSpent: ITimeSpent[];
  type: "OBJECTIVE"
}

type TypeSetData = React.Dispatch<React.SetStateAction<Idata>>

// [Selected Focus] + [Selected Action] + [Selected High-Impact Outcome]

export const ObjectiveModal: React.FC<IMissionModal> = ({isUpdating,setIsOpen,setNodeObj,objectiveDynamicProperties})  => {
  const selectedActivityId = useSelector((state:RootState) => state.globalStates.selectedActivityId);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [data, setData] = useState<Idata>({
    specificFocuses: "",
    specificFocusesFillerWord: "",
    actionVerbs: "",
    actionVerbsFillerWord: "",
    highImpactOutcomesObjective: "",
    highImpactOutcomesObjectiveFillerWord: "",
    timeFrames: "",
    rating: 50,
    timeSpent: [],
    type: "OBJECTIVE"
  });
  const [activityCategory, setActivityCategory] = useState(null);



  useEffect(() => {
    if(objectiveDynamicProperties){
      setData({
        specificFocuses: objectiveDynamicProperties.specificFocuses || "",
        specificFocusesFillerWord: objectiveDynamicProperties.specificFocusesFillerWord || "",
        actionVerbs: objectiveDynamicProperties.actionVerbs || "",
        actionVerbsFillerWord: objectiveDynamicProperties.actionVerbsFillerWord || "",
        highImpactOutcomesObjective: objectiveDynamicProperties.highImpactOutcomesObjective || "",
        highImpactOutcomesObjectiveFillerWord: objectiveDynamicProperties.highImpactOutcomesObjectiveFillerWord || "",
        timeFrames: objectiveDynamicProperties.timeFrames || "",
        rating: objectiveDynamicProperties.rating || 50,
        timeSpent: objectiveDynamicProperties.timeSpent || [],
        type: "OBJECTIVE"
      });
    }
  }, [objectiveDynamicProperties]);

  useEffect(() => {
      (async () => {
        const res = await getActivityById(selectedActivityId);
        // console.log(selectedActivityId);
        // console.log(res);
        if(res && res.success){
          setActivityCategory(res.activity.category);
        }
        else {
          toast.error(res?.message || "Unable to get Activity");
        }
      })()
  }, [selectedActivityId]);


  if(!activityCategory){
    return <Modal
              saveBtnText="Create"
              onSubmit={()=>{}}
              isOpen={true}
              setIsOpen={setIsOpen}
              hideBtns={true}
              onEscape={() => {setIsOpen(false)}}
              header
            >
              <>Loading...</>
            </Modal>
  }

  return (
    <>
        <Modal
          saveBtnText="Create"
          onSubmit={()=>{}}
          isOpen={true}
          setIsOpen={setIsOpen}
          hideBtns={true}
          onEscape={() => {setIsOpen(false)}}
          header
        >
          <> 
            {
              currentScreen === 1 && <Welcome data={data} setCurrentScreen={setCurrentScreen} setData={setData}/>
            }
            {
              currentScreen === 2 && <HighImpactOutcomesObjective data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 3 && <SpecificFocuses data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 4 && <ActionVerbs data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 5 && <TimeFrames data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 6 && <Rating isUpdating={isUpdating} setNodeObj={setNodeObj} setIsOpen={setIsOpen} data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
              
          </>
        </Modal>
    </>
  )
}


interface ITitle {
  data: Idata;
}

const Title: React.FC<ITitle> = ({data}) => {
  return <h2 className='text-center font-bold text-xl text-gray-400'>Craft Your Bold Objective!</h2>
}


interface IInputField {
  labelText: string;
  smallText: string;
  name: string;
  placeHolder: string;
  id: string;
  value: string;
  onChange: any;
  children?: any;
  setIsHighlighted: any;
}

const InputField: React.FC<IInputField> = ({labelText,smallText,name,placeHolder,id,value,onChange,children,setIsHighlighted}) => {

  const handleFocus = (e:React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsHighlighted(true);
  }


  useEffect(() => {
    document.addEventListener("click",(e: any) => {
      if(e.target.tagName !== "INPUT"){
        setIsHighlighted(false);
      }
    })
    
    return () => {
      document.removeEventListener("click",()=>{});
    };
  }, [setIsHighlighted]);
  

  return <div className='relative'>
            <div className="flex flex-col gap-1">
              <label className='text-gray-400 font-bold' htmlFor="">{labelText}</label>
                <input 
                  onFocus={handleFocus} 
                  // onBlur={handleBlur} 
                  value={value} 
                  onChange={onChange} 
                  type="text" 
                  placeholder={placeHolder} 
                  name={name} 
                  id={id} 
                  className='border p-2 rounded-md'
                />
              <small className='text-gray-400'>{smallText}</small>
            </div>
            {children}
        </div>
}


interface INextAndBackButton {
  nextClick: any;
  backClick: any;
  backText?: any;
  nextText?: any;
}

const NextAndBackButton: React.FC<INextAndBackButton> = ({nextClick,backClick,backText,nextText}) => {


  return <>
      <div className="flex gap-4">
          <NewButton onClick={backClick} full label={`${backText || "Back"}`}/>
          <NewButton onClick={nextClick} full label={`${nextText || "Next"}`}/>
      </div>
  </>
}


interface IDropDown {
  list: string[],
  value: string;
  setData: any;
  isShow: boolean;
}

const DropDown: React.FC<IDropDown> = ({list,value,setData,isShow}) => {
  list = list.filter(item => item.toLowerCase().includes(value.toLowerCase()));
 
  
  if(!isShow){
    return <></>;
  }

  const handleClick = (e:React.MouseEvent<HTMLDivElement>,item:string) => {
    setData(item);
  }
  
  return <div className='absolute top-full left-0 border bg-white w-full rounded-md z-[10]'>
            <div className="w-full h-full flex flex-col max-h-[150px] overflow-auto py-2 text-gray-400">
                 {
                    list.map((item,idx) => <div onClick={(e) => handleClick(e,item)} key={idx} className='hover:bg-gray-100 px-4 py-1 flex items-center cursor-pointer'>{item}</div>)
                 }
            </div>
        </div>
}


interface IWelcome {
  setCurrentScreen: any;
  setData: TypeSetData;
  data: Idata;
}


const Welcome: React.FC<IWelcome> = ({setCurrentScreen,setData,data}) => {
  useStartEndTime(setData,"WELCOME");



  return <>
      <Title data={data}/>

      <p className='text-gray-400 text-center'>
            Your Objective is the next bold step to make
            the higher goal a reality.
      </p>
      
      <div className="">
          <ParentNodeObjective/>

          <Triangle topAngle={50} text='Key Focus Area to Push Progress'/>

          <div className="border flex flex-col items-center py-2">
            <h1 className='text-3xl font-bold  text-gray-400'>Your Bold Objective</h1>
            <small className=' text-gray-600'>e.g Streamline Product Development Process</small>
          </div>
      </div>

      <p className='text-gray-400'>
            Now we’re going to craft a focused and bold
            Objective that directly drives progress for your
            Parent Goal.
      </p>

      <NewButton onClick={() => setCurrentScreen(2)} full label='Next'/>
  </>
}


interface IChange {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const SpecificFocuses: React.FC<IChange> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
    "Launching a game-changing MVP",
    "Building a product users cannot live without", 
    "Cracking the code for product-market fit", 
    "Turning early adopters into loyal customers",
    "Designing a revenue model that fuels growth", 
    "Creating a brand people trust and value", 
    "Developing a scalable, robust product",
    "Proving demand and securing early funding", 
    "Building the foundation for a high-impact startup", 
    "Mastering the art of selling and scaling"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"SpecificFocuses".toUpperCase());

  return <>

      <div>
          <p className='text-gray-400 text-center'>Choose Your Focus</p>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>

          <PreView currentScreen="specificFocuses" data={data} setData={setData} keyList={keyList}/>
          <div className='h-4'/>

          <InputField
              labelText='What is your core mission right now?'
              smallText="Every great startup needs a clear focus. Select the one that best describes what you want to achieve next. This will help you stay on track and move forward effectively."
              name='specificFocuses'
              placeHolder='Your Focus'
              id='specificFocuses'
              value={data.specificFocuses}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,specificFocuses: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              value={data.specificFocuses}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,specificFocuses: str}})}
              isShow={isHighlighted}
            />
          </InputField>  
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={() => setCurrentScreen(4)}
      />
  </>
}


interface IImpact {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const ActionVerbs: React.FC<IImpact> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
     "Dominate",
     "Ignite",
     "Unleash",
     "Revolutionise",
     "Break through",
     "Supercharge",
     "Master",
     "Transform",
     "Amplify",
     "Redefine",
  ]); 
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"ActionVerbs".toUpperCase());

  return <>
      <div>
          <p className='text-gray-400 text-center'>Choose Your Action</p>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          {/* <PreView value={`${data.specificFocuses} to ${data.actionVerbs}`}/>
          <Triangle text='Bold action' topAngle={60}/> */}

          <PreView currentScreen="actionVerbs" data={data} setData={setData} keyList={keyList}/>
          <div className='h-4'/>


          <InputField
              labelText='How will you make it happen?'
              smallText="Now, choose an action that captures how you will drive your goal forward. Select the one that feels the most exciting and realistic for you."
              name='actionVerbs'
              placeHolder='Enter the action you want to take'
              id='actionVerbs'
              value={data.actionVerbs}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,actionVerbs: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              value={data.actionVerbs}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,actionVerbs: str}})}
              isShow={isHighlighted}
            />
          </InputField> 
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(3)}
          nextClick={() => setCurrentScreen(5)}
      />
  </>
}


interface IWho {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const HighImpactOutcomesObjective: React.FC<IWho> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
    "Achieve unstoppable product-market fit",
    "Unlock sustainable, predictable revenue",
    "Attract high-value investors with confidence",
    "Build a user base that fuels viral growth",
    "Secure funding to scale quickly and efficiently",
    "Outperform competitors and establish market leadership",
    "Create an MVP that users highly recommend",
    "Develop a business model that generates consistent revenue",
    "Engineer a seamless, high-converting customer journey",
    "Launch a startup that makes a meaningful impact",
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"HighImpactOutcomesObjective".toUpperCase());

  return <>
      <div className="">
          <p className='text-gray-400 text-center'>Choose Your High-Impact Outcome</p>

          <ParentNodeObjective/>

          <Triangle text='big impact on' topAngle={50}/>

          <PreView currentScreen="highImpactOutcomesObjective" data={data} setData={setData} keyList={keyList}/>
          <div className='h-4'/>


          <InputField
              labelText='What is the ultimate win?'
              smallText="What does success look like for you? Select the high-impact outcome that motivates you the most."
              name='highImpactOutcomesObjective'
              placeHolder='Enter the outcome of your action'
              id='highImpactOutcomesObjective'
              value={data.highImpactOutcomesObjective}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,highImpactOutcomesObjective: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              value={data.highImpactOutcomesObjective}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,highImpactOutcomesObjective: str}})}
              isShow={isHighlighted}
            />
          </InputField>
      </div>


      <NextAndBackButton
          backClick={() => setCurrentScreen(1)}
          nextClick={() => setCurrentScreen(3)}
      />
  </>
}


interface ITime {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const TimeFrames: React.FC<ITime> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
        "By 2025",
        "By 2030",
        "By 2050",
        "Within 1 Year",
        "Within 3 Years",
        "Within 5 Years",
        "Over the Next Decade",
        "By the End of the Decade",
        "In the Next 10 Years",
        "By Mid-Century",
        "By 2050 and Beyond",
        "In Future Generations",
        "As Soon As Possible",
        "Immediately",
        "Over Time",
        "In Our Lifetime",
        "For the Next Generation",
        "Forever",
        "For Years to Come",
        "For a Better Future"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"TimeFrames".toUpperCase());

  return <>
      <div className="">
          <ParentNodeObjective/>
          
          <Triangle text='big impact on' topAngle={50}/>

          {/* <PreView value={`${data.specificFocuses} ${data.actionVerbs} ${data.highImpactOutcomesObjective} ${data.timeFrames}`}/>
          <Triangle text='Objective Timeframe' topAngle={75}/> */}

          <PreView currentScreen="timeFrames" data={data} setData={setData} keyList={keyList}/>
          <div className='h-4'/>


          <InputField
              labelText='When will this happen?'
              smallText="Adding a timeframe helps you stay focused and track progress."
              name='timeFrames'
              placeHolder='Enter a time frame'
              id='timeFrames'
              value={data.timeFrames}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,timeFrames: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
            >
              <DropDown 
                list={list} 
                value={data.timeFrames}
                setData={(str: string) => setData((prev:Idata) => {return {...prev,timeFrames: str}})}
                isShow={isHighlighted}
              />
          </InputField>
      </div>


      <NextAndBackButton
          backClick={() => setCurrentScreen(4)}
          nextClick={() => setCurrentScreen(6)}
      />
  </>
}


interface IRating   {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
  setIsOpen: any;
  setNodeObj: any;
  isUpdating?: boolean;
}

const Rating: React.FC<IRating> = ({isUpdating,setCurrentScreen,data,setData,setIsOpen,setNodeObj}) => {
  const {startTime} = useStartEndTime(setData,"RATING");
  const {handleCreateOrUpdateNode} = useCreateOrUpdateNode();
  const getText = (value: number) => {
     if(value < 40){
        return "It looks like this Objective could be refined. What would make it more exciting or meaningful for you?";
     }
     if(value > 40 && value < 70){
        return "Does this feel aligned with your goals? You can refine it for more clarity or move forward."
     }
     return "Great job! This Objective looks ready to guide your next steps!";
  }




  return <div>
      <div className="">
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>

          <PreView currentScreen="none" data={data} setData={setData} keyList={keyList}/>
      </div>
      
      <h3 className='text-gray-400 text-center mt-8'>You Objective is nearly ready...</h3>
      <p className='text-gray-400 text-center mt-2'>Let's check how  inspired do you feel?</p>
      <h3 className='text-gray-400 font-bold text-center mt-2'>It makes me want to do:</h3>

      <div className="flex items-center gap-2 text-gray-400 mb-4 mt-2">
          <p className='text-nowrap'>Some Dusting.</p>
          <input type="range" min="1" max="100" value={data.rating} onChange={(e) => setData((prev: Idata) => {return {...prev,rating: Number(e.target.value)}})} className="w-full" id="myRange"/>
          <p className='text-nowrap'>A Victory Roar!</p>
      </div>

      <p className='text-gray-400 text-center'>{getText(data.rating)}</p>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={(e:React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleCreateOrUpdateNode({
              finalString: `${data.actionVerbs} ${data.actionVerbsFillerWord} ${data.specificFocuses} ${data.specificFocusesFillerWord} ${data.highImpactOutcomesObjective} ${data.highImpactOutcomesObjectiveFillerWord} ${data.timeFrames}`,
              data,
              startTime,
              isUpdating
            });
          }}
          backText={"Refine Objective"}
          nextText={"Finish"}
      />
  </div>
}




