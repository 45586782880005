import { 
    DOMAIN_NAME
} from "../../constants";
import {
    fetchRequest
} from "../../utils";
const rootURL = `${DOMAIN_NAME}/admin/users`


export interface IUser {
    _id: string;
    name: string;
    email: string;
    password: string;
    role: 'user' | 'admin';
    profilePic?: {
      url?: string;
      publicId?: string;
    };
    firebaseNotificationTokens: string[],
    createdAt: string;
    updatedAt: string;
    hasAccess: boolean;
    nodeCount: number;
    lastLogin: Date | null;
    isApprovedByAdmin: boolean
}

export const getAllUsersAdmin = async ()  => {

    interface Ires {
        success: boolean;
        message: string;
        users?: IUser[]
        nodeCount?: any[]
    }

    try {
        const res: Ires = await fetchRequest(`${rootURL}`, "GET", {});
        
        return res;

    } 
    catch (error:any) {
        console.error(error); // Log error for debugging

        return {
            success: false,
            message: error.message,
            users: [],
            nodeCount: [],
        }
    }
}

export const getUserByIdAdmin = async (userId: string)  => {

    interface Ires {
        success: boolean;
        message: string;
        user: IUser | null
    }

    try {
        const res: Ires = await fetchRequest(`${rootURL}/${userId}`, "GET", {});
        
        return res;

    } 
    catch (error:any) {
        console.error(error); // Log error for debugging

        return {
            success: false,
            message: error.message,
            user: null
        }
    }
}

export const getUserRoleAdmin = async ()  => {
    interface Ires {
        success: boolean;
        message: string;
        role: 'user' | 'admin'
    }

    try {
        const res: Ires = await fetchRequest(`${rootURL}/role`, "GET", {});
        
        return res;

    } 
    catch (error:any) {
        console.error(error); // Log error for debugging

        return {
            success: false,
            message: error.message,
            role: 'user'
        }
    }
}


export interface IUserEdit {
    name: string;
    email: string;
    role: "user" | "admin";
    hasAccess: boolean;
    isApprovedByAdmin: boolean;
}

export const updateUserByIdAdmin = async (userId: string,user: IUserEdit)  => {

    interface Ires {
        success: boolean;
        message: string;
        user: IUser | null
    }

    try {
        const res: Ires = await fetchRequest(`${rootURL}/${userId}`, "PUT", user);
        
        return res;

    } 
    catch (error:any) {
        console.error(error); // Log error for debugging

        return {
            success: false,
            message: error.message,
            user: null
        }
    }
}


export const deleteUserByIdAdmin = async (userId: string)  => {

    interface Ires {
        success: boolean;
        message: string;
        user: IUser;
    }

    try {
        const res: Ires = await fetchRequest(`${rootURL}/${userId}`, "DELETE", {});
        
        return res;

    } 
    catch (error:any) {
        console.error(error); // Log error for debugging

        return {
            success: false,
            message: error.message,
            user: null
        }
    }
}