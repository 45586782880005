import { useDispatch } from "react-redux";
import { getDeeperInsightsData, setIsShowDeeperInsights } from "../../store/actions";
import { Modal } from "./Index";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { YourInsightsFullView } from "../DeeperInsights";
import { getExploitationDurationsOfUserByActivityId } from "../../store/actions/exploitationDuration";
import { getTaskProgressOfUser, ITaskProgresses } from "@store/actions/taskProgress";



export function DeeperInsightsModal() {
  const dispatch:any = useDispatch();
  const selectedActivityId = useSelector((state:RootState) => state.globalStates.selectedActivityId);
  const [deeperInsightsData, setDeeperInsightsData] = useState<any>(null);
  const [exploitationDurations, setExploitationDurations] = useState<any>(null);
  const [taskProgresses, setTaskProgresses] = useState<ITaskProgresses[] | null>(null);


  useEffect(() => {
    (async () => {
      if(selectedActivityId){
        const res = await getDeeperInsightsData(selectedActivityId || "");
        // console.log(res);
        if(res && res.success){
          const data = {...res};
          delete data.message;
          delete data.success;
          setDeeperInsightsData(res);
        }
        else {
          toast.error("Something went wrong!");
        }
      }
    })()
  }, [selectedActivityId]);


  useEffect(() => {
    (async() => {
      if(selectedActivityId){
        const res = await getExploitationDurationsOfUserByActivityId(selectedActivityId);
        // console.log(res);
        if(res?.success){
          setExploitationDurations(res.exploitationDurations);
        }

        const res1 = await getTaskProgressOfUser(selectedActivityId);

        if(res1?.success){
          setTaskProgresses(res1.taskProgresses);
        }
      }
    })()
  }, [selectedActivityId]);


  
  if(!deeperInsightsData || !exploitationDurations || !taskProgresses){
    return <>
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=> dispatch(setIsShowDeeperInsights(bool))}
      hideBtns={true}
      onEscape={()=>{}}
      width={1100}
      header
    >
       <>Loading...</>
    </Modal>
    </>
  }

  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=> dispatch(setIsShowDeeperInsights(bool))}
      hideBtns={true}
      onEscape={()=>{}}
      width={1400}
      header
    >
       <>
          <YourInsightsFullView 
            deshboardData={deeperInsightsData} 
            selectedActivity={""} 
            XLChange={deeperInsightsData?.XLChange} 
            historiclData={deeperInsightsData.historiclData} 
            factorsMinMax={deeperInsightsData.actorsMinMax} 
            percentageOfChangeOfFactors={deeperInsightsData.percentageOfChangeOfFactors}
            setIsOpen={deeperInsightsData.setIsYourInsightsFullViewOn}
            expliotationDuration={exploitationDurations}
            taskProgresses={taskProgresses}
          />
       </>
    </Modal>
  )
}



