import { useEffect, useState } from "react";

export function useIsMobile(){
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500? true:false);
  
  
    useEffect(() => {
        window.addEventListener("orientationchange",(e:any) => {
          if(e.target.screen.orientation.type === "portrait-primary"){
            setIsMobile(true);
          }
          else {
            setIsMobile(false);
          }
        })
  
      return ()=> {
        window.removeEventListener("orientationchange",() => {
          setIsMobile(false);
        });
      }
    }, []);
  
    return isMobile
}