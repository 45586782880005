import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNode,
  copyPasteNodes,
  cutPasteNodes,
  unDeferDescendantsTasks,
  unCompleteCompletedNodes,
  pauseTask,
  setAddNodeModalOpen,
  setUpdateNodeModalOpen,
  setParentIdOfNodeTobeCreated,
  setCopiedNodeId,
  setCutNodeId,
  setDepthOfNodeTobeCreated,
  setNavigatePointerNodeId,
  setManuallyScheduledNodeId,
  setStartTime,
  addChallenge,
  addRoutine,
  setObjectiveArr,
  setDeadline,
  setCurrentTask,
  setCurrentTasksParents,
  setNodeIdToBeEdited,
  unCompleteRecurringTask,
  updateNode,
  setParentNodeObjectiveAndType,
  setCopiedOrCutNodeObjective,
  setIsAddTreeModalOpen,
  impulseAndCognipulseAndPhySelfiePopup,
  setIsShowDeeperInsights,
  setIsShowUnlockPotentialNow,
  setNewCognipulseId,
  setIsAddingcaptureParkNode,
  setNodeToDelegate,
  setIsDelegateNodeModalOpen
} from "../../../store/actions";
import { toast } from "react-toastify";
import { Icons } from "../../../components/Icons";
import { RootState } from "../../../store/store";
import { capitalized, getCurrentTask } from "../../../utils";
import { NODE_TYPES, SET_IS_PAUSED } from "../../../constants";
import { arrayToObject, formatPurpose } from "../../../components/Modal/Purpose";
import {XLScoreChart} from "../../../components/charts"
import { FlowScoreChart } from "../../../components/charts/components/FlowScoreChart";
import moment from "moment";
import { streamGraph } from "../../../assets";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { addPriority } from "../../../store/actions/priority";
import { NewButton } from "../../../components/Buttons/NewButton";
import { useAutoPlayTask } from "../../../hooks";
import { ProgressBar } from "@components/ProgressBar";
import { adjustBrightness } from "@utils/adjustBrightness";
import { getTextColorForBackground } from "@utils/getTextColorForBackground";
import { EditSVG,ShareSVG,AddNodeSVG,PlusSVG,MenuSVG} from "../../../components/SVG";




interface NodeProps {
  d: any; // Replace 'any' with your specific type for the node data
  setCurrentNodeToRank: (node: any) => void; // Replace 'any' with the appropriate type
  copiedNodes: string[]; // Assuming copiedNodes is an array of string IDs
}

const Node: React.FC<NodeProps> = ({ d, setCurrentNodeToRank, copiedNodes}) => {
  const navigatorId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);
  const dispatch:any = useDispatch();
  const [onHover, setOnHover] = useState(false);

  const handleCutNode = (e:React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    dispatch(setCopiedOrCutNodeObjective(d.data.objective));
    dispatch(setCutNodeId(d.data._id));
  }
  

  return (
    <div onMouseEnter={()=> setOnHover(true)} onMouseLeave={()=> setOnHover(false)}  title={d.data.objective} className='div-inside-foren-object w-full h-full px-2 py-1 border-[1px] border-black overflow-hidden' style={{ backgroundColor: adjustBrightness(d.data),color: getTextColorForBackground(adjustBrightness(d.data)), border: d.data._id === navigatorId ? "5px solid black" : "5px solid white",borderRadius: "20px"}}>
      <div className="flex h-full flex-col">
        {
          !d.data.isDelegatedNode && <>
            <div className="flex-1">
              {d.depth === 1 ? <LifeDivision d={d} onHover={onHover}/> : <Other onHover={onHover} d={d} setCurrentNodeToRank={setCurrentNodeToRank} copiedNodes={copiedNodes}/>}
            </div>
          </>
        }
        {
          d.data.isDelegatedNode && <>
             <div className="bg-white p-2 flex items-start">
                <p className="flex-1 text-gray-400 font-bold max-h-[100px] overflow-auto">{d.data.objective}</p>
                {
                  d.data?.delegatedTo[0]?.isRootOfDelegation && <img title="Cut Node" onClick={handleCutNode} className="w-7 h-7 rounded-md cursor-pointer" src="https://cdn-icons-png.flaticon.com/512/0/382.png" alt="cut" />
                }
              </div>
          </>
        }
      </div>
    </div>
  );
}

export default Node;



interface LifeDivisionProps {
  d: {
    data: {
      _id: any;
      objective: string; // Adjust type based on your actual data structure
      // Add other properties if needed
    };
    depth: number; // Assuming depth is a number, adjust if needed
  };
  onHover: boolean;
}

const LifeDivision: React.FC<LifeDivisionProps> = ({ d ,onHover}) => {
  const dispatch:any = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(setUpdateNodeModalOpen(true));
    dispatch(setNodeIdToBeEdited(d.data?._id));
  };


  const handleOnTextClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
      const txt = e.currentTarget.innerText;
      if (txt) {
        navigator.clipboard.writeText(txt);
        toast.success("Copied!");
      }
      return;
  };

  return (
    <div className="flex flex-col">
      <div className="">{d.data.objective}</div>

      {
        onHover && <>
          <div className="flex flex-col flex-1 pl-3 gap-3">
            <div className="flex gap-2 items-top other-info">
                  <p className="mb-0 font-bold text-gray-200">Vision: </p>
                  <div onClick={handleClick} className="flex-1 px-3 rounded-md overflow-y-auto">
                    Not Set
                  </div>
            </div>
            <div className="flex gap-2 items-top other-info">
                  <p className="mb-0 font-bold text-gray-200">Purpose: </p>
                  <div onClick={handleClick} className="flex-1 px-3 rounded-md overflow-y-auto">
                    Not Set
                  </div>
            </div>
            {window.location.hostname === "localhost" && (
                <div className="flex gap-2 items-top other-info">
                  <p className="mb-0 font-bold text-gray-200">_id: </p>
                  <div onClick={handleOnTextClick} className="flex-1 px-3 rounded-md overflow-y-auto">
                    {d.data._id}
                  </div>
                </div>
              )}
          </div>
        </>
      }
      
    </div>
  );
};

interface OtherProps {
  d: any;
  setCurrentNodeToRank: any;
  copiedNodes: string[];
  onHover: boolean;
}

const Other: React.FC<OtherProps> = ({onHover,d,setCurrentNodeToRank,copiedNodes}) => {
  const { data } = d;
  const dispatch:any = useDispatch();
  const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
  const navigatePointerNodeId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);
  const nodeDashboardData = useSelector((state: RootState) => state.globalStates.nodeDeshboardData);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId);
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const isShowDeeperInsights = useSelector((state: RootState) => state.globalStates.isShowDeeperInsights);
  const [showChallengeDetail, setShowChallengeDetail] = useState<boolean>(false);
  const XLScoreRef = useRef(null);
  const flowScoreRef = useRef(null);
  const isMobile = useIsMobile();
  const [isShowOnMobile, setIsShowOnMobile] = useState(false);
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const {autoPlayTask} = useAutoPlayTask();
  

  const handleActionNow = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      
      if(d.data.isDefered){
        const ids = d.descendants().map((item:any) => item.data._id);
        dispatch(unDeferDescendantsTasks(ids,ifUnDeferSuccess));
        return;
      }
      else {
        ifUnDeferSuccess();
      }

    
      function ifUnDeferSuccess(isUndefer?:boolean){

          if (currentTask?.startTime) {
            const funcs = {
                preRun: () => {},
                runIfSuccess: () => {},
                runIfFails: () => {}
            }
            dispatch(pauseTask({
              nodeId: currentTask._id,
              endTime: new Date(),
              isPause: false,
              funcs
            }));
            dispatch(setStartTime(null));
            console.log("Task has been paused here");
          }

          // create arry of parent objective from item to project 
          let parentObjectives = [];
          let tempNode = d.parent;

          while (tempNode.depth > 2) {
              parentObjectives.push(tempNode.data);
              tempNode = tempNode.parent;
          }

          if(isUndefer){
            d.descendants().forEach((item:any) => {
              item.data.isDefered = false;
            });
          }
          
          // find current task and paretn objective 
          const result = getCurrentTask(d.data,parentObjectives);
          
          dispatch({ type: SET_IS_PAUSED, payload: true });
          dispatch(setCurrentTask(result.currentTask));
          dispatch(setCurrentTasksParents(result.parentObjectives));

          if(result.currentTask){
            autoPlayTask(result.currentTask);
          }
          
          if(!manuallyScheduleNodeId || manuallyScheduleNodeId[manuallyScheduleNodeId.length-1] !== d.data._id){
            if(manuallyScheduleNodeId){
              dispatch(setManuallyScheduledNodeId([...manuallyScheduleNodeId,d.data._id]));
            }
            else {
              dispatch(setManuallyScheduledNodeId([d.data._id]));
            }
          }
      }
  };

  const handleUnDefer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    // if (d.children === undefined) {
      // dispatch(unDeferTask(d.data.deferedInheritedFrom));
    // } 
    // else {
      const ids = d.descendants().map((item:any) => item.data._id);
      dispatch(unDeferDescendantsTasks(ids));
    // }
  };

  const handleUnCompleteRecurringTask = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const isConfirm = window.confirm("Do You Want to Un Complete This");

    if (isConfirm) {
      if (d.data.recurring?.days && d.data.recurring.days.length > 0) {
        dispatch(unCompleteRecurringTask(d.data._id));
      } 
      else {
        dispatch(updateNode({ ...d.data, isCompleted: false },()=>{},()=>{}));
      }
    }
  };

  const handleAddChallenge = (e: React.MouseEvent<HTMLButtonElement>,type: "day" | "week") => {
    e.stopPropagation();

    dispatch(addChallenge({ type, node: d.data._id }));
    setShowChallengeDetail(false);
  };

  const handleAddRoutine = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(addRoutine({ node: d.data._id }));
    setShowChallengeDetail(false);
  };

  const handleAddPriority = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(addPriority({ node: d.data._id }));
    setShowChallengeDetail(false);
  };

  const handleUpdateClick = (e:any) => {
    e.stopPropagation();
    dispatch(setParentNodeObjectiveAndType(d.parent));
    dispatch(setUpdateNodeModalOpen(true));
    dispatch(setNodeIdToBeEdited(d.data._id));
    dispatch(setIsAddingcaptureParkNode(false));
  }

  const handleCopyText = (e:any,txt: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(txt);
        toast.success("Copied!");
  }

  const handleCreateCognipulse = async (e:any) => {
    e.stopPropagation();
    dispatch(impulseAndCognipulseAndPhySelfiePopup(d.data._id,false,false,true,false));
  }

  const handleCreateImpulse = async (e:any) => {
    e.stopPropagation();
    dispatch(impulseAndCognipulseAndPhySelfiePopup(d.data._id,true,false,false,true));
  }

  const handleCreatePhySelfie = async (e:any) => {
    e.stopPropagation();
    dispatch(setNewCognipulseId(null));
    dispatch(impulseAndCognipulseAndPhySelfiePopup(d.data._id,false,true,false,false));
  }

  const handleUnlockPotentialAndDeepterInsights = (e:React.MouseEvent<HTMLButtonElement>,isUnlockPotentialNow:boolean) => {

    e.stopPropagation(); 

    if(isUnlockPotentialNow){
      dispatch(setIsShowUnlockPotentialNow(true));
      return;
    }

    dispatch(setIsShowDeeperInsights(true));
  }

  const handleShare = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(setNodeToDelegate(d.data));
      dispatch(setIsDelegateNodeModalOpen(true));
  }
  


  return (
    <>
      <div onClick={()=>{setShowMenu(false);setShowChallengeDetail(false);}}  className="flex flex-col gap-2">
        <div className="rounded-md">
          {
            onHover && <>
                <h1 className="node-type text-xs text-center">
                    {   
                      d.depth > 2 && d.data.type !== NODE_TYPES[0] &&  d.data.type.split("_").map((n:string) => capitalized(n)).join(" ")
                    }
                </h1>
            </>
          }
          
 
          {
            d.depth > 2 && <>
                <p>
                  {
                    (d.data.type && onHover) === NODE_TYPES[0]? "Project":""
                  }
                </p>
                {
                  d.data.type === NODE_TYPES[0] && onHover && <>
                    <div className="p-2 flex items-start mb-1">
                        <p className="flex-1 font-bold max-h-[100px] overflow-auto">
                          {d.data.objectiveDynamicProperties?.title}
                        </p>
                    </div>
                  </>
                }
            </>
          }

          <p>
            {
              d.depth > 2 && onHover && <>{d.data.type === NODE_TYPES[0]? "Mission":""}</>
            }
            {/* {
              d.depth === 2 && onHover && "Categories of Improvement"
            } */}
          </p>
          <div className="flex items-start">
            <p className="flex-1 font-bold max-h-[100px] overflow-auto">{d.data.objective}</p>
            {/* {
              onHover && <img onClick={handleUpdateClick} className="w-7 h-7 rounded-md cursor-pointer" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrrubJPmPOe0-okTPlS9EqtIPITAHDPAwF8Q&s" alt="pencil"/>
            } */}
            {
              onHover && <EditSVG onClick={handleUpdateClick} color={getTextColorForBackground(adjustBrightness(d.data))} width={20} height={20}/>
            }
          </div>
        </div>

        {
          onHover && <>
          {
          d.depth === 2 && <>
              <h1>Purpose</h1>
              <div className="p-2 flex items-start">
                {(d.data.purpose && d.data.purpose.length > 0)? formatPurpose(arrayToObject(d.data.purpose)):""}
              </div>
          </>
        }
        {
          d.depth >= 3 && <div className="flex items-center gap-2">
              <span className="text-sm">
                {`${parseFloat(`${data.completedPortion * 100}`).toFixed(0)}%`}
              </span>
              <div className="flex-1">
                <ProgressBar 
                  progressColor={getTextColorForBackground(adjustBrightness(d.data))} 
                  backgroundColor={adjustBrightness({...d.data})} 
                  labelColor={adjustBrightness({...d.data,color: "#9ca3af"})} 
                  height={18} 
                  progress={data.completedPortion * 100} 
                  text={``}
                  borderRadius={10}
                />
              </div>
          </div>
        }

        {window.location.hostname === "localhost" && (
            <div className="flex gap-2 items-top other-info">
              <p className="mb-0 font-bold text-gray-200">_id: </p>
              <div onClick={(e) => handleCopyText(e,d.data._id)} className="flex-1 px-3 rounded-md overflow-y-auto">
                {d.data._id}
              </div>
            </div>
        )}

        {
          isDelegated(d)?  <>
            <div className="flex gap-2 items-top other-info">
              <p className="mb-0 font-bold text-gray-200">{d.data.user === loggedInUser._id? "Delegated To:":"Delegated From:"}</p>
              <div className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
                {
                  d.data.user === loggedInUser._id? d.data.delegatedTo[0]?.user?.name:d.data?.user?.name
                }
              </div>
            </div>
          </>:""
        }

        <div className="flex justify-between">
          <div className="flex-1 flex flex-wrap gap-1">

            <NewButton nodeData={d.data} label="Action Now" onClick={handleActionNow}/>

            <div className="left-2">
              <NewButton nodeData={d.data} label="Assign" onClick={(e) => { e.stopPropagation(); setShowChallengeDetail(p => !p); }}/>
      

              {showChallengeDetail && (
                <>
                  <div className="flex flex-col gap-2 mt-2 bg-white p-2 rounded-md">
                    <NewButton nodeData={d.data} label="Daily Challenge" onClick={(e)=>handleAddChallenge(e,"day")}/>
                    <NewButton nodeData={d.data} label="Weekly Challenge" onClick={(e)=>handleAddChallenge(e,"week")}/>
                    <NewButton nodeData={d.data} label="Routine" onClick={handleAddRoutine}/>
                    <NewButton nodeData={d.data} label="Priority" onClick={handleAddPriority}/>
                  </div>
                </>
              )}
            </div>

            
            <NewButton nodeData={d.data} label="" icon={<ShareSVG color={adjustBrightness(d.data)}/>} onClick={handleShare}/>

            {
              !d.children && <NewButton nodeData={d.data} label="Cognipulse" onClick={handleCreateCognipulse}/>
            }
            {
              !d.children && <NewButton nodeData={d.data} label="Impulse" onClick={handleCreateImpulse}/>
            }
            {
              !d.children && <NewButton nodeData={d.data} label="Psy-Selfie" onClick={handleCreatePhySelfie}/>
            }
            {
              d.data.isDefered && <NewButton nodeData={d.data} title="Un-Defer Task" label="D" onClick={handleUnDefer}/>
            }
            {
              d.data.isCompleted && <NewButton nodeData={d.data} label={`${d.data.isTempCompleted ? "Temp" : ""} ${d.data.isRecurringCompleted ? "Recurring" : ""} Completed`} onClick={handleUnCompleteRecurringTask}/>
            }
            {
              d.data.isDeleted && <NewButton nodeData={d.data} variant="danger" label="Deleted" disabled onClick={()=>{}}/>
            }
            {
              d.data._id === cutNodeId && <NewButton nodeData={d.data} label="Cut" disabled onClick={()=>{}}/>
            }
            {
              d.data._id === copyNodeId && <NewButton nodeData={d.data} label="Copied" disabled onClick={()=>{}}/>
            }
            
          </div>
          {d.children && (
            <p className="text-[12px]  font-bold text-end mb-0">
              TEPP: {parseFloat(`${d.data.estimatedProductivityPoints}`).toFixed(0)}
            </p>
          )}

          {d.children && (
            <p className="ml-5 text-[12px] font-bold text-end mb-0">
              TED: {d.data.totalEstimatedTaskDuration}
            </p>
          )}

          {!d.children && (
            <p className="text-[12px] font-bold text-end mb-0">
              EPP: {parseFloat(`${d.data.estimatedProductivityPoints}`).toFixed(0)}
            </p>
          )}

          {!d.children && (
            <p className="ml-5 text-[12px]  font-bold text-end mb-0">
              ED: {d.data.estimatedTaskDuration}
            </p>
          )}
        </div>  
          </>
        }

        <div className="">
          <CrudButtons onHover={onHover} copiedNodes={copiedNodes} d={d} setCurrentNodeToRank={setCurrentNodeToRank} showMenu={showMenu} setShowMenu={setShowMenu}/>
        </div>

        {
          onHover && <>
              {/* dashboard  */}
              <div className="w-full">
                  {
                    isMobile && navigatePointerNodeId === d.data._id && <NewButton onClick={(e)=>{e.stopPropagation();setIsShowOnMobile((p)=> !p)}} label={`${isShowOnMobile? "Hide":"Show"} Data`}/>
                  }
                  {
                    (((isMobile && isShowOnMobile) || !isMobile) && d.depth > 1 && navigatePointerNodeId === d.data._id && nodeDashboardData && !isShowDeeperInsights) && <>
                    <div className="flex flex-wrap border rounded-md bg-white/30">
                        <div className="w-full">
                          <h1 className="text-center py-2 font-bold text-md text-gray-600">Cognitive & Flow Potential @ {moment(nodeDashboardData.lastCognipulseDate).format("DD/MM/YY HH:mm")}</h1>
                          <div className="flex justify-center pb-2 border-b border-gray-300"><NewButton onClick={(e)=> handleUnlockPotentialAndDeepterInsights(e,false)} label="Deeper Insights"/></div>
                        </div>
                        <div className="border-r border-gray-300">
                          <h2 className="text-gray-400 font-bold text-center">Cognitive</h2>
                          <div ref={XLScoreRef} className="w-[250px]" id="xl-score-in-node">
                            <XLScoreChart containerRef={XLScoreRef} score={nodeDashboardData.XLScore} change={nodeDashboardData.XLChange}/>
                          </div>
                        </div>
                        <div className="">
                          <h2 className="text-gray-400 font-bold text-center">Flow</h2>
                          <div ref={flowScoreRef} className="w-[250px]" id="flow-score-in-node">
                            <FlowScoreChart containerRef={flowScoreRef} score={nodeDashboardData.flowScore} change={nodeDashboardData.flowChange}/>
                          </div>
                        </div>
                        <div className="w-full h-[230px] border-t border-gray-300 p-2 relative">
                            <img src={streamGraph} alt="stream graph" className="w-full h-full rounded-md"/>
                            <div className="absolute top-0 left-0 w-full h-full bg-black/20 p-2 rounded-md">
                                <div className="w-full h-full flex flex-wrap gap-2 items-center justify-center">
                                    <NewButton onClick={(e)=> handleUnlockPotentialAndDeepterInsights(e,false)} label="Deeper Insights"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                  }
              </div>
          </>
        }
        
      </div>
    </>
  );
};


interface CrudButtonsProps {
  d: any;
  showMenu: any;
  setShowMenu: any;
  setCurrentNodeToRank: (node: any) => void; // Replace `any` with the actual type if possible
  copiedNodes: string[];
  onHover: boolean;
}

const CrudButtons: React.FC<CrudButtonsProps> = ({ onHover,d, setCurrentNodeToRank,showMenu, setShowMenu,copiedNodes}) => {
  const dispatch:any = useDispatch();
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId); // Replace `any` with your Redux state type
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);

  const handleCrudOperations = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowMenu(false);
    const operation = e.currentTarget.title;

    switch (operation) {
      case "Update":
        dispatch(setUpdateNodeModalOpen(true));
        dispatch(setNodeIdToBeEdited(d.data._id));
        break;
      case "Delete":
        if (window.confirm("Are You Sure")) {
          dispatch(deleteNode(d.data._id, d.data.parent));
          dispatch(setNavigatePointerNodeId(d.parent.parent.data._id));
        }
        break;
      case "Copy":
        dispatch(setCopiedNodeId(d.data._id));
        dispatch(setCopiedOrCutNodeObjective(d.data.objective));
        break;
      case "Cut":
        dispatch(setCopiedOrCutNodeObjective(d.data.objective));
        dispatch(setCutNodeId(d.data._id));
        break;
      case "Paste":
        if (cutNodeId) {
          const isCopied = copiedNodes.find(id => id === d.data._id);
          if(isCopied){
            toast.error("Cannot paste into a node that is already cut.");
            return;
          }
          dispatch(cutPasteNodes(cutNodeId, d.data._id));
          dispatch(setCutNodeId(null));
        }
        if (copyNodeId) {
          dispatch(copyPasteNodes(copyNodeId, d.data._id));
          // dispatch(setCopiedNodeId(null));
        }
        break;
      case "Rank":
        setCurrentNodeToRank(d);
        break;
      case "Un Complete All Children":
        setShowMenu(false);
        const arrOfIds = d.descendants().filter((item:any) => item.data.isCompleted).map((item:any) => item.data._id);
        if (arrOfIds.length > 0) {
          dispatch(unCompleteCompletedNodes(arrOfIds));
        }
        break;
      default:
        break;
    }
  };

  const handleCreateNode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setParentNodeObjectiveAndType(d));
    dispatch(setObjectiveArr(null));
    dispatch(setDeadline(null));
    setShowMenu(false);
    dispatch(setAddNodeModalOpen(true));
    dispatch(setParentIdOfNodeTobeCreated(d.data._id));
    dispatch(setDepthOfNodeTobeCreated(d.depth + 1));
    dispatch(setIsAddingcaptureParkNode(false));
  };

  const handleCreateTree = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    
    dispatch(setParentIdOfNodeTobeCreated(d.data._id));
    dispatch(setIsAddTreeModalOpen(true));
  }

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const buttons = [
    {
      label: <Icons.MenuWhite/>,
      operation: "Menu",
    },
    {
      label: <Icons.PlausWhite/>,
      operation: "Create",
    },
    {
      label: "Delete",
      operation: "Delete",
    },
    {
      label: "Copy",
      operation: "Copy",
    },
    {
      label: "Cut",
      operation: "Cut",
    },
    {
      label: "Paste",
      operation: "Paste",
    },
    {
      label: "Rank",
      operation: "Rank",
    },
    {
      label: "Un Complete All Children",
      operation: "Un Complete All Children",
    },
  ];

  return (
    <>
      <div className="crud-buttons">
          {
            onHover && <>
            <div className="w-full flex justify-between">
                <NewButton 
                    nodeData={d.data} 
                    onClick={handleMenuClick} 
                    style={{width: "40px",height: "40px"}} 
                    label="" 
                    icon={<MenuSVG color={adjustBrightness(d.data)} width={25} height={25}/>} 
                    rounded
                />
                
                <div className="right flex flex-wrap gap-1">
                  {
                    loggedInUser?.role === "admin" && <>
                        <NewButton nodeData={d.data} onClick={handleCreateTree} style={{width: "40px",height: "40px"}} label="" icon={<AddNodeSVG color={adjustBrightness(d.data)} width={25} height={25}/>} rounded/>
                    </>
                  }
                  
                  <NewButton nodeData={d.data} onClick={handleCreateNode} style={{width: "40px",height: "40px"}} label="" icon={<PlusSVG color={adjustBrightness(d.data)} width={25} height={25}/>} rounded/>
                </div>
            </div>
            </>
          }
          

          {showMenu && (
              <div className="w-[240px] mt-2 p-2 text-gray-600 bg-gray-200 min-w-[190px] bottom-[60px] flex flex-col items-start rounded-md">
                {buttons.slice(2, 8).map(item => (
                    <NewButton
                      label={item.label}
                      onClick={handleCrudOperations}
                      key={item.operation}
                      title={item.operation}
                      variant="gray"
                      style={{borderRadius: "0"}}
                      full
                    />
                ))}
              </div>
           )}
      </div>
    </>
  );
};


function isDelegated(d:any){
  return d.data.delegatedTo && d.data.delegatedTo.length
}



















