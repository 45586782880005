import { useEffect, useState } from "react";
import {deleteUserByIdAdmin, getUserByIdAdmin, IUser, IUserEdit, updateUserByIdAdmin } from "../../../../store/actions";
import { toast } from "react-toastify";
import { AddTree } from "./AddTree";
import { NewButton } from "../../../../components/Buttons/NewButton";



interface EditUserModalProps {
    setIsShowUserEditModal: any;
    idOfUserToEdit: string;
    setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
}


export function EditUserModal({setIsShowUserEditModal,idOfUserToEdit,setUsers}:EditUserModalProps) {
    const [user, setUser] = useState<null | IUser>(null);
    const [isAddingTree, setIsAddingTree] = useState(false);
    

    const handleUserChange = (e: any) => {
        if(e.target.value === "isApprovedByAdmin" || e.target.value === "hasAccess"){
            setUser((prev:any) => {
                return {
                    ...prev,
                    [e.target.value]: e.target.value === "isApprovedByAdmin"? true:e.target.checked
                }
            })
            return;
        }
        setUser((prev:any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const userData: IUserEdit = {
            name: user?.name || "",
            email: user?.email || "",
            role: user?.role || "user",
            hasAccess: user?.hasAccess as boolean,
            isApprovedByAdmin: user?.isApprovedByAdmin as boolean
        }
        
        const res = await updateUserByIdAdmin(idOfUserToEdit,userData);

        if(res.success){
            setUsers(prev => {
                return prev.map(preUser => preUser._id === idOfUserToEdit? {...preUser,...res.user}:preUser)
            })
            setIsShowUserEditModal(false);
        }
        else {
            toast.error(res.message);
        }
    }

    const handleDelete = async (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if(window.confirm("Deleting user account is permanent and cannot be undone")){
            const res = await deleteUserByIdAdmin(idOfUserToEdit)
            
            if(res.success){
                setUsers(prev => {
                    return prev.filter(preUser => preUser._id !== idOfUserToEdit);
                })
                setIsShowUserEditModal(false);
            }
            else {
                toast.error(res.message);
            }
        }
    }

    useEffect(() => {
        (async () => {
            if(idOfUserToEdit){
                const res = await getUserByIdAdmin(idOfUserToEdit);
                
                if(res.success){
                    setUser(res.user)
                }
            }
        })()

        return () => {
            
        };
    }, [idOfUserToEdit]);


    return (
        <div className='mx-auto px-4 py-8 sm:px-8'>
            <div className="flex items-center justify-between pb-6">
                    <div>
                        <h2 className="font-semibold text-gray-700">Edit User</h2>
                        <span className="text-xs text-gray-500">You can edit any details of this user</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="ml-10 space-x-8 lg:ml-40">
                        <NewButton
                            onClick={(e)=> {e.preventDefault();setIsShowUserEditModal(false);}}
                            label="Back"
                        />
                        </div>
                    </div>
                    </div>

                    {
                        !isAddingTree && <div className="overflow-y-hidden rounded-lg border">
                                            {
                                                !user && <>Loadin..</>
                                            }
                                            {
                                                user && <>
                                                        <div className="w-full">
                                                            <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                                                            <div className="flex justify-end">
                                                                <NewButton
                                                                    onClick={handleDelete}
                                                                    label="Delete"
                                                                    variant="danger"
                                                                />
                                                            </div>
                                                            <form className="flex flex-wrap gap-[2%]">
                                                                <div className="mb-4 w-[49%]">
                                                                    <label htmlFor="name" className="mb-2.5 block font-medium text-black dark:text-white">
                                                                        Name
                                                                    </label>
                                                                    <div className="relative">
                                                                        <input
                                                                        value={user.name}
                                                                        type="text"
                                                                        name="name"
                                                                        id="name"
                                                                        onChange={handleUserChange}
                                                                        placeholder="Enter your Full Name"
                                                                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-4 w-[49%]">
                                                                    <label htmlFor="email" className="mb-2.5 block font-medium text-black dark:text-white">
                                                                        Email
                                                                    </label>
                                                                    <div className="relative">
                                                                        <input
                                                                        value={user.email}
                                                                        type="email"
                                                                        name="email"
                                                                        id="email"
                                                                        onChange={handleUserChange}
                                                                        placeholder="Enter your email"
                                                                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-4 w-[49%]">
                                                                    <label htmlFor="role" className="mb-2.5 block font-medium text-black dark:text-white">
                                                                        Role
                                                                    </label>
                                                                    <div className="relative">
                                                                        <select onChange={handleUserChange} value={user.role} name="role" id="role" className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                                                                            <option value="user">User</option>
                                                                            <option value="admin">Admin</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="mb-4 w-[49%] flex gap-4 items-center">
                                                                    <div className="">
                                                                        <div className="flex items-center mb-4">
                                                                            <input 
                                                                                onChange={handleUserChange}
                                                                                checked={user.hasAccess}
                                                                                id="hasAccess" 
                                                                                type="checkbox" 
                                                                                value="hasAccess" 
                                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                            />
                                                                            <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Has Access</label>
                                                                        </div>
                                                                        <div className="flex items-center mb-4">
                                                                            <input 
                                                                                onChange={handleUserChange} 
                                                                                checked={user.isApprovedByAdmin}
                                                                                id="isApprovedByAdmin" 
                                                                                type="checkbox" 
                                                                                value="isApprovedByAdmin" 
                                                                                className="text-nowrap w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                            />
                                                                            <label htmlFor="default-checkbox" className="text-nowrap ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">User approved</label>
                                                                        </div>
                                                                    </div>

                                                                    <NewButton
                                                                        onClick={(e)=>{e.preventDefault();setIsAddingTree(true)}}
                                                                        label="Add Tree"
                                                                        variant="gray"
                                                                    />
                                                                    
                                                                </div>

                                                                <div className="mb-5 w-full">
                                                                    <NewButton
                                                                        onClick={handleSubmit}
                                                                        label="Save"
                                                                        full
                                                                    />
                                                                </div>
                                                            </form>
                                                            </div>
                                                        </div>
                                                </>
                                            }
                                        </div>
                    }
                    {
                        isAddingTree && <AddTree idOfUserToEdit={idOfUserToEdit} setIsAddingTree={setIsAddingTree}/>
                    }
        </div>
    )
}



