import React, { useEffect, useState } from 'react'
import { Modal } from '../Index'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Triangle } from '../../Triangle';
import { useStartEndTime } from '../hooks/useStartEndTime';
import { capitalized } from '../../../utils';
import { NewButton } from '../../Buttons/NewButton';
import { useCreateOrUpdateNode } from '../hooks/useCreateOrUpdateNode';
import { InputField, PreView } from '../components';


interface IMissionModal {
  setIsOpen: any;
  setNodeObj: any;
  objectiveDynamicProperties?: any;
  isUpdating?: boolean;
}


const keyList =  ["taskActionVerbs","specificActivities","deliverableOutcomes"];
const screenNames = ['WELCOME',"PROJECT_TITLE","WHERE","WHAT","WHO","WHEN","WOW"] as const;
type TScreenName = typeof screenNames[number];

interface ITimeSpent {
  startTime: Date,
  endTime: Date,
  screenName: TScreenName;
}

interface Idata {
  taskActionVerbs: string;
  taskActionVerbsFillerWord: string;
  specificActivities: string;
  specificActivitiesFillerWord: string;
  deliverableOutcomes: string;
  deliverableOutcomesFillerWord: string;
  estimatedTaskDuration: string;
  type: "TASK";
  rating: number;
  timeSpent: ITimeSpent[];
}

type TypeSetData = React.Dispatch<React.SetStateAction<Idata>>

export const TaskModal: React.FC<IMissionModal> = ({isUpdating,setIsOpen,setNodeObj,objectiveDynamicProperties})  => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [data, setData] = useState<Idata>({
    taskActionVerbs: "",
    taskActionVerbsFillerWord: "",
    specificActivities: "",
    specificActivitiesFillerWord: "",
    deliverableOutcomes: "",
    deliverableOutcomesFillerWord: "",
    estimatedTaskDuration: "",
    type: "TASK",
    rating: 50,
    timeSpent: []
  });


 
  useEffect(() => {
    if(objectiveDynamicProperties){
      setData({
        taskActionVerbs: objectiveDynamicProperties.taskActionVerbs || "",
        taskActionVerbsFillerWord: objectiveDynamicProperties.taskActionVerbsFillerWord || "",
        specificActivities: objectiveDynamicProperties.specificActivities || "",
        specificActivitiesFillerWord: objectiveDynamicProperties.specificActivitiesFillerWord || "",
        deliverableOutcomes: objectiveDynamicProperties.deliverableOutcomes || "",
        deliverableOutcomesFillerWord: objectiveDynamicProperties.deliverableOutcomesFillerWord || "",
        estimatedTaskDuration: objectiveDynamicProperties.estimatedTaskDuration || "",
        rating: objectiveDynamicProperties.rating || 50,
        type: "TASK",
        timeSpent: objectiveDynamicProperties.timeSpent || []
      });
    }
  }, [objectiveDynamicProperties]);

  return (
    <>
        <Modal
          saveBtnText="Create"
          onSubmit={()=>{}}
          isOpen={true}
          setIsOpen={setIsOpen}
          hideBtns={true}
          onEscape={() => {setIsOpen(false)}}
          header
        >
          <> 
            {
              currentScreen === 1 && <Welcome data={data} setCurrentScreen={setCurrentScreen} setData={setData}/>
            }
            {
              currentScreen === 2 && <DeliverableOutcomes data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 3 && <SpecificActivities data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 4 && <TaskActionVerbs data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 5 && <EstimatedDuration data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 6 && <Rating isUpdating={isUpdating} setNodeObj={setNodeObj} setIsOpen={setIsOpen} data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }   
          </>
        </Modal>
    </>
  )
}


interface ITitle {
  data: Idata;
}

const Title: React.FC<ITitle> = ({data}) => {
  return <h2 className='text-center font-bold text-xl text-gray-400'>Craft Your Task!</h2>
}


export const ParentNodeObjective = () => {
  const parentNodeObjective = useSelector((state: RootState) => state.globalStates.parentNodeObjective);
  const parentNodeType = useSelector((state: RootState) => state.globalStates.parentNodeType);


  return <>
      <div className="">
        <small className='text-gray-400'>{parentNodeType.split("_").map((n:string) => capitalized(n)).join(" ")}</small>
        <div className="border-2 font-bold p-2">
            <p className='text-gray-400 text-center'>
                {parentNodeObjective || "Create a Key Result First"}
            </p>
        </div>
      </div>
  </>
}





interface INextAndBackButton {
  nextClick: any;
  backClick: any;
  backText?: any;
  nextText?: any;
}

const NextAndBackButton: React.FC<INextAndBackButton> = ({nextClick,backClick,backText,nextText}) => {


  return <>
      <div className="flex gap-4">
          <NewButton onClick={backClick} full label={`${backText || "Back"}`}/>
          <NewButton onClick={nextClick} full label={`${nextText || "Next"}`}/>
      </div>
  </>
}


interface IDropDown {
  list: string[],
  value: string;
  setData: any;
  isShow: boolean;
  readOnlyList?: string[];
}

const DropDown: React.FC<IDropDown> = ({list,value,setData,isShow,readOnlyList=[]}) => {
  list = list.filter(item => item.toLowerCase().includes(value.toLowerCase()));
 
  
  if(!isShow){
    return <></>;
  }

  const handleClick = (e:React.MouseEvent<HTMLDivElement>,item:string) => {
    if(readOnlyList.includes(item)){
      return;
    }
    setData(item);
  }
  
  if(list.length === 0){
    return null
  }

  return <div className='absolute top-full left-0 border bg-white w-full rounded-md z-[10]'>
            <div className="w-full h-full flex flex-col max-h-[150px] overflow-auto py-2 text-gray-400">
                 {
                    list.map((item,idx) => <div onClick={(e) => handleClick(e,item)} key={idx} className={`${readOnlyList.includes(item)? "bg-gray-400 text-white no-hide":"hover:bg-gray-100 cursor-pointer"}  px-4 py-1 flex items-center`}>{item}</div>)
                 }
            </div>
        </div>
}



interface IWelcome {
  setCurrentScreen: any;
  setData: TypeSetData;
  data: Idata;
}


const Welcome: React.FC<IWelcome> = ({setCurrentScreen,setData,data}) => {
  useStartEndTime(setData,"WEOCOME");



  return <>
      <Title data={data}/>

      <p className='text-gray-400 text-center'>
          Your Key Result is your focus.
      </p>
      
      <div className="">
          <ParentNodeObjective/>

          <Triangle topAngle={50} text='Actionable,Measurable Result that drives Progress'/>

          <div className="border flex flex-col items-center py-2">
            <h1 className='text-3xl font-bold  text-gray-400'>Task</h1>
            <small className=' text-gray-600'>e.g Streamline Product Development Process</small>
          </div>
      </div>

      <p className='text-gray-400'>
          This process is about crafting a clear,
          impactful, actionable task that directly
          contributes to achieving your Key Result.
      </p>

      <NewButton onClick={() => setCurrentScreen(2)} full label='Next'/>
  </>
}


interface IChange {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const TaskActionVerbs: React.FC<IChange> = ({setCurrentScreen,data,setData}) => {
  const [readOnlyList] = useState([
    "Strategic Actions",
    "Creative Actions",
    "Analytical Actions",
    "Execution-Oriented Actions",
    "Communication Actions",
    "Collaboration-Focused Actions",
    "Marketing and Sales Actions",
    "Learning and Development Actions",
    "Monitoring and Improvement Actions",
    "Administrative and Process Actions",
    "Problem-Solving Actions"
  ])
  const [list] = useState([
       "Design", "Build", "Execute", "Develop", "Optimise", "Create",
        "Lead", "Launch", "Test", "Scale"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"TaskActionVerbs".toUpperCase());

  return <>

      <div>
          <p className='text-gray-400 text-center'>What action will you take?</p>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          <PreView currentScreen="taskActionVerbs" data={data} setData={setData} keyList={keyList}/>

          <div className='h-4'/>
          {/* <Triangle text='' topAngle={5}/> */}

          <InputField
              labelText="What Action should you take"
              smallText=""
              name='taskActionVerbs'
              placeHolder=''
              id='taskActionVerbs'
              value={data.taskActionVerbs}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,taskActionVerbs: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              readOnlyList={readOnlyList}
              value={data.taskActionVerbs}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,taskActionVerbs: str}})}
              isShow={isHighlighted}
            />
          </InputField>  
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(3)}
          nextClick={() => setCurrentScreen(5)}
      />
  </>
}


interface IImpact {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const SpecificActivities: React.FC<IImpact> = ({setCurrentScreen,data,setData}) => {
  const [readOnlyList] = useState([
    "Design & Development Activities",
    "Documentation & Writing Activities",
    "Analysis & Research Activities",
    "Presentation & Communication Activities",
    "Creative & Content Activities",
    "Process & Workflow Activities",
    "Marketing & Sales Activities",
    "Product & Technology Activities",
    "Learning & Development Activities",
    "Operations & Administration Activities",
    "Metrics & Performance Activities"
  ])
  const [list] = useState([
    "Marketing Campaign", "Sales Funnel", "Product Prototype", "Team Onboarding",
    "Financial Plan", "Customer Survey", "Partnership Agreement",
    "Website Optimisation", "Social Media Strategy", "Investor Pitch"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"SpecificActivities".toUpperCase());


  return <>
      <div>
          <p className='text-gray-400 text-center'>What specific activity will you focus on?</p>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          <PreView currentScreen="specificActivities" data={data} setData={setData} keyList={keyList}/>

          <div className='h-4'/>
          {/* <Triangle text='' topAngle={40}/> */}

          <InputField
              labelText="What specific activity do you want to focus on"
              smallText=""
              name='specificActivities'
              placeHolder=''
              id='specificActivities'
              value={data.specificActivities}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,specificActivities: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              readOnlyList={readOnlyList}
              value={data.specificActivities}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,specificActivities: str}})}
              isShow={isHighlighted}
            />
          </InputField> 
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={() => setCurrentScreen(4)}
      />
  </>
}



interface ITime {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const DeliverableOutcomes: React.FC<ITime> = ({setCurrentScreen,data,setData}) => {
  const [readOnlyList] = useState([
    "Product & Feature Outcomes",
    "User-Centric Outcomes",
    "Stakeholder & Collaboration Outcomes",
    "Marketing & Sales Outcomes",
    "Analysis & Research Outcomes",
    "Performance & Reporting Outcomes",
    "Learning & Development Outcomes",
    "Project Management & Planning Outcomes",
    "Operations & Administrative Outcomes",
    "Impact & Value Outcomes"
  ])
  const [list] = useState([
      "Customer Acquisition", "Revenue Growth", "Market Share", "Lead Generation",
      "Brand Awareness", "Team Alignment", "Operational Efficiency",
      "Product Delivery", "Cost Savings", "User Engagement"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"DeliverableOutcomes".toLowerCase());

  return <>
      <div className="">
          <p className='text-gray-400 text-center'>What will be the deliverable or outcome of this activity?</p>

          <ParentNodeObjective/>
          
          <Triangle text='big impact on' topAngle={50}/>

          <PreView currentScreen="deliverableOutcomes" data={data} setData={setData} keyList={keyList}/>

          {/* <Triangle text='' topAngle={75}/> */}
          <div className='h-4'/>

          <InputField
              labelText='What is the outcome or deliverable?'
              smallText=""
              name='deliverableOutcomes'
              placeHolder='Enter deliverable or outcome'
              id='deliverableOutcomes'
              value={data.deliverableOutcomes}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,deliverableOutcomes: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
            >
              <DropDown 
                list={list} 
                readOnlyList={readOnlyList}
                value={data.deliverableOutcomes}
                setData={(str: string) => setData((prev:Idata) => {return {...prev,deliverableOutcomes: str}})}
                isShow={isHighlighted}
              />
          </InputField>
      </div>


      <NextAndBackButton
          backClick={() => setCurrentScreen(1)}
          nextClick={() => setCurrentScreen(3)}
      />
  </>
}


interface IEstimatedDuration {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const EstimatedDuration:React.FC<IEstimatedDuration> = ({setCurrentScreen,data,setData}) => {
  // const [readOnlyList] = useState([])
  // const [list] = useState([
  //   "15 mins",
  //   "30 mins",
  //   "60 mins",
  //   "1 hour",
  //   "2 hours",
  //   "3 hours",
  //   "1 day",
  //   "2 days",
  //   "3 days",
  // ]);
  const [, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"DeliverableOutcomes".toLowerCase());

  return <>
      <div className="">
          <p className='text-gray-400 text-center'>Estimate Task Duration</p>
          
          <PreView currentScreen='node' data={data} setData={setData} keyList={keyList}/>
          <div className="h-5"/>

          <InputField
              labelText='How long do you think this task will take to complete? Be realistic but aim for focus and efficiency.'
              smallText="Please enter time in minutes"
              name='estimatedTaskDuration'
              placeHolder='How long will it take to complete?'
              id='estimatedTaskDuration'
              value={data.estimatedTaskDuration}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,estimatedTaskDuration: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
              type="number"
            />
              {/* <DropDown 
                list={list} 
                readOnlyList={readOnlyList}
                value={data.estimatedTaskDuration}
                setData={(str: string) => setData((prev:Idata) => {return {...prev,estimatedTaskDuration: str}})}
                isShow={isHighlighted}
              /> */}
          {/* </InputField> */}
      </div>


      <NextAndBackButton
          backClick={() => setCurrentScreen(4)}
          nextClick={() => setCurrentScreen(6)}
      />
  </>
}


interface IRating   {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
  setIsOpen: any;
  setNodeObj: any;
  isUpdating?: boolean;
}

const Rating: React.FC<IRating> = ({isUpdating,setCurrentScreen,data,setData,setIsOpen,setNodeObj}) => {
  const {startTime} = useStartEndTime(setData,"RATING");
  const {handleCreateOrUpdateNode} = useCreateOrUpdateNode();
  const getText = (value: number) => {
     if(value < 40){
        return "It looks like this Task could be refined. What would make it more exciting or meaningful for you?";
     }
     if(value > 40 && value < 70){
        return "Does this feel aligned with your goals? You can refine it for more clarity or move forward."
     }
     return "Great job! This task looks ready to guide your next steps!";
  }



  return <div>
      <div className="">
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          {/* <input className='border border-2 p-2 text-center text-gray-400 w-full font-bold' type="text" value={finalString} onChange={(e) => setFinalString(e.target.value)}/> */}
          <PreView currentScreen="none" data={data} setData={setData} keyList={keyList}/>
      </div>
      
      <h3 className='text-gray-400 text-center mt-8'>You Task is nearly ready...</h3>
      <p className='text-gray-400 text-center mt-2'>Let's check how  inspired do you feel?</p>
      <h3 className='text-gray-400 font-bold text-center mt-2'>It makes me want to do:</h3>

      <div className="flex items-center gap-2 text-gray-400 mb-4 mt-2">
          <p className='text-nowrap'>Some Dusting.</p>
          <input type="range" min="1" max="100" value={data.rating} onChange={(e) => setData((prev: Idata) => {return {...prev,rating: Number(e.target.value)}})} className="w-full" id="myRange"/>
          <p className='text-nowrap'>A Victory Roar!</p>
      </div>

      <p className='text-gray-400 text-center'>{getText(data.rating)}</p>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={(e:React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            handleCreateOrUpdateNode({
              finalString: `${data.taskActionVerbs} ${data.taskActionVerbsFillerWord} ${data.specificActivities} ${data.specificActivitiesFillerWord} ${data.deliverableOutcomes}`,
              data,
              startTime,
              isUpdating
            });
          }}
          backText={"Refine Task"}
          nextText={"Finish"}
      />
  </div>
}





