  import moment from "moment";
  import { IUser } from "@store/actions";
import { NewButton } from "@components/Buttons/NewButton";

  interface userProps {
    user: IUser;
    index: number;
    setIsShowUserEditModal: React.Dispatch<React.SetStateAction<boolean>>
    setIdOfUserToEdit: React.Dispatch<React.SetStateAction<string>>
  }
  
  export function User({user,index,setIsShowUserEditModal,setIdOfUserToEdit}:userProps){

    const handleEditClick = (e:React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setIdOfUserToEdit(user._id);
      setIsShowUserEditModal(true);
    }
  
    return <>
        <tr>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap">{index+1}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0 rounded-full border flex justify-center items-center bg-gray-200">
                        <span className="font-2xl font-bold">{user.name[0].toUpperCase()}</span>
                      </div>
                      <div className="ml-3">
                        <p className="whitespace-no-wrap">{user.name}</p>
                      </div>
                    </div>
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <p className="whitespace-no-wrap">{user.email}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <p className="whitespace-no-wrap">{user.role}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap">{moment(user.createdAt).format("lll")}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap">{user.nodeCount}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap">{user.lastLogin? moment(user.lastLogin).format("lll"):"N/A"}</p>
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              {
                !user.hasAccess && <span className="rounded-full bg-red-200 px-3 py-1 text-xs font-semibold text-red-900">Inactive</span>
              }
              {
                user.hasAccess && <span className="rounded-full bg-green-200 px-3 py-1 text-xs font-semibold text-green-900">Active</span>
              }
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              {
                !user.isApprovedByAdmin && <span className="rounded-full bg-red-200 px-3 py-1 text-xs font-semibold text-red-900">Pending</span>
              }
              {
                user.isApprovedByAdmin && <span className="rounded-full bg-green-200 px-3 py-1 text-xs font-semibold text-green-900">Approved</span>
              }
          </td>
          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <NewButton
                  onClick={handleEditClick}
                  label="Edit"
                  full
              />
          </td>
        </tr>
    </>
  }