import React, { useEffect } from "react";

interface ITextArea {
    name: any;
    label: any;
    value: any;
    handleChange: any;
    isHeigLighted: any;
    onTextAreaClick?: any;
}



export const TextArea: React.FC<ITextArea> = ({onTextAreaClick, name, label, value, handleChange,isHeigLighted}) => {
    const myRef = React.useRef<HTMLTextAreaElement>(null);

    const onChange = (e:any) => {
      handleChange(e);
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
      const textarea = e.target;
      textarea.scrollTop = textarea.scrollHeight;
    }

    const onClick = () => {
      if(onTextAreaClick){
        onTextAreaClick();
      }
    }
  
    useEffect(() => {
      if(myRef.current && isHeigLighted){
        myRef.current.focus();
        myRef.current.style.height = 'auto';
        myRef.current.style.height = `${myRef.current.scrollHeight}px`;
        const textarea = myRef.current;
        textarea.scrollTop = textarea.scrollHeight;
      }
    }, [isHeigLighted]);
  


  
    return <>
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label
            className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
            htmlFor={name}
          >
            {label}
          </label>
        </div>
        <div className="md:w-2/3">
          <textarea
            className="bg-gray-200 overflow-hidden appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder=""
            name={name}
            id={name}
            value={value}
            onChange={onChange}
            autoFocus={isHeigLighted}
            ref={myRef}
            onClick={onClick}
          />
        </div>
      </div>
    </>
}
  