import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  NavBar,
  SideBar
} from './layout';

import {
  Login,
  Signup,
  PageNotFound,
  SetWorkingTime,
  ICICLETree,
  Achievement,
  SetObjective,
  PastDeadlines,
  CapturePark,
  TrashCan,
  ExDuration,
  XLRate,
  ApprovalPendingByAdmin,
  NoAccess
} from './pages';

import {
  getLoggedInUser,
  getTreeData
} from "./store/actions";

import {
  Modals,
  PrivateRoute
} from './components';

import {generateFireBaseToken} from "./firebasePushNotification";
import { RootState } from './store/store';
// import { isMobile } from 'react-device-detect';
import { CursorText } from './components/CursorText';
import { AdminDashboard } from './pages/Admin/Index';
import PageTitle from './pages/Admin/components/PageTitle';
import Tables from './pages/Admin/pages/Tables';
import Calendar from './pages/Admin/pages/Calendar';
import Profile from './pages/Admin/pages/Profile';
import FormElements from './pages/Admin/pages/Form/FormElements';
import FormLayout from './pages/Admin/pages/Form/FormLayout';
import Settings from './pages/Admin/pages/Settings';
import Chart from './pages/Admin/pages/Chart';
import Alerts from './pages/Admin/pages/UiElements/Alerts';
import Buttons from './pages/Admin/pages/UiElements/Buttons';
import SignIn from './pages/Admin/pages/Authentication/SignIn';
import SignUp from './pages/Signup/Index';
import Users from './pages/Admin/pages/Users';
import { useIsMobile } from '@hooks/useIsMobile';




const App: React.FC = () => {
  const dispatch:any = useDispatch();
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId);
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const isShowSidebar = useSelector((state: RootState) => state.globalStates.isShowCaptureUIOnMobile);
  const isAdminDashboardView = useSelector((state: RootState) => state.globalStates.isAdminDashboardView);
  const currentView = useSelector((state: RootState) => state.globalStates.currentView);
  const mainContainer: any = useRef();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!loggedInUser) {
      dispatch(getLoggedInUser());
    }
    if (loggedInUser) {
      dispatch(getTreeData());
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
      generateFireBaseToken();
  }, []);

 
 

  return <>
      <BrowserRouter>
              {
                !isAdminDashboardView && <NavBar />
              }
              <div className="w-full min-h-[calc(100vh-64px)] flex relative">
                  {
                    (currentView === "both" || isMobile) && <>
                    <div style={{left: isShowSidebar? "0":"-100%"}} className="max-[500px]:z-[1] max-[500px]:absolute max-[500px]:left-0 max-[500px]:w-full min-[500px]:w-[240px] min-[500px]:min-w-[240px] min-[500px]:overflow-auto h-[calc(100vh-64px)] bg-gray-300">
                        <SideBar/>
                    </div>
                    </>
                  }
                  {
                    currentView === "focus" && <>
                    <div className="absolute top-0 left-0 z-[100] w-[100vw] h-[calc(100vh-64px)] bg-gray-400">
                        <SideBar/>
                    </div>
                    </>
                  }
                  
                  <div ref={mainContainer} className="flex-1 h-[calc(100vh-64px)]">
                      <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path='/signup' element={<Signup />} />
                        <Route path='/set-working-time' element={<PrivateRoute><SetWorkingTime/></PrivateRoute>} />
                        <Route path='/achievement' element={<PrivateRoute><Achievement /></PrivateRoute>} />
                        <Route path='/' element={<PrivateRoute><ICICLETree mainContainer={mainContainer}/></PrivateRoute>} />
                        <Route path='/capture-park' element={<PrivateRoute><CapturePark mainContainer={mainContainer} /></PrivateRoute>} />
                        <Route path='/set-objective' element={<PrivateRoute><SetObjective /></PrivateRoute>} />
                        <Route path='/past-deadlines' element={<PrivateRoute><PastDeadlines /></PrivateRoute>} />
                        <Route path='/trash-can' element={<PrivateRoute><TrashCan /></PrivateRoute>} />
                        <Route path='/ex-duration/:taskId' element={<PrivateRoute><ExDuration /></PrivateRoute>} />
                        <Route path='/xlrate' element={<PrivateRoute><XLRate /></PrivateRoute>} />
                        <Route path='/admin-approval-pending' element={<PrivateRoute noApproval><ApprovalPendingByAdmin/></PrivateRoute>} />
                        <Route path='/in-active' element={<PrivateRoute noApproval><NoAccess/></PrivateRoute>} />
                        <Route path='admin-dashboard' element={<AdminDashboard/>}>
                            <Route
                              path="users"
                              element={
                                <>
                                  <PageTitle title="10X Dashboard | Users" />
                                  {/* <ECommerce /> */}
                                  <Users />
                                </>
                              }
                            />
                            <Route
                              path="calendar"
                              element={
                                <>
                                  <PageTitle title="Calendar | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <Calendar />
                                </>
                              }
                            />
                            <Route
                              path="profile"
                              element={
                                <>
                                  <PageTitle title="Profile | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <Profile />
                                </>
                              }
                            />
                            <Route
                              path="forms/form-elements"
                              element={
                                <>
                                  <PageTitle title="Form Elements | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <FormElements/>
                                </>
                              }
                            />
                            <Route
                              path="forms/form-layout"
                              element={
                                <>
                                  <PageTitle title="Form Layout | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <FormLayout />
                                </>
                              }
                            />
                            <Route
                              path="tables"
                              element={
                                <>
                                  <PageTitle title="Tables | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <Tables />
                                </>
                              }
                            />
                            <Route
                              path="settings"
                              element={
                                <>
                                  <PageTitle title="Settings | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <Settings />
                                </>
                              }
                            />
                            <Route
                              path="chart"
                              element={
                                <>
                                  <PageTitle title="Basic Chart | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <Chart />
                                </>
                              }
                            />
                            <Route
                              path="ui/alerts"
                              element={
                                <>
                                  <PageTitle title="Alerts | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <Alerts />
                                </>
                              }
                            />
                            <Route
                              path="ui/buttons"
                              element={
                                <>
                                  <PageTitle title="Buttons | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <Buttons />
                                </>
                              }
                            />
                            <Route
                              path="auth/signin"
                              element={
                                <>
                                  <PageTitle title="Signin | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <SignIn />
                                </>
                              }
                            />
                            <Route
                              path="auth/signup"
                              element={
                                <>
                                  <PageTitle title="Signup | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                                  <SignUp />
                                </>
                              }
                            />
                        </Route>
                        <Route path='*' element={<PageNotFound />} />
                      </Routes>
                  </div>
              </div>
              {
                (cutNodeId || copyNodeId) && <CursorText/>
              }
              <Modals/>
      </BrowserRouter>
  </>
  
}

export default App;





