import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { FlowScoreChart, XLScoreChart } from '../../../components/charts';
import moment from 'moment';
import { getTreeData } from '../../../pages/ICICLETree/Index';
import { getNodeDashboardData } from '../../../store/actions';

export function FeedBack() {
    const XLScoreRef = useRef(null);
    const flowScoreRef = useRef(null);
    const [sideBarDashboardData, setSideBarDashboardData] = useState<any>(null);
    const [currentTaskActivityId, setCurrentTaskActivityId] = useState(null);
    const currentTask = useSelector((state:RootState) => state.globalStates.currentTask);
    const nodeDashboardData = useSelector((state: RootState) => state.globalStates.nodeDeshboardData);
    const root = getTreeData();

    useEffect(() => {
        if(currentTask){
            const cn = root.descendants().find((n:any) => n.data._id === currentTask._id);
        
            if(cn){
                const cateOfImp = cn.ancestors()[cn.ancestors().length-3];
   
                if(cateOfImp?.data?.activity){
                    setCurrentTaskActivityId(cateOfImp?.data?.activity)
                }
            }
        }
    }, [root,currentTask]);


    useEffect(() => {
        (async () => {
          if(currentTaskActivityId){
            const res = await getNodeDashboardData(currentTaskActivityId || "");
            if(res && res.success){
                const data = {...res};
                delete data.success;
                delete data.message;
                setSideBarDashboardData(data)
            }
            else {
              setSideBarDashboardData(null)
            }
          }
        })()
    }, [currentTaskActivityId,nodeDashboardData]);

    
    if(!sideBarDashboardData){
        return null;
    }

    return (
        <div className="w-full">
            {
            sideBarDashboardData && <>
            <div className="flex flex-wrap border rounded-md bg-white/30">
                        <div className="w-full">
                            <h1 className="text-center py-4 border-b border-gray-300 font-bold text-sm text-gray-600">Cognitive & Flow Potential @ {moment(sideBarDashboardData.lastCognipulseDate).format("DD/MM/YY HH:mm")}</h1>
                        </div>
                        <div className="w-[30%] mx-auto min-w-[105px]">
                            {/* <h2 className="text-gray-400 font-bold text-center">Cognitive</h2> */}
                            <div ref={XLScoreRef} className="w-full" id='xl-score-in-side-bar'>
                                <XLScoreChart containerRef={XLScoreRef} score={sideBarDashboardData.XLScore} change={sideBarDashboardData.XLChange}/>
                            </div>
                        </div>
                        <div className="w-[30%] mx-auto min-w-[105px]">
                            {/* <h2 className="text-gray-400 font-bold text-center">Flow</h2> */}
                            <div ref={flowScoreRef} className="w-full" id='flow-score-in-side-bar'>
                                <FlowScoreChart containerRef={flowScoreRef} score={sideBarDashboardData.flowScore} change={sideBarDashboardData.flowChange}/>
                            </div>
                        </div>
            </div>
            </>
            }
        </div>
    )
}
