import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { NEW_EVENT } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import { createEvent, updateEvent } from '../../../store/actions/event';
import { toast } from 'react-toastify';

interface LinesProps {
  date?: Date; // Optional date prop
  todaysEvents?: any;
  currentDay?: any;
}

function Lines({ date,currentDay}: LinesProps) {
  const dispatch:any = useDispatch();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef<HTMLDivElement>(null); // Create a ref
  const dropIndiRef = useRef<HTMLDivElement>(null);
  const [{ isOver}, drop] = useDrop(() => ({
    accept: NEW_EVENT,
    drop: (item: any) => date? handleWeekDrop(item,date):handleDayDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));


  const handleDayDrop = (item:any) => {
    const i = Number(dropIndiRef.current?.style?.top.replace("px",""));
    const date = dropIndiRef.current?.getAttribute("data-date");
    const startTime = convertMinutesToDate(i,new Date(date || ""));
    const endTime = convertMinutesToDate(i + 30,new Date(date || ""));
    
    if(moment().startOf("day").isAfter(moment(date))){
      toast.error("You can't schedule events in the past.");
      return;
    }

    if (!item.isUpdate) {
      dispatch(createEvent({ startTime, endTime, node: item._id }));
      return;
    }
    
    const duration = moment.duration(moment(item.endTime).diff(moment(item.startTime)));
    const newEndTime = moment(startTime).add(duration.asMinutes(), 'minutes');

    if (moment().isBetween(moment(startTime), newEndTime)) {
      dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
      return;
    }

    dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
  } 

  const handleWeekDrop = (item:any,date:any) => {
    const i = Number(dropIndiRef.current?.style?.top.replace("px",""));
    const startTime = convertMinutesToDate(i, new Date(date));
    const endTime = convertMinutesToDate(i + 30, new Date(date));

    // Create event if drag object is dragged from sidebar 
    if (!item.isUpdate) {
      dispatch(createEvent({ startTime, endTime, node: item._id }));
      return;
    }

    const duration = moment.duration(moment(item.endTime).diff(moment(item.startTime)));
    const newEndTime = moment(startTime).add(duration.asMinutes(), 'minutes');

    dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
  }
  
  const handleOnDragOver = (event: React.MouseEvent<HTMLDivElement>) => {
    if (dropRef.current) {
      const rect = dropRef.current.getBoundingClientRect();
      setPosition({x: event.clientX - rect.left,y: event.clientY - rect.top});
    }
  };

  // Attach the drop function to the ref manually
  useEffect(() => {
    if (dropRef.current) {
      drop(dropRef);
    }
  }, [drop, dropRef]);

  return (
    <>
      <div 
        ref={dropRef}
        onDragOver={handleOnDragOver}
        className="w-full h-full bg-gray-100/50 relative"
      >
          {
            new Array(48).fill(1).map((_,i) => {
              return <>
                <div style={{position: "absolute",left: 0,top: `${i*30}px`}} className="bg-gray-200 h-[1px] w-full"></div>
            </>
            })
          }
          {
            <div data-date={currentDay?.clone()?.toDate()} ref={dropIndiRef} style={{left: 0,top: `${position.y}px`,visibility: isOver? "visible":"hidden"}} className="absolute w-full h-[1px] bg-green-600"/>
          }
      </div>
      {/* {new Array(1440).fill(1).map((_, i) => (
        <Line key={i} i={i} date={date} currentDay={currentDay}/>
      ))} */}
    </>
  );
}

export default Lines;

// interface LineProps {
//   i: number; // Line index
//   date?: Date; // Optional date prop
//   currentDay?: Date; // Optional date prop
// }

// function Line({ i, date,currentDay }: LineProps) {
//   const dispatch:any = useDispatch();
//   const [{ isOver }, drop] = useDrop(() => ({
//     accept: NEW_EVENT,
//     drop: (item: any) => (date ? handleDropWeek(item, i, date) : handleDrop(item, i,currentDay)),
//     collect: (monitor) => ({
//       isOver: !!monitor.isOver(),
//     }),
//   }));
  
 

//   const handleDrop = (item: any, i: number,cd:any) => {
//     const startTime = convertMinutesToDate(i,new Date(currentDay || ""));
//     const endTime = convertMinutesToDate(i + 30,new Date(currentDay || ""));
    

//     // console.log(moment(startTime).format("h:m a"));
//     // console.log(moment(endTime).format("h:m a"));
    
//     // if (!item.isUpdate) {
//     //   dispatch(createEvent({ startTime, endTime, node: item._id }));
//     //   return;
//     // }
    

//     // const duration = moment.duration(moment(item.endTime).diff(moment(item.startTime)));
//     // const newEndTime = moment(startTime).add(duration.asMinutes(), 'minutes');

//     // if (moment().isBetween(moment(startTime), newEndTime)) {
//     //   dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
//     //   return;
//     // }

//     // dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
//   };

//   const handleDropWeek = (item: any, i: number, date: Date) => {
//     const startTime = convertMinutesToDate(i, new Date(date));
//     const endTime = convertMinutesToDate(i + 30, new Date(date));


//     // Create event if drag object is dragged from sidebar 
//     if (!item.isUpdate) {
//       dispatch(createEvent({ startTime, endTime, node: item._id }));
//       return;
//     }

//     const duration = moment.duration(moment(item.endTime).diff(moment(item.startTime)));
//     const newEndTime = moment(startTime).add(duration.asMinutes(), 'minutes');

//     dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
//   };

//   return (
//     <div
//       ref={drop}
//       className="line"
//       style={{
//         backgroundColor: isOver ? 'blue' : `${i % 30 === 0 ? 'rgb(243 244 246)' : ''}`,
//         height: '1px',
//         width: '100%',
//       }}
//     />
//   );
// }

function convertMinutesToDate(minutes: number, date?: Date): Date {
  if (minutes < 0 || minutes > 1440) {
    throw new Error('Invalid input: minutes must be in the range of 0 to 1440.');
  }

  const now = date || new Date();
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  now.setHours(hours, mins, 0, 0);
  
  return now;
}
