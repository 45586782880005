import React, { useEffect, useState } from "react";
import {Modal} from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import {
  extendTaskDuration,
  setTimingAlertModalOpen,
  setStartTime,
  completeTaskLocal,
  setManuallyScheduledNodeId,
  setParentIdOfNodeTobeCreated,
  setNavigatePointerNodeId,
  impulseAndCognipulseAndPhySelfiePopup,
  updateNode,
} from "../../store/actions";
import { Formik } from "formik";
import { RootState } from "../../store/store";
import { 
  SET_CURRENT_TASK, 
  SET_IS_PAUSED,  
  UPDATE_NODE 
} from "../../constants";
import { confettiRise } from "../../assets/sounds";
import { toast } from "react-toastify";
import { NewButton } from "../Buttons/NewButton";
import { RatingUI } from "@components/ImpulseRating";
import { createTaskProgress, getLastProgressOfUser } from "@store/actions/taskProgress";

interface IndexProps {}

export const TimeAlertModal: React.FC<IndexProps> = () => {
  const dispatch:any = useDispatch();
  const isTimingAlertModalOpen = useSelector((state: RootState) => state.globalStates.isTimingAlertModalOpen); // Replace `any` with your state type
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with your state type
  const [sliderValues, setSliderValues] = React.useState([0]);
  const selectedActivityId = useSelector((state:RootState) => state.globalStates.selectedActivityId);

  const handleSubmit = async (values: any, e: any) => {
    const currentTaskId = currentTask._id;
    const extraDurationInMins = Number(values.minutes);
    const wereYouWorking = values.isWorking === "Yes";
   
    const data = {
      wereYouWorking: wereYouWorking,
      extraEstimatedDuration: extraDurationInMins,
    };

    dispatch(extendTaskDuration(currentTask._id, data));
    dispatch(setTimingAlertModalOpen(false));
    dispatch(setStartTime(new Date()));
    handleSubmitRating(Math.floor(Number(sliderValues[0])));
    await createTaskProgress({node: currentTaskId,progress: Math.floor(Number(sliderValues[0])),activity: selectedActivityId,createdAt: new Date()});
    e.resetForm();
  };

  const handleSubmitRating = (value: number) => {
      const node = {
          _id: currentTask?._id,
          completedPortion: value/100,
      }
      const ifSuccess = () => {}
      dispatch(updateNode(node,ifSuccess,()=>{}));
  }

  useEffect(() => {
    (async ()=> {
      const res = await getLastProgressOfUser(currentTask._id);

      if(res && res.success && res.progress){
        setSliderValues([res.progress])
      }
    })()
  }, [currentTask._id]);

  return (
    <Modal
      saveBtnText=""
      onSubmit={handleSubmit}
      isOpen={isTimingAlertModalOpen}
      setIsOpen={(bool) => { dispatch(setTimingAlertModalOpen(bool)); }}
      hideBtns={true}
    >
      <Formik
        initialValues={{ isWorking: "Yes", okrGradePercentage: 0, extraMinutes: 0, minutes: 5 }}
        onSubmit={handleSubmit}
        validate={() => { }}
      >
        {(e) => (<Form e={e} sliderValues={sliderValues} setSliderValues={setSliderValues} handleSubmitRating={handleSubmitRating}/>)}
      </Formik>
    </Modal>
  );
};



interface FormProps {
  e: any; // Replace `any` with the appropriate type for your form values
  sliderValues: number[];
  setSliderValues: any;
  handleSubmitRating: (value:number)=>void;
}

const Form: React.FC<FormProps> = ({e,sliderValues,setSliderValues,handleSubmitRating}) => {
  const { values, handleChange } = e;
  const dispatch:any = useDispatch();
  const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with the appropriate type
  const [isShowCompleteUI, setIsShowCompleteUI] = useState<null | boolean>(null);
  const selectedActivityId = useSelector((state:RootState) => state.globalStates.selectedActivityId);

  const handleCompleteTask = async () => {
    const isWorking = values.isWorking;
    const wereYouWorking = isWorking === "Yes";

    // const treeData = getTreeData();
  
    // function ifParentTaskHasOneChild(){
    //     const children = treeData.descendants().find((n:any) => n.data._id === currentTask.parent)?.children.filter((c:any) => !c.data.isCompleted && !c.data.isTempCompleted);;
    //     if (children?.length === 1) {
    //         dispatch({ type: SET_WERE_YOU_WORKING, payload: wereYouWorking });
    //         dispatch(setTimingAlertModalOpen(false));
    //         dispatch(setParentTaskCompleteAlertModalOpen(true));  
    //     }
    //     else {
    //       dispatch(setCurrentTask(null));
    //     }
    // }
    
   
    setIsSubmiting(true);
    const res:any = await completeTaskLocal(currentTask._id,currentTask.nodeProductivityValue, wereYouWorking,1);
    

    if (res.success) {
      const currentTaskId = currentTask._id;
      // ifParentTaskHasOneChild();
      confettiRise.volume = .1;
      confettiRise.play();
      toast.success(res.message);
      dispatch(setTimingAlertModalOpen(false));
      dispatch({ type: SET_IS_PAUSED, payload: false });
      dispatch({ type: UPDATE_NODE, payload: {...res.node,startTime: null} });
      const remainingIds = manuallyScheduleNodeId?.filter((_id:any) => _id !== res.node._id);
      dispatch(setManuallyScheduledNodeId(remainingIds));
      dispatch({ type: SET_CURRENT_TASK, payload: res.node });
      dispatch(setParentIdOfNodeTobeCreated(currentTask.parent));
      dispatch(setNavigatePointerNodeId(currentTask.parent));
      dispatch(impulseAndCognipulseAndPhySelfiePopup(currentTask._id,true,false,false,false));
      handleSubmitRating(Math.floor(Number(sliderValues[0])));
      await createTaskProgress({node: currentTaskId,progress: Math.floor(Number(sliderValues[0])),activity: selectedActivityId,createdAt: new Date()});
      await createTaskProgress({node: currentTaskId,progress: 0,activity: selectedActivityId,createdAt: new Date()});
    } 
    else {
      dispatch(setTimingAlertModalOpen(true));
      toast.error(res.message);
    }
    setIsSubmiting(false);
  }

  useEffect(() => {
    if(sliderValues[0] > 0){
      if(sliderValues[0] > 70){
        setIsShowCompleteUI(true);
      }
      else {
        setIsShowCompleteUI(false);
      }
    }
  }, [sliderValues]);

  return (
    <form className="w-full">
      
        <p className="text-center text-gray-500 font-bold">Were you actively working on this task when the alert appeared?</p>
        <div className="flex justify-center mt-3">
          <div className="border flex">
            <label htmlFor="default-radio-1" className={`cursor-pointer px-4 py-1 border-r ${values.isWorking === "Yes"? "bg-green-600 text-white font-bold border-none":""}`}>
              <input
                onChange={handleChange}
                value="Yes"
                checked={values.isWorking === "Yes"}
                id="default-radio-1"
                type="radio"
                name="isWorking"
                className="hidden"
              />
              Yes
            </label>
            <label htmlFor="default-radio-2" className={`cursor-pointer px-4 py-1 ${values.isWorking === "No"? "bg-green-600 text-white font-bold border-none":""}`}>
              <input 
                onChange={handleChange} 
                value={"No"} 
                checked={values.isWorking === "No"} 
                id="default-radio-2" 
                type="radio"  
                name="isWorking" 
                className="hidden"
              />
              No
            </label>
          </div>
        </div>

        <div className="mt-8">
          <RatingUI 
              currentGoal={currentTask}
              sliderValues={sliderValues}
              setSliderValues={setSliderValues}
          />
        </div>

        {
          isShowCompleteUI && <p className="text-center text-gray-500 font-bold">Great! Click to complete!</p>
        }


        {
          !isShowCompleteUI && <>
              <div className="flex justify-center mb-2">
                <div className="w-[40%]">
                    <select 
                      value={values.minutes} 
                      onChange={handleChange} 
                      name='minutes' 
                      id="minutes" 
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                        {
                          window.location.hostname === "localhost" && <option value={1}>{1} Min</option>
                        }
                        {
                          new Array(5).fill(1).map((_, i) => (
                            <option key={i} value={(i + 1) * 5}>{(i + 1) * 5} Mins</option>
                          ))
                        }
                    </select> 
                </div>
              </div>
          </>
        }
        

        <div className="flex gap-14 justify-center mt-5">
            {
              isShowCompleteUI && <NewButton isLoading={isSubmiting} label="Task done" onClick={handleCompleteTask} full/>
            }
            {
              !isShowCompleteUI && <NewButton label="Add time" onClick={e.handleSubmit} full/>
            }
        </div>


        <p onClick={(e) => {e.preventDefault();setIsShowCompleteUI((p) => !p)}} className="mt-2 underline text-end cursor-pointer">{isShowCompleteUI? "I want to add more time to this task":"Would you like to complete this task"}</p>
    </form>
  );
};



