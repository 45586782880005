import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { getCompletedNodes, setNavigatePointerNodeId, toggleIsCompletedNodesVisible } from '@store/actions';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store'; // Adjust import path as necessary
// import { Main } from '../../layout';
import { NewButton } from '@components/Buttons/NewButton';
import { leftArrow, rightArrow } from '../../assets';

const Index: React.FC = () => {
    const [filterDate, setFilterDate] = useState<{ start: Moment; end: Moment }>({
        start: moment().startOf("day"),
        end: moment().endOf("day"),
    });
    const isCompleted = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible);
    const dispatch:any = useDispatch();
    const navigation = useNavigate();
    const [totalPP, setTotalPP] = useState(0);
    const [allCompletedNodes, setAllCompletedNodes] = useState<Array<any>|null>(null);
    const [allCompletedExploitationDurations, setAllCompletedExploitationDurations] = useState<Array<any>|null>(null);

    // console.log(allCompletedNodes);

    const handleAchievementClick = (node: { _id: string }) => {
        if (!isCompleted) {
            dispatch(toggleIsCompletedNodesVisible());
        }
        dispatch(setNavigatePointerNodeId(node?._id));
        navigation("/");
    };

    const handlePrevNext = (isNext: boolean) => {
        setFilterDate(prev => ({
            start: moment(prev.start)[isNext ? 'add' : 'subtract'](1, "day").startOf("day"),
            end: moment(prev.end)[isNext ? 'add' : 'subtract'](1, "day").endOf("day"),
        }));
    };

    useEffect(() => {
        (async () => {
            const res = await getCompletedNodes({startDate: filterDate.start.toDate(),endDate: filterDate.end.toDate()});

            // console.log(res);
            

            if(res?.success){
                const tp = res.nodes.reduce((a:number,b:any) => {
                    if(b.isRecurring || b.recurring?.frequency){
                        const PP = b.productivityPointsHistory.find((pp:any) => moment(pp.date).startOf('day').isSame(moment(filterDate.start).startOf("day")))?.productivityPoints || 0;
                        return a + PP;
                    }
                    return a + b.productivityPoints 
                },0);
                // const l = res.nodes.filter((n:any) => n.completionDate === null);
                // console.log(l);
                setTotalPP(tp);
                setAllCompletedNodes(res.nodes);
                setAllCompletedExploitationDurations(res.allExploitationDurations);
            }
        })()
    }, [filterDate]);

    return (
        <>
            <div className='p-2 bg-gray-100 h-[calc(100vh-64px)] overflow-auto'>
                {/* Prev and Next buttons */}
                <div className='border-b pb-4 flex items-center justify-center mt-3 gap-5'>
                    <div className="flex items-center justify-center p-1 bg-white rounded-md">
                        <NewButton
                            label=""
                            onClick={(e) => {e.preventDefault();handlePrevNext(false); }}
                            icon={<><img src={leftArrow} alt="left-arrow"/></>}
                            variant='gray'
                            style={{borderTopRightRadius: "0",borderBottomRightRadius: "0"}}
                            title='Prev'
                        />
                        <span
                            className="px-3.5"
                        >
                            {moment(filterDate.start).format("DD/MM/YYYY")}
                        </span>
                        <NewButton
                            label=""
                            onClick={(e) => {e.preventDefault();handlePrevNext(true); }}
                            icon={<><img src={rightArrow} alt="right-arrow"/></>}
                            variant='gray'
                            style={{borderTopLeftRadius: "0",borderBottomLeftRadius: "0"}}
                            title='Next'
                        />
                    </div>
                    <p>PP: {parseFloat(`${totalPP}`).toFixed(2)}</p>
                </div>
                

                <div className="flex flex-col py-5 overflow-auto">
                    {allCompletedNodes && allCompletedNodes.length === 0 && (
                        <h2 className="flex justify-center rounded-md text-sm p-3 text-gray-400">Data Not found</h2>
                    )}
                    {allCompletedNodes && allCompletedNodes.map((node, idx) => (
                        <div
                        style={{backgroundColor: node.coiColor}} 
                        onClick={() => handleAchievementClick(node)} 
                        key={idx} className="cursor-pointer flex flex-col bg-white mb-1 rounded-md shadow-md p-2">
                            <span>Obj: {node.objective} 
                                {(node.isRecurring || node.recurring?.frequency) && <span className='ml-1 bg-blue-600 text-[8px] p-1 rounded-md text-white'>Rec Node</span>}
                            </span>
                            <span>Est: {node.estimatedTaskDuration}</span>
                            <span>Exp: {allCompletedExploitationDurations ? allCompletedExploitationDurations[node._id].split(":")[0]:""}</span>
                            <span>All Exp: {allCompletedExploitationDurations ? allCompletedExploitationDurations[node._id].split(":")[1]:""}</span>
                            {
                                (node.productivityPoints !== 0 && !node.isRecurring && !node.recurring?.frequency) && <span>PP: {parseFloat(`${node.productivityPoints}`).toFixed(2)}</span>
                            }
                            {
                                (node.productivityPointsHistory.length !== 0 && (node.isRecurring || node.recurring?.frequency)) && <span>PP: {parseFloat(`${node.productivityPointsHistory.find((pp:any) => moment(pp.date).startOf('day').isSame(moment(filterDate.start).startOf("day")))?.productivityPoints}`).toFixed(2)}</span>
                            }
                            {
                                !node.isRecurringTask && <span>Date: {moment(node.completionDate).format("MMMM Do YYYY, h:mm:ss a")}</span>
                            }
                            {
                                (node.isRecurringTask) && <span>Date: {moment(node.completedOn.find((date:any) => moment(date).startOf('day').isSame(moment(filterDate.start).startOf("day")))).format("MMMM Do YYYY, h:mm:ss a")}</span>
                            }
                            {/* {
                                (node.isRecurring || node.recurring?.frequency) && <span>
                                {
                                    node.completedOn?.map((date:any) => <div className='text-red-400'>{moment(date).format("MMMM Do YYYY, h:mm:ss a")}</div>)
                                }
                                </span>
                            } */}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Index;
