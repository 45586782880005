
type GetTextWidthOptions = {
    fontSize?: number; // in px
    fontWeight?: string;
    fontFamily?: string;
    fontStyle?: string;
  };
  
  /**
   * Returns the width of the text in pixels based on given font properties.
   * 
   * @param text The text to measure.
   * @param options Optional styling options (fontSize, fontWeight, fontFamily, fontStyle).
   * @returns Width of the text in pixels.
   */
export function getTextWidth(text: string,options: GetTextWidthOptions = {}): number {
    const {fontSize = 16,fontWeight = 'normal',fontFamily = 'Satoshi, sans-serif',fontStyle = 'normal',} = options;
  
    // Create canvas and context for measuring
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    if (!context) return 0;
  
    // Set the font properties
    context.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;
  
    // Measure text width
    const metrics = context.measureText(text);
    return metrics.width;
}