import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/actions/auth';
// import { Main } from '../../layout';
import { Formik, FormikHelpers } from 'formik';
import Form from './Form';
// import { DOMAIN_NAME } from '@constants/constants';



interface RootState {
  loggedInUser: any; // Replace 'any' with the appropriate type for loggedInUser
}

interface FormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const dispatch:any = useDispatch();
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const navigator = useNavigate();

  const handleSubmit = (values: any, actions: FormikHelpers<FormValues>) => {
    dispatch(loginUser(values));
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (loggedInUser) {
      navigator('/');
    }
  }, [loggedInUser, navigator]);

  return (
    <>
      <>
        <div className="flex justify-center items-center h-full">
          <div className="w-full max-w-md mx-auto my-10 p-4 rounded-md shadow sm:p-8 bg-gray-100 text-gray-900">
            <h2 className="mb-3 text-3xl font-semibold text-center">Login to your account</h2>
            <p className="text-sm text-center text-gray-400">
              Don't have an account?{' '}
              <Link to="/signup" className="focus:underline hover:underline">
                Sign up here
              </Link>
            </p>
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={() => {}}
              onSubmit={handleSubmit}
            >
              {(e) => <Form e={e} />}
            </Formik>
          </div>
        </div>
      </>
    </>
  );
};

export default Login;
