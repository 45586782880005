import { FACTOR_COLORS } from "@constants/index";



export const driversInfo = "These factors drive the performance of the activity being measured, they range from 0 to 100 and higher scores indicate higher performance potential."
export const constrainersInfo = "These factors constrain the performance of the activity being measured, even though they may not be directly connected to it. They range from 0 to -100, and lower scores indicate lower performance potential.";

export const factors = {
    drivers: [
      {
        factor: "Fun",
        bg: FACTOR_COLORS["FUN"],
        toolTip: `This indicates how much you are driven by the activity* itself (i.e. when you do in an activity simply because you enjoy it)`
      },
      {
        factor: "Purpose",
        bg: FACTOR_COLORS["PURPOSE"],
        toolTip: `This indicates how much you are driven by the value and impact of  activity* whether you enjoy it or not!`
      },
      {
        factor: "Growth",
        bg: FACTOR_COLORS["GROWTH"],
        toolTip: `This indicates how much you are driven by the self-development value of the activity*`
      }
    ],
    constrainers: [
      {
        factor: "Emotives",
        bg: FACTOR_COLORS["EMOTIVES"],
        toolTip: `This indicates how much you do the activity* because of emotional pressure/s (i.e. guilt, peer pressure, fear, etc.) not because you enjoy or value the activity itself.`
      },
      {
        factor: "Utility",
        bg: FACTOR_COLORS["UTILITY"],
        toolTip: `This indicates how much you do the activity* to gain a reward, or avoid a punishment.`
      },
      {
        factor: "Apathy",
        bg: FACTOR_COLORS["APATHY"],
        toolTip: `A high score here suggests you do not have a strong reason why you're doing the activity*  and tends to result in feelings of suppression, detachment and apathy about the activity.`
      }
    ]
} 