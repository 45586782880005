import { ADD_PRIORITY, REMOVE_PRIORITY, RESET_PRIORITIES, SET_PRIORITIES, UPDATE_PRIORITY } from "../../constants";
  
  interface Routine {
    _id: string;
    [key: string]: any; // Define additional properties as needed
  }
  
  interface Action {
    type: string;
    payload: any; // Define this type based on the payload of each action
  }
  
  const priorities = (state: Routine[] = [], action: Action): Routine[] => {
    switch (action.type) {
      case RESET_PRIORITIES:
            return [];
      case SET_PRIORITIES:
        return action.payload;
  
      case ADD_PRIORITY:
        return [...state, action.payload];
  
      case UPDATE_PRIORITY:
        return state.map(routine => routine._id === action.payload._id ? { ...routine, ...action.payload } : routine);
  
      case REMOVE_PRIORITY:
        return state.filter(routine => routine._id !== action.payload.priorityId);
  
      default:
        return state;
    }
  }
  
  export { priorities };
  