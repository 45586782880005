import { fetchRequest } from "../../utils";
import { 
    ADD_NODE, 
    RESET_TREE, 
    SET_TREE, 
    UPDATE_NODE,
    COPY_PASTE_NODES,
    CUT_PASTE_NODES,
    TOGGLE_COMPLETED_NODES,
    TOGGLE_DELETED_NODES,
    DOMAIN_NAME,
    UNCOMPLETE_COMPLETED_NODES,
    UN_DEFERED_DESCENDANTS,
    SET_CURRENT_TASK,
    SET_IS_PAUSED,
    UPDATE_MULTIPLE_NODES,
    DELETE_NODE,
    UN_COMPLETE_MULTIPLE_NODES,
    RANK_NODES,
    ADD_TREE
} from "../../constants";
import { toast } from "react-toastify";
import { 
    setChallengesOfUser,
    setAddNodeModalOpen, 
    setNavigatePointerNodeId, 
    setParentIdOfNodeTobeCreated,
    getStateOfUser,
    setRoutinesOfUser,
    setCurrentTask,
    createPlanningDuration,
    setIsAddTreeModalOpen,
    impulseAndCognipulseAndPhySelfiePopup
} from "./index";

import { totalExploitationDuration } from "../../utils/initialCalculations";
import store, { RootState } from "../store";
import {cogniPulse1} from "../../assets/sounds";
import { setPrioritysOfUser } from "./priority";

// Define interfaces for the expected response types
interface ApiResponse {
    success: boolean;
    message?: string;
    nodes?: Node[];
    node?: Node;
    newSourceNode?: Node;
    sourceParentId?: string;
    runningTask?: Node
}

interface Node {
    _id: string;
    parent: string | null;
    children?: Node[];
    processed?: boolean;
    recurring?: {
        frequency?: string;
    };
    taskExploitationDuration?: number;
    taskExploitationDurationAsArray?: number[];
    startTimeInMs: any
    startTime: any 
    // Add other properties as needed
}

// Updated makeTreeFromArray function with types
function makeTreeFromArray(rootNode: Node, allNodes: any,delegatedChildren:any[]): Node {
    // let delChildren = [...delegatedChildren];
    let nodes = 0;
    let processedids:string[] = [];

    function visit(node: Node) {
        if (node.processed) {
            return;
        }
        processedids.push(node._id);
        node.processed = true;
        nodes++;


        // if (node.children && node.children.length > 0) {
            const children = allNodes.filter((d:any) => d.parent === node._id);
            
            const ch:any[] = []
            node.children?.forEach(child => {
                const node = children.find((c:any) => c._id === child)
                if(node){
                    ch.push(node);
                }
            });

            const nonRefChild = children.filter((child:any) => !ch.find(c => child._id === c._id));
            
            node.children = [...ch,...nonRefChild]
        // } 
        // else {
        //     node.children = [];
        // }


        if (node.children) {
            node.children.forEach(child => visit(child));
        }
    }
    visit(rootNode);

    if (allNodes.length !== nodes) {
        console.log({ all: allNodes.length, nodes });
        const remaningNodes = allNodes.filter((node:any) => processedids.indexOf(node._id) === -1)
        console.log(remaningNodes);
    }


    return rootNode;
}


export const getTreeData = () => async (dispatch: any) => {
    try {
        dispatch({ type: SET_TREE, payload: null });
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/?isDeleted=false`, "GET", {});
        // console.log(res);

        if (res.success) {
            if (res.nodes?.length === 0) {
                dispatch(setAddNodeModalOpen(true));
                dispatch(setParentIdOfNodeTobeCreated(""));
                return;
            }

            // const idsToExclude = ["622b4c4462e200a6f6141511"]

            // const root = res.nodes?.find(node => {
            //     const n = res.nodes?.find(n => (n._id === node.parent && idsToExclude.indexOf(n._id) === -1));
            //     return n !== undefined;
            // });
            const myRes:any = res;
            const delegatedChildren = buildDelegatedTree(myRes.allDeligatedNodes);
            const delNodes = myRes.allDeligatedNodes?.map((node:any) => node.delegatedTo[0].isRootOfDelegation? {...node,parent: node.delegatedTo[0].parent}:{...node});

            // console.log(delNodes);
            
            
            const root = res.nodes?.find(node => node.parent === null);
            const allNodes:any = res.nodes;
            const tree:any = makeTreeFromArray(root as Node, [...allNodes,...delNodes],delegatedChildren);

            // console.log(tree);
            
            if(delegatedChildren.length > 0){
                tree.children = [
                    ...tree.children || [],
                    {
                        ...tree,
                        _id: "asdfuha;sdfasdjfasdfshdf",
                        objective: "Delegated",
                        children: [
                            {
                                ...tree,
                                _id: "asdfuha;asdfsadfdf",
                                objective: "Delegated category",
                                children: [
                                    {
                                        ...tree,
                                        _id: "asdfuha;asdfsadasffdfsdfg",
                                        objective: "Delegated project",
                                        deadline: new Date().setDate(new Date().getDate()+1),
                                        children: delegatedChildren,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
  
            dispatch(getStateOfUser());
            dispatch({ type: SET_TREE, payload: tree });
            dispatch(setChallengesOfUser());
            dispatch(setRoutinesOfUser());
            dispatch(setPrioritysOfUser());
            
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: RESET_TREE });
    }
}

export const createNode = (node: Node,ifSuccess:()=>void,ifFail:()=>void) => async (dispatch: any) => {
    try {
        const res: any = await fetchRequest(`${DOMAIN_NAME}/nodes`, "POST", { ...node });
        console.log(res);
        if (res.success) {
            toast.success(res.message);
            ifSuccess();
            const newNode:any = { ...res.node, children: [] };
            dispatch({ type: ADD_NODE, payload: { newNode } });
            dispatch(setNavigatePointerNodeId(newNode._id));
            setTimeout(() => {
                dispatch(setNavigatePointerNodeId(newNode.parent));
            }, 0);
            if(res.nodeToUnCompletedAtFrontEnd && res.nodeToUnCompletedAtFrontEnd.length > 0){
                dispatch({ type: UN_COMPLETE_MULTIPLE_NODES, payload: res.nodeToUnCompletedAtFrontEnd });
            }
            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: res.node?._id});
        } else {
            ifFail();
            toast.error(res.message);
            if(node.parent)
            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: node.parent});
        }
    } catch (error) {
        console.error(error);
    }
}

export const addTreeToANode = (parentId: string,children: Node[],setIsSubmiting:any) => async (dispatch: any) => {
    try {
        setIsSubmiting(true);
        const res: any = await fetchRequest(`${DOMAIN_NAME}/nodes/tree`, "POST", {parentId,nodes:children });
        setIsSubmiting(false);

        
        if(res.success){
            const rootNodes = buildTreeFromArray(res.createdNodes)
            dispatch({type: ADD_TREE,payload: {newTree: rootNodes}});
            dispatch(setIsAddTreeModalOpen(false));
        }
        else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const updateNode = (node: any,ifSuccess:()=>void,ifFail:()=>void) => async (dispatch: any) => {
    try {

        const res: any = await fetchRequest(`${DOMAIN_NAME}/nodes/${node._id}`, "PUT", { ...node });

        // console.log(res);
        if (res.success) {
            toast.success(res.message);
            ifSuccess();
            dispatch({ type: UPDATE_NODE, payload: res.node });
            if(res.nodeToUnCompletedAtFrontEnd && res.nodeToUnCompletedAtFrontEnd.length > 0){
                dispatch({ type: UN_COMPLETE_MULTIPLE_NODES, payload: res.nodeToUnCompletedAtFrontEnd });
            }
            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: node?._id});
        } else {
            toast.error(res.message);
            ifFail();
            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: node?._id});
        }
    } catch (error) {
        console.error(error);
    }
}

export const deleteNode = (nodeId: string, parentNodeId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/${nodeId}`, "DELETE", {});
        if (res.success) {
            toast.success("Deleted Successfully!");
            dispatch({ type: DELETE_NODE, payload: { nodeId, parentNodeId } });
        } else {
            console.error(res);
        }
    } catch (error) {
        console.error(error);
    }
}

export const deleteNodePermanently = async (nodeId: string)  => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/${nodeId}`, "DELETE", {});
        
        return res;

    } catch (error) {
        console.error(error);
    }
}

export const rankNodesManually = (parent: string, rankedNodes: Node[]) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/rankNodes/${parent}`, "PUT", { rankedNodes });

        // console.log(res);

        if (res.success) {
            // toast.success(res.message);
            dispatch({type: RANK_NODES,payload: rankedNodes});
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const copyPasteNodes = (sourceId: string, destinationId: string) => async (dispatch: any) => {
    try {
        const res: any = await fetchRequest(`${DOMAIN_NAME}/nodes/copy/source/${sourceId}/destination/${destinationId}`, "PUT", {});
        
        console.log(res);
        // nodeToUnCompletedAtFrontEnd
        
        if (res.success) {
            toast.success(res.message);
            const newSourceNode = makeTreeFromArray(res.newSourceNode,res.final,[]);
            dispatch({ type: COPY_PASTE_NODES, payload: { newSourceNode, destinationId } });

            if(res.nodeToUnCompletedAtFrontEnd && res.nodeToUnCompletedAtFrontEnd.length > 0){
                dispatch({ type: UN_COMPLETE_MULTIPLE_NODES, payload: res.nodeToUnCompletedAtFrontEnd });
            }
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const cutPasteNodes = (sourceId: string, destinationId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/cut/source/${sourceId}/destination/${destinationId}`, "PUT", {});
        
        // console.log(res);

        if (res.success) {
            toast.success(res.message);
            dispatch({ type: CUT_PASTE_NODES, payload: { sourceId, sourceParentId: res.sourceParentId, destinationId } });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const toggleCompletedNodes = (isCompletedNodesVisible: boolean) => (dispatch: any) => {
    dispatch({ type: TOGGLE_COMPLETED_NODES, payload: isCompletedNodesVisible });
}

export const toggleDeletedNodes = (isDeletedNodesVisible: boolean) => (dispatch: any) => {
    dispatch({ type: TOGGLE_DELETED_NODES, payload: isDeletedNodesVisible });
}

export const unCompleteCompletedNodes = (arrOfIds: string[]) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/un-complete/completed-nodes`, "PUT", { arrOfIds });
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: UNCOMPLETE_COMPLETED_NODES, payload: arrOfIds });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const deferTask = ({ id, numberOfTasks, time }: { id: string; numberOfTasks?: number; time?: string }) => async (dispatch: any) => {
    try {
        const nodeId = id;
        const body = {
            time: time? new Date(time):undefined,
            deferByTask: numberOfTasks,
            deferByTaskDate: new Date().getTime(),
            endTime: new Date()
        }

        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/defer/${nodeId}`, "PUT", body);

        console.log(res);

        if (res.success) {

            toast.success(res.message);
            dispatch({ type: SET_IS_PAUSED, payload: false });

            if (res.runningTask) {
                dispatch({ type: UPDATE_MULTIPLE_NODES, payload: [res.node, res.runningTask] });
            } 
            else {
                dispatch({ type: UPDATE_NODE, payload: res.node });
            }

        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const unDeferTask = (nodeId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/undefer/${nodeId}`, "PUT", {});
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: UPDATE_NODE, payload: res.node });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const unDeferDescendantsTasks = (descendantsIds: string[],ifSuccess?:(isUndefer?:boolean)=>void) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/descendants/undefer`, "PUT", { descendantsIds });
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: UN_DEFERED_DESCENDANTS, payload: descendantsIds });
            if(ifSuccess) ifSuccess(true);
        } else {
            toast.error(res.message);
        }
    } catch (error
    ) {
        console.error(error);
    }
}

export const getChildren = async (parentId: string): Promise<ApiResponse> => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/children/${parentId}`, "GET", {});
        return res;
    } catch (error) {
        console.error(error);
        throw error; // Propagate the error for handling in the calling function
    }
}

interface Ifuncs {
    preRun: () => void
    runIfSuccess: () => void
    runIfFails: () => void
}

export const playTask = (nodeId: string, startTime: string, funcs:Ifuncs) => async (dispatch: any) => {
    try {
        funcs.preRun();
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/play/${nodeId}`, "PUT", { startTime });
     
        if (res.success) {
            funcs.runIfSuccess();
            cogniPulse1.volume = .1;
            cogniPulse1.play();
            toast.success("Task is running");
            const state = store.getState();
            const myNode:any = res.node;
            dispatch(setCurrentTask({...state.globalStates.currentTask,...myNode}));
            dispatch(impulseAndCognipulseAndPhySelfiePopup(nodeId,false,false,false,false));
        } 
        else {
            toast.error("Unable to play task");
            funcs.runIfFails();
        }
    } catch (error) {
        console.error(error);
    }
}


interface IpauseTask {
    nodeId: string;
    endTime: Date;
    isPause: boolean;
    funcs: Ifuncs;
}

export const pauseTask = ({nodeId, endTime, isPause,funcs}:IpauseTask) => async (dispatch: any) => {
    interface IRes extends ApiResponse {
        newExploitationDuration: any
    }

    try {
        funcs.preRun();
        
        const res: IRes = await fetchRequest(`${DOMAIN_NAME}/nodes/task/pause/${nodeId}`, "PUT", { endTime });
        
        // console.log(res);
        
        if (res.success) {
            funcs.runIfSuccess();
            toast.success("Task Paused!");
            dispatch({ type: SET_IS_PAUSED, payload: isPause !== undefined ? isPause : true });
            dispatch({ type: UPDATE_NODE, payload: res.node });
            const payload:any = res.node;
            if(payload){
                payload.taskExploitationDurationAsArray = payload.taskExploitationDuration;
                const isRecurring = payload.recurring?.frequency ? true : false;
                payload.taskExploitationDuration = totalExploitationDuration(payload.taskExploitationDuration, isRecurring);
                dispatch({ type: SET_CURRENT_TASK, payload });
            }
            dispatch(impulseAndCognipulseAndPhySelfiePopup(nodeId,true,false,false,false));
        } else {
            toast.error("Unable to Pause!");
            funcs.runIfFails();
        }
    } catch (error) {
        console.error(error);
    }
}

export const extendTaskDuration = (nodeId: string, data: any) => async (dispatch: any) => {
    interface IRes extends ApiResponse {
        newExploitationDuration: any
    }

    try {
        const res: IRes = await fetchRequest(`${DOMAIN_NAME}/nodes/task/extend-task-duration/${nodeId}`, "PUT", data);
        
        // console.log(res);
        
        if (res.success) {
            toast.success(res.message);
            const payload:any = res.node;
            if(payload){
                const rootState:RootState = store.getState();
                payload.taskExploitationDurationAsArray = payload.taskExploitationDuration;
                const isRecurring = payload.recurring?.frequency ? true : false;
                payload.nodeProductivityValue = rootState.globalStates.currentTask?.nodeProductivityValue;
                payload.taskExploitationDuration = totalExploitationDuration(payload.taskExploitationDuration, isRecurring);
                dispatch({ type: SET_CURRENT_TASK, payload });
                dispatch(impulseAndCognipulseAndPhySelfiePopup(nodeId,true,false,false,false));
            }
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

// export const completeTask = (nodeId: string,nodeProductivityValue: number, endTime?: string, okrGradePercentage?: number, wereYouWorking?: boolean) => async (dispatch: any) => {
//     try {
//         const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/complete/${nodeId}`, "PUT", { wereYouWorking: wereYouWorking?true:false,nodeProductivityValue});
//         if (res.success) {
//             confettiRise.volume = .1
//             confettiRise.play();
//             toast.success(res.message);
//             dispatch(setTimingAlertModalOpen(false));
//             dispatch({ type: SET_IS_PAUSED, payload: false });
//             dispatch({ type: UPDATE_NODE, payload: res.node });
//             // const state = store.getState();
//             // dispatch(setCurrentTask({...state.globalStates.currentTask,startTime: null}));
//         } 
//         else {
//             dispatch(setTimingAlertModalOpen(true));
//             toast.error(res.message);
//         }
//     } catch (error) {
//         console.error(error);
//     }
// }

export const completeTaskLocal = async (nodeId: string,nodeProductivityValue: number, wereYouWorking: boolean,completedPortion:number) =>  {
    try {

        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/complete/${nodeId}`, "PUT", { wereYouWorking: wereYouWorking?true:false,nodeProductivityValue,completedPortion});
        
        return res;

    } catch (error) {
        console.error(error);
    }
}

export const unCompleteRecurringTask = (taskId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/recurring/un-complete/${taskId}`, "PUT", {});
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: SET_IS_PAUSED, payload: false });
            dispatch({ type: UPDATE_NODE, payload: {...res.node,isUnCOmpleteParent: true} });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const getAllTempDeletedNodes = async (): Promise<ApiResponse> => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/temp/deleted/nodes`, "GET", {});
        return res;
    } catch (error) {
        console.error(error);
        throw error; // Propagate the error for handling in the calling function
    }
}

export const updatePopupCameUpTime = async (nodeId: string, popupCameUpTime: Date) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/${nodeId}`, "PUT", { popupCameUpTime });
        if (res.success) {
            console.log(res);
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const getRunningTask = async () => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/running/task`, "GET", { });
        
        return res;
    } catch (error) {
        console.error(error);
    }
}

export const getTaskById = async (taskId: string) => {

    interface Ires {
        success: boolean;
        message: string;
        node: any;
    }

    try {
        const res: Ires = await fetchRequest(`${DOMAIN_NAME}/nodes/${taskId}`, "GET", { });
        
        return res;
        
    } catch (error) {
        console.error(error);
    }
}

export const getTaskByIdToUpdate = async (taskId: string) => {

    interface Ires {
        success: boolean;
        message: string;
        node: any;
    }

    try {
        const res: Ires = await fetchRequest(`${DOMAIN_NAME}/nodes/to-update/${taskId}`, "GET", { });
        
        return res;
        
    } catch (error) {
        console.error(error);
    }
}

export const getAllCOIByUserId = async (userId:string) => {
    try {
        interface IRes {
            success: boolean;
            message: string;
            categoryOfImprovement: any[]
            personalCategoryOfImprovement: any[]
            professionalCategoryOfImprovement: any[]
        }

        const res: IRes = await fetchRequest(`${DOMAIN_NAME}/nodes/coi/${userId}`, "GET", {});
        
        return res;

    } catch (error) {
        console.log(error);

        return {
            success: false,
            message: "error",
            categoryOfImprovement: [],
            personalCategoryOfImprovement: [],
            professionalCategoryOfImprovement: [],
        }
    }
}


interface IBodydelegateTask {
    email: string;
}

export const delegateTask = async (delegateTaskId:string,body:IBodydelegateTask) => {
    try {
        interface IRes {
            success: boolean;
            message: string;
        }

        const res: IRes = await fetchRequest(`${DOMAIN_NAME}/nodes/task/delegate/${delegateTaskId}`, "PUT", {...body});
        
        return res;

    } catch (error:any) {
        console.log(error);

        return {
            success: false,
            message: error.message,
        }
    }
}


export const getDelegatedNodes = async () => {
    try {
        interface IRes {
            success: boolean;
            message: string;
            nodes: any[]
        }

        const res: IRes = await fetchRequest(`${DOMAIN_NAME}/nodes/delegated/nodes`, "GET", {});
        
        return res;

    } catch (error:any) {
        console.log(error);

        return {
            success: false,
            message: error.message,
            nodes: []
        }
    }
}


// interface IGetCompletedNodes {
//     startDate: Date;
//     endDate: Date;
// }

// export const getCompletedNodes = async ({startDate,endDate}: IGetCompletedNodes) => {

//     interface Ires {
//         success: boolean;
//         message: string;
//         nodes: any;
//         allExploitationDurations: any;
//     }

//     try {
//         const res: Ires = await fetchRequest(`${DOMAIN_NAME}/nodes/completed-nodes/today?startDate=${startDate}&endDate=${endDate}`, "GET", { });
        
//         return res;
        
//     } catch (error) {
//         console.error(error);
//     }
// }

function buildTreeFromArray(array:Node[]){

    const rootNodes:any = [];

    function visit(node:any){
        const children = array.filter((n:any) => n.parent === node._id);

        node.children = children;

        if(node.children){
            node.children.forEach((child:any) => visit(child))
        }
    }

    array.forEach((child:any) => {
        const parent = array.find((n:any) => n._id === child.parent);
        if(!parent){
            rootNodes.push(child);
        }
    });

    rootNodes.forEach((root:any) => {visit(root)});

    return rootNodes;
}


function buildDelegatedTree(allDeligatedNodes:any){
    // const rootNodes = allDeligatedNodes?.filter((node:any) => node.delegatedTo[0].isRootOfDelegation);
    const rootNodes = allDeligatedNodes?.filter((node:any) => node.delegatedTo[0].isRootOfDelegation && !node.delegatedTo[0].parent);
    const children = [];

    for (let index = 0; index < rootNodes.length; index++) {
        const root = rootNodes[index];
        root.parent = root.delegatedTo[0].parent;
        const tree = makeTreeFromArray(root as Node, allDeligatedNodes,[]);
        children.push(tree);
    }

    return children;
}
