import React, { useEffect, useState } from 'react';
// import { Main } from "../../layout";
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getExploitationDurationsByNodeId } from '../../store/actions/exploitationDuration';
import { NewButton } from '../../components/Buttons/NewButton';




function ExDurations() {
  const location = useLocation();
  const navigator = useNavigate();
  const [exploitationDurations, setexploitationDurations] = useState<any>(null);
  const node:any = location.state?.node;

  useEffect(() => {
    (async () => {
      if(node?._id){
        const res = await getExploitationDurationsByNodeId(node._id);
        console.log(res);
        if(res?.success){
          setexploitationDurations(res.exploitationDurations)
        }
      }
    })()
  }, [node]);

  return (
    <>
      <div className='flex justify-between items-center px-4 border-b py-4'>
        <h5 className='font-bold text-center'>{node?.objective}</h5>
        <NewButton onClick={(e) => {e.preventDefault();navigator("/")}} label="Back"/>
      </div>
      <div className='px-4 py-4 flex flex-col gap-2'>
        {
          (!exploitationDurations || exploitationDurations.length === 0) && <div className='bg-gray-100 rounded-md p-2 flex justify-center'>Data not found!</div>
        }
        {
          exploitationDurations?.map((exDur:any, idx:number) => (
            <div className='bg-gray-100 rounded-md p-2 flex justify-between' key={idx}>
              <span>{parseFloat((moment(exDur.endTime).diff(moment(exDur.startTime), "seconds") / 60).toString()).toFixed(1)} Mins</span>
              <span>{moment(exDur.startTime).format("LLL")}</span>
            </div>
          ))
        }
      </div>
    </>
  );
}

export default ExDurations;
