export function getCurrentColorForXLScore(value:any,chartName:"XL_SCORE_CHART" | "FLOW_SCORE_CHART") {

    if(chartName === "FLOW_SCORE_CHART"){
          if (value < 0 || value > 100) {
            throw new Error('Value must be between 0 and 100');
          }
        
          const getShade = (startColor:any, endColor:any, ratio:any) => {
            const hex = (start:any, end:any, ratio:any) => Math.round(start + ratio * (end - start)).toString(16).padStart(2, '0');
            const r = hex(parseInt(startColor.slice(1, 3), 16), parseInt(endColor.slice(1, 3), 16), ratio);
            const g = hex(parseInt(startColor.slice(3, 5), 16), parseInt(endColor.slice(3, 5), 16), ratio);
            const b = hex(parseInt(startColor.slice(5, 7), 16), parseInt(endColor.slice(5, 7), 16), ratio);
            return `#${r}${g}${b}`;
          };
        
          const colorRanges = [
            { min: 0, max: 20, startColor: '#008080', endColor: '#008080' }, // Dark red to red
            { min: 20, max: 40, startColor: '#007e8c', endColor: '#007e8c' }, // Dark orange to orange
            { min: 40, max: 60, startColor: '#439fa3', endColor: '#439fa3' }, // Dark green to green
            { min: 60, max: 80, startColor: '#78c0ba', endColor: '#78c0ba' }, // Dark yellow to yellow
            { min: 80, max: 100, startColor: '#a2fdfd', endColor: '#a2fdfd' } // Dark yellow to yellow
          ];
        
          for (const range of colorRanges) {
            if (value >= range.min && value <= range.max) {
              const ratio = (value - range.min) / (range.max - range.min);
              return getShade(range.startColor, range.endColor, ratio);
            }
          }
    }

    if (value < 0 || value > 100) {
        throw new Error('Value must be between 0 and 100');
    }  

    const getShade = (startColor:any, endColor:any, ratio:any) => {
        const hex = (start:any, end:any, ratio:any) => Math.round(start + ratio * (end - start)).toString(16).padStart(2, '0');
        const r = hex(parseInt(startColor.slice(1, 3), 16), parseInt(endColor.slice(1, 3), 16), ratio);
        const g = hex(parseInt(startColor.slice(3, 5), 16), parseInt(endColor.slice(3, 5), 16), ratio);
        const b = hex(parseInt(startColor.slice(5, 7), 16), parseInt(endColor.slice(5, 7), 16), ratio);
        return `#${r}${g}${b}`;
    };

    const colorRanges = [
        { min: 0, max: 25, startColor: '#dc6d60', endColor: '#dc6d60' }, // Dark red to red
        { min: 25, max: 50, startColor: '#ffa162', endColor: '#ffa162' }, // Dark orange to orange
        { min: 50, max: 75, startColor: '#64e55a', endColor: '#64e55a' }, // Dark green to green
        { min: 75, max: 100, startColor: '#eee164', endColor: '#eee164' } // Dark yellow to yellow
    ];

    for (const range of colorRanges) {
        if (value >= range.min && value <= range.max) {
        const ratio = (value - range.min) / (range.max - range.min);
        return getShade(range.startColor, range.endColor, ratio);
        }
    }
}