import { useDispatch } from "react-redux";
import { setIsShowUnlockPotentialNow } from "../../store/actions";
import { Modal } from "./Index";

export function UnlockPotentialNowModal() {
  const dispatch:any = useDispatch();

  return (
        <Modal
          saveBtnText="Create"
          onSubmit={()=>{}}
          isOpen={true}
          setIsOpen={(bool)=> dispatch(setIsShowUnlockPotentialNow(bool))}
          hideBtns={true}
          onEscape={()=>{}}
          width={1100}
        >
           <> UnlockPotentialNowModal</>
        </Modal>
  )
}
