import { FC } from "react";
import { cn } from "@utils/cn"; // Utility function for conditional classes
import { getTextColorForBackground } from "@utils/getTextColorForBackground";
import { adjustBrightness } from "@utils/adjustBrightness";

interface ButtonProps {
  label: string | React.ReactNode;
  onClick: (e:React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  variant?: "primary" | "secondary" | "danger" | "transparent" | "gray";
  size?: "sm" | "md" | "lg";
  full?:boolean;
  rounded?:boolean;
  spinner?:boolean;
  style?: React.CSSProperties;
  ref?: React.Ref<HTMLButtonElement>;
  title?: string;
  type?: "submit" | "button";
  nodeData?: any;
}

export const NewButton: FC<ButtonProps> = ({nodeData=null,type="button",title,ref,style,spinner,rounded,full,label,onClick,isLoading = false,disabled = false,icon,variant = "primary",size = "md"}) => {
  const nodeDataStyle = nodeData? {backgroundColor: getTextColorForBackground(adjustBrightness(nodeData)),color: adjustBrightness(nodeData)}:{};
  
  if(label === "" && icon){
    return <button
              type={type}
              title={title}
              ref={ref}
              onClick={!isLoading && !disabled ? onClick : undefined}
              disabled={isLoading || disabled}
              className={cn(
                "flex items-center justify-center gap-2 rounded-md font-medium transition-all duration-200 ease-in-out",
                "focus:outline-none focus:ring-2 focus:ring-offset-2",
                full && "w-full",
                size === "sm" && "px-3 py-1 text-sm",
                size === "md" && "px-4 py-2 text-base",
                size === "lg" && "px-5 py-3 text-lg",
                variant === "primary" && "bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500",
                variant === "secondary" && "bg-gray-500 text-white hover:bg-gray-600 focus:ring-gray-400",
                variant === "danger" && "bg-red-600 text-white hover:bg-red-700 focus:ring-red-500",
                variant === "transparent" && "bg-transparent text-black hover:bg-gray-100 focus:ring-gray-500",
                variant === "gray" && "bg-gray-200 text-black hover:bg-gray-100 focus:ring-gray-500",
                !rounded && "max-h-[40px]",
                rounded && "rounded-full w-[45px] md:w-[30px] h-[45px] md:h-[30px] p-2",
                spinner && "p-0",
                (isLoading || disabled) && "cursor-not-allowed opacity-50",
                "active:scale-95" ,// Press effect
                nodeData && `hover:bg-[${adjustBrightness(nodeData)}] focus:ring-[${adjustBrightness(nodeData)}]`
              )}
              style={{...style,...nodeDataStyle}}
            >
              {
                isLoading ? (<span className="animate-spin h-5 w-5 border-4 border-white border-t-transparent rounded-full"></span>) : (<>{icon}</>)
              }
            </button>
  }

  return (
    <button
      type={type}
      title={title}
      ref={ref}
      onClick={!isLoading && !disabled ? onClick : undefined}
      disabled={isLoading || disabled}
      className={cn(
        "flex items-center justify-center gap-2 rounded-md font-medium transition-all duration-200 ease-in-out",
        "focus:outline-none focus:ring-2 focus:ring-offset-2",
        full && "w-full",
        size === "sm" && "px-3 py-1 text-sm",
        size === "md" && "px-4 py-2 text-base",
        size === "lg" && "px-5 py-3 text-lg",
        variant === "primary" && "bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500",
        variant === "secondary" && "bg-gray-500 text-white hover:bg-gray-600 focus:ring-gray-400",
        variant === "danger" && "bg-red-600 text-white hover:bg-red-700 focus:ring-red-500",
        variant === "transparent" && "bg-transparent text-black hover:bg-gray-100 focus:ring-gray-500",
        variant === "gray" && "bg-gray-200 text-black hover:bg-gray-100 focus:ring-gray-500",
        !rounded && "max-h-[40px]",
        rounded && "rounded-full w-[45px] md:w-[30px] h-[45px] md:h-[30px] p-2",
        spinner && "p-0",
        (isLoading || disabled) && "cursor-not-allowed opacity-50",
        "active:scale-95" ,// Press effect
        nodeData && `hover:bg-[${adjustBrightness(nodeData)}] focus:ring-[${adjustBrightness(nodeData)}]`
      )}
      style={{...style,...nodeDataStyle}}
    >
      {isLoading ? (
        <span className="animate-spin h-5 w-5 border-4 border-white border-t-transparent rounded-full"></span>
      ) : (
        <>
          {icon && <span className="max-w-[24px]">{icon}</span>}
          {/* {icon && <span className="w-[24px]">{icon}</span>} */}
          {label}
        </>
      )}
    </button>
  );
};





