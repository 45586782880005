import React, { ReactNode, KeyboardEvent } from 'react';
import { NewButton } from '../Buttons/NewButton';

interface ModalProps {
  children: ReactNode;
  title?: string;
  onEscape?: () => void;
  cancelBtnText?: string;
  saveBtnText?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (v:any,e:any) => void;
  hideBtns?: boolean;
  showTopArrow?: boolean;
  width?: number;
  header?: boolean;
  handleClose?: any;
}

export const Modal: React.FC<ModalProps> = ({handleClose,header,width,children,title,onEscape,cancelBtnText = "Cancel",saveBtnText = "Save",isOpen,setIsOpen,onSubmit,hideBtns = false,showTopArrow = true}) => {

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Escape" && onEscape) {
      onEscape();
    }
  }

  if (!isOpen) {
    return null;
  }

  
  return (
    <div tabIndex={0} onKeyDown={handleKeyDown} className='fixed z-[101] top-0 left-0 w-full h-full bg-black/60'>
      <div className='w-full h-full overflow-auto p-2 md:p-0'>
        <div style={{width: `${width? `${width}px`:"600px"}`}} className={`bg-white my-4 mx-auto max-w-full h-auto rounded-md`}>
          {
            header && <>
              <div className="flex items-start justify-between p-2 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {title}
                </h3>
                {
                  showTopArrow && <NewButton 
                                    variant='transparent'
                                    label='' 
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if(handleClose){
                                        handleClose();
                                      }
                                      setIsOpen(false);
                                    }}
                                    icon={<svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>}
                                  />
                }
              </div>
            </>
          }
          
          <div className="p-6 space-y-6">
            {children}
          </div>
          {
            !hideBtns && <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <NewButton variant='gray' label={cancelBtnText} onClick={(e) => {e.preventDefault();setIsOpen(false)}}/>
              <NewButton label={saveBtnText} onClick={(e)=> onSubmit(e,"")}/>
            </div>
          }
        </div>
      </div>
    </div>
  )
};

