import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { deferTask, setCurrentTask, setIsCurrentTaskRunning, setManuallyScheduledNodeId } from "../../../store/actions";
import moment from "moment";

interface TaskItem {
	minutes?: number;
	text: string;
	byTask?: boolean;
	numberOfTasks?: number;
}

interface DiferDurationsProps {
	id: string;
	left?: boolean;
	x?: number;
	toggleIsDifferMenuOpen: () => void;
}

const DiferDurations: React.FC<DiferDurationsProps> = ({ x,id, left, toggleIsDifferMenuOpen }) => {
	const dispatch:any = useDispatch();
	const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
	const isCurrentTaskRunning = useSelector((state: RootState) => state.globalStates.isCurrentTaskRunning);

	const arr: TaskItem[] = [
		{
			numberOfTasks: 1,
			text: "1 Task",
			byTask: true
		},
		{
			minutes: 5,
			text: "5 Mins"
		},
		{
			minutes: 30,
			text: "30 Mins"
		},
		{
			minutes: 60,
			text: "1 hr"
		},
		{
			minutes: 120,
			text: "2 hr"
		},
		{
			minutes: 720,
			text: "12 hrs"
		},
	];

	if (window.location.hostname === "localhost") {
		arr.push({ minutes: 1, text: "1 min" });
	}

	const handleClick = ({ minutes, byTask, numberOfTasks }: TaskItem) => {
		if (isCurrentTaskRunning) {
			dispatch(setIsCurrentTaskRunning(false));
		}

		if (byTask) {
			dispatch(deferTask({ id, numberOfTasks}));
			dispatch(setCurrentTask(null));
			toggleIsDifferMenuOpen();
			return;
		}

		const time = moment().add(minutes as number, "minutes");
		if (manuallyScheduleNodeId && manuallyScheduleNodeId[manuallyScheduleNodeId.length - 1] === id) {
			const remainingIds = manuallyScheduleNodeId.filter((_id:any) => _id !== id);
			if (remainingIds.length) {
				dispatch(setManuallyScheduledNodeId(remainingIds));
			} 
			else {
				dispatch(setManuallyScheduledNodeId([]));
			}
		}
		dispatch(deferTask({ id, time: time.toString() }));
		dispatch(setCurrentTask(null));
		toggleIsDifferMenuOpen();
	};

	return (
		<div style={{left: x? x:""}} className={`absolute z-[100] ${left ? "-top-[140%] left-[70px]" : "top-[120%] -left-[110%]"} bg-white rounded-md w-[100px] shadow-md`}>
			<div className="flex flex-col text-gray-600 p-1 text-[12px] font-bold">
				{arr.map((item, idx) => (
					<div onClick={() => handleClick(item)} key={idx} className="hover:text-white hover:bg-gray-600 cursor-pointer text-center">
						{item.text}
					</div>
				))}
			</div>
		</div>
	);
};


export {DiferDurations}