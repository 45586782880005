import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

interface RootState {
  loggedInUser: any; // Replace `any` with the appropriate type for your loggedInUser state
}

interface PrivateRouteProps {
  children: React.ReactNode;
  noApproval?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children,noApproval=false}) => {
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);

  if (!loggedInUser) {
    return <Navigate to="/login" replace />;
  }

  if(!loggedInUser.isApprovedByAdmin && !noApproval){
    return <Navigate to="/admin-approval-pending" replace />;
  }
  
  if(!loggedInUser.hasAccess && !noApproval){
    return <Navigate to="/in-active" replace />;
  }

  return <>{children}</>;
};

