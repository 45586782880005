import { useEffect, useState } from "react";
import { parseIndentedString } from "../../../../components/Modal/AddTreeModal";
import { addTreeToANodetoOtherUserByAdmin, getAllCOIByUserId } from "../../../../store/actions";
import { toast } from "react-toastify";
import moment from "moment";
import { NewButton } from "../../../../components/Buttons/NewButton";


interface IAddTree {
    idOfUserToEdit: string;
    setIsAddingTree: any;
}

export function AddTree({idOfUserToEdit,setIsAddingTree}:IAddTree){
    const [personalCOI, setPersonalCOI] = useState<any>([]);
    const [professionalCOI, setProfessionalCOI] = useState<any>([]);
    const [selectedCOI, setSelectedCOI] = useState("#");
    const [rawNodeData, setRawNodeData] = useState("");
    

    const handleAddData = async (e:any) => {
        e.preventDefault();
        const stringChildren = JSON.stringify(parseIndentedString(rawNodeData), null, 2);
        const children = JSON.parse(stringChildren);

        const res = await addTreeToANodetoOtherUserByAdmin(selectedCOI,children,idOfUserToEdit);

        if(res.success){
            setIsAddingTree(false);
            toast.success("Added Successfully!")
        }
        else {
            toast.error(res.message);
        }
    }

    useEffect(() => {
        (async () => {
            const res = await getAllCOIByUserId(idOfUserToEdit);
            
            console.log(res);
            if(res.success){
                function findAddedByAdmin(pro:any){
                    const addedByAdmin = pro.children.filter((ch:any) => ch.isAddedByAdmin).map((c:any) => `${c?.objectiveDynamicProperties?.title} ${moment(c.createdAt).format("DD/MM/YY")}`).join(",");

                    return {
                        ...pro,
                        addedByAdmin
                    };
                }
                const professtional = res.professionalCategoryOfImprovement.map(findAddedByAdmin)
                const persoanl = res.personalCategoryOfImprovement.map(findAddedByAdmin)
                setProfessionalCOI(professtional);
                setPersonalCOI(persoanl);
            }
        })()
    }, [idOfUserToEdit]);


    return <>
    
            <div className="rounded-lg border p-4">
                <div className="mb-8">
                    <select value={selectedCOI} onChange={(e) => {setSelectedCOI(e.target.value)}} name="nodeId" id="nodeId" className="w-full border-2 border-gray-200 rounded-md p-2">
                        <option value="#">Select COI</option>
                        {
                            personalCOI.map((coi:any) => <option value={coi._id}>Pro - {coi.objective} [{coi.addedByAdmin || "Empity"}]</option>)
                        }
                        {
                            professionalCOI.map((coi:any) => <option value={coi._id}>Per - {coi.objective} [{coi.addedByAdmin || "Empity"}]</option>)
                        }
                    </select>

                    {
                        selectedCOI !== "#" && <>
                        
                            <div className="mt-4">
                                <textarea className="border rounded-md p-2 w-full" placeholder="Tree Data..." value={rawNodeData} onChange={(e)=>{setRawNodeData(e.target.value)}}/>
                            </div>
                        </>
                    }
                </div>

                <div className="mb-5 w-full flex gap-4">
                    <NewButton
                      label="Back"
                      onClick={(e)=>{e.preventDefault();setIsAddingTree(false)}}
                      full
                      variant="gray"
                    />
                    <NewButton
                      label="Add"
                      onClick={handleAddData}
                      full
                    />
                </div>
            </div>
    
    </>
}