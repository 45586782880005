import { PRIMARY_COLOR } from "@constants/index";
import { getCurrentColorForXLScore } from "../utils";
import { Quadrants } from "./Quadrant";

interface IProgressBar {
  width: any;
  progress: any;
  change: any;
  chartName: "XL_SCORE_CHART" | "FLOW_SCORE_CHART"
  id: string;
}

export const ProgressBar: React.FC<IProgressBar> = ({chartName,width,progress,change,id}) =>  {
  const noText = (id === "xl-score-in-side-bar" || id === "flow-score-in-side-bar")? true:false;
    const strokeWidth = noText? calculatePercentage(width,10):calculatePercentage(width,7.9);
    const r = noText? calculatePercentage(width,40):calculatePercentage(width,27);
    const strokeDasharray = 2 * 3.14 * r;
    const strokeDashoffset = strokeDasharray * ((100 - progress)/100);
    const SVGWidth = width;
    const SVGHeight = width;
    const left = calculatePercentage(width,50);
    const top = calculatePercentage(width,50);     
    const maskId = `myMask${chartName}-${id}`;
    const shadowId = `shadow${chartName}-${id}`;
    const brainColor = getCurrentColorForXLScore(progress,chartName); 
    const scoreTextSize = noText? calculatePercentage(width,21):calculatePercentage(width,15);
    const changeTextSize = noText? calculatePercentage(width,8):calculatePercentage(width,5);
    

    

    return (<>
        <svg 
        id={`introSelector${chartName}`} 
        style={{overflow: "visible"}} 
        width={SVGWidth} 
        height={SVGHeight} 
        >
          
          
          <defs>
              <mask id={maskId}>
                {/* Add mask content here */}
                <circle r={r} cx={left} cy={top} fill="transparent" stroke={`white`} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray+1} strokeDashoffset={strokeDashoffset+2}/>
              </mask>
              
              <filter id={shadowId} colorInterpolationFilters="sRGB">
                <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5"/>
              </filter>

              <filter id="outer-shadow" x="-50%" y="-50%" width="200%" height="200%">
                <feDropShadow dx="5" dy="5" stdDeviation="4" floodColor="black" floodOpacity="0.5" />
              </filter>
          </defs>

          <circle filter={`url(#${shadowId})`} r={r} cx={left} cy={top} fill="transparent" stroke={PRIMARY_COLOR} strokeWidth={strokeWidth}/>
          
         
          {/* apply gradient  */}
          <foreignObject x={0} y={0} width={SVGWidth} height={SVGHeight} mask={`url(#${maskId})`}>
            <div 
              style={{
                width: `100%`,
                height: `100%`,
                background: chartName === "XL_SCORE_CHART"? `conic-gradient(from 90deg, #dc6d60 18%, #ffa162 43%, #64e55a 61%, #eee164 89%)`:`conic-gradient(from 90deg, #008080 0%, #a2fdfd 100%)`
              }}
            />
          </foreignObject>
          
          {
            !noText && <Quadrants id={id} chartName={chartName} shadowId={shadowId}  radius={r+(strokeWidth/2)} SVGWidth={SVGWidth} SVGHeight={SVGHeight}/>
          }
          

          {/* score circle  */}
          <foreignObject width={SVGWidth} height={SVGHeight} x={0} y={0}>
            <div className="w-full h-full flex justify-center items-center">
                <div style={{width: `${(r*2)-strokeWidth-20}px`,height: `${(r*2)-strokeWidth-20}px`}} className="w-[35%] h-[35%] rounded-full shadow-[0px_6px_12px_-3px_rgba(0,0,0,0.3)] flex flex-col justify-center items-center">
                    <span style={{color: brainColor,fontSize: `${scoreTextSize}px`}} className="font-bold leading-none mb-[2px]">{parseFloat(progress).toFixed(0)}</span>
                    <span style={{fontSize: `${changeTextSize}px`}} className={`${change < 0 ? 'text-red-600':'text-green-600'} flex justify-center items-center gap-1 leading-none font-bold`}>{parseFloat(change).toFixed(0)}% {change < 0 ? "⬇":"⬆"}</span>
                </div>
            </div>
          </foreignObject>
        </svg>
    </>)
}


function calculatePercentage(wholeNumber:number, percentage:number) {
  // Ensure the inputs are numbers
  if (typeof wholeNumber !== 'number' || typeof percentage !== 'number') {
      throw new Error('Both inputs must be numbers');
  }

  // Calculate the percentage
  const result = (wholeNumber * percentage) / 100;

  // Return the result
  return result;
}