
export function NoAccess() {
  
    return (
    <div className='h-[calc(100vh-64px)] flex justify-center items-center flex-col'>
      <h1 className='font-bold text-2xl text-gray-600 mb-4'>You do not have permission to use the system</h1>
      <p className='text-gray-600'>Please contact us at <a className='text-blue-400 underline' href="mailto:howard@10x.systems">howard@10x.systems</a> for assistance.</p>
    </div>
  )
}
