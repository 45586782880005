import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getHeightTopVal } from '../utils';
import moment from 'moment';
import { NEW_EVENT, UPDATE_EVENT } from '../../../constants';
import { useDrag } from 'react-dnd';
import { updateEventLocally } from '../../../store/actions/event';
import { ResizableBox } from 'react-resizable';
import { toast } from 'react-toastify';

// Define types for event and props
interface Event {
  _id: string;
  startTime: string;
  endTime: string;
  node?: {
    objective?: string;
    objectiveArr?: string[];
    color?: string;
  };
}

interface ScheduleDurationsProps {
  todaysEvents: Event[];
  currentDay: moment.Moment;
  setEventToUpdate?: (event: Event) => void;
  setIsAddEventModalOpen: (isOpen: boolean) => void;
}

interface ScheduleDurationProps {
  event: Event;
  handleClick: (event: Event) => void;
}

// ScheduleDurations component
function ScheduleDurations({todaysEvents,currentDay,setEventToUpdate,setIsAddEventModalOpen}: ScheduleDurationsProps) {

  // console.log(todaysEvents);
  

  return (
    <>
      {todaysEvents && todaysEvents.map((event, i) => (
        <ScheduleDuration
          handleClick={handleClick}
          // setEventToUpdate={setEventToUpdate}
          key={i}
          event={event}
          // currentDay={currentDay}
        />
      ))}
    </>
  );

  function handleClick(event: Event) {
    if(setEventToUpdate){
      setEventToUpdate(event);
      setIsAddEventModalOpen(true);
    }
  }
}

export default ScheduleDurations;

// ScheduleDuration component
function ScheduleDuration({event,handleClick}: ScheduleDurationProps) {
  const dispatch = useDispatch();
  const { height, top } = getHeightTopVal(event);
  const [endTime, setEndTime] = useState<moment.Moment>(moment(new Date(event.endTime)));
  const objective = (event.node?.objectiveArr?.length && event.node?.objectiveArr?.length > 1) ? event.node?.objectiveArr.join(" ") : event.node?.objective;
  const [tempEndTime, setTempEndTime] = useState<string | null>(null);
  const resizableBox:any = useRef(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: NEW_EVENT,
    item: { ...event, isUpdate: true, endTime },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleResizeStop = async (d: any, target: any, event: Event) => {
    setTempEndTime(null);
    const currentHeight = target.size.height;
    let newEndTime = moment(new Date(event.startTime)).add(currentHeight, "minutes");

    const res:any = await updateEventLocally(event._id, { endTime: newEndTime.toDate() });
    if (res.success) {
      setEndTime(newEndTime);
      dispatch({ type: UPDATE_EVENT, payload: res.event });
      toast.success(res.message);
    } 
    else {
      resizableBox.current.state.height = height;
      setEndTime(moment(new Date(event.endTime)));
      toast.error(res.message);
    }
  };

  
  

  // const handleResize = (e: any, target: any, g: any) => {
  //   const height = target.size.height;
  //   const et = moment(new Date(event.startTime)).add(height, "minutes").format('h:mm a');
  //   setTempHeight(height);
  //   setTempEndTime(et);
  // };

  return (
    <>
      <ResizableBox
        height={height}
        axis="y"
        resizeHandles={['s']}
        style={{ top, position: "absolute", display: isDragging ? "none" : "", backgroundColor: event.node?.color }}
        onResizeStop={(d, target) => handleResizeStop(d, target, event)}
        // onResize={handleResize}
        className="bg-gray-400"
        ref={resizableBox}
      />
      <div
        onClick={() => handleClick(event)}
        title={objective}
        ref={drag}
        style={{ position: "absolute", top, height: height - 10, cursor: "pointer" }}
        className="w-full"
      >
        <div style={{ fontSize: height < 10 ? `${height}px` : "10px" }} className="overflow-hidden">
          <span className='ml-2 mr-5'>{objective}</span>
          <span className='mr-2'>{moment(new Date(event.startTime)).format('h:mm a')} - {tempEndTime || moment(event.endTime).format('h:mm a')}</span>
        </div>
      </div>
    </>
  );
}
