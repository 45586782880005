import  { useEffect, useState } from 'react'

export default function useGetParentWidth(containerRef:any) {
    const [width, setWidth] = useState(null);
    const [id, setId] = useState("");
  
    useEffect(() => {
      if(containerRef){
          setWidth(containerRef.current.clientWidth);
          setId(containerRef.current.id);
      }
    }, [containerRef]);

    return [width,id];
}
