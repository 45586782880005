import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { rankNodes } from '../../utils';
import { rankNodesManually } from '../../store/actions/tree';
import { DragAndDropList } from './components/DragAndDropList';
import { Icons } from '../../components/Icons';
import { useScrollToTop } from '../../hooks';

// Define the type for the Node data structure
interface NodeData {
  _id: string;
  objective: string;
  relativeImpactValueNodeAsScale10to0: number;
  isDeleted?: boolean;
  parent: {
    data: NodeData;
    children: NodeData[];
  };
}

// Props for the Index component
interface IndexProps {
  setCurrentNodeToRank?: any;
  currentNodeToRank?: any;
  setIsOrdered?: any;
}

function Index({ setCurrentNodeToRank, currentNodeToRank ,setIsOrdered}: IndexProps) {
  const dispatch = useDispatch();
  const currentNode = currentNodeToRank?.parent.data;
  const [nodesToRank, setNodesToRank] = useState<any[]>([]);
  const [currentImpact, setCurrentImpact] = useState(0);
  const impacts = [`Order by the Size Impact on`, `Order by the Speed of Impact on`];
  useScrollToTop();

  const handleImpactChange = () => {
    if (currentImpact === 1) {
      setCurrentNodeToRank(null);
      setIsOrdered(true);
    } 
    else {
      setCurrentImpact(prev => prev + 1);
    }

    if (currentImpact <= 1) {
      relativeImpactValueNodeAsScale10to0Calculator(currentImpact, nodesToRank, setNodesToRank, dispatch);
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      setCurrentNodeToRank(null);
    }
  }

  useEffect(() => {
    (async () => {
      const children = currentNodeToRank?.parent.children.map((n:any) => n.data) || [];
      setNodesToRank(children.filter((d:any) => !d.isDeleted).sort((a:any, b:any) => b.relativeImpactValueNodeAsScale10to0 - a.relativeImpactValueNodeAsScale10to0));
    })();
  }, [currentNodeToRank]);

  const color = currentNode?.color ? `bg-[${currentNode?.color}]` : '';

  return (
    <div tabIndex={0} onKeyDown={handleKeyDown} className='w-full min-h-full bg-white'>
      <div className="flex flex-col lg:flex-row h-full gap-4">
        <div className="p-4 w-full md:w-[550px] md:max-w-[50%]">
          <div onClick={() => setCurrentNodeToRank(null)} className='w-[40px] rotate-180' title='Back'>
              <Icons.NextCircleBlack/>
          </div>
          <h4>Parent:</h4>
          {/* <Node d={{ data: currentNode }} /> */}
          <div className={`${color} ${currentNode.children?.length ? "bg-yellow-400" : "bg-green-400"} w-full border border-gray-400 mb-[8px] p-[8px] rounded-md`}>
                <div className="w-full h-3 rounded-md bg-blue-600/30">
                  <div style={{ width: `${currentNode.completedPortion * 100}%` }} className="rounded-md bg-blue-600 text-white text-[8px] pl-2 text-center">
                    {parseFloat(`${currentNode.completedPortion * 100}`).toFixed(2)}%
                  </div>
                </div>
                <p className='bg-gray-300 mt-2 p-1 rounded-md mb-1'>{currentNode.objective}</p>
                <div className='flex'>
                  {currentNode.children?.length && currentNode.children?.length > 0 && <p className="text-[12px] font-bold text-end mb-0">TED: {currentNode.totalEstimatedTaskDuration}</p>}
                  {currentNode.children?.length === 0 && <p className="text-[12px] font-bold text-end mb-0">ED: {currentNode.estimatedTaskDuration}</p>}
                </div>
          </div>
        </div>

        <div className="p-4 border overflow-y-auto flex-1">
          <div className='flex items-center'>
            <div className='w-[95%]'>{impacts[currentImpact]} 
              <span className='mr-5 bg-blue-500 text-white px-2 rounded-md'>{currentNode?.objective}</span>
            </div>
            <div className='w-[40px]' onClick={handleImpactChange}>
              <Icons.NextCircleBlack/>
            </div>
          </div>
          <p className="text-center py-2">Biggest Impact</p>
          <div className="flex flex-col flex-1 h-[calc(100vh-258px)] overflow-auto border p-2 rounded-md">
            {nodesToRank.length > 0 && <DragAndDropList list={nodesToRank} setList={setNodesToRank} />}
          </div>
          <p className="text-center py-2">Smallest Impact</p>
        </div>
      </div>
    </div>
  );
}

export default Index;

async function relativeImpactValueNodeAsScale10to0Calculator(currentImpact: number, nodesToRank: NodeData[], setNodesToRank: React.Dispatch<React.SetStateAction<NodeData[]>>, dispatch: any) {
  const nodes = JSON.parse(JSON.stringify(nodesToRank));

  if (currentImpact === 0) {
    rankNodes(nodes, "size");
    setNodesToRank(nodes);
  } 
  else {
    rankNodes(nodes, "speed");

    try {
      // nodes.forEach((element:any) => {
      //   console.log(`Obj: ${element.objective},`, element.relativeImpactValueNodeAsScale10to0);
      // });
      dispatch(rankNodesManually(nodes[0].parent, nodes));
    } 
    catch (error) {
      console.log(error);
    }
  }
}
