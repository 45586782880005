

interface ShareActionButtonProps {
  color?: string;
  height?: number;
  width?: number;
  onClick?: (e:React.MouseEvent<SVGElement>)=>void;
}

export function ShareActionButton({width=50,height=50,color="#000000"}:ShareActionButtonProps) {
  return (
    
   <svg 
    fill={color} 
    width={width} 
    height={height}
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 512 512" 
    enable-background="new 0 0 512 512" 
    xmlSpace="preserve"
   >
    <g>
      <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472
        c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"/>
      <path d="M342.906,210.25c22.734,0,41.172-18.438,41.172-41.172c0-22.719-18.438-41.156-41.172-41.156s-41.172,18.438-41.172,41.156
        c0,1.781,0.312,3.438,0.516,5.156l-104.328,52.438c-7.438-7.312-17.594-11.844-28.844-11.844
        c-22.719,0-41.156,18.438-41.156,41.156c0,22.734,18.438,41.188,41.156,41.188c11.484,0,21.859-4.734,29.328-12.328l103.891,52.594
        c-0.25,1.812-0.562,3.594-0.562,5.453c0,22.75,18.438,41.188,41.172,41.188s41.172-18.438,41.172-41.188
        c0-22.703-18.438-41.172-41.172-41.172c-11.609,0-22.062,4.875-29.531,12.609l-103.719-52.5c0.266-1.922,0.594-3.828,0.594-5.844
        c0-2.219-0.328-4.391-0.656-6.547l103.562-52.016C320.656,205.281,331.188,210.25,342.906,210.25z"/>
    </g>
</svg>
  )
}
