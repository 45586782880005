import {
  SET_ADD_NODE_MODAL_OPEN,
  SET_COPIED_NODE_ID,
  SET_CUT_NODE_ID,
  SET_PARENT_ID, 
  SET_UPDATE_NODE_MODAL_OPEN,
  SET_NAVIGATE_POINTER_NODE_ID,
  SET_UPCOMMING_TASK_MODAL_OPEN,
  SET_PARENT_TASK_COMPLETE_ALERT_MODAL_OPEN,
  SET_PANNING_TREE_CENTER_NODE_AXISES,
  TOGGLE_IS_COMPLETED_NODES_VISIBLE,
  TOGGLE_IS_DELETED_NODES_VISIBLE,
  TOGGLE_IS_PANNING_TREE_CENTER,
  SET_FOCUS_BLOCKS,
  SET_CURRENT_TASK,
  SET_UPCOMING_TASK,
  SET_CURRENT_TASKS_PARENTS,
  SET_UPCOMING_TASKS_PARENTS,
  SET_TIMING_ALERT_MODAL_OPEN,
  SET_START_TIME,
  SET_END_TIME,
  SET_DEPTH,
  SET_POWER_LAW,
  SET_CAPTURE_PARK_NAVIGATOR_ID,
  SET_COLORS,
  SET_MANUALLY_SCHEDULED_NODE_ID,
  SET_WERE_YOU_WORKING,
  SET_IS_PAUSED,
  SET_IS_FULL_VIEW_ON,
  SET_IS_CURRENT_TASK_RUNNING,
  SET_OBJECTIVE_ARR,
  SET_DEADLINE,
  SET_NODE_ID_TO_BE_EDITED,
  SET_IS_SHOW_CAPTURE_UI_ON_MOBILE,
  SET_PARENT_NODE_OBJECTIVE,
  SET_PARENT_NODE_TYPE,
  SET_COPIED_OR_CUT_NODE_OBJECTIVE,
  SET_IS_CREATE_COGNIPULSE_MODAL_OPEN,
  SET_COGNIPULSE_GOALS,
  SET_IS_CREATE_IMPULSE_MODAL_OPEN,
  SET_IS_ADD_TREE_MODAL_OPEN,
  SET_IS_ADMIN_DASHBOARD_VIEW,
  SET_IS_PHY_SELFIE_MODAL_OPEN,
  RESET_GLOBAL_STATES,
  SET_CURRENT_TASK_TO_COC,
  SET_NODE_DASHBOARD_DATA,
  SET_IS_SHOW_DEEPER_INSIGHTS,
  SET_IS_SHOW_UNLOCK_POTENTIAL_NOW,
  SET_SELECTED_ACTIVITY_ID,
  SET_NEW_COGNIPULSE_ID,
  SET_IS_ADDING_CAPTURE_PARK_NODE,
  SET_IS_DELEGATE_NODE_MODAL_OPEN,
  SET_NODE_TO_DELEGATE,
  SET_IS_CREATE_IMPULSE_AFTER_TASK_COMPLETE_MODAL_OPEN,
  SET_IS_PAUSE_MODAL_OPEN,
  SET_CURRENT_VIEW,
  SET_IS_RATING_AFTER_COMPLETE_MODAL_OPEN
} from "../../constants";


export type ViewTypes = "both" | "capture" | "focus";

interface State {
  createNodeBtnClickTime: number;
  updateNodeBtnClickTime: number;
  isAddNodeModalOpen: boolean;
  isUpdateNodeModalOpen: boolean;
  isTimingAlertModalOpen: boolean;
  isUpcomingTaskModalOpen: boolean;
  isParentTaskCompleteAlertModalOpen: boolean;
  isPanningTreeCenter: boolean;
  parentId: string | null;
  node: any | null; // Define this type based on the structure of node
  copiedNodeId: string | null;
  cutNodeId: string | null;
  navigatePointerNodeId: string | null;
  panningTreeCenterNodeAxises: any | null; // Define this type based on the structure of panningTreeCenterNodeAxises
  isCompletedNodesVisible: boolean;
  isDeletedNodesVisible: boolean;
  focusBlocks: any | null; // Define this type based on the structure of focusBlocks
  currentTask: any | null; // Define this type based on the structure of currentTask
  upcomingTask: any | null; // Define this type based on the structure of upcomingTask
  currentTasksParents: any | null; // Define this type based on the structure of currentTasksParents
  upcomingTasksParents: any | null; // Define this type based on the structure of upcomingTasksParents
  startTime: any; // Define this type based on the structure of startTime
  endTime: any; // Define this type based on the structure of endTime
  depth: number;
  powerLaw: number;
  captureParkNavigatorId: string | null;
  colors: any | null; // Define this type based on the structure of colors
  manuallyScheduleNodeId: any;
  wereYouWorking: any | null; // Define this type based on the structure of wereYouWorking
  isPaused: boolean;
  isFullViewOn: boolean;
  challenges: any[]; // Define this type based on the structure of challenges
  isCurrentTaskRunning: boolean;
  objectiveArr: string[] | null;
  deadline: string | null;
  nodeIdToBeEdited: string | null;
  isShowCaptureUIOnMobile: boolean;
  parentNodeObjective: string;
  parentNodeType: string;
  copiedOrCurNodeObjective: string;
  isCreateCognipulseModalOpen: boolean;
  isCreateImpulseModalOpen: boolean;
  isAddTreeModalOpen: boolean;
  isAdminDashboardView: boolean;
  isPhySelfieModalOpen: boolean;
  cognipulseGoals: {
    currentGoal: any;
    parentGoal: any;
    categoryOfImprovementGoal: any;
  },
  currentTaskToCOC: any[];
  nodeDeshboardData: any;
  isShowDeeperInsights: boolean;
  isShowUnlockPotentialNow: boolean;
  selectedActivityId: string | null;
  newCognipulseId: string | null;
  isAddingCaptureParkNode: boolean;
  isDelegateNodeModalOpen: boolean;
  nodeToDelegate: Node | null;
  isCreateImpulseAfterTaskCompleteModalOpen: boolean;
  isPauseModalOpen: boolean;
  currentView: ViewTypes;
  isRatingAfterCompleteModalOpen: boolean;
}

interface Action {
  type: string;
  payload: any; // Define this type based on the payload of each action
}

const defaultState: State = {
  createNodeBtnClickTime: 0,
  updateNodeBtnClickTime: 0,
  isAddNodeModalOpen: false,
  isUpdateNodeModalOpen: false,
  isTimingAlertModalOpen: false,
  isUpcomingTaskModalOpen: false,
  isParentTaskCompleteAlertModalOpen: false,
  isPanningTreeCenter: false,
  parentId: null,
  node: null,
  copiedNodeId: null,
  cutNodeId: null,
  navigatePointerNodeId: null,
  panningTreeCenterNodeAxises: null,
  isCompletedNodesVisible: false,
  isDeletedNodesVisible: false,
  focusBlocks: null,
  currentTask: null,
  upcomingTask: null,
  currentTasksParents: null,
  upcomingTasksParents: null,
  startTime: null,
  endTime: null,
  depth: 0,
  powerLaw: 2,
  captureParkNavigatorId: null,
  colors: null,
  manuallyScheduleNodeId: null,
  wereYouWorking: null,
  isPaused: false,
  isFullViewOn: false,
  challenges: [],
  isCurrentTaskRunning: false,
  objectiveArr: null,
  deadline: null,
  nodeIdToBeEdited: null,
  isShowCaptureUIOnMobile: true,
  parentNodeObjective: "",
  parentNodeType: "",
  copiedOrCurNodeObjective: "",
  isCreateCognipulseModalOpen: false,
  isCreateImpulseModalOpen: false,
  isAddTreeModalOpen: false,
  cognipulseGoals: {
    currentGoal: null,
    parentGoal: null,
    categoryOfImprovementGoal: null
  },
  isAdminDashboardView: false,
  isPhySelfieModalOpen: false,
  currentTaskToCOC: [],
  nodeDeshboardData: null,
  isShowDeeperInsights: false,
  isShowUnlockPotentialNow: false,
  selectedActivityId: null,
  newCognipulseId: null,
  isAddingCaptureParkNode: false,
  isDelegateNodeModalOpen: false,
  nodeToDelegate: null,
  isCreateImpulseAfterTaskCompleteModalOpen: false,
  isPauseModalOpen: false,
  currentView: "both",
  isRatingAfterCompleteModalOpen: false,
};

const globalStates = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case RESET_GLOBAL_STATES:
      return defaultState;
    case SET_ADD_NODE_MODAL_OPEN:
      return {
        ...state,
        isAddNodeModalOpen: action.payload,
        createNodeBtnClickTime: action.payload ? new Date().getTime() : 0
      };
    case SET_UPDATE_NODE_MODAL_OPEN:
      return {
        ...state,
        isUpdateNodeModalOpen: action.payload,
        updateNodeBtnClickTime: action.payload ? new Date().getTime() : 0,
        node: action.payload ? state.node : null
      };
    case SET_TIMING_ALERT_MODAL_OPEN:
      return {
        ...state,
        isTimingAlertModalOpen: action.payload
      };
    case SET_UPCOMMING_TASK_MODAL_OPEN:
      return {
        ...state,
        isUpcomingTaskModalOpen: action.payload
      };
    case SET_PARENT_TASK_COMPLETE_ALERT_MODAL_OPEN:
      return {
        ...state,
        isParentTaskCompleteAlertModalOpen: action.payload
      };
    case SET_PARENT_ID:
      return {
        ...state,
        parentId: action.payload,
      };
    case SET_DEPTH:
      return {
        ...state,
        depth: action.payload,
      };
    case SET_COPIED_NODE_ID:
      return {
        ...state,
        copiedNodeId: action.payload,
        cutNodeId: null,
      };
    case SET_CUT_NODE_ID:
      return {
        ...state,
        cutNodeId: action.payload,
        copiedNodeId: null,
      };
    case SET_NAVIGATE_POINTER_NODE_ID:
      return {
        ...state,
        navigatePointerNodeId: action.payload,
      };
    case SET_PANNING_TREE_CENTER_NODE_AXISES:
      return {
        ...state,
        panningTreeCenterNodeAxises: action.payload,
      };
    case TOGGLE_IS_COMPLETED_NODES_VISIBLE:
      return {
        ...state,
        isCompletedNodesVisible: !state.isCompletedNodesVisible,
      };
    case TOGGLE_IS_DELETED_NODES_VISIBLE:
      return {
        ...state,
        isDeletedNodesVisible: !state.isDeletedNodesVisible,
      };
    case TOGGLE_IS_PANNING_TREE_CENTER:
      return {
        ...state,
        isPanningTreeCenter: !state.isPanningTreeCenter,
      };
    case SET_FOCUS_BLOCKS:
      return {
        ...state,
        focusBlocks: action.payload,
      };
    case SET_CURRENT_TASK:
      return {
        ...state,
        currentTask: action.payload,
      };
    case SET_CURRENT_TASKS_PARENTS:
      return {
        ...state,
        currentTasksParents: action.payload,
      };
    case SET_UPCOMING_TASK:
      return {
        ...state,
        upcomingTask: action.payload,
      };
    case SET_UPCOMING_TASKS_PARENTS:
      return {
        ...state,
        upcomingTasksParents: action.payload,
      };
    case SET_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      };
    case SET_END_TIME:
      return {
        ...state,
        endTime: action.payload,
      };
    case SET_POWER_LAW:
      return {
        ...state,
        powerLaw: action.payload,
      };
    case SET_CAPTURE_PARK_NAVIGATOR_ID:
      return {
        ...state,
        captureParkNavigatorId: action.payload,
      };
    case SET_COLORS:
      return {
        ...state,
        colors: action.payload,
      };
    case SET_MANUALLY_SCHEDULED_NODE_ID:
      return {
        ...state,
        manuallyScheduleNodeId: action.payload,
      };
    case SET_WERE_YOU_WORKING:
      return {
        ...state,
        wereYouWorking: action.payload,
      };
    case SET_IS_PAUSED:
      return {
        ...state,
        isPaused: action.payload,
      };
    case SET_IS_FULL_VIEW_ON:
      return {
        ...state,
        isFullViewOn: action.payload,
      };
    case SET_IS_CURRENT_TASK_RUNNING:
      return {
        ...state,
        isCurrentTaskRunning: action.payload,
      };
    case SET_OBJECTIVE_ARR:
      return {
        ...state,
        objectiveArr: action.payload,
      };
    case SET_DEADLINE:
      return {
        ...state,
        deadline: action.payload,
      };
    case SET_NODE_ID_TO_BE_EDITED:
      return {
        ...state,
        nodeIdToBeEdited: action.payload,
      };
    case SET_IS_SHOW_CAPTURE_UI_ON_MOBILE:
      return {
        ...state,
        isShowCaptureUIOnMobile: action.payload,
      };
    case SET_PARENT_NODE_OBJECTIVE:
      return {
        ...state,
        parentNodeObjective: action.payload,
      };
    case SET_PARENT_NODE_TYPE:
      return {
        ...state,
        parentNodeType: action.payload,
      };
    case SET_COPIED_OR_CUT_NODE_OBJECTIVE:
      return {
        ...state,
        copiedOrCurNodeObjective: action.payload,
      };
    case SET_IS_CREATE_COGNIPULSE_MODAL_OPEN:
      return {
        ...state,
        isCreateCognipulseModalOpen: action.payload,
      };
    case SET_IS_CREATE_IMPULSE_MODAL_OPEN:
      return {
        ...state,
        isCreateImpulseModalOpen: action.payload,
      };
    case SET_COGNIPULSE_GOALS:
      return {
        ...state,
        cognipulseGoals: action.payload,
      };
    case SET_IS_ADD_TREE_MODAL_OPEN:
      return {
        ...state,
        isAddTreeModalOpen: action.payload,
      };
    case SET_IS_ADMIN_DASHBOARD_VIEW:
      return {
        ...state,
        isAdminDashboardView: action.payload,
      };
    case SET_IS_PHY_SELFIE_MODAL_OPEN:
      return {
        ...state,
        isPhySelfieModalOpen: action.payload,
      };
    case SET_CURRENT_TASK_TO_COC:
      return {
        ...state,
        currentTaskToCOC: action.payload,
      };
    case SET_NODE_DASHBOARD_DATA:
      return {
        ...state,
        nodeDeshboardData: action.payload,
      };
    case SET_IS_SHOW_DEEPER_INSIGHTS:
      return {
        ...state,
        isShowDeeperInsights: action.payload,
      };
    case SET_IS_SHOW_UNLOCK_POTENTIAL_NOW:
      return {
        ...state,
        isShowUnlockPotentialNow: action.payload,
      };
    case SET_SELECTED_ACTIVITY_ID:
      return {
        ...state,
        selectedActivityId: action.payload,
      };
    case SET_NEW_COGNIPULSE_ID:
      return {
        ...state,
        newCognipulseId: action.payload,
      };
    case SET_IS_ADDING_CAPTURE_PARK_NODE:
      return {
        ...state,
        isAddingCaptureParkNode: action.payload,
      };
    case SET_IS_DELEGATE_NODE_MODAL_OPEN:
      return {
        ...state,
        isDelegateNodeModalOpen: action.payload,
      };
    case SET_NODE_TO_DELEGATE:
      return {
        ...state,
        nodeToDelegate: action.payload,
      };
    case SET_IS_CREATE_IMPULSE_AFTER_TASK_COMPLETE_MODAL_OPEN:
      return {
        ...state,
        isCreateImpulseAfterTaskCompleteModalOpen: action.payload,
      };
    case SET_IS_PAUSE_MODAL_OPEN:
      return {
        ...state,
        isPauseModalOpen: action.payload,
      };
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.payload,
      };
    case SET_IS_RATING_AFTER_COMPLETE_MODAL_OPEN:
      return {
        ...state,
        isRatingAfterCompleteModalOpen: action.payload,
      };
    default:
      return state;
  }
}

export default globalStates;
