

interface PlayActionButtonProps {
    color?: string;
    height?: number;
    width?: number;
    onClick?: (e:React.MouseEvent<SVGElement>)=>void;
}

export function PlayActionButton({width=50,height=50,color="#000000"}:PlayActionButtonProps) {
  return (
    <svg
        fill={color} 
        width={width} 
        height={height} 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        viewBox="0 0 512 512" 
        enable-background="new 0 0 512 512" 
        xmlSpace="preserve"
    >
<path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472
	c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"/>
<path d="M353.661,237.879l-154.174-89.594c-16.844-9.969-32.987-1.938-32.987,17.844v179.766c0,19.75,16.143,27.797,32.987,17.812
	l152.955-89.578C369.348,264.16,370.552,247.848,353.661,237.879z"/>
</svg>
  )
}
