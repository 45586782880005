
export const APP_VERSION = "{{576}}";

const version = 'api/v1';

let root = "http://localhost:5000";


if(window.location.hostname !== "localhost" || isMobileTablet()){
    root = "https://tenxproductivityreactapp.herokuapp.com";
}


export const DOMAIN_NAME = `${root}/${version}`;


export const NODE_TYPES = ["PROJECT_MISSION","MOONSHOT_OBJECTIVE","OBJECTIVE","KEY_RESULT","TASK"];


export const PRIMARY_COLOR = "#DDDDDD";
export const SECONDRY_COLOR = "#666666";
export const ACCENT_COLOR = "#333333";
export const PRIMARY_BUTTON_COLOR = "#001f3f";
export const SECONDRY_BUTTON_COLOR = "#39CCCC";

const gapBetweenQuadrant = .05;
export const QUADRANTS = [
    {
        title: "Emergent",
        desc: "Untapped Potential",
        angles: {
        startAngle: (Math.PI/2)+gapBetweenQuadrant,
        endAngle: Math.PI-gapBetweenQuadrant,
        },
        fill: '#d40404',
        labels: [
        {
            text: 'Lack of Goal Clarity',
            img: '',
            tooltip: "You possess significant potential to refine and clarify your goals, creating a clear direction that fuels your purpose and motivation.",
        },
        {
            text: 'Low Intrinsic Motivation',
            img: '',
            tooltip: "There's a vast reserve of internal motivation within you, waiting to be tapped into to energise your engagement and efforts.",
        },
        {
            text: 'Resistance to Change',
            img: '',
        tooltip: "You have the inherent ability to become more adaptable, turning new situations, processes, or ideas into opportunities for growth.",
        },
        {
            text: 'Fear of Failure',
            img: '',
            tooltip: "Your potential to reframe failure as a learning opportunity is immense, reducing apprehension and encouraging initiative.",
        },
        {
            text: 'Decision-Making Paralysis',
            img: '',
            tooltip: "You are well-equipped to overcome decision-making paralysis, with the capacity to navigate choices more decisively and confidently.",
        },
        {
            text: 'Emotional Exhaustion',
            img: '',
            tooltip: "There's a profound potential within you to replenish your emotional energy, fostering renewed interest and motivation.",
        },
        {
        text: 'Limited Self-Efficacy',
        img: '',
        tooltip: "You have an enormous latent potential to enhance your belief in your abilities, driving you to take action and achieve your goals.",
        },
        {
        text: 'Cognitive Overload',
        img: '',
        tooltip: "You have a lot of potential to reduce the impact of overwhelming information or demands on your capacity to engage or make decisions.",
        },
        ]
    },
    {
        title: "Focused",
        desc: "Tactical Potential",
        angles: {
        startAngle: Math.PI+gapBetweenQuadrant,
        endAngle: (Math.PI+(Math.PI/2))-gapBetweenQuadrant,
        },
        fill: '#ffa805',
        labels: [
        {
            text: 'Efficient Decision Making',
            img: '',
            tooltip: "Your current potential to make informed and timely decisions based on familiar contexts.",
        },
        {
            text: 'Operational Awareness',
            img: '',
            tooltip: "Your current potential in maintaining awareness of standard operations and workflow efficiency.",
        },
        {
            text: 'Consistent Skill Execution',
            img: '',
        tooltip: "Your current potential in reliably performing well-practised skills within routine tasks.",
        },
        {
            text: 'Effective Resource Management',
            img: '',
            tooltip: "Your current potential in organising and utilising resources efficiently in known operational settings.",
        },
        {
            text: 'Adaptability to Minor Changes',
            img: '',
            tooltip: "Your current potential in adjusting methods or approaches to minor variations in familiar procedures.",
        },
        {
            text: 'Routine Problem-Solving',
            img: '',
            tooltip: "Your current potential in applying known solutions to common routine operations issues.",
        },
        {
        text: 'Standard Risk Assessment',
        img: '',
        tooltip: "Your current potential in identifying and managing typical risks in standard tasks and processes.",
        }
        ]
    },
    {
        title: "Productive Flow",
        desc: "High Adaptive & Tactical Potential",
        angles: {
        startAngle: (Math.PI+(Math.PI/2))+gapBetweenQuadrant,
        endAngle: (Math.PI+(Math.PI))-gapBetweenQuadrant,
        },
        fill: '#26ff05',
        labels: [
        {
            text: 'Learning Agility',
            img: '',
            tooltip: "Your current potential in learning new tasks.",
        },
        {
            text: 'Cultural Flexibility',
            img: '',
            tooltip: "Your current potential for cultural adaptability.",
        },
        {
            text: 'Interpersonal Adaptability',
            img: '',
        tooltip: "The extent to which you can utilise your interpersonal adaptability skills.",
        },
        {
            text: 'Goal-Oriented Creativity',
            img: '',
            tooltip: "Your current potential in goal-oriented problem-solving.",
        },
        {
            text: 'Stress Management Capacity',
            img: '',
            tooltip: "Your current potential to handle stress.",
        },
        {
            text: 'Crisis Response Effectiveness',
            img: '',
            tooltip: "Your current potential to effectively manage a crisis.",
        },
        {
            text: 'Uncertainty Adaptability',
            img: '',
            tooltip: "Your current personal potential to adjust and effectively respond to physically demanding or changing environments.",
        },
        {
        text: 'Physical Adaptability',
        img: '',
        tooltip: "Your current potential for handling uncertain situations.",
        },
        ]
    },
    {
        title: "Creative Flow",
        desc: "High Adaptive & Lower Tactical Potential",
        angles: {
        startAngle: (Math.PI+(Math.PI))+gapBetweenQuadrant,
        endAngle: (Math.PI+(Math.PI)+(Math.PI/2))-gapBetweenQuadrant,
        },
        fill: '#ffe205',
        labels: [
        {
            text: 'Divergent Thinking',
            img: '',
            tooltip: "Your current potential in generating multiple, unique solutions to open-ended problems.",
        },
        {
            text: 'Conceptual Expansion',
            img: '',
            tooltip: "Your current potential in broadening perspectives and integrating disparate ideas into cohesive, innovative concepts.",
        },
        {
            text: 'Curiosity and Exploration',
            img: '',
        tooltip: "Your current potential in seeking new information, experiences, and insights to fuel creativity.",
        },
        {
            text: 'Risk-Taking in Idea Generation',
            img: '',
            tooltip: " Your current potential in proposing bold, unconventional ideas, even at the risk of failure.",
        },
        {
            text: 'Persistence Through Creative Blocks',
            img: '',
            tooltip: " Your current potential to maintain momentum in creative endeavours, even when faced with obstacles.",
        },
        {
            text: 'Collaborative Creativity',
            img: '',
            tooltip: "Your current potential in leveraging group dynamics to enhance idea generation and innovation.",
        },
        {
            text: 'Adaptive Expertise',
            img: '',
            tooltip: "Your current potential in applying deep, flexible knowledge to novel and complex problems.",
        },
        {
        text: 'Openness to Experience',
        img: '',
        tooltip: "Your potential to embrace new experiences, perspectives, and challenges to enrich creativity.",
        },
        ]
    },
];


export  const EMOJIES = [
    {emoji: "😀", name: "Happy"},
    {emoji: "😔", name: "Pensive"},
    {emoji: "😳", name: "Embarrassed"},
    {emoji: "😫", name: "Overwhelmed"},
    {emoji: "😟", name: "Unhappy"},
    {emoji: "😎", name: "In the Zone"},
    {emoji: "😊", name: "Positive"},
    {emoji: "🥰", name: "Loved"},
    {emoji: "😧", name: "Worried"},
    {emoji: "😠", name: "Angry"},
    {emoji: "🤬", name: "Very angry"},
    {emoji: "​​😕", name: "Uncertain"},
    {emoji: "​😲​", name: "Surprised"},
    {emoji: "😔​​", name: "Sad"},
    {emoji: "🥱", name: "Tired"},
    {emoji: "😎", name: "Cool"},
    {emoji: "😐", name: "Ok-ish"},
    {emoji: "🥳", name: "Excited"},
    {emoji: "💪", name: "Motivated"},
    {emoji: "😫", name: "Stressed"},
    {emoji: "🥺", name: "Lonely"},
    {emoji: "🙏", name: "Grateful"},
    {emoji: "😲", name: "Surprised"},
    {emoji: "😤", name: "Frustrated"},
    {emoji: "😰", name: "Anxious"},
    {emoji: "🧐", name: "Deep Thought"},
    {emoji: "🌊", name: "In Flow"}
  ]


  export const FACTOR_COLORS = {
    XLSCORE: "#66FF00",
    FUN: "#F5A571",
    PURPOSE: "#73B5EB",
    GROWTH: "#86D1AC",
    EMOTIVES: "#F19B96",
    UTILITY: "#8A9AED",
    APATHY: "#8F7472",
    CHALLENGE: "#ff6666"
} 


export const XL_FACTORS = [
    "Fun",
    "Purpose",
    "Growth",
    "Emotives",
    "Utility",
    "Apathy",
    "Challenge",
    "SelfEfficacy",
    "Autonomy",
    "ClearGoal",
    "Feedback",
];


function isMobileTablet(){
    let check = false;
    let myWindow:any = window;
    (function(a){
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) 
            check = true;
    })(navigator.userAgent || navigator.vendor || myWindow.opera);
    return check;
}




  
  































