import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { setNavigatePointerNodeId, setNodeIdToBeEdited, setUpdateNodeModalOpen } from '../../store/actions/globalStates';
// import { Main } from "../../layout";
import { useNavigate } from 'react-router-dom';
import { NewButton } from '../../components/Buttons/NewButton';

// Define the type for the Node structure
interface Node {
  _id: string;
  objective: string;
  objectiveArr: string[];
  deadline?: string;
  isCompleted?: boolean;
  isDeleted?: boolean;
  children?: Node[];
  parent?: Node;
  depth: number
}


// Define the root state structure
interface RootState {
  tree: Node;
}

function Index() {
  const root = useSelector((state: RootState) => state.tree);
  const [nodes, setNodes] = useState<Node[]>([]);
  const dispatch:any = useDispatch();
  const navigator = useNavigate();

  const updateNode = (d: Node) => {
    dispatch(setUpdateNodeModalOpen(true));
    dispatch(setNodeIdToBeEdited(d._id));
  };

  const goToNode = (d: Node) => {
    navigator("/");
    dispatch(setNavigatePointerNodeId(d._id));
  };

  useEffect(() => {
    const rootNode = JSON.parse(JSON.stringify(root));
    parentNodeCompletionAndDeletion(rootNode);
    let nodes: Node[] = [];

    function visit(d: Node) {
      // Set the depth
      if (!d.parent) {
        d.depth = 0;
      }
      d.children?.forEach(ch => {
        ch.depth = d.depth + 1;
      });

      if (d.deadline && !d.isCompleted && !d.isDeleted) {
        nodes = [...nodes, { ...d }];
      }

      d.children?.forEach(child => visit(child));
    }
    visit(rootNode);

    if (nodes.length !== 0) {
      setNodes(nodes.sort((a, b) => moment(b.deadline).isAfter(moment(a.deadline)) ? -1 : 1));
    }
  }, [root]);

  return (
    <>
      <div className='p-2 h-full overflow-auto'>
        <h4 className='text-center uppercase'>Deadlines</h4>
        <div className="mt-10 flex flex-col gap-2">
          {
            nodes.length === 0 && <div className="text-center">No Deadline Found</div>
          }
          {
            nodes.map((item, index) => (
              <div key={index} className='bg-gray-200 p-2 rounded-md flex gap-5 items-center'>
                <div className="flex-1 flex justify-between">
                  <div>{item?.objectiveArr?.length > 1 ? item.objectiveArr.join(" ") : item.objective}</div>
                  <div>{item.children?.length && item.children?.length > 0 ? "" : "Leaf Node"}</div>
                </div>
                <div className="flex gap-2">
                  <div onClick={() => updateNode(item)} className='px-2 text-white flex items-center' style={{ backgroundColor: moment(item.deadline).isBefore(moment()) ? "rgb(185 28 28)" : "rgb(21 128 61)" }}>
                    {moment(item.deadline).format("DD-MM-YYYY")}
                  </div>
                  <NewButton
                    label="Go to"
                    onClick={(e) =>{e.preventDefault();goToNode(item)}}
                  />
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
}

export default Index;

function parentNodeCompletionAndDeletion(root: Node) {
  function visit(d: Node) {
    if (d.children) {
      d.children.forEach(td => visit(td));

      const completedNodes = d.children.filter(node => node.isCompleted);
      if (d.children.length > 0 && completedNodes.length === d.children.length) {
        d.isCompleted = true;
      }

      const deletedNodes = d.children.filter(node => node.isDeleted);
      if (d.children.length > 0 && deletedNodes.length === d.children.length) {
        d.isDeleted = true;
      }
    }
  }
  visit(root);
}
