  import { arc } from "d3";
  import { QUADRANTS, SECONDRY_COLOR } from "@constants/index";
  import { motion } from "framer-motion";

  interface IQuadrants {
    radius: any;
    SVGHeight: any;
    SVGWidth: any;
    shadowId: any;
    chartName: "XL_SCORE_CHART" | "FLOW_SCORE_CHART";
    id: string;
  }
  
  export const Quadrants: React.FC<IQuadrants> = ({chartName,radius,SVGHeight,SVGWidth,shadowId,id}) => {
    const arcGenerator:any = arc().innerRadius(radius+10).outerRadius(radius+15);
    const secondLayerTextArc =  arc().innerRadius(radius+30).outerRadius(radius+30);
    const secondLayerTextArcBottom =  arc().innerRadius(radius+39).outerRadius(radius+39); 
  
  
    if(chartName === "FLOW_SCORE_CHART"){
      const quard = [
          {
            title: "Low Flow",
            desc: "Minimal engagement, limited performance",
            fill: '#008080'
          },
          {
              title: "Below Average Flow",
              desc: "Some engagement, below optimal performance",
              fill: '#007e8c'
          },
          {
              title: "Moderate Flow",
              desc: "Steady engagement, moderate performance",
              fill: '#439fa3'
          },
          {
              title: "High Flow",
              desc: "High engagement, effective performancel",
              fill: '#78c0ba'
          },
          {
              title: "Optimal Flow",
              desc: "Peak engagement, maximised performance",
              fill: '#a2fdfd'
          }
      ]
      const arcGenerator:any = arc().innerRadius(radius+10).outerRadius(radius+15);
      const textArcGenerator:any = arc().innerRadius(radius+30).outerRadius(radius+30);
      const oppositeTextArcGenerator:any = arc().innerRadius(radius+42).outerRadius(radius+42);
  
        return (
              <>
              <g transform={`translate(${SVGWidth/2},${SVGHeight/2})`} >
                {
                  quard.map((quadrant,idx) => {
                    const dur = 0;
                    const parentDur = dur*3;
                    const delay = idx*parentDur;
                    const quadrantAngle = (Math.PI*2)/5;
                    const startFrom = Math.PI/2;
                    const gapBetweeenSections = .05;
                    const startAngle = ((quadrantAngle*idx)+startFrom)+gapBetweeenSections;
                    const endAngle = ((quadrantAngle*(idx+1))+startFrom)-gapBetweeenSections;
                    const isOpposite = (idx === 0 || idx === 1)? true:false;
                    const pathId = `slt-${idx}-${id}`
                    
                    return <g key={idx}>
      
                        <motion.path filter="url(#shadow)"  transition={{duration: dur,delay: delay+0}} initial={{opacity: 0}} animate={{opacity: 1}} fill={quadrant.fill} d={arcGenerator({startAngle,endAngle})}/>
                    
                        <path fill="black" d={!isOpposite? oppositeTextArcGenerator({startAngle,endAngle}):textArcGenerator({startAngle,endAngle})}></path>
       
                        <path id={pathId} fill="black" d={isOpposite? oppositeTextArcGenerator({startAngle,endAngle}):textArcGenerator({startAngle,endAngle})}/>
                        
                        <text fill={SECONDRY_COLOR}>
                          <textPath  href={`#${pathId}`} className='text-sm' textAnchor='middle' startOffset={isOpposite? "75%":"25%"}>
                              {quadrant.title}
                          </textPath>
                        </text>
                    </g>
                  })
                }
              </g>
          </>
        )
    }
  
  
    return (
      <>
        <g transform={`translate(${SVGWidth/2},${SVGHeight/2})`} >
            {
              QUADRANTS.map((quadrant,idx) => {
                const isBQ = idx===0 || idx===1;
                const slt:any = isBQ? secondLayerTextArcBottom:secondLayerTextArc;
                const dur =  0;
                const parentDur = dur*3;
                const delay = idx*parentDur;
                const pathId = `slt-${idx}-${id}`
  
                return <motion.g key={idx} transition={{duration: parentDur,delay: delay}} initial={{opacity: 0}} animate={{opacity: 1}}>
                    {/* quadrant arcs  */}
                    <motion.path filter={`url(#${shadowId})`}  transition={{duration: dur,delay: delay+0}} initial={{opacity: 0}} animate={{opacity: 1}} fill={idx === 0? "#dc6d60":idx === 1? "#ffa162":idx === 2? "#64e55a":"#eee164"} d={arcGenerator(quadrant.angles)}/>
                    
                    {/* second layer text  */}
                    <path id={pathId} fill="black" d={slt(quadrant.angles)}/>
                    <motion.text fill={SECONDRY_COLOR} transition={{duration: dur,delay: delay+.2}} initial={{opacity: 0}} animate={{opacity: 1}}>
                      <textPath className='text-sm' startOffset={isBQ? "75%":"25%"} textAnchor='middle' href={`#${pathId}`}>
                          {quadrant.title.replace(" Flow","")}
                      </textPath>
                    </motion.text>
                </motion.g>
              })
            }
        </g>
      </>
    )
  }