import React from "react";
import { hierarchy, partition } from "d3";
import { useDispatch, useSelector } from "react-redux";
import { setIsFullViewOn, setNavigatePointerNodeId } from "../../../store/actions";
import { numberInRange, rankNodes } from "../../../utils";
import moment from "moment";
import { RootState } from "../../../store/store";
import { adjustBrightness } from "@utils/adjustBrightness";

// Define the structure of Node
interface Node {
  _id: string;
  objective: string;
  children: Node[];
  isCompleted: boolean;
  isDeleted: boolean;
  updatedAt: string;
  color?: string;
  taskOrder?: number;
  relativeImpactValueNodeAsScale10to0?: number;
  recurring?: { frequency: number };
  isCapturePark?: boolean;
  value: any;
  isTempCompleted: any;
}

// Define the props for FullView
interface FullViewProps {
  data: Node;
  width: number;
  height: number;
}

function FullView({ data, width, height }: FullViewProps) {
  const isCompletedNodesVisible = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible);
  const isDeletedNodesVisible = useSelector((state: RootState) => state.globalStates.isDeletedNodesVisible);
  const dispatch:any = useDispatch();
  const values: number[] = [];

  function addValueKey(node: Node) {
    if (node.children.length === 0) {
      let val = numberInRange(10, 5000);
      while (values.indexOf(val) !== -1) {
        val = numberInRange(10, 5000);
      }
      values.push(val);
      node.value = val;
    }

    if (node.children) {
      node.children.forEach(item => {
        addValueKey(item);
      });
    }
  }

  cal(data, isCompletedNodesVisible, isDeletedNodesVisible);
  addValueKey(data);

  const _partition = partition<Node>()
    .size([height, width])
    .padding(1);

  const root = _partition(hierarchy(data)
    .sum(d => d.value)
  );

  const handleClick = (d: any) => {
    let parent = d.parent;
    let isChildOfCapturePark = false;

    while (parent !== null) {
      if (parent.data.isCapturePark) {
        isChildOfCapturePark = true;
        parent = null;
      } else {
        parent = parent.parent;
      }
    }

    if (!isChildOfCapturePark) {
      dispatch(setNavigatePointerNodeId(d.data._id));
      dispatch(setIsFullViewOn(false));
    }
  }

    
  return (
    <svg width={width} height={height}>
      <g transform='translate(0,0)'>
        {
          root.descendants().map(d => (
            <g key={d.data._id} transform={`translate(${d.y0},${d.x0})`}>
              <foreignObject
                width={d.y1 - d.y0}
                height={d.x1 - d.x0}
                onClick={() => handleClick(d)}>
                <TempNode d={d} height={d.x1 - d.x0} />
              </foreignObject>
            </g>
          ))
        }
      </g>
    </svg>
  );
}

export default FullView;



function TempNode({ d }: { d: any,height: any }) {
  const navigatorId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);

  return (
    <div
      title={d.data.objective}
      className='w-full h-full py-1 px-2'
      style={{ backgroundColor: adjustBrightness(d.data), border: d.data._id === navigatorId ? "5px solid black" : "0px solid black" }}>
      {d.data.objective}
    </div>
  );
}

function cal(data: Node, isShowCompleted: boolean, isShowDeleted: boolean) {
  const categorysOfChangeIds: string[] = [];

  data.children.forEach(child => {
    child.children.forEach(ch => {
      categorysOfChangeIds.push(ch._id);
    });
  });

  function visit(d: Node) {
    if(!d.color){
      d.color = "#708989"
    }

    if (d.children) {
      d.children.forEach(child => {
        child.color = child.color || d.color;
        return visit(child);
      });
      parentNodeComplete(d);
      parentNodeDelete(d);
    }
  }
  visit(data);

  function visit1(d: Node) {
    relativeImpactValueNodeAsScale10to0(d);

    if (d.children) {
      d.children.forEach(child => visit1(child));
    }
  }
  visit1(data);

  function relativeImpactValueNodeAsScale10to0(d: Node) {
    const isRanked = d.children.find(child => (!child.relativeImpactValueNodeAsScale10to0 && !child.isDeleted));
    const completedOnes = [...d.children].filter(child => (child.isCompleted || child.isDeleted));
    const notCompletedOnes = [...d.children].filter(child => (!child.isCompleted && !child.isDeleted));
    d.children = [...notCompletedOnes, ...completedOnes];

    if (d.children?.length !== 0 && isRanked) {
      rankNodes(d.children, "both");
    }

    const childrenWithTaskOrder = [...d.children].filter((child:any) => (child.taskOrder > 0 && !child.isCompleted && !child.isDeleted)).sort((a:any, b:any) => a.taskOrder - b.taskOrder);
    const childrenWithNoTaskOrder = [...d.children].filter(child => ((!child.taskOrder || child.taskOrder <= 0) && !child.isCompleted && !child.isDeleted)).sort((a:any, b:any) => b.relativeImpactValueNodeAsScale10to0 - a.relativeImpactValueNodeAsScale10to0);
    const deletedOrCompletedChildren = [...d.children].filter(child => (child.isCompleted || child.isDeleted));

    d.children = [...childrenWithTaskOrder, ...childrenWithNoTaskOrder, ...deletedOrCompletedChildren];
  }

  // mark node completed if children are completed
  function parentNodeComplete(d: Node) {
    if (d.isCompleted) {
      return;
    }

    const isCOI = categorysOfChangeIds.indexOf(d._id) !== -1;

    // iteration from leaf to root 
    const completedNodes = d.children.filter(node => (node.isCompleted || node.isDeleted));
    if (!isCOI && (d.children.length > 0 && (completedNodes.length === d.children.length))) {
      d.isCompleted = true;
      d.isTempCompleted = true;
    }

    const isCompletedToday = d.children.find(node => moment(node.updatedAt).startOf("day").isSame(moment().startOf("day")));
    if (!isCompletedToday && d?.recurring?.frequency && !isCOI && (d.children.length > 0 && (completedNodes.length === d.children.length))) {
      d.isCompleted = false;
      d.isTempCompleted = false;
    }

    if (!isShowCompleted) {
      d.children = d.children.filter(c => !c.isCompleted);
    }
  }

  // mark node deleted if children are deleted and delete it from UI 
  function parentNodeDelete(d: Node) {
    const deletedNodes = d.children.filter(node => node.isDeleted);
    if (d.children.length > 0 && (deletedNodes.length === d.children.length)) {
      d.isDeleted = true;
    }

    if (!isShowDeleted) {
      d.children = d.children.filter(c => !c.isDeleted);
    }
  }
}
