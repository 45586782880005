import React,{ useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from "../../store/actions/auth";
import { updateOrCreateState } from "../../store/actions/state";
import { APP_VERSION } from '../../constants/constants';
import { SearchBox } from './components/SearchBox';
import moment from 'moment';
import { RootState } from '../../store/store';
import { getCompletedNodes, getUserRoleAdmin, setCurrentView, setIsShowCaptureUIOnMobile } from '../../store/actions';
import { NewButton } from '../../components/Buttons/NewButton';
import { useNextTaskPopupAlert } from './hooks/useNextTaskPopupAlert';
import { MenuSVG } from '@components/SVG';
import { cn } from '@utils/cn';
import { useIsMobile } from '@hooks/useIsMobile';


const mainNavigationItems = [
  { name: 'Focus Mode', href: '/', current: true },
  { name: 'Flow Plan', href: '/', current: true },
  { name: 'Focus & Plan Mode', href: '/', current: true },
  { name: 'Schedule', href: '/set-working-time', current: true },
  { name: 'Achievement', href: '/achievement', current: true },
];

const hiddenNavigationItems = [
  { name: 'Capture Park', href: '/capture-park', current: false },
  { name: 'xlrate', href: '/xlrate', current: true },
  { name: 'Admin', href: '#', current: true },
  { name: 'Trash', href: '/trash-can', current: false },
  { name: 'Logout', href: '#', current: true },
];




const Navbar: React.FC = () => {
  const [active, setActive] = useState<string>("Focus & Plpan Mode");
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const tree = useSelector((state: RootState) => state.tree);
  const navigatePointerNodeId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);
  const isShowCaptureUIOnMobile = useSelector((state: RootState) => state.globalStates.isShowCaptureUIOnMobile);
  const navigator = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<string>("");
  const dispatch:any = useDispatch();
  const [totalProductivityPoints, setTotalProductivityPoints] = useState<number>(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useIsMobile();
  useNextTaskPopupAlert();

  const handleNavLinkClick = async (e:any,item:any) => {
    setIsMenuOpen(false);
    setActive(item.name);
    if(item.name === "Admin"){
      const res = await getUserRoleAdmin();
      if(res.success && res.role === "admin"){
        navigator("/admin-dashboard/users");
      }
    }
    if(item.name === "Focus Mode"){
      dispatch(setCurrentView("focus"));
    }
    if(item.name === "Flow Plan"){
      dispatch(setCurrentView("capture"));
    }
    if(item.name === "Focus & Plan Mode"){
      dispatch(setCurrentView("both"));
    }
    if(item.name === "Logout"){
      dispatch(logoutUser());
    }
  }

  const handleViewChange = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if(isShowCaptureUIOnMobile){
      dispatch(setIsShowCaptureUIOnMobile(false));
      return;
    }
    dispatch(setIsShowCaptureUIOnMobile(true));
  }

  useEffect(() => {
    if (location.pathname !== "/login") {
      setPrevLocation(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (loggedInUser && location.pathname === "/login") {
      navigator(prevLocation);
    }
  }, [loggedInUser, navigator, location.pathname, prevLocation]);

  useEffect(() => {
    let time:any = undefined;
    if(tree){
      time = setTimeout(() => {
        if (navigatePointerNodeId && navigatePointerNodeId !== tree._id) {
          updateOrCreateState(navigatePointerNodeId,"");
        }
      }, 5000);
    }

    return () => clearTimeout(time);
  }, [navigatePointerNodeId, tree]);

  useEffect(() => {
    (async () => {
        const res = await getCompletedNodes({startDate: moment().startOf("day").toDate(),endDate: moment().endOf("day").toDate()});

        if(res?.success){
            const tp = res.nodes.reduce((a:number,b:any) => {
                if(b.isRecurring || b.recurring?.frequency){
                    const PP = b.productivityPointsHistory.find((pp:any) => moment(pp.date).startOf('day').isSame(moment().startOf("day")))?.productivityPoints || 0;
                    return a + PP;
                }
                return a + b.productivityPoints 
            },0);
            setTotalProductivityPoints(tp);
        }
    })()
  }, [tree]);

 

  
  return (
    <nav className='bg-gray-800 sticky w-full top-0 z-50'>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 min-h-16 items-center justify-between">
              {
                loggedInUser && <>
                    <MenuSVG id='mainMenu' color='#aaa' height={20} width={20} onClick={()=>{setIsMenuOpen(!isMenuOpen)}}/>


                    <div className="text-white hidden sm:flex">
                      {
                        mainNavigationItems.map((item,idx) => <>
                          <NavLink
                              key={idx}
                              to={item.href}
                              onClick={(e) => {handleNavLinkClick(e,item);setActive(item.name)}}
                              className={cn(
                                'px-3 py-2 rounded-md text-sm font-medium no-underline',
                                item.name === active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            )}
                          >
                            {item.name}
                          </NavLink>
                        </>)
                      }
                      <SearchBox/>
                    </div>
                </>
              }

              
              
              
              <div className="flex items-center gap-2">
                  <NavLink to="/" className="no-underline text-white text-lg">
                    10XPro<span className='bg-white text-black p-1 text-[10px] font-bold ml-1 rounded-lg'>v{APP_VERSION.replace(/\{\{(.*?)\}\}/, '$1')}</span>
                  </NavLink>
                  {
                    <div title="Daily Productivity Points" className='text-white'>PP: {parseFloat(`${totalProductivityPoints}`).toFixed(2)}</div>
                  }

                  {/* view change button  */}
                  <div className="min-[500px]:hidden">
                      <NewButton onClick={handleViewChange} label='' size='lg'/>
                  </div>
              </div>


              {/* login and signup buttons  */}
              {
                !loggedInUser && <>
                    <div className="">
                      <NavLink
                        to="/login"
                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                      >
                        Login
                      </NavLink>
                      <NavLink
                        to="/signup"
                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                      >
                        Signup
                      </NavLink>
                    </div>
                </>
              }
              

              {/* menu  */}
              {
                (isMenuOpen && loggedInUser) && <>
                    <div className="absolute z-[10000] top-[66px] left-0 p-2 rounded-md bg-gray-800 text-white">
                         <div className="flex flex-col w-[200px] max-w-full">
                              <div className="text-white block sm:hidden flex flex-col">
                                  {
                                    mainNavigationItems.filter(item => ((item.name !== "Focus Mode" && item.name !== "Focus & Plpan Mode") && isMobile)).map((item,idx) => <>
                                      <NavLink
                                          key={idx}
                                          to={item.href}
                                          onClick={(e) => {handleNavLinkClick(e,item);setActive(item.name)}}
                                          className={cn(
                                            'px-3 py-2 rounded-md text-sm font-medium no-underline text-center',
                                            item.name === active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        )}
                                      >
                                        {item.name}
                                      </NavLink>
                                    </>)
                                  }
                              </div>
                              {
                                  hiddenNavigationItems.map((item,idx) => <>
                                        <NavLink
                                            key={idx}
                                            to={item.href}
                                            onClick={(e) => {handleNavLinkClick(e,item);setActive(item.name)}}
                                            className={cn(
                                              'px-3 py-2 rounded-md text-sm font-medium no-underline text-center',
                                              item.name === active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                              item.name === "Logout" && "bg-red-600 hover:bg-red-400 text-white"
                                          )}
                                        >
                                          {item.name}
                                        </NavLink>
                                  </>)
                                }
                         </div>
                    </div>
                </>
              }
          </div>
        </div>
    </nav>
  );
}

export default Navbar;


