import { cn } from "@utils/cn";

type TDataKeys = "powerfulVerb" | "keyTransformationalConcept" | "ultimateBenefit" | "timeFrames";

interface IPreView {
  data: any;
  currentScreen: string;
  setData: any;
  keyList: string[];
}

export const PreView: React.FC<IPreView> = ({data,currentScreen,setData,keyList}) => {


  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setData((prev:any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    });
  }

  return <>
      <div className='min-h-[44px] border border-2 p-2 text-gray-400 flex flex-wrap'>
        {
          keyList.map((page,idx) => {
            const myKey = `${page}FillerWord`;

            return <>
                <span 
                    key={idx} 
                    className={cn(
                      "border p-1 rounded-md ",
                      currentScreen.toLowerCase() === page.toLowerCase() && "bg-blue-700 text-white border-blue-700 font-bold"
                    )}
                >
                  {data[page as TDataKeys] || <span className='opacity-25'>{page}</span>}
                </span>
                {
                  keyList.length !== (idx+1) && <input onChange={handleChange} name={myKey} value={`${data[myKey as keyof any]}`} type='text' className='border w-[50px] text-center'/>
                }
            </>
          })
        }
      </div>
  </>
}