import moment from "moment";
import { arrayToObject, formatPurpose } from "../../components/Modal/Purpose";
import {
  SET_ADD_NODE_MODAL_OPEN,
  SET_COPIED_NODE_ID,
  SET_CUT_NODE_ID,
  TOGGLE_IS_PANNING_TREE_CENTER,
  SET_NAVIGATE_POINTER_NODE_ID,
  SET_PANNING_TREE_CENTER_NODE_AXISES,
  SET_PARENT_ID,
  SET_UPDATE_NODE_MODAL_OPEN,
  TOGGLE_IS_COMPLETED_NODES_VISIBLE,
  TOGGLE_IS_DELETED_NODES_VISIBLE,
  SET_FOCUS_BLOCKS,
  SET_CURRENT_TASK,
  SET_CURRENT_TASKS_PARENTS,
  SET_TIMING_ALERT_MODAL_OPEN,
  SET_END_TIME,
  SET_DEPTH,
  SET_UPCOMING_TASK,
  SET_UPCOMMING_TASK_MODAL_OPEN,
  SET_UPCOMING_TASKS_PARENTS,
  SET_POWER_LAW,
  SET_CAPTURE_PARK_NAVIGATOR_ID,
  SET_MANUALLY_SCHEDULED_NODE_ID,
  SET_IS_FULL_VIEW_ON,
  SET_IS_CURRENT_TASK_RUNNING,
  SET_OBJECTIVE_ARR,
  SET_DEADLINE,
  SET_NODE_ID_TO_BE_EDITED,
  SET_IS_SHOW_CAPTURE_UI_ON_MOBILE,
  SET_PARENT_NODE_OBJECTIVE,
  SET_PARENT_NODE_TYPE,
  SET_COPIED_OR_CUT_NODE_OBJECTIVE,
  SET_IS_CREATE_COGNIPULSE_MODAL_OPEN,
  SET_COGNIPULSE_GOALS,
  SET_IS_CREATE_IMPULSE_MODAL_OPEN,
  SET_IS_ADD_TREE_MODAL_OPEN,
  SET_IS_ADMIN_DASHBOARD_VIEW,
  SET_IS_PHY_SELFIE_MODAL_OPEN,
  SET_CURRENT_TASK_TO_COC,
  SET_NODE_DASHBOARD_DATA,
  SET_IS_SHOW_DEEPER_INSIGHTS,
  SET_IS_SHOW_UNLOCK_POTENTIAL_NOW,
  SET_SELECTED_ACTIVITY_ID,
  SET_NEW_COGNIPULSE_ID,
  SET_IS_ADDING_CAPTURE_PARK_NODE,
  SET_IS_DELEGATE_NODE_MODAL_OPEN,
  SET_NODE_TO_DELEGATE,
  SET_IS_CREATE_IMPULSE_AFTER_TASK_COMPLETE_MODAL_OPEN,
  SET_IS_PAUSE_MODAL_OPEN,
  SET_CURRENT_VIEW,
  SET_IS_RATING_AFTER_COMPLETE_MODAL_OPEN
} from "../../constants";
// import store from "../store";
import { SET_PARENT_TASK_COMPLETE_ALERT_MODAL_OPEN } from "../../constants";
import { getTreeData } from "../../pages/ICICLETree/Index";
import { getLatestSurveyByNodeId } from "./xlrate";
import { getCurrentTask } from "@utils/getCurrentTask";
import { getTaskById } from "./tree";
import { ViewTypes } from "@store/reducers/globalStates";

export const setParentIdOfNodeTobeCreated = (Id: string) => async (dispatch: any) => {
  dispatch({ type: SET_PARENT_ID, payload: Id });
};

export const setDepthOfNodeTobeCreated = (depth: number) => async (dispatch: any) => {
  dispatch({ type: SET_DEPTH, payload: depth });
};

export const setAddNodeModalOpen = (bool: boolean) => async (dispatch: any) => {
  dispatch({ type: SET_ADD_NODE_MODAL_OPEN, payload: bool });
};

export const setUpdateNodeModalOpen = (bool: boolean) => async (dispatch: any) => {
  dispatch({ type: SET_UPDATE_NODE_MODAL_OPEN, payload: bool });
};

export const setUpcomingTaskModalOpen = (bool: boolean) => async (dispatch: any) => {
  dispatch({ type: SET_UPCOMMING_TASK_MODAL_OPEN, payload: bool });
};


export const setNodeIdToBeEdited = (nodeId: string | null) => async (dispatch: any) => {
  dispatch({ type: SET_NODE_ID_TO_BE_EDITED, payload: nodeId });
};

export const setCopiedNodeId = (nodeId: string | null) => async (dispatch: any) => {
  dispatch({ type: SET_COPIED_NODE_ID, payload: nodeId });
};

export const setCutNodeId = (nodeId: string | null) => async (dispatch: any) => {
  dispatch({ type: SET_CUT_NODE_ID, payload: nodeId });
};

export const setNavigatePointerNodeId = (nodeId: string) => async (dispatch: any) => {
  dispatch({ type: SET_NAVIGATE_POINTER_NODE_ID, payload: nodeId });
};

export const setPanningTreeCenterNodeAxises = (axises: any) => async (dispatch: any) => {
  dispatch({ type: SET_PANNING_TREE_CENTER_NODE_AXISES, payload: axises });
};

export const toggleIsCompletedNodesVisible = () => async (dispatch: any) => {
  dispatch({ type: TOGGLE_IS_COMPLETED_NODES_VISIBLE });
  // const state = store.getState();
  // dispatch(toggleCompletedNodes(state.globalStates.isCompletedNodesVisible));
};

export const toggleIsDeletedNodesVisible = () => async (dispatch: any) => {
  dispatch({ type: TOGGLE_IS_DELETED_NODES_VISIBLE });
  // const state = store.getState();
  // dispatch(toggleDeletedNodes(state.globalStates.isDeletedNodesVisible));
};

export const toggleIsPanningTreeCenter = () => async (dispatch: any) => {
  dispatch({ type: TOGGLE_IS_PANNING_TREE_CENTER });
};

export const setFocusBlocks = (focusBlocks: any) => async (dispatch: any) => {
  dispatch({ type: SET_FOCUS_BLOCKS, payload: focusBlocks });
};

export const setCurrentTask = (currentTask: any) => async (dispatch: any) => {
  dispatch({ type: SET_CURRENT_TASK, payload: currentTask });
};

export const setCurrentTasksParents = (currentTasksParents: any) => async (dispatch: any) => {
  dispatch({ type: SET_CURRENT_TASKS_PARENTS, payload: currentTasksParents });
};

export const setUpcomingTask = (currentTask: any) => async (dispatch: any) => {
  dispatch({ type: SET_UPCOMING_TASK, payload: currentTask });
};

export const setUpcomingTasksParents = (currentTasksParents: any) => async (dispatch: any) => {
  dispatch({ type: SET_UPCOMING_TASKS_PARENTS, payload: currentTasksParents });
};

export const setTimingAlertModalOpen = (bool: boolean) => async (dispatch: any) => {
  dispatch({ type: SET_TIMING_ALERT_MODAL_OPEN, payload: bool });
};

export const setStartTime = (time: Date | null) => (dispatch: any) => {
  // dispatch({ type: SET_START_TIME, payload: time });
};

export const setEndTime = (time: Date) => (dispatch: any) => {
  dispatch({ type: SET_END_TIME, payload: time });
};

export const setParentTaskCompleteAlertModalOpen = (bool: boolean) => async (dispatch: any) => {
  dispatch({
    type: SET_PARENT_TASK_COMPLETE_ALERT_MODAL_OPEN,
    payload: bool,
  });
};

export const setPowerLaw = (powerLaw: any) => (dispatch: any) => {
  dispatch({ type: SET_POWER_LAW, payload: powerLaw });
};

export const setCaptureParkNavigatorId = (id: string) => (dispatch: any) => {
  dispatch({ type: SET_CAPTURE_PARK_NAVIGATOR_ID, payload: id });
};

export const setManuallyScheduledNodeId = (id: string[]) => (dispatch: any) => {  
  dispatch({ type: SET_MANUALLY_SCHEDULED_NODE_ID, payload: id });
};

export const setIsFullViewOn = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_FULL_VIEW_ON, payload: bool });
};

export const setIsCurrentTaskRunning = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_CURRENT_TASK_RUNNING, payload: bool });
};

export const setObjectiveArr = (arr: string[] | null) => (dispatch: any) => {
  dispatch({ type: SET_OBJECTIVE_ARR, payload: arr });
};

export const setDeadline = (deadline: string | null) => (dispatch: any) => {
  dispatch({ type: SET_DEADLINE, payload: deadline });
};

export const setIsShowCaptureUIOnMobile = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_SHOW_CAPTURE_UI_ON_MOBILE, payload: bool });
};

export const setParentNodeObjectiveAndType = (d: any) => (dispatch: any) => {
  let parentNodeObjective = "";
  let parentNodeType = "";
  
  if(d?.data){
    parentNodeObjective = d.data.objective;
    parentNodeType = d.data.type;
  }
  
  
  dispatch({ type: SET_PARENT_NODE_OBJECTIVE, payload: parentNodeObjective});
  dispatch({ type: SET_PARENT_NODE_TYPE, payload: parentNodeType});
};


export const setCopiedOrCutNodeObjective = (text: string) => (dispatch: any) => {
  dispatch({ type: SET_COPIED_OR_CUT_NODE_OBJECTIVE, payload: text});
};


export const setIsCreateCognipulseModalOpen = (bool: boolean) => (dispatch: any) => {
  
  dispatch({ type: SET_IS_CREATE_COGNIPULSE_MODAL_OPEN, payload: bool});
};


export const setIsCreateImpulseModalOpen = (bool: boolean) => (dispatch: any) => {
  
  dispatch({ type: SET_IS_CREATE_IMPULSE_MODAL_OPEN, payload: bool});
};

export const setIsAddTreeModalOpen = (bool: boolean) => (dispatch: any) => {
  
  dispatch({ type: SET_IS_ADD_TREE_MODAL_OPEN, payload: bool});
};


export const impulseAndCognipulseAndPhySelfiePopup = (nodeId: any,isImpulse: boolean,isPhySelfie:boolean,isCognipulseButtonClicked:boolean,isImpulseButtonClicked:boolean) => async (dispatch: any) => {
  const root = getTreeData();
  let clickedTask = root?.descendants()?.find((n:any) => n.data._id === nodeId);
  

  if(!clickedTask){
    return;
  }


  if(!isImpulseButtonClicked && !isCognipulseButtonClicked && !isPhySelfie){
    let taskThatHasEstimatedDurationMoreThen60Mins = clickedTask;
    // let temp1 = clickedTask;
  
    // while (temp1) {
    //     if((temp1.children && temp1.data.totalEstimatedTaskDuration > 60) || (!temp1.children && temp1.data.estimatedTaskDuration > 60)){
    //       taskThatHasEstimatedDurationMoreThen60Mins = temp1;
    //       break;
    //     }
    //     temp1 = temp1.parent;
    // }
  
    // if(!taskThatHasEstimatedDurationMoreThen60Mins){
    //   return;
    // }

  
    // if(!taskThatHasEstimatedDurationMoreThen60Mins.data.isAutoCognipulseEnabled){
    //   return;
    // }


    const res = await getLatestSurveyByNodeId(taskThatHasEstimatedDurationMoreThen60Mins.data._id,isImpulse);
  
    if(res.success && res.survey){
      const time = moment().diff(moment(res.survey.createdAt),"minute");
      if(time < 60){
        return;
      }
    }

    clickedTask = taskThatHasEstimatedDurationMoreThen60Mins;
  }
  

  const ct:any = getCurrentTask(clickedTask.data,[]);
  // const currentGoal = {...clickedTask.data,depth: clickedTask.depth};
  // const parentGoal = {...clickedTask.parent.data,depth: clickedTask.parent.depth};
  let categoryOfImprovementGoal = null;
  let currentTaskToCOC = [];

  const curTask = root?.descendants()?.find((n:any) => n.data._id === ct.currentTask._id);
  let temp:any = curTask;
  

  while (temp) {
    if(temp.depth !== 2){
      currentTaskToCOC.push({objective: temp.data.objective,_id: temp.data._id,depth: temp.depth});
    }
    
    if(temp.depth === 2){
      currentTaskToCOC.push({objective: (temp.data.purpose && temp.data.purpose.length > 0)? formatPurpose(arrayToObject(temp.data.purpose)):temp.data.objective,_id: temp.data._id,depth: temp.depth});
      categoryOfImprovementGoal = {...temp.data,depth: temp.depth};
      temp = null;
      break;
    }
    
    temp = temp.parent;

    if(temp.depth < 2){
      temp = null;
      break;
    }
  }

  
  if(!isImpulseButtonClicked && !isCognipulseButtonClicked && !isPhySelfie){
    const res = await getTaskById(ct.currentTask._id);
    if(!res?.node!.isAutoCognipulseEnabled){
      return;
    }
  }

  
  dispatch({ type: SET_COGNIPULSE_GOALS, payload: {
    currentGoal: {...ct.currentTask},
    parentGoal: currentTaskToCOC[1],
    categoryOfImprovementGoal
  }});


  // console.log(isPhySelfie);
  if(isPhySelfie){
    dispatch(setIsPhySelfyModalOpen(true));
  }
  else {
    if(isImpulse){
      dispatch(setIsCreateImpulseModalOpen(true));
    }
    else {
      dispatch(setIsCreateCognipulseModalOpen(true));
    }
  }

  dispatch({type: SET_CURRENT_TASK_TO_COC,payload: currentTaskToCOC});
};


export const setIsAdminDashboardView = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_ADMIN_DASHBOARD_VIEW, payload: bool});
};


export const setIsPhySelfyModalOpen = (bool: boolean) => (dispatch: any) => {

  dispatch({ type: SET_IS_PHY_SELFIE_MODAL_OPEN, payload: bool});
  
};


export const setNodeDashboardData = (data: any) => (dispatch: any) => {
  dispatch({ type: SET_NODE_DASHBOARD_DATA, payload: data});
};

export const setIsShowDeeperInsights = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_SHOW_DEEPER_INSIGHTS, payload: bool});
};

export const setIsShowUnlockPotentialNow = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_SHOW_UNLOCK_POTENTIAL_NOW, payload: bool});
};

export const setSelectedActivityId = (stringOrNull: string | null) => (dispatch: any) => {
  dispatch({ type: SET_SELECTED_ACTIVITY_ID, payload: stringOrNull});
};

export const setNewCognipulseId = (stringOrNull: string | null) => (dispatch: any) => {
  dispatch({ type: SET_NEW_COGNIPULSE_ID, payload: stringOrNull});
};

export const setIsAddingcaptureParkNode = (bool: Boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_ADDING_CAPTURE_PARK_NODE, payload: bool});
};

export const setIsDelegateNodeModalOpen = (bool: Boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_DELEGATE_NODE_MODAL_OPEN, payload: bool});
};

export const setNodeToDelegate = (node: Node | null) => (dispatch: any) => {
  dispatch({ type: SET_NODE_TO_DELEGATE, payload: node});
};

export const setIsCreateImpulseAfterTaskCompleteModalOpen = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_CREATE_IMPULSE_AFTER_TASK_COMPLETE_MODAL_OPEN, payload: bool});
};


export const setIsPauseModalOpen = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_PAUSE_MODAL_OPEN, payload: bool});
};



export const setCurrentView = (str: ViewTypes) => (dispatch: any) => {
  dispatch({ type: SET_CURRENT_VIEW, payload: str});
};


export const setIsRatingAfterCompleteModalOpen = (bool: boolean) => (dispatch: any) => {
  dispatch({ type: SET_IS_RATING_AFTER_COMPLETE_MODAL_OPEN, payload: bool});
};

