import { useDispatch } from 'react-redux';
import { playTask, setIsCurrentTaskRunning, setStartTime, setUpcomingTaskModalOpen } from '../store/actions';
import { AppDispatch } from '../store/store';

export function useAutoPlayTask() {
    const dispatch:AppDispatch = useDispatch();

    const autoPlayTask = (task:any) => {
            if (!task) return;
            
            const startTime = new Date();
            dispatch(setIsCurrentTaskRunning(true));
            dispatch(setStartTime(startTime));
        
            const funcs = {
                    preRun: () => {
                        // setIsPlayPauseLoading(true);
                    },
                    runIfSuccess: () => {
                      dispatch(setUpcomingTaskModalOpen(false));
                    },
                    runIfFails: () => {
                        // setIsPlayPauseLoading(false);
                        dispatch(setIsCurrentTaskRunning(false));
                        dispatch(setStartTime(null));
                    }
            }
        
            dispatch(playTask(task._id, startTime.toString(),funcs));
    }


    return {autoPlayTask};
}

