

interface PauseActionButtonProps {
  color?: string;
  height?: number;
  width?: number;
  onClick?: (e:React.MouseEvent<SVGElement>)=>void;
}

export function PauseActionButton({width=50,height=50,color="#000000"}:PauseActionButtonProps) {
  return (
    <svg 
    fill={color} 
    width={width} 
    height={height} 
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 512 512" 
    enable-background="new 0 0 512 512" 
    xmlSpace="preserve"
    >
    <g>
      <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472
        c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"/>
      <g>
        <polygon points="271.5,336.5 335.5,336.5 335.5,211.5 335.5,176.5 271.5,176.5 		"/>
        <rect x="175.5" y="176.5" width="64" height="160"/>
      </g>
    </g>
    </svg>
  )
}
