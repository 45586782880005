import React, { useRef, useState, useEffect } from 'react';
// import { Main } from "../../layout";
import Calendar from "./Calendar/Index";
import { calc } from '../ICICLETree/Index';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { 
  setCurrentTask, 
  setCurrentTasksParents, 
  // setManuallyScheduledNodeId 
} from '../../store/actions';
import { RootState } from '../../store/store';



interface TreeNode {
  _id: string;
  children: TreeNode[];
}

function Index() {
  const [tasks, setTasks] = useState<any[] | null>(null);
  const events = useSelector((state: RootState) => state.events);
  const myTree = useSelector((state: RootState) => state.tree);
  const [dimensions] = useState({ height: 800, width: 1200 });
  const focusRef = useRef<HTMLDivElement | null>(null);
  const dispatch:any = useDispatch();
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);

  
  useEffect(() => {
    // dispatch(setManuallyScheduledNodeId([]));
    const todaysElapsedMinutes = moment().diff(moment().startOf("day"), 'minutes');
    const currentEvent:any = events?.find((ev: any) => 
      moment(ev.startTime).day() === moment().day() && todaysElapsedMinutes >= moment(ev.startTime).diff(moment(ev.startTime).startOf("day"), "minutes") && todaysElapsedMinutes <= moment(ev.endTime).diff(moment(ev.endTime).startOf("day"), "minutes"));

    const data = JSON.parse(JSON.stringify(myTree));

    if (currentEvent && !currentTask?.startTime && !currentTask?.isSomeOneWorkingOnThis) {
      let node1: any;
      let node2: any;

      data.children.forEach((element: TreeNode) => {
        element.children.forEach((el: TreeNode) => {
          if (el?._id === currentEvent.node?._id) {
            node1 = element;
            node2 = el;
          }
        });
      });

      if (node1 && node2) {
        data.children = data.children.filter((el:any) => el._id === node1._id);
        data.children[0].children = data?.children[0]?.children.filter((el:any) => el._id === node2._id);
        calc(data, events || [], dimensions, focusRef,true);
      }
    } 
    else {
      if (!currentTask?.startTime && !currentTask?.isSomeOneWorkingOnThis) {
        dispatch(setCurrentTask(null));
        dispatch(setCurrentTasksParents(null));
      }
    }
  }, [events, dimensions, myTree, dispatch, currentTask?.startTime,currentTask?.isSomeOneWorkingOnThis]);

  return (
    <>
      <Calendar tasks={tasks} date={new Date()} setTasks={setTasks}/>
    </>
  );
}

export default Index;
