import { calculateValueFromPercentage } from "@utils/calculateValueFromPercentage";
import { cn } from "@utils/cn";
import { getTextWidth } from "@utils/getTextWidth";


interface Props {
    progress: number;
    height?: number;
    text?: string;
    borderRadius?: number;
    progressColor?: string;
    backgroundColor?: string;
    labelColor?: string;
}



export function ProgressBar({progress,height=6,text="",borderRadius=0,progressColor,backgroundColor,labelColor}:Props) {
    const fontSize = calculateValueFromPercentage(80,height);
    const textWidth = getTextWidth(text,{fontSize});
 

    return (
        <div 
          className="relative"
          style={{
            height: `${height}px`,
            borderRadius: `${borderRadius}px`,
            backgroundColor: backgroundColor? `${backgroundColor}`:"#c4c4c4",
            border: progressColor? `1px solid ${progressColor}`:`unset`
          }}
        >
            <div
            className="h-full transition-all ease-in-out"
            style={{
                width: `${progress}%`,
                transitionDuration: `${1}s`,
                borderRadius: `${borderRadius}px`,
                backgroundColor: progressColor? `${progressColor}`:"#2563eb"
            }}
            >
                <span 
                    className={cn(
                        "text-white absolute left-[50%] -top-[8%]",
                        labelColor && `text-[${labelColor}]`
                    )} 
                    style={{fontSize: `${fontSize}px`,left: `calc(50% - ${textWidth/2}px)`}}
                >
                    {text}
                </span>
            </div>
        </div>
    );
}





