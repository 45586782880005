export function getCOINodeColor(node:any){
    let temp = node;
    let coiNode = null;

    if(node.depth === 2){
        return node?.data?.color || null
    }
    
    while (temp.parent) {
        if(temp.depth === 2){
            coiNode = temp;
            break;
        }
        temp = temp.parent;
    }

    return coiNode?.data?.color || null
}