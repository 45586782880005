import dayjs from 'dayjs';


const standardDeviation = (arr:any, usePopulation: boolean = false) => {
    const mean = arr.reduce((acc:any, val:any) => acc + val, 0) / arr.length;
    return Math.sqrt(
      arr
        .reduce((acc:any, val:any) => acc.concat((val - mean) ** 2), [])
        .reduce((acc:any, val:any) => acc + val, 0) /
        (arr.length - (usePopulation ? 0 : 1))
    );
};

function OPQCalculator(selectedRanges:any,accessor?:any){
    accessor = accessor || "XLScore";
    
    const scores = selectedRanges.map((item:any) => item[accessor]);
    
    const sd = standardDeviation(scores);

    if(isNaN(sd)){
        return "Not Enough Data";
    }
    
    return sd;
}

function groupDatesByWeek(allSurveysWithXLScores:any,dateAccessor:any,XLScoreAccessor:any) {
    const dates = allSurveysWithXLScores.map((xl:any) => {return {...xl,createdAt: xl[dateAccessor]}});
    let lastWeekStart = dayjs(dates[dates.length-1][dateAccessor]).startOf('week').format('DD-MM-YYYY');
    let lastDate = dayjs(dates[dates.length-1][dateAccessor]).format('DD-MM-YYYY');
  
    const groupedDates = dates.reduce((acc:any, xl:any) => {
      let startOfWeek:any = dayjs(xl[dateAccessor]).endOf('week').subtract(1,"day").valueOf();
  
      if(lastWeekStart === startOfWeek){
        startOfWeek = lastDate;
      }
  
      if (!acc[startOfWeek]) {
        acc[startOfWeek] = [];
      }
  
      acc[startOfWeek].push({createdAt: xl[dateAccessor],XLScore: xl[XLScoreAccessor]});
  
      return acc;
    }, {});
  
    return groupedDates;
};

export function allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores:any,dateAccessor?:any,XLScoreAccessor?:any) {

    dateAccessor = dateAccessor || "createdAt";
    XLScoreAccessor = XLScoreAccessor || "XLScore";

    const datesByWeek = groupDatesByWeek(allSurveysWithXLScores,dateAccessor,XLScoreAccessor);
    const result = [];
  
    for (const key in datesByWeek) {
      if(datesByWeek[key].length > 1){
        const OPQ:any = OPQCalculator(datesByWeek[key]);
        result.push({OPQ: isNaN(OPQ)? 0:OPQ, date: Number(key)});
      }
    }
  
    if(result.length === 0){
      return [];
    }

    result.sort((a,b) => a.date-b.date);
  
    result.unshift({OPQ: result[0].OPQ, date: new Date(allSurveysWithXLScores[0][dateAccessor]).getTime()});
  
    return result;
}

export function mapper_0_100_to__100_100(XLScore: number) {
  return ((XLScore - 0) / (100 - 0)) * (100 - (-100)) + (-100);
}

export function mapper__100_100_to_0_100(XLScore: number){
  return ((XLScore - (-100)) / (100 - (-100))) * (100 - 0) + 0;
}
