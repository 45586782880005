import React, { useEffect } from 'react';
import {
  AddNodeModal,
  UpdateNodeModal,
  TimeAlertModal,
  UpCommingTaskModal,
  ParentTaskCompleteAlertModal,
} from "../../components";
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { CreateCognipulseModal } from '../../components/Modal/CreateCognipulseModal';
import { CreateImpulseModal } from '../../components/Modal/CreateImpulseModal';
import { AddTreeModal } from '../../components/Modal/AddTreeModal';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsAdminDashboardView } from '../../store/actions';
import { CreatePhySelfie } from '../../components/Modal/CreatePhySelfieModal';
import { DeeperInsightsModal } from '../../components/Modal/DeeperInsightsModal';
import { UnlockPotentialNowModal } from '../../components/Modal/UnlockPotentialNowModal';
import { DelegateNodeModal } from '../../components/Modal/DelegateNodeModal';


interface IndexProps {
  children?: React.ReactNode; // Adjust this type based on how you expect to use the children prop
  onlyModals?: boolean
}

export const Main: React.FC<IndexProps> = ({ children,onlyModals }) => { 
  const isUpdateNodeModalOpen = useSelector((state:RootState) => state.globalStates.isUpdateNodeModalOpen);
  const isAddNodeModalOpen = useSelector((state:RootState) => state.globalStates.isAddNodeModalOpen);
  const isCreateCognipulseModalOpen = useSelector((state:RootState) => state.globalStates.isCreateCognipulseModalOpen);
  const isCreateImpulseModalOpen = useSelector((state:RootState) => state.globalStates.isCreateImpulseModalOpen);
  const isAddTreeModalOpen = useSelector((state:RootState) => state.globalStates.isAddTreeModalOpen);
  const isPhySelfieModalOpen = useSelector((state:RootState) => state.globalStates.isPhySelfieModalOpen);
  const isShowDeeperInsights = useSelector((state:RootState) => state.globalStates.isShowDeeperInsights);
  const isShowUnlockPotentialNow = useSelector((state:RootState) => state.globalStates.isShowUnlockPotentialNow);
  const isDelegateNodeModalOpen = useSelector((state:RootState) => state.globalStates.isDelegateNodeModalOpen);
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  

  useEffect(() => {
    dispatch(setIsAdminDashboardView(location.pathname.includes("admin-dashboard")))
  }, [location,dispatch]);

 

  if(onlyModals){
    return <>
      {
        isAddNodeModalOpen && <AddNodeModal />
      }
      {
        isUpdateNodeModalOpen && <UpdateNodeModal />
      }
      {
        isCreateCognipulseModalOpen && <CreateCognipulseModal/>
      }
      {
        isCreateImpulseModalOpen && <CreateImpulseModal/>
      }
      {
        isAddTreeModalOpen && <AddTreeModal/>
      }
      {
        isPhySelfieModalOpen && <CreatePhySelfie/>
      }
      {
        isShowDeeperInsights && <DeeperInsightsModal/>
      }
      {
        isShowUnlockPotentialNow && <UnlockPotentialNowModal/>
      }
      {
        isDelegateNodeModalOpen && <DelegateNodeModal/>
      }
      <TimeAlertModal />
      <UpCommingTaskModal />
      <ParentTaskCompleteAlertModal />
    </>
  }
  
  return (
    <div className='h-[calc(100vh-64px)] w-full text-black'>
      {children}
      {
        isAddNodeModalOpen && <AddNodeModal />
      }
      {
        isUpdateNodeModalOpen && <UpdateNodeModal />
      }
      {
        isCreateCognipulseModalOpen && <CreateCognipulseModal/>
      }
      {
        isCreateImpulseModalOpen && <CreateImpulseModal/>
      }
      {
        isAddTreeModalOpen && <AddTreeModal/>
      }
      {
        isPhySelfieModalOpen && <CreatePhySelfie/>
      }
      {
        isShowDeeperInsights && <DeeperInsightsModal/>
      }
      {
        isShowUnlockPotentialNow && <UnlockPotentialNowModal/>
      }
      {
        isDelegateNodeModalOpen && <DelegateNodeModal/>
      }
      <TimeAlertModal />
      <UpCommingTaskModal />
      <ParentTaskCompleteAlertModal />
    </div>
  );
}





