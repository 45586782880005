import { fetchRequest } from "../../utils";
import { DOMAIN_NAME } from "../../constants";
import { toast } from "react-toastify";

export interface ITaskProgresses {
    _id: string;
    user: string;
    node: string;
    progress: number;
    activity: string | null;
    createdAt: Date;
    updatedAt: Date;
}

interface Response {
    success: boolean;
    message: string;
}

export const getTaskProgressOfUser =  async (selectedActivityId:string) => {

    interface Res extends Response {
        taskProgresses: ITaskProgresses[]
    }


    try {
        const res: Res = await fetchRequest(`${DOMAIN_NAME}/task-progresses?activityId=${selectedActivityId}`, "GET", {});
        
        return res;
        
    } 
    catch (error:any) {
        toast.error(error.message)
        console.error(error);
        return null
    }
}


export const getLastProgressOfUser =  async (nodeId:string) => {

    interface Res extends Response {
        progress: number
    }

    try {
        const res: Res = await fetchRequest(`${DOMAIN_NAME}/task-progresses/${nodeId}`, "GET", {});
        
        return res;
        
    } 
    catch (error:any) {
        toast.error(error.message)
        console.error(error);
        return null
    }
}


type IcreateGoalProgressProps = Omit<ITaskProgresses, "_id" | "user" | "activity" | "updatedAt"> & {
    _id?: string;
    user?: string;
    activity?: string | null;
    updatedAt?: Date;
    inheritEndTimeFromTaskCompletionEndTime?: boolean;
};

export const createTaskProgress = async (body:IcreateGoalProgressProps) => {

    interface Res extends Response {
        taskProgress: ITaskProgresses
    }

    try {
        const res: Res = await fetchRequest(`${DOMAIN_NAME}/task-progresses`, "POST", {...body});
        
        return res;
        
    } 
    catch (error:any) {
        toast.error(error.message)
        console.error(error);
        return null
    }
}
