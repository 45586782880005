export function adjustBrightness(data:any): string {
    let percent = 10;
    let hex = data.color;

    if(data.type === "PROJECT_MISSION"){
      percent = 0;
    }
    if(data.type === "MOONSHOT_OBJECTIVE"){
      percent = -28;
    }
    if(data.type === "OBJECTIVE"){
      percent = -42;
    }
    if(data.type === "KEY_RESULT"){
      percent = -56;
    }
    if(data.type === "TASK"){
      percent = -70;
    }

    hex = hex.replace(/^#/, '');
    
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    r = Math.min(255, Math.max(0, r + (r * percent / 100)));
    g = Math.min(255, Math.max(0, g + (g * percent / 100)));
    b = Math.min(255, Math.max(0, b + (b * percent / 100)));

    return `#${((1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b)).toString(16).slice(1).toUpperCase()}`;
}