import React, { useState } from 'react';
// import { PrimaryButton } from "../index";
import { Constrainers } from './components/Constrainers';
import { Drivers } from './components/Drivers';
import { NewButton } from '../../Buttons/NewButton';






interface IFactors {
  Fun: any;
  Purpose: any;
  Growth: any;
  Emotives: any;
  Utility: any;
  Apathy: any;
  factorsMinMax: any;
  percentageOfChangeOfFactors: any;
}

export const Factors: React.FC<IFactors> = ({Fun,Purpose,Growth,Emotives,Utility,Apathy,factorsMinMax,percentageOfChangeOfFactors}) => {
  const [showRange,setShowRange] = useState(false);
  const [showChange,setShowChange] = useState(false);
  
  return (
    <>
        <div className="flex flex-col gap-2 justify-center items-streach w-[100%]">
          <div className="flex justify-around mt-4">
            <NewButton size='sm'  onClick={()=> setShowRange(p=>!p)} label={`${showRange? "Hide":"Show"} Range`}/>
            <NewButton size='sm'  onClick={()=> setShowChange(p=>!p)} label={`${showChange? "Hide":"Show"} Change`}/>
          </div>
          <div className="flex flex-col gap-2">
            <Drivers showChange={showChange} percentageOfChangeOfFactors={percentageOfChangeOfFactors} factorsMinMax={factorsMinMax} Fun={Fun} Purpose={Purpose} Growth={Growth} showRange={showRange}/>
            <Constrainers showChange={showChange} percentageOfChangeOfFactors={percentageOfChangeOfFactors} factorsMinMax={factorsMinMax} Emotives={Emotives} Utility={Utility} Apathy={Apathy} showRange={showRange}/>
          </div>
        </div>
    </>
  )
}










