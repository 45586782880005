import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { removeChallenge, removeRoutine, setCurrentTask, setCurrentTasksParents, setIsFullViewOn, setManuallyScheduledNodeId, setNavigatePointerNodeId, toggleIsCompletedNodesVisible } from "../../../store/actions";
import { getTreeData } from "../../../pages/ICICLETree/Index";
import { getCurrentTask } from "../../../utils";
import { SET_IS_PAUSED } from "../../../constants";
import { removePriority } from "../../../store/actions/priority";
import { getCOINodeColor } from "../../../utils/getCOINodeColor";
import { NewButton } from "../../../components/Buttons/NewButton";
import { ProgressBar } from "@components/ProgressBar";

interface RoutinesAndChallengesProps {
    treeData: any; // Adjust type based on your treeData structure
}



export const RoutinesAndChallenges: React.FC<RoutinesAndChallengesProps> = ({ treeData }) => {
    const dispatch:any = useDispatch();
    const [chalengeType, setChallengeType] = useState<"day" | "week">("day");
    const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
    const mytree = useSelector((state: RootState) => state.tree);
    const Gchallenges = useSelector((state: RootState) => state.challenges);
    const Groutines = useSelector((state: RootState) => state.routines);
    const Gpriorities = useSelector((state: RootState) => state.priorities);
    const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
    const [routines, setRoutines] = useState<any>([]);
    const [challenges, setChallenges] = useState<any>([]);
    const [priorities, setPriorities] = useState<any>([]);
    const [activeTab, setActiveTab] = useState("PRIORITIES");
    

    const handleRemoveChallenge = (_id: string) => {
        dispatch(removeChallenge(_id));
    };

    const handleLoadTask = (item: any) => {
        dispatch(setIsFullViewOn(false));
        dispatch(setNavigatePointerNodeId(item.node._id));
        
        let node:any = undefined;

        // find node from tree 
        if (treeData) {
            const data = getTreeData(); // Ensure getTreeData is defined and returns the correct type

            test(data);
            function test(d: any) {
                if(d.data._id === item.node._id){
                    node = d;
                    return 
                }

                if (d.children) {
                    d.children.forEach((child:any) => test(child));
                }
            }
        }
        else {
            return;
        }

        if(!node){
            dispatch(toggleIsCompletedNodesVisible())
            return;
        }
        
        if(currentTask?.startTime){
            return;
        }

        // create arry of parent objective from item to project 
        let parentObjectives = [];
        let tempNode = node.parent;

        while (tempNode.depth > 2) {
            parentObjectives.push(tempNode.data);
            tempNode = tempNode.parent;
        }

        // find current task and paretn objective 
        const result = getCurrentTask(node.data,parentObjectives);
        
        dispatch({ type: SET_IS_PAUSED, payload: true });
        dispatch(setCurrentTask(result.currentTask));
        dispatch(setCurrentTasksParents(result.parentObjectives));

        if(!manuallyScheduleNodeId || manuallyScheduleNodeId[manuallyScheduleNodeId.length-1] !== item.node._id){
			if(manuallyScheduleNodeId){
			  dispatch(setManuallyScheduledNodeId([...manuallyScheduleNodeId,item.node._id]));
			}
			else {
			  dispatch(setManuallyScheduledNodeId([item.node._id]));
			}
		}
    };

    const handleRemoveRoutine = (routineId: string) => {
        dispatch(removeRoutine(routineId));
    };
    
    const handleRemovePriority = (priorityId: string) => {
        dispatch(removePriority(priorityId));
    };

    useEffect(() => {
        const tree = getTreeData();
        const rs:any = [];
        const cs:any = [];
        const pr:any = [];

        if(tree){
            tree.descendants().map((node:any) => {
                
                const c = Gchallenges.find(c => c.node?._id === node.data._id);
                if(c){
                    const coiNodeColor = getCOINodeColor(node);
                    cs.push({...c,node: {...c.node,completedPortion: node.data.completedPortion,coiNodeColor}});
                }
                const r = Groutines.find(c => c.node?._id === node.data._id);
                if(r){
                    const coiNodeColor = getCOINodeColor(node);
                    rs.push({...r,node: {...r.node,completedPortion: node.data.completedPortion,coiNodeColor}});
                }
                const p = Gpriorities.find(c => c.node?._id === node.data._id);
                if(p){
                    const coiNodeColor = getCOINodeColor(node);
                    pr.push({...p,node: {...p.node,completedPortion: node.data.completedPortion,coiNodeColor}});
                }
                return null;
            })
        }

        Gchallenges.forEach(ch => {
            const c = cs.find((c:any) => c._id === ch._id);
            if(!c){
                cs.push({...ch,node: {...ch.node,completedPortion: 1}});
            }
        });
        Groutines.forEach(ch => {
            const r = rs.find((c:any) => c._id === ch._id);
            if(!r){
                rs.push({...ch,node: {...ch.node,completedPortion: 1}});
            }
        });
        Gpriorities.forEach(ch => {
            const p = pr.find((c:any) => c._id === ch._id);
            if(!p){
                pr.push({...ch,node: {...ch.node,completedPortion: 1}});
            }
        });


        setRoutines(rs.sort((a:any,b:any) =>  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
        setChallenges(cs.sort((a:any,b:any) =>  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
        setPriorities(pr.sort((a:any,b:any) =>  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
    }, [Gchallenges,Groutines,Gpriorities,mytree]);
    

    return (
        <>
            <div className='flex mt-2 border'>
                <a href="/" onClick={(e) => {e.preventDefault();setActiveTab("PRIORITIES")}} className={`${activeTab === "PRIORITIES"? "bg-white":""} text-center px-2 text-center flex-1`}>
                    PR
                </a>
                <a href="/" onClick={(e) => {e.preventDefault();setActiveTab("CHALLENGES")}} className={`${activeTab === "CHALLENGES"? "bg-white":""} border-r text-center px-2 text-center flex-1`}>
                    Challenges
                </a>
                <a href="/" onClick={(e) => {e.preventDefault();setActiveTab("ROUTINES")}} className={`${activeTab === "ROUTINES"? "bg-white":""} border-r text-center px-2 text-center flex-1`}>
                    Routines
                </a>
            </div>

            {activeTab === "CHALLENGES" && (
                <div className='mt-3 p-2 bg-white rounded-md'>
                    <div className='flex items-center justify-between border-b pb-2'>
                        <NewButton full label={`${chalengeType === "day" ? "Day" : "Week"} Challenges`} onClick={() => setChallengeType(p => p === "day" ? "week" : "day")}/>
                    </div>
                    <div className='flex flex-col p-1'>
                        {challenges.filter((ch:any) => Gchallenges.find(c => c._id === ch._id)).filter((ch:any) => ch.type === chalengeType).map((item:any, idx:number) => (
                            <div key={idx} style={{ backgroundColor: `${item.node?.coiNodeColor}` }} className={`border-b p-2`}>
                                {/* <div className='bg-gray-200 h-[5px] rounded-md'>
                                    <div style={{ width: `${item.node?.completedPortion*100}%` }} className='h-full rounded-md bg-blue-600' />
                                </div> */}
                                <ProgressBar progress={item.node?.completedPortion*100}/>
                                <div className='flex justify-between items-center'>
                                    <a href="/" onClick={(e) =>{e.preventDefault();handleLoadTask(item)}} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1'>{item.node?.objective}</a>
                                    <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemoveChallenge(item._id)}  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {activeTab === "ROUTINES" && (
                <div className='mt-3 p-2 bg-white rounded-md'>
                    <div className='flex items-center justify-between border-b pb-2'>
                        <h6 className='mb-0'>Routines</h6>
                    </div>
                    <div className='flex flex-col p-1'>
                        {routines.filter((ch:any) => Groutines.find(c => c._id === ch._id)).map((item:any, idx:number) => (
                            <div key={idx} style={{ backgroundColor: `${item.node.coiNodeColor}` }} className={`border-b p-2`}>
                                <ProgressBar progress={item.node?.completedPortion*100}/>
                                <div className='flex justify-between items-center'>
                                    <a href="/" onClick={(e) => {e.preventDefault();handleLoadTask(item)}} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1'>{item.node?.objective}</a>
                                    <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemoveRoutine(item._id)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {activeTab === "PRIORITIES" && (
                <div className='mt-3 p-2 bg-white rounded-md'>
                <div className='flex items-center justify-between border-b pb-2'>
                    <h6 className='mb-0'>Priorities</h6>
                </div>
                <div className='flex flex-col p-1'>
                    {priorities.filter((ch:any) => Gpriorities.find(c => c._id === ch._id)).map((item:any, idx:number) => (
                        <div key={idx} style={{ backgroundColor: `${item.node.coiNodeColor}` }} className={`border-b p-2`}>
                            <ProgressBar progress={item.node?.completedPortion*100}/>
                            <div className='flex justify-between items-center'>
                                <a href="/" onClick={(e) =>{e.preventDefault();handleLoadTask(item)}} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1 break-all'>{item.node?.objective}</a>
                                <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemovePriority(item._id)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            )}
        </>
    );
};


