import { createNode, setAddNodeModalOpen, setUpdateNodeModalOpen, updateNode } from "@store/actions";
import { AppDispatch, RootState } from "@store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";


export const useCreateOrUpdateNode = () => {
    const dispatch:AppDispatch = useDispatch();
    const parentId = useSelector((state: RootState) => state.globalStates.parentId);
    const createNodeBtnClickTime = useSelector((state: RootState) => state.globalStates.createNodeBtnClickTime);
    const nodeIdToBeEdited = useSelector((state: RootState) => state.globalStates.nodeIdToBeEdited);

    interface IProps {
        finalString: string;
        data: any;
        startTime: any;
        isUpdating?: boolean;
    }

    const handleCreateOrUpdateNode = ({finalString,data,startTime,isUpdating}:IProps) => {
        const endTime = new Date();

        const node:any = {
            parent: parentId,
            startTimeInMs:createNodeBtnClickTime,
            endTimeInMs: new Date().getTime(),
            isRecurringTask: false,
            objective: finalString,
            objectiveDynamicProperties: {
                ...data,
                timeSpent: [...data.timeSpent,{startTime,endTime,screenName: "RATING"}]
            },
            deadline: !data.timeFrames? "":new Date(data.timeFrames).toString() === "Invalid Date"? "":data.timeFrames,
            estimatedTaskDuration: isNaN(Number(data.estimatedTaskDuration))? 1:Number(data.estimatedTaskDuration),
            type: data.type
        };


        if(!node.deadline){
            delete node.deadline;
        }

        
        // if node created successfully then this func will run
        const ifCreateSuccess = () => {
            dispatch(setAddNodeModalOpen(false));
        }

        // if there is an error while creating node this func will run
        const ifCreateFails = () => {
            
        }

        // if node updated successfully then this func will run
        const ifUpdateSuccess = () => {
            dispatch(setUpdateNodeModalOpen(false));
        }

        // if there is an error while updating node this func will run
        const ifUpdateFials = () => {
            
        }


        if(isUpdating){
            node._id = nodeIdToBeEdited;
            delete node.parent;
            dispatch(updateNode(node,ifUpdateSuccess,ifUpdateFials));
        }
        else {
            dispatch(createNode(node,ifCreateSuccess,ifCreateFails));
        }
    }

    return {handleCreateOrUpdateNode};
}


// function getEstimatedTaskDuration(estimatedTaskDuration?:string){
//     if(!estimatedTaskDuration){
//         return 1;
//     }
//     if(estimatedTaskDuration === "15 mins"){
//         return 15;
//     }
//     if(estimatedTaskDuration === "30 mins"){
//         return 30;
//     }
//     if(estimatedTaskDuration === "1 hour"){
//         return 60;
//     }
//     if(estimatedTaskDuration === "2 hours"){
//         return 120;
//     }
//     if(estimatedTaskDuration === "3 hours"){
//         return 180;
//     }
//     if(estimatedTaskDuration === "1 day"){
//         return 1440;
//     }
//     if(estimatedTaskDuration === "2 days"){
//         return 2880;
//     }
//     if(estimatedTaskDuration === "3 days"){
//         return 4320;
//     }
//     if(!isNaN(Number(estimatedTaskDuration))){
//         return Number(estimatedTaskDuration)
//     }
//     return undefined;
// }

