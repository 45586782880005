import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import moment from 'moment';
import { NewButton } from '@components/Buttons/NewButton';
import { leftArrow, rightArrow } from '@assets/index';


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function Header({isDisplayTask,setIsDisplayTask,setEventToUpdate,startOfWeek,currentDay,setWeekAhead,setDayAhead,dayAhead,currentView,setcurrentView,setIsAddEventModalOpen,weekAhead,setCalenderElVisivility,calenderElVisivility}: any) {
  const isDayView = currentView === 'Day view';

  const handleViewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setcurrentView(e.target.value);
  };

  const nextAndPrevWeek = (isPrev: boolean) => {
    if (currentView === 'Week view') {
      setWeekAhead((prev:any) => (isPrev ? prev - 1 : prev + 1));
    }
    if (currentView === 'Day view') {
      setDayAhead((prev:any) => (isPrev ? prev - 1 : prev + 1));
    }
  };

  const handleCalenderVisivilityChange = (action: string) => {
    if(setCalenderElVisivility){
      setCalenderElVisivility((prev:any) => ({
        ...prev,
        [action]: !prev[action],
      }));
    }
  };

  const handleAddEvent = () => {
    setEventToUpdate(null);
    setIsAddEventModalOpen(true);
  };


  return (
    <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
      <div>
        <h1 className="text-lg font-semibold leading-6 text-gray-900">
          <time dateTime="2022-01-22" className="hidden sm:inline">
            {currentView === 'Day view' && (
              <>
                {dayAhead === 0 && moment().format('MMMM D, YYYY')}
                {dayAhead !== 0 && currentDay.format('MMMM D, YYYY')}
              </>
            )}
            {currentView === 'Week view' && (
              <>
                {weekAhead === 0 && moment().format('MMMM D, YYYY')}
                {weekAhead !== 0 && startOfWeek.clone().format('MMMM D, YYYY')}
              </>
            )}
          </time>
        </h1>
        <p className="mt-1 text-sm text-gray-500">
          {currentView === 'Day view' && (
            <>
              {dayAhead === 0 && moment().format('dddd')}
              {dayAhead !== 0 && currentDay.format('dddd')}
            </>
          )}
          {currentView === 'Week view' && (
            <>
              {weekAhead === 0 && moment().format('dddd')}
              {weekAhead !== 0 && startOfWeek.clone().format('dddd')}
            </>
          )}
        </p>
      </div>
      <div className="flex items-center">
        <Pagination prev={()=>nextAndPrevWeek(true)} next={()=>nextAndPrevWeek(false)} currentData={currentView.split(' ')[0]}/>
        <select onChange={handleViewChange} className='p-2 bg-white rounded-md border md:ml-4'>
          <option value="Day view">Day View</option>
          <option value="Week view">Week View</option>
        </select>

        <div className="h-6 w-px bg-gray-300 md:mx-4"/>

        <div className="hidden  md:flex md:items-center gap-1">
          <NewButton
            label="Add event"
            onClick={handleAddEvent}
          />
          <NewButton
            label={isDisplayTask? "Task View":"COI View"}
            onClick={()=> {setIsDisplayTask((p:boolean) => !p)}}
          />

          {/* <NewButton
                label={"FB"}
                onClick={() => handleCalenderVisivilityChange('focusBlocks')}
                style={{opacity: calenderElVisivility?.focusBlocks? 1:.5}}
              /> */}
          <NewButton
            label={"Sch"}
            onClick={() => handleCalenderVisivilityChange('scheduleDurations')}
            style={{opacity: calenderElVisivility?.scheduleDurations? 1:.5}}
          />

       
          {((!isDayView && startOfWeek.clone().isSameOrBefore(moment().endOf("week"))) || (isDayView && currentDay.clone().isSameOrBefore(moment().endOf("day")))) && (
            <>
              <NewButton
                label={"Exp Dur"}
                onClick={() => handleCalenderVisivilityChange('exploitationDurations')}
                style={{opacity: calenderElVisivility?.exploitationDurations? 1:.5}}
              />
              <NewButton
                label={"Plan Dur"}
                onClick={() => handleCalenderVisivilityChange('planningDurations')}
                style={{opacity: calenderElVisivility?.planningDurations? 1:.5}}
              />
            </>
          )}
        </div>

        <Menu as="div" className="relative ml-6 md:hidden">
          <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                      onClick={handleAddEvent}
                    >
                      Create event
                    </span>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Go to today
                    </span>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Day view
                    </span>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Week view
                    </span>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Month view
                    </span>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Year view
                    </span>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
}

export default Header;



interface IPagination {
  currentData: string;
  prev: (e:React.MouseEvent<HTMLButtonElement>) => void;
  next: (e:React.MouseEvent<HTMLButtonElement>) => void
}

function Pagination({currentData,next,prev}:IPagination){

  return <>
        <div className="flex items-center justify-center p-1 bg-white rounded-md border">
            <NewButton
                label=""
                onClick={prev}
                icon={<><img className='w-[14px] h-[14px]' src={leftArrow} alt="left-arrow"/></>}
                variant='gray'
                style={{borderTopRightRadius: "0",borderBottomRightRadius: "0"}}
                title='Prev'
            />
            <span
                className="px-3.5"
            >
                {currentData}
            </span>
            <NewButton
                label=""
                onClick={next}
                icon={<><img  className='w-[14px] h-[14px]' src={rightArrow} alt="right-arrow"/></>}
                variant='gray'
                style={{borderTopLeftRadius: "0",borderBottomLeftRadius: "0"}}
                title='Next'
            />
        </div>
  </>
} 