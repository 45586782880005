interface ShareSVGProps {
  color?: string;
  height?: number;
  width?: number;
  onClick?: (e:React.MouseEvent<SVGElement>)=>void;
}


export function AddNodeSVG({color="black",width=50,height=50}:ShareSVGProps){

    return <>
      <svg fill={color} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M6,6H4A1,1,0,0,1,4,4H6V2A1,1,0,0,1,8,2V4h2a1,1,0,0,1,0,2H8V8A1,1,0,0,1,6,8Zm15,4v4a1,1,0,0,1-1,1H16a1,1,0,0,1-1-1V13H8v6h7V18a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H16a1,1,0,0,1-1-1V21H7a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1h8V10a1,1,0,0,1,1-1h4A1,1,0,0,1,21,10ZM17,21h2V19H17Zm2-10H17v2h2Z"></path>
        </g>
      </svg>
    </>
  }