

interface NextFastStepActionButtonProps {
    color?: string;
    height?: number;
    width?: number;
    onClick?: (e:React.MouseEvent<SVGElement>)=>void;
  }

export function NextFastStepActionButton({width=50,height=50,color="#000000"}:NextFastStepActionButtonProps) {
  return (
    <svg 
    fill={color} 
    width={width} 
    height={height}
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 512 512" 
    enable-background="new 0 0 512 512" 
    xmlSpace="preserve"
    >
<g>
	<path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472
		c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"/>
	<polygon points="128,336 224,256 128,176 	"/>
	<polygon points="224,256 224,336 320,256 224,176 	"/>
	<polygon points="320,240 320,336 352,336 352,176 320,176 	"/>
</g>
</svg>
  )
}
