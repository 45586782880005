import React, { useState } from "react";
import {Modal} from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  pauseTask,
} from "../../store/actions";
import {
  setUpcomingTaskModalOpen,
  setCurrentTask,
  setCurrentTasksParents,
  setUpcomingTask,
  setUpcomingTasksParents,
  setStartTime,
  setNavigatePointerNodeId,
} from "../../store/actions/globalStates";
import { NewButton } from "../Buttons/NewButton";
import { leftArrow, rightArrow } from "../../assets";
import { useNavigate } from "react-router-dom";
import { useAutoPlayTask } from "../../hooks";


interface RootState {
  globalStates: {
    isUpcomingTaskModalOpen: boolean;
    startTime: number | null;
    currentTask: any; // Replace `any` with your actual task type
    upcomingTask: any; // Replace `any` with your actual task type
    upcomingTasksParents: any[]; // Replace `any` with your actual task parent type
  };
}

export const UpCommingTaskModal: React.FC = () => {
  const dispatch:any = useDispatch();
  const isUpcomingTaskModalOpen = useSelector((state: RootState) => state.globalStates.isUpcomingTaskModalOpen);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const upcomingTask = useSelector((state: RootState) => state.globalStates.upcomingTask);
  const upcomingTasksParents = useSelector((state: RootState) => state.globalStates.upcomingTasksParents);
  const [currentIndexOfMissionResult, setCurrentIndexOfMissionResult] = useState(() => upcomingTasksParents?.length? upcomingTasksParents?.length-1:0);
  const [currentIndexOfTask, setCurrentIndexOfTask] = useState(0);
  const myNavigator = useNavigate();
  const {autoPlayTask} = useAutoPlayTask();

  

  const handleSubmit = (values: { isWorking: string; okrGradePercentage: number; extraMinutes: number; }, e: any) => {
    if (currentTask?.startTime) {
      const funcs = {
          preRun: () => {},
          runIfSuccess: () => {},
          runIfFails: () => {}
      }
      dispatch(pauseTask({
        nodeId: currentTask._id,
        endTime: new Date(),
        isPause: false,
        funcs
      }));
      dispatch(setStartTime(null));
      console.log("Task has been paused here");
    }
    
    dispatch(setCurrentTask(upcomingTask));
    dispatch(setCurrentTasksParents(upcomingTasksParents));
    dispatch(setUpcomingTask(null));
    dispatch(setUpcomingTasksParents(null));
    dispatch(setUpcomingTaskModalOpen(false));
    e.resetForm();
  };

  const onEscape = () => {
    // Add any specific functionality you want on escape
  };

  const handlePrevClick = (e:React.MouseEvent<HTMLButtonElement>,name:"missionResult"|"currentTask") => {
    e.preventDefault();
    if(name === "missionResult"){
      if(currentIndexOfMissionResult > 0){
        setCurrentIndexOfMissionResult(prev => prev-1);
      }
      return;
    }

    if(currentIndexOfTask > 0 && name === "currentTask"){
      if(!upcomingTasksParents[upcomingTasksParents.length-1]?.children[currentIndexOfTask-1].children || upcomingTasksParents[upcomingTasksParents.length-1]?.children[currentIndexOfTask-1].children.length === 0){
        setCurrentIndexOfTask(currentIndexOfTask-1);
      }
    }
  }

  const handleNextClick = (e:React.MouseEvent<HTMLButtonElement>,name:"missionResult"|"currentTask") => {
    e.preventDefault();

    if(name === "missionResult"){
      if(currentIndexOfMissionResult < upcomingTasksParents?.length-1){
        setCurrentIndexOfMissionResult(prev => prev+1);
      }
      return;
    }

    if(upcomingTasksParents[upcomingTasksParents.length-1]?.children[currentIndexOfTask+1] && name === "currentTask"){
      if(!upcomingTasksParents[upcomingTasksParents.length-1]?.children[currentIndexOfTask+1].children || upcomingTasksParents[upcomingTasksParents.length-1]?.children[currentIndexOfTask+1].children.length === 0){
        setCurrentIndexOfTask(currentIndexOfTask+1);
      }
    }
  }

  const handlePlay = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const ct = upcomingTasksParents[upcomingTasksParents.length-1]?.children[currentIndexOfTask];
    dispatch(setCurrentTask(ct));
    dispatch(setCurrentTasksParents(upcomingTasksParents));
    autoPlayTask(ct);
  }

  const handleAnotherTask = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setNavigatePointerNodeId(upcomingTask._id));
    myNavigator("/");
    dispatch(setUpcomingTaskModalOpen(false));
  }
 

  
  if(!currentTask){
    return <Modal
            saveBtnText=""
            onSubmit={handleSubmit}
            isOpen={isUpcomingTaskModalOpen}
            setIsOpen={(bool) => { dispatch(setUpcomingTaskModalOpen(bool)); }}
            hideBtns={true}
            onEscape={onEscape}
            showTopArrow={false}
          >
            <div>
                <h1 className="mb-2 text-lg font-bold text-center">Focus Block Activated!</h1>

                <p className="text-center mb-6">You’re stepping into <span className="font-bold text-green-600">{upcomingTasksParents && upcomingTasksParents.length? upcomingTasksParents[0]?.objective:""}</span> mode!</p>
                

                {/* mission result  */}
                <h1 className="mb-2 text-lg font-bold text-center">Your Mission Result</h1>
                <div className="flex items-center gap-2 mb-6">
                  <NewButton onClick={(e)=>handlePrevClick(e,"missionResult")} variant="transparent" style={{padding: "2px 6px",fontSize: "10px",fontWeight: "normal"}} icon={<img src={leftArrow} alt="left-arrow"/>} label=""/>
                  <p className="border p-2 flex-1 rounded-md">
                    {
                      upcomingTasksParents && upcomingTasksParents.length && upcomingTasksParents[currentIndexOfMissionResult]?.objective
                    }
                  </p>
                  <NewButton onClick={(e)=>handleNextClick(e,"missionResult")} variant="transparent" style={{padding: "2px 6px",fontSize: "10px",fontWeight: "normal"}} icon={<img src={rightArrow} alt="right-arrow"/>} label=""/>
                </div>


                 {/* current task  */}
                <h1 className="mb-2 text-lg font-bold text-center">Your Current Task</h1>
                <div className="flex items-center gap-2 mb-6">
                  <NewButton onClick={(e)=>handlePrevClick(e,"currentTask")} variant="transparent" style={{padding: "2px 6px",fontSize: "10px",fontWeight: "normal"}} icon={<img src={leftArrow} alt="left-arrow"/>} label=""/>
                  <p className="border p-2 flex-1 rounded-md">
                    {
                      upcomingTasksParents && upcomingTasksParents.length && upcomingTasksParents[upcomingTasksParents.length-1]?.children[currentIndexOfTask].objective
                    }
                  </p>
                  <NewButton onClick={(e)=>handleNextClick(e,"currentTask")} variant="transparent" style={{padding: "2px 6px",fontSize: "10px",fontWeight: "normal"}} icon={<img src={rightArrow} alt="right-arrow"/>} label=""/>
                </div>
                

                <p className="text-center mb-6">Press Play to start this mission</p>

                <div className="flex justify-center gap-4">
                  <NewButton label="Play" onClick={handlePlay}/>
                  <NewButton label="Choose Another Task" onClick={handleAnotherTask} variant="secondary"/>
                </div>
            </div>
          </Modal>
  }

  return (
    <Modal
      saveBtnText=""
      onSubmit={handleSubmit}
      isOpen={isUpcomingTaskModalOpen}
      setIsOpen={(bool) => { dispatch(setUpcomingTaskModalOpen(bool)); }}
      hideBtns={true}
      onEscape={onEscape}
      showTopArrow={false}
    >
      <Formik
        initialValues={{ isWorking: "Yes", okrGradePercentage: 0, extraMinutes: 0 }}
        onSubmit={handleSubmit}
        validate={() => { }}
      >
        {(e) => <Form e={e} />}
      </Formik>
    </Modal>
  );
};





interface FormProps {
  e: any
}

const Form: React.FC<FormProps> = ({ e }) => {
  return (
    <form className="w-full">
      <FormData e={e} />
    </form>
  );
};


interface FormDataProps {
  e: {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: any
  };
}

const FormData: React.FC<FormDataProps> = ({ e }) => {
  // const { handleChange } = e;
  const dispatch:any = useDispatch();
  
  const upcomingTask = useSelector((state: RootState) => state.globalStates.upcomingTask); // Replace `any` with your state type
  const upcomingTasksParent = useSelector((state: RootState) => state.globalStates.upcomingTasksParents); // Replace `any` with your state type
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with your state type
  const currentTasksParents = useSelector((state: any) => state.globalStates.currentTasksParents); // Replace `any` with your state type

  // const progress = [
  //   {
  //     lavel: "No real progress",
  //     percentage: 33,
  //     bg: "bg-red-500"
  //   },
  //   {
  //     lavel: "Made Progress but didn't complete",
  //     percentage: 33,
  //     bg: "bg-yellow-500"
  //   },
  //   {
  //     lavel: "Completed",
  //     percentage: 40,
  //     bg: "bg-green-500"
  //   },
  // ];

  return (
    <>
      {/* <div className="mb-14">
        <p>Rate your progress on {currentTask?.objective} (think 'impact' {currentTasksParents ? currentTasksParents[0]?.objective : ""}):</p>
        
        <div className="">
          <div className="flex justify-between">
            {
              new Array(11).fill(1).map((_, i) => (
                <div className="flex flex-col items-center" key={i}>
                  <label htmlFor="">{i * 10}%</label>
                  <input type="radio" onChange={handleChange} value={i * 10} name='okrGradePercentage' />
                </div>
              ))
            }
          </div>
          <div className="flex mt-2">
            {
              progress.map(item => (
                <div key={item.lavel} className={`w-[${item.percentage}%] h-2`}>
                  <p className={`${item.bg} h-2 mb-1`} />
                  <p className='text-center text-xs'>{item.lavel}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div> */}

      <p className="text-top">
        Attention! The scheduled category of improvement for your current task has expired. 
        Please select one of the following options:
      </p>


      <ol className="list-decimal ml-5 mt-5">
          <li>Continue <span className='text-green-700 font-bold'>{currentTask ? currentTask.objective : ""}</span> from <span className='text-green-700 font-bold'>{currentTasksParents ? currentTasksParents[0]?.objective : ""}</span></li>
          <li>Start <span className='text-green-700 font-bold'>{upcomingTask ? upcomingTask.objective : ""}</span> from <span className='text-green-700 font-bold'>{upcomingTasksParent ? upcomingTasksParent.map((item:any) => item.objective).join("/") : ""}</span></li>
      </ol>

      <div className="flex gap-4 mt-10">
        <NewButton
          variant="secondary"
          label={"Start Another"}
          onClick={e.handleSubmit}
          full
        />
        
        <NewButton
          label={"Continue"}
          onClick={(e) => {e.preventDefault();dispatch(setUpcomingTaskModalOpen(false))}}
          full
        />
      </div>
    </>
  );
};

