import React, { useState } from "react";
import { ProgressBar } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { 
  setAddNodeModalOpen,
  setUpdateNodeModalOpen,
  setParentIdOfNodeTobeCreated,
  setCopiedNodeId,
  setCutNodeId, 
  setDepthOfNodeTobeCreated,
  setNavigatePointerNodeId,
  setCurrentTask,
  setCurrentTasksParents,
  setNodeIdToBeEdited,
  setIsAddingcaptureParkNode
} from "../../../store/actions/globalStates";
import { deleteNode, copyPasteNodes, cutPasteNodes, unDeferTask } from "../../../store/actions/tree";
import { colors } from '../colors';
import moment from "moment";
import { toast } from "react-toastify";
import { RootState } from "../../../store/store";
import { NewButton } from "../../../components/Buttons/NewButton";

const myColors: Record<string, string> = {};

interface NodeData {
  objective: string;
  color?: string;
  isCompleted: boolean;
  isTempCompleted?: boolean;
  isRecurringCompleted?: boolean;
  isDeleted: boolean;
  isDefered: boolean;
  deferedInheritedFrom?: string;
  taskExploitationDurationAsArray?: Array<any>;
  inheritedFrom?: string;
  _id: string;
  parent?: any; // You may want to specify a more precise type for the parent.
}

interface NodeProps {
  d: any
  setCurrentNodeToRank: (node: any) => void;
  height: number; // Adjust this type if necessary.
}

const Node: React.FC<NodeProps> = ({ d, setCurrentNodeToRank, height }) => {
  const dispatch:any = useDispatch();
  const navigatorId = useSelector((state: RootState) => state.globalStates.captureParkNavigatorId);
  let bg: any;

  if (d.depth === 3) {
    bg = colors.length > (d.depth + d.height) ? colors[d.depth + d.height] : colors[0];
    bg = d.data.color || bg;
    myColors[d.data.objective] = bg; 
  } else if (d.depth > 3) {
    bg = myColors[d.parent.data.objective];
    bg = d.data.color || bg;
    myColors[d.data.objective] = bg; 
  } else {
    bg = d.data.color || (colors.length > d.depth ? colors[d.depth] : colors[0]);
  }

  const handleUnDefer = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(unDeferTask(d.data.deferedInheritedFrom));
  }

  const handleCurrentTask = (e: React.MouseEvent) => {
    e.stopPropagation();
    const parentObjective: NodeData[] = [];
    
    const parent = (node: any) => {
      parentObjective.push(node.data);
      if (node.depth === 3 || !node.parent) {
        return;
      }
      parent(node.parent);
    };
    
    parent(d.parent);
    dispatch(setCurrentTask(d.data));
    dispatch(setCurrentTasksParents(parentObjective));
  }

  const handleUpdateClick = () => {
    dispatch(setUpdateNodeModalOpen(true));
    dispatch(setNodeIdToBeEdited(d.data._id));
    dispatch(setIsAddingcaptureParkNode(true));
  }

  return (
    <div 
      title={d.data.objective} 
      className='w-full h-full p-2 border-[1px] border-black overflow-hidden' 
      style={{ backgroundColor: bg, border: d.data._id === navigatorId ? "10px solid black" : "1px solid black" }}
    >
      <div className="flex h-full flex-col">
        <div className="flex-1">
            <div className="bg-white p-2 flex items-start">
              <p className="flex-1 text-gray-400 font-bold max-h-[100px] overflow-auto">{d.data.objective}</p>
              <img onClick={handleUpdateClick} className="w-7 h-7 rounded-md cursor-pointer" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrrubJPmPOe0-okTPlS9EqtIPITAHDPAwF8Q&s" alt="pencil" />
            </div>
        </div>
        <div className="">
            <CrudButtons d={d} setCurrentNodeToRank={setCurrentNodeToRank} />
        </div>
      </div>
      {/* {d.data.isCompleted && 
        <div className="absolute bottom-[50px] px-2 rounded-md text-white left-2 bg-green-700">
          {d.data.isTempCompleted ? "Temp" : ""} {d.data.isRecurringCompleted ? "Recurring" : ""} Completed
        </div>
      }
      {d.data.isDeleted && 
        <div className="absolute bottom-[80px] px-2 rounded-md text-white left-2 bg-red-700">Deleted</div>
      }
      {d.data.isDefered && 
        <div onClick={handleUnDefer} className="absolute top-[70px] px-2 rounded-md text-white left-2 bg-blue-700 cursor-pointer">D</div>
      }
      {!d.children && 
        <div onClick={handleCurrentTask} className="absolute top-[80px] px-2 rounded-md text-white left-2 bg-blue-700 cursor-pointer">Action Now</div>
      }
      <div className="flex h-full flex-col">
        <div className="flex-1">
          {d.depth === 1 ? <LifeDivision d={d} /> : <Other height={height} d={d} setCurrentNodeToRank={setCurrentNodeToRank} />}
        </div>
        <div className="">
          <CrudButtons d={d} setCurrentNodeToRank={setCurrentNodeToRank} />
        </div>
      </div> */}
    </div>  
  );
}

export default Node;



interface LifeDivisionProps {
  d: {
    data: {
      objective: string;
    };
  };
}

const LifeDivision: React.FC<LifeDivisionProps> = ({ d }) => {
  return (
    <div className="flex flex-col">
      <div className="">{d.data.objective}</div>
      <div className="flex flex-col flex-1 pl-3 gap-3">
        <div className="h-[50%] flex flex-col">
          <p className="mb-1">Vision</p>
          <div className="flex-1 bg-gray-200 px-3 mt-1 rounded-md overflow-y-auto">Not Set</div>
        </div>
        <div className="h-[50%] flex flex-col">
          <p className="mb-1">Purpose</p>
          <div className="flex-1 bg-gray-200 px-3 mt-1 rounded-md overflow-y-auto">Not Set</div>
        </div>
      </div>
    </div>
  );
};


interface OtherProps {
  d: any;
  height: number; // Define height as needed
  setCurrentNodeToRank?: any
}

const Other: React.FC<OtherProps> = ({ d, height }) => {
  const { data } = d;
  const dispatch:any = useDispatch();
  const [showAllInfo, setShowAllInfo] = useState(false);

  const lavels = [
    "role",
    "objective",
    "result",
    "estimatedTaskDuration",
    "totalEstimatedTaskDuration",
    "taskExploitationDuration",
    "totalTaskExploitationDuration",
    "nodeProductivityValue",
    "nodeProductivityWeightedValue",
    "deadline",
    "tempDeadline",
    "_id",
  ];

  const handleOnTextClick = (e: React.MouseEvent, txt: string) => {
    e.stopPropagation();
    if (txt === "_id") {
      const txt = (e.target as HTMLElement).innerText; // Cast to HTMLElement
      if (txt) {
        navigator.clipboard.writeText(txt);
        toast.success("Copied!");
      }
      return;
    }
    dispatch(setUpdateNodeModalOpen(true));
    dispatch(setNodeIdToBeEdited(d.data._id));
  };

  const handleMouseOverAndLeave = (isEnter: boolean) => {
    setShowAllInfo(isEnter);
  };

  return (
    <>
      <div
        onMouseEnter={() => handleMouseOverAndLeave(true)}
        onMouseLeave={() => handleMouseOverAndLeave(false)}
        className="flex flex-col gap-2"
      >
        {d.depth >= 3 && (
          <div className="w-full h-3 progress-bar">
            <ProgressBar progress={data.completedPortion * 100} />
          </div>
        )}
        {lavels.map((item, i) => (
          (i === 1 || showAllInfo) && (
            <InfoField
              key={i}
              item={item}
              d={d}
              handleMouseOverAndLeave={handleMouseOverAndLeave}
              handleOnTextClick={handleOnTextClick}
            />
          )
        ))}
      </div>
    </>
  );
};



interface InfoFieldProps {
  d: {
    data: {
      inheritedFrom?: string; // Optional, adjust based on your data structure
      [key: string]: any; // Allows other properties in data
    };
  };
  handleOnTextClick: (e: React.MouseEvent, txt: string) => void;
  item: string;
  handleMouseOverAndLeave?: any
}

const InfoField: React.FC<InfoFieldProps> = ({ d, handleOnTextClick, item }) => {
  const dispatch:any = useDispatch();

  const clickHandle = (e: React.MouseEvent, txt: string) => {
    if (txt === "tempDeadline") {
      setTimeout(() => {
        dispatch(setNavigatePointerNodeId(d.data.inheritedFrom || ""));
      }, 1000);
    } else {
      handleOnTextClick(e, txt);
    }
  };

  const formater = (txt: any, item: string, parentObject: any) => {
    if (item === "objective") {
      return parentObject.objectiveArr?.join(" ") || parentObject.objective;
    }

    if (item === "deadline" || item === "tempDeadline") {
      return moment(txt).format("MMMM Do YYYY");
    }
    return txt;
  };

  return (
    <div className="flex gap-2 items-top other-info">
      <p className="mb-0 font-bold text-gray-200">{item[0].toLocaleUpperCase()}{item.substring(1, item.length)}:</p>
      <div onClick={(e) => clickHandle(e, item)} className="flex-1 bg-gray-200 px-3 rounded-md overflow-y-auto">
        {d.data[item] ? formater(d.data[item], item, d.data) : "Not Set"}
      </div>
    </div>
  );
};


interface CrudButtonsProps {
  d: any;
  setCurrentNodeToRank: (node: any) => void; // Adjust the type based on your data structure
}

const CrudButtons: React.FC<CrudButtonsProps> = ({ d, setCurrentNodeToRank }) => {
  const dispatch:any = useDispatch();
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId); // Adjust the type as needed
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId); // Adjust the type as needed
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleCrudOperations = (e: any) => {
    e.stopPropagation();
    setShowMenu(false);
    const operation = e.currentTarget.title || e.currentTarget.parentElement.title;

    if (operation === "Update") {
      dispatch(setUpdateNodeModalOpen(true));
      dispatch(setNodeIdToBeEdited(d.data._id));
    }
    if (operation === "Delete") {
      if (window.confirm("Are You Sure")) {
        dispatch(deleteNode(d.data._id, d.data.parent));
        dispatch(setNavigatePointerNodeId(d.parent.parent.data._id));
      }
    }
    if (operation === "Copy") {
      dispatch(setCopiedNodeId(d.data._id));
    }
    if (operation === "Cut") {
      dispatch(setCutNodeId(d.data._id));
    }
    if (operation === "Paste") {
      if (cutNodeId) {
        dispatch(cutPasteNodes(cutNodeId, d.data._id));
      }
      if (copyNodeId) {
        dispatch(copyPasteNodes(copyNodeId, d.data._id));
      }
    }
    if (operation === "Rank") {
      setCurrentNodeToRank(d);
    }
  };

  const handleCreateNode = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(setAddNodeModalOpen(true));
    dispatch(setParentIdOfNodeTobeCreated(d.data._id));
    dispatch(setDepthOfNodeTobeCreated(d.depth + 1));
    dispatch(setIsAddingcaptureParkNode(true));
  };

  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const buttons = [
    {
      label: <img title="Menu" alt="menu" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAT0lEQVR4nO3WwQkAIAwDwIzezesWKuEO8jf4aBIAHpmSZEuS1w9QJK0/MiUBAPLBRTbj0zoatySpKTIlAQDywUU249M6GrckqSkyJQEg1x3OPOvHECpbTAAAAABJRU5ErkJggg=="/>,
      operation: "Menu"
    },
    {
      label: <img alt="menu" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAU0lEQVR4nO3TwQmAUAwFwfTfdLx7VtwvM5ACHktmAIDX7e2OtYbEKFKjSI0iNYrUKFKTK7If32MMGUUm/VvHWkNiFKlRpEaRGkVqFKn5TREAmBNcXKbvEbAKdz4AAAAASUVORK5CYII=" title="Create"/>,
      operation: "Create"
    },
    {
      label: "Delete",
      operation: "Delete"
    },
    {
      label: "Copy",
      operation: "Copy"
    },
    {
      label: "Cut",
      operation: "Cut"
    },
    {
      label: "Paste",
      operation: "Paste"
    },
    {
      label: "Rank",
      operation: "Rank"
    }
  ];

  return (
    <>
      <div className="crud-buttons">
        <div className="flex flex-wrap justify-between">
          {showMenu ? (
            <div className="rounded-full bg-white/10">
              <img
                title="Menu Close"
                className="rotate-45 w-10 h-10 cursor-pointer p-2 text-white"
                onClick={handleMenuClick}
                alt="menu"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAU0lEQVR4nO3TwQmAUAwFwfTfdLx7VtwvM5ACHktmAIDX7e2OtYbEKFKjSI0iNYrUKFKTK7If32MMGUUm/VvHWkNiFKlRpEaRGkVqFKn5TREAmBNcXKbvEbAKdz4AAAAASUVORK5CYII="
              />
            </div>
          ) : (
            <div className="rounded-full bg-white/10">
              <img 
                title="Menu Open"
                className="w-10 h-10 cursor-pointer p-2 text-[12px] font-bold text-white"
                onClick={handleMenuClick}
                alt="menu"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAT0lEQVR4nO3WwQkAIAwDwIzezesWKuEO8jf4aBIAHpmSZEuS1w9QJK0/MiUBAPLBRTbj0zoatySpKTIlAQDywUU249M6GrckqSkyJQEg1x3OPOvHECpbTAAAAABJRU5ErkJggg=="
              />
            </div>
          )}
          <div className="rounded-full bg-white/10">
            <img
                title="Create"
                className="w-10 h-10 cursor-pointer p-2 text-white"
                onClick={handleCreateNode}
                alt="Create"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAU0lEQVR4nO3TwQmAUAwFwfTfdLx7VtwvM5ACHktmAIDX7e2OtYbEKFKjSI0iNYrUKFKTK7If32MMGUUm/VvHWkNiFKlRpEaRGkVqFKn5TREAmBNcXKbvEbAKdz4AAAAASUVORK5CYII="
            />
          </div>
        </div>
        {showMenu && (
          <div className="p-2 absolute text-gray-600 bg-gray-400 min-w-[190px] bottom-[60px] flex flex-col gap-1">
            {buttons.slice(2, 7).map(item => (
              <NewButton
                  label={item.label}
                  onClick={handleCrudOperations}
                  key={item.operation}
                  title={item.operation}
                  variant="gray"
                  style={{borderRadius: "0"}}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};






