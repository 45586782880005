import React from 'react'

export function ApprovalPendingByAdmin() {
  
    return (
    <div className='h-[calc(100vh-64px)] flex justify-center items-center flex-col'>
      <h1 className='font-bold text-4xl text-gray-600 mb-4'>Thanks for signing up!</h1>
      <p className='text-gray-600'>An administrator is reviewing your account, and you'll be notified once it's approved.</p>
    </div>
  )
}
