import loggedInUser from "./auth";
import treeReducer from "./tree";
import globalStatesReducer from "./globalStates";
import eventReducer from "./event";
import treeToDisplayReducer from "./treeToDisplay";
import {challenges} from "./challenges";
import {routines} from "./routine";
import { combineReducers } from '@reduxjs/toolkit';
import { priorities } from "./priority";


const rootReducer = combineReducers({
    loggedInUser: loggedInUser,
    tree: treeReducer,
    globalStates: globalStatesReducer,
    events: eventReducer,
    treeToDisplay: treeToDisplayReducer,
    challenges: challenges,
    routines: routines,
    priorities: priorities
})


export default rootReducer;