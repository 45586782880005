// import { InfoIconWithToolTip } from "../../InfoIconWithToolTip";
import { factors } from "../utils";
import { Factor } from "./Factor";

interface IDrivers {
    Fun:any;
    Purpose:any;
    Growth:any;
    showRange:any;
    factorsMinMax:any;
    percentageOfChangeOfFactors:any;
    showChange:any;
  }
  
  export const Drivers: React.FC<IDrivers> = ({Fun,Purpose,Growth,showRange,factorsMinMax,percentageOfChangeOfFactors,showChange}) => {
    const facs = [Fun,Purpose,Growth];
    const margin = (factor:any) => {
      return factor.factor === "Fun"? "12px":factor.factor === "Purpose"? "7px":"";
    }
  
    return <>
            <div className="flex flex-col items-center border-4 border-secondry-color rounded p-2">
              <p className='relative z-[1] text-center mb-0 font-bold pb-2 flex justify-center items-center gap-2 text-accent-color'>
                <span>Drivers</span> 
                {/* <InfoIconWithToolTip content={driversInfo}/> */}
              </p>
              <div className="flex justify-around w-full">
                {
                    factors.drivers.map((factor,idx) => {
                      const change = percentageOfChangeOfFactors?  percentageOfChangeOfFactors[factor.factor]:0;
                      return <div id={`introSelector${factor.factor}Drivers`} style={{marginLeft: margin(factor)}} key={idx} className="flex flex-col items-center">
                              <div className="flex justify-center gap-9 w-full">
                                  <div key={factor.toolTip} className='text-xs flex items-center gap-1 text-secondry-color relative z-[1]'>
                                    <span>{factor.factor}</span> 
                                    {/* <InfoIconWithToolTip content={factor.toolTip}/> */}
                                  </div>
                              </div>
                              <div className='w-[100%] flex justify-center gap-[60px] p-2'>
                                <Factor showChange={showChange} showRange={showRange} key={factor.factor} value={facs[idx]} bg={factor.bg} isConstrainers={false} min={factorsMinMax?.min[factor.factor]} max={factorsMinMax?.max[factor.factor]} change={change}/>
                              </div>
                            </div>
                    })
                }
              </div>
            </div>
    </>
  }
  