
import useGetParentWidth from '@hooks/useGetParentWidth';
import { ProgressBar } from './ProgressBar';


interface IScoreInBrainWithLabels {
    containerRef: any;
    score: number;
    change: number;
}

export const FlowScoreChart: React.FC<IScoreInBrainWithLabels> = ({containerRef,score,change}) =>  {
    const [width,id] = useGetParentWidth(containerRef);


    if(typeof(width) !== "number"){
          return null;
    }
    
    return <>
        <ProgressBar width={width} progress={score} change={change} chartName="FLOW_SCORE_CHART" id={id as string}/>
    </> 
}












