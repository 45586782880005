import {
    fetchRequest,
    setToken
} from "../../utils";
import { 
    LOGIN_USER, 
    RESET_TREE,
    DOMAIN_NAME,
    RESET_AUTH,
    RESET_CHALLENGES,
    RESET_EVENTS,
    RESET_GLOBAL_STATES,
    RESET_ROUTINE,
    RESET_TREE_TO_DISPLAY
} from "../../constants";
import { toast } from "react-toastify";
import { getAllEventsOfLoggedInUser } from "./event";

interface User {
    username: string;
    password: string;
}

interface Response {
    success: boolean;
    message: string;
    token?: string;
    user?: any;
}

export const loginUser = (user: User) => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/auth/login`, "POST", user);

        // console.log(res);
        
        if(res.success){
            toast.success("Login Success!");
            if (res.token) {
                setToken(res.token);
            }
            dispatch(getAllEventsOfLoggedInUser());
            dispatch({ type: LOGIN_USER, payload: res.user });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const logoutUser = () => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/auth/logout`, "POST", {});
        
        if(res.success){
            setToken("");
            document.cookie = `token +=; path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
            toast.success("Logout Success!");
            dispatch({ type: RESET_AUTH, payload: null});
            dispatch({ type: RESET_CHALLENGES, payload: null});
            dispatch({ type: RESET_EVENTS, payload: null});
            dispatch({ type: RESET_GLOBAL_STATES, payload: null});
            dispatch({ type: RESET_ROUTINE, payload: null});
            dispatch({ type: RESET_TREE, payload: null});
            dispatch({ type: RESET_TREE_TO_DISPLAY, payload: null});
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}
