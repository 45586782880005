import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import CircularSlider from "react-circular-slider-svg";
import { useAnimate } from "framer-motion";
import {cogniPulse1,cogniPulse2,cogniPulse3} from "../assets/sounds";
import { BackArrowBold, ForwardArrowBold } from './Icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { getLabelFromDepth } from '../utils/getLabelFromDepth';
import { NewButton } from './Buttons/NewButton';
import { leftArrow, rightArrow } from '../assets';

const MIN_ZOOM = .7;
const MAX_ZOOM = 1.3;
const ANIMATION_DURATION = 1;
const MIN_DURATION = 0;
const AJJESTMENT_Y = 10;
const EASE_FUNC = "linear";
const BROWSER_NAME = getBorwserName();

interface ISurveyUI {
  questions: any;
  answers: any;
  setAnswers: any;
  handleSubmit: any;
  isSubmiting: any;
  surveyQuestionInstruction?: any;
  dayOrWeekGoal?: any;
  label?: any;
  isShowFinish: Boolean;
  goalToDisplayInHeader?: string;
  goalToDisplayInHeaderTitle?: string;
  hideHeader?: boolean;
}

const SurveyUI: React.FC<ISurveyUI> = ({hideHeader=false,goalToDisplayInHeader,goalToDisplayInHeaderTitle,isShowFinish,label,dayOrWeekGoal,questions,answers,setAnswers,handleSubmit,isSubmiting,surveyQuestionInstruction}) => {
  const [sliderValues, setSliderValues] = React.useState([4]);
  const [tempSliderValues, setTempSliderValues] = React.useState([4]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [steps,setSteps] = useState({stpesCount: questions.map((q:any,i: number) => i+1),currentStep: 1});
  const nextButtonRef = useRef(null);
  const [starttime, setStarttime] = useState<null | Date | number>(null);
  const [isBarClicked, setIsBarClicked] = useState(false);
  const [, setIsNextButtonClicked] = useState(true);
  const [showQues, setShowQues] = useState(true);
  const [scope,prevAnimation,nextAnimation]:any = useSliderAnimation();
  const currentTaskToCOC = useSelector((state:RootState) => state.globalStates.currentTaskToCOC);
  const [headerTaskIndex, setHeaderTaskIndex] = useState(1);


  const next = ({auto}:any) => {
      if(auto && currentIndex === questions.length-1){
        console.log("you can't auto submit");
        return;
      }

      if(isSubmiting){
        console.log("is submitting is true");
        return;
      }
      if(!showQues){
        console.log("showQues is true");
        return;
      }
      
      if(!isBarClicked  && answers[currentIndex] === undefined){
        toast.error("Please Touch/Click Slider");
        return;
      }
      setIsBarClicked(() => false);

      if(currentIndex === questions.length-1){
        handleSubmit(sliderValues[0],questions[currentIndex].XLFactor,starttime);
        return;
      }

      setTempSliderValues(sliderValues);

      setCurrentIndex(p => p+1);

      setSteps((prev) => {
        return {
          ...prev,
          currentStep: prev.currentStep+1
        }
      });

      
      setAnswers((prev:any)=>{
        if(!prev){
          return [{
            starttime,
            endtime: new Date().getTime(),
            rating: sliderValues[0],
            XLFactor: questions[currentIndex].XLFactor
          }]
        }
        const obj = {
          starttime,
          endtime: new Date().getTime(),
          rating: sliderValues[0],
          XLFactor: questions[currentIndex].XLFactor
        }
        const isExists = prev.find((ans:any) => ans.XLFactor === questions[currentIndex].XLFactor);
        if(isExists){
          return prev.map((ans:any) => ans.XLFactor === questions[currentIndex].XLFactor? obj:ans)
        }
        return [...prev,obj];
      })

      const prevAns = answers?.find((ans:any) => ans.XLFactor === questions[currentIndex+1].XLFactor);

      if(prevAns){
        setSliderValues([prevAns.rating]);
      }
      else {
        setSliderValues([4]);
        setIsBarClicked(false);
      }
      setIsNextButtonClicked(true);
      setStarttime(new Date().getTime());
      
      // animation 
      setShowQues(false);
      nextAnimation();
      setTimeout(() => {
        setShowQues(true);
      }, (ANIMATION_DURATION*1000)+10);
  }

  const back = () => {
    if(!showQues){
      return;
    }
    if(currentIndex === 0){
      return;
    }
    setIsBarClicked(() => false);
    setTempSliderValues(sliderValues);

    setCurrentIndex(p => p-1);
    setSteps((prev) => {
        return {
          ...prev,
          currentStep: prev.currentStep-1
        }
    });
    const prevAns = answers.find((ans:any) => ans.XLFactor === questions[currentIndex-1].XLFactor);
    setSliderValues([prevAns.rating]); 
    setIsNextButtonClicked(false);

    // animation 
    setShowQues(false);
    prevAnimation();
    setTimeout(() => {
      setShowQues(true);
    }, (ANIMATION_DURATION*1000)+10);
  }

  const headerTaskForwardAndBack = (isForward:boolean) => {
    if(isForward){
      if(headerTaskIndex > 1){
        setHeaderTaskIndex(p => p-1);
      }
      return;
    }
    
    if(headerTaskIndex < currentTaskToCOC.length - 1){
      setHeaderTaskIndex(p => p+1);
    }
  }



  useEffect(() => {
    setStarttime(new Date().getTime());
  }, []);

  useEffect(() => {
    setSteps({stpesCount: questions.map((q:any,i: number) => i+1),currentStep: 1});
    setSliderValues([4]);
    setCurrentIndex(0);
    setStarttime(new Date().getTime());
    setIsBarClicked(false);
  }, [questions]);

  return (
      <section className='mx-auto max-w-screen-xl pb-4 px-4 sm:px-8'>
        <div className="mx-auto max-w-[200px]">
          <ProgressBar steps={steps}/>
        </div>
        {
          (goalToDisplayInHeader && hideHeader !== true) && <>
            <div className='mx-auto max-w-xs text-center mt-5 flex flex-col justify-center items-center'>
              {
                goalToDisplayInHeaderTitle &&  <h2 className='text-gray-400 text-sm font-bold mb-1'>{getLabelFromDepth(currentTaskToCOC[headerTaskIndex]?.depth)}</h2>
              }
              <div className='flex items-center gap-2'>
                <div onClick={() => headerTaskForwardAndBack(false)} className="w-5 h-5 cursor-pointer"><BackArrowBold/></div>
                <div className="flex-1">
                    <p className='bg-gray-400/20 text-gray-600 text-sm px-4 py-1 rounded-md'>{currentTaskToCOC[headerTaskIndex]?.objective.length > 30? `${currentTaskToCOC[headerTaskIndex]?.objective.substring(0,60)}...`:currentTaskToCOC[headerTaskIndex]?.objective}</p>
                </div>
                <div onClick={() => headerTaskForwardAndBack(true)} className="w-5 h-5 cursor-pointer"><ForwardArrowBold/></div>
              </div>
            </div>
          </>
        }
     
        <div className="mx-auto max-w-6xl mb-[50px]">
            <div className="flex gap-10 items-center justify-center">
                <div ref={scope} className="relative w-[300px] h-[300px] flex justify-center items-center mobile-slider">
                    <ArcSlider 
                    surveyQuestionInstruction={surveyQuestionInstruction}
                    // isNextButtonClicked={isNextButtonClicked} 
                    setIsBarClicked={setIsBarClicked} 
                    isBarClicked={isBarClicked} 
                    values={sliderValues} 
                    setValues={setSliderValues} 
                    currentQuestion={questions[currentIndex]?.question}
                    showQues={showQues}
                    tempValues={tempSliderValues}
                    setTempValues={setTempSliderValues}
                    next={next}
                    />
                </div>
            </div>
            <div className="flex justify-between max-w-[300px] mx-auto">
                  <h1 className='text-red-600 text-[16px] select-none'>Strongly disagree</h1>
                  <h1 className='text-green-600 text-[16px] select-none'>Strongly agree</h1>
            </div>
        </div>
        <div className=" flex justify-center items-center gap-4">
            <NewButton
              variant='gray'
              onClick={back}
              label=''
              icon={<img src={leftArrow} alt=''/>}
              disabled={currentIndex !== 0? false:true}
            />
            <NewButton
              variant='gray'
              onClick={next}
              label=''
              icon={<img src={rightArrow} alt=''/>}
              ref={nextButtonRef}
              isLoading={((currentIndex === questions.length-1 && isShowFinish) || (currentIndex === questions.length-1 && !isShowFinish)) && isSubmiting? true:false}
            />
        </div>
      </section>
  )
}

export {SurveyUI};


interface IArcSlider {
  showQues: any;
  next: any;
  surveyQuestionInstruction: any;
  currentQuestion: any;
  values: any;
  setValues: any;
  setIsBarClicked: any;
  isBarClicked: any;
  tempValues: any;
  setTempValues: any;
}

const  ArcSlider: React.FC<IArcSlider> = ({showQues,next,surveyQuestionInstruction,currentQuestion,values,setValues,setIsBarClicked,isBarClicked,tempValues,setTempValues}) => {

  const handleChange = (v:any)=> {
    if(!showQues){
      return;
    }
    setValues(() => [v]);
    setTempValues(() => [v]);
  }

  const handleMouseUp = () => {
    if(!showQues){
      return;
    }
    setIsBarClicked(()=> true);
  }

  
  useEffect(() => {
    let time:any = undefined;
    if(isBarClicked){
      time = setTimeout(() => {
        const items = [cogniPulse1,cogniPulse2,cogniPulse3];
        const cogniPulse = items[Math.floor(Math.random() * items.length)];
        cogniPulse.volume = .1;
        if (cogniPulse.paused) {
          cogniPulse.play();
        }
        else{
          cogniPulse.pause();
            cogniPulse.currentTime = 0
        }
        cogniPulse.play();
        next({auto: true});
      }, 500);
    }

    return () => {
      clearTimeout(time);
    }
  }, [values,isBarClicked,next]);


  return (
    <>
      <div onMouseUp={handleMouseUp} onTouchEnd={handleMouseUp} id='arc-slider-1' className='absolute w-[300px] h-[225px] flex justify-center items-center'>
        <MyCircularSlider values={values} handleChange={handleChange}/>
      </div>

      {
        (BROWSER_NAME !== "Safari" && BROWSER_NAME !== "unknown") && <>
            <div onMouseUp={handleMouseUp} onTouchEnd={handleMouseUp} id='arc-slider-2' className='absolute w-[300px] h-[225px] flex justify-center items-center'>
              <MyCircularSlider values={tempValues} handleChange={handleChange}/>
            </div>
        </>
      }
      

      <div id='question-container' className="select-none w-[230px] h-[230px] absolute top-[30px] rounded-full flex flex-col text-center text-[14px] p-3 justify-start items-center  text-secondry-color">
            {
              surveyQuestionInstruction && <p className="text-center text-xl mb-2 font-bold text-gray-400">{surveyQuestionInstruction}</p>
            }

            <div className="Container text-gray-600" dangerouslySetInnerHTML={{__html: currentQuestion}}></div>
      </div>
    </>
  )
}




interface IMyCircularSlider {
    values: any;
    handleChange: any;
    bg?: any;
}

const MyCircularSlider: React.FC<IMyCircularSlider> = ({values,handleChange}) => {

  return (
    <CircularSlider
          size={300}
          minValue={1}
          maxValue={7}
          startAngle={60}
          endAngle={300}
          angleType={{
            direction: "cw",
            axis: "-y"
          }}
          handle1={{
            value: values[0],
            onChange: handleChange
          }}
          arcColor="rgb(79 70 229)"
          arcBackgroundColor={"#CECECE"}
        />
  )
}


interface IProgressBar {
  steps: any;
}

const ProgressBar: React.FC<IProgressBar> = ({steps}) => {
  const completedSteps = steps.currentStep-1;
  const totalSteps = steps.stpesCount.length;
  const percentage = (completedSteps/totalSteps)*100;
 

  return (
    <>
      <div>
        <span id="ProgressLabel" className="sr-only">Loading</span>

        <span
          role="progressbar"
          aria-labelledby="ProgressLabel"
          aria-valuenow={75}
          className="block rounded-full bg-[#CECECE]"
        >
          <span
          className="block h-[4px] rounded-full bg-indigo-600"
          style={{width: `${percentage}%`}}
          />
        </span>
      </div>
    </>
  )
}


function useSliderAnimation(){
  const [scope, animate] = useAnimate();
  
  const nextAnim = () => {
    animate([
      ["#arc-slider-1",{scale: MIN_ZOOM,y: AJJESTMENT_Y,opacity: 0},{ease: EASE_FUNC,duration: MIN_DURATION}],
      ["#arc-slider-2",{scale: 1, y: 0,opacity: 1},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#question-container",{scale: MIN_ZOOM,opacity: 0},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#arc-slider-1",{scale: 1,y: 0, opacity: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION, at: MIN_DURATION}],
      ["#arc-slider-2",{scale: MAX_ZOOM,y: -AJJESTMENT_Y, opacity: 0},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}],
      ["#question-container",{scale: 1,opacity: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}]
    ]);
  }

  const prevAnim = () => {
    animate([
      ["#arc-slider-1",{scale: MAX_ZOOM,y: -AJJESTMENT_Y, opacity: 0},{ease: EASE_FUNC,duration: MIN_DURATION}],
      ["#arc-slider-2",{scale: 1, y: 0, opacity: 1},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#question-container",{opacity: 0,scale: MAX_ZOOM},{ease: EASE_FUNC,duration: MIN_DURATION,at: "<"}],
      ["#arc-slider-1",{scale: 1,y: 0, opacity: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION, at: MIN_DURATION}],
      ["#arc-slider-2",{scale: MIN_ZOOM,y: AJJESTMENT_Y, opacity: 0},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}],
      ["#question-container",{opacity: 1,scale: 1},{ease: EASE_FUNC,duration: ANIMATION_DURATION,at: MIN_DURATION}]
    ]);
  }

  return [scope,prevAnim,nextAnim];
}


function getBorwserName() {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera'
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return 'Firefox';
  } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!(document as any).documentMode === true)){
    return 'IE';
  } else {
    return 'unknown';
  }
}