import React, { useState, useEffect, useRef } from "react";
import {Modal} from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import { 
  updateNode,
  setCurrentTask, 
  setUpdateNodeModalOpen,
  createPlanningDuration,
  setDeadline,
  setObjectiveArr,
  getTaskByIdToUpdate,
  getActivityOfUser,
  setNavigatePointerNodeId
} from "../../store/actions";
import { Formik } from "formik";
import RecurringForm from '../RecurringForm';
import moment from "moment";
import { toast } from "react-toastify";
import { RootState } from "../../store/store";
import { InputField, TextArea } from "../FormFields";
import { capitalized } from "../../utils";
import { NODE_TYPES } from "../../constants";
import { MissionModal } from "./nodeAddingGuides/MissionModal";
import { MoonShotModal } from "./nodeAddingGuides/MoonShotModal";
import { ObjectiveModal } from "./nodeAddingGuides";
import { TaskModal } from "./nodeAddingGuides/TaskModal";
import { KeyResultModal } from "./nodeAddingGuides/KeyResultModal";
import { arrayToObject, formatPurpose, Purpose } from "./Purpose";
import { NewButton } from "../Buttons/NewButton";



export const UpdateNodeModal: React.FC = () => {
  const dispatch:any = useDispatch();
  const isUpdateNodeModalOpen = useSelector((state: RootState) => state.globalStates.isUpdateNodeModalOpen);
  const updateNodeBtnClickTime:any = useSelector((state: RootState) => state.globalStates.updateNodeBtnClickTime);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const nodeIdToBeEdited = useSelector((state: RootState) => state.globalStates.nodeIdToBeEdited);
  const tree = useSelector((state: RootState) => state.tree);
  const [nodeToBeEdited, setNodeToBeEdited] = useState<any>(null);
  const [timingData, setTimingData] = useState<{ [key: string]: any }>({});
  const deadlineG = useSelector((state: RootState) => state.globalStates.deadline); 
  const deadline = nodeToBeEdited?.deadline ? moment(nodeToBeEdited.deadline).format("YYYY-MM-DDTHH:mm") : "";
  const objectiveArr = useSelector((state: RootState) => state.globalStates.objectiveArr);
  const [isShowMissionModal, setIsShowMissionModal] = useState(false);
  const [isShowMoonshotModal, setIsShowMoonshotModal] = useState(false);
  const [isShowObjectiveModal, setIsShowObjectiveModal] = useState(false);
  const [isShowKeyResultModal, setIsShowKeyResultModal] = useState(false);
  const [isShowTaskModal, setIsShowTaskModal] = useState(false);
  const [isCategoryOfImprovementNode, setIsCategoryOfImprovementNode] = useState(false);
  const [parentNodeObjective, setParentNodeObjective] = useState("");
  const isAddingCaptureParkNode = useSelector((state:RootState) => state.globalStates.isAddingCaptureParkNode);

  const handleSubmit = (values: any, e: { resetForm: () => void }) => {  
    
    if(values.taskOrder === ""){
      toast.error("Please Enter Task Order!")
      return;
    }
    
    if (!values.estimatedTaskDuration || values.estimatedTaskDuration === 0) {
      toast.error("Estimated Task Duration can't be zero or Undefined");
      return;
    }

    let isProjectAndObjectiveNode = false;

    tree?.children.forEach(child => {
      child.children.forEach(chidl1 => {
        chidl1.children.forEach(chidl2 => {
          if(chidl2._id === values._id){
            isProjectAndObjectiveNode = true;
          }
        })
      })
    });

    if(!isAddingCaptureParkNode && isProjectAndObjectiveNode && (!values.deadline || new Date(values.deadline) < new Date())){
      toast.error("Deadline is required and must be set in the future.")
      return;
    }


    // remove empity values 
    for (const key in values) {
      if (values[key] === undefined || values[key] === "") {
        delete values[key]
      }
    }

 
    if(values.isCompleted){
      dispatch(setNavigatePointerNodeId(nodeToBeEdited.parent));
    }

    const node = { ...values, startTimeInMs: updateNodeBtnClickTime, endTimeInMs: new Date().getTime(),isRecurringTask:values.isRecurringTask?true:false,recurring: values.isRecurringTask? timingData:undefined };
    
    dispatch(updateNode(node,()=>{},()=>{}));
    
    if (currentTask?._id === values._id) {
      dispatch(setCurrentTask({ 
        ...values, 
        startTimeInMs: updateNodeBtnClickTime, 
        endTimeInMs: new Date().getTime(), 
        recurring: timingData,
        nodeProductivityValue: currentTask.nodeProductivityValue
      }));
    }
    
    e.resetForm();
    dispatch(setUpdateNodeModalOpen(false));
    dispatch(setDeadline(null));
    dispatch(setObjectiveArr(null));
  };

  const onEscape = async () => {
    await createPlanningDuration({startTime: updateNodeBtnClickTime,endTime: new Date(),node: nodeToBeEdited?._id});
    dispatch(setUpdateNodeModalOpen(false));
  }

  useEffect(() => {
    setTimingData(() => {
      const recurring = nodeToBeEdited?.recurring;
      if (!recurring?.frequency) {
        return {
          startTime: "",
          endTime: "",
          time: "",
          days: [],
          frequency: ""
        };
      }
      return {
        startTime: !recurring.duration ? recurring.startTime : recurring.duration.start || "",
        endTime: !recurring.duration ? recurring.endTime : recurring.duration.end || "",
        time: !recurring.hours ? recurring.time : `${recurring.hours < 10 ? `0${recurring.hours}` : recurring.hours}:${(recurring.minutes && recurring.minutes < 10) ? `0${recurring.minutes}` : recurring.minutes}`,
        days: recurring.days || [],
        frequency: recurring.frequency || ""
      };
    });
  }, [nodeToBeEdited]);


  useEffect(() => {
    (async () => {
      if(nodeIdToBeEdited){
        // const time = new Date().getTime();
        // const res = await getTaskById(nodeIdToBeEdited);
        // console.log(new Date().getTime() - time);
        // const time1 = new Date().getTime();
        const res = await getTaskByIdToUpdate(nodeIdToBeEdited);
        // console.log(new Date().getTime() - time1);
        if(res && res.success){
          const node = res.node;
          if(deadlineG){
            node.deadline = deadlineG;
          }
          if(objectiveArr){
            node.objectiveArr = objectiveArr;
            node.objective = objectiveArr.join(" ");
          }
          if(!node.taskOrder){
            node.taskOrder = 0;
          }
          setNodeToBeEdited(node)
        }
      }
    })()
  }, [nodeIdToBeEdited,deadlineG,objectiveArr]);


  useEffect(() => {
    let isCOIN = false;
    let parent:any = "";
    tree?.children.forEach(child => {
      child.children.forEach(chidl1 => {
          if(chidl1._id === nodeIdToBeEdited){
            isCOIN = true;
            parent = chidl1.parent
          }
      })
    });

    if(isCOIN){
      tree?.children.forEach(child => {
        if(child._id === parent){
          setParentNodeObjective(`${child.objective}`.trim().toLowerCase());
        }
      });
    }
    setIsCategoryOfImprovementNode(isCOIN);
  }, [tree,nodeIdToBeEdited]);


  if(isShowTaskModal && isUpdateNodeModalOpen){
      return <TaskModal isUpdating={true} setIsOpen={setIsShowTaskModal} setNodeObj={setNodeToBeEdited} objectiveDynamicProperties={nodeToBeEdited.objectiveDynamicProperties}/>
  }
  
  if(isShowKeyResultModal && isUpdateNodeModalOpen){
    return <KeyResultModal isUpdating={true} setIsOpen={setIsShowKeyResultModal} setNodeObj={setNodeToBeEdited} objectiveDynamicProperties={nodeToBeEdited.objectiveDynamicProperties}/>
  }

  if(isShowObjectiveModal && isUpdateNodeModalOpen){
    return <ObjectiveModal isUpdating={true} setIsOpen={setIsShowObjectiveModal} objectiveDynamicProperties={nodeToBeEdited.objectiveDynamicProperties}    setNodeObj={setNodeToBeEdited}/>
  }

  if(isShowMoonshotModal && isUpdateNodeModalOpen){
    return <MoonShotModal isUpdating={true} setIsOpen={setIsShowMoonshotModal} objectiveDynamicProperties={nodeToBeEdited.objectiveDynamicProperties}    setNodeObj={setNodeToBeEdited}/>
  }

  if(isShowMissionModal && isUpdateNodeModalOpen){
    return <MissionModal isUpdating={true} setIsOpen={setIsShowMissionModal} objectiveDynamicProperties={nodeToBeEdited.objectiveDynamicProperties}    setNodeObj={setNodeToBeEdited}/>
  }


  return (
    <Modal
      saveBtnText="Create"
      onSubmit={handleSubmit}
      isOpen={isUpdateNodeModalOpen}
      setIsOpen={(bool) => { dispatch(setUpdateNodeModalOpen(bool)); }}
      hideBtns={true}
      onEscape={onEscape}
      header
    >
      {nodeToBeEdited && (
        <Formik
          initialValues={{ ...nodeToBeEdited, deadline, objective: nodeToBeEdited?.objective}}
          onSubmit={handleSubmit}
          validate={() => { }}
        >
          {(e) => (
            <Form 
            e={e} 
            setIsShowObjectiveModal={setIsShowObjectiveModal} 
            nodeToBeEdited={nodeToBeEdited} 
            setIsShowMoonshotModal={setIsShowMoonshotModal} 
            setNodeToBeEdited={setNodeToBeEdited} 
            timingData={timingData} 
            setTimingData={setTimingData} 
            setIsShowMissionModal={setIsShowMissionModal}
            setIsShowKeyResultModal={setIsShowKeyResultModal}
            setIsShowTaskModal={setIsShowTaskModal}
            isCategoryOfImprovementNode={isCategoryOfImprovementNode}
            parentNodeObjective={parentNodeObjective}
            />
          )}
        </Formik>
      )}
    </Modal>
  );
}


interface FormProps {
  e: any
  timingData: any; // Replace with the actual type if known
  nodeToBeEdited: any; // Replace with the actual type if known
  setTimingData: React.Dispatch<React.SetStateAction<any>>; // Replace with the actual type if known
  setIsShowMissionModal: any;
  setNodeToBeEdited: any;
  setIsShowMoonshotModal: any;
  setIsShowObjectiveModal: any;
  setIsShowKeyResultModal: any;
  setIsShowTaskModal: any;
  isCategoryOfImprovementNode: boolean;
  parentNodeObjective: string;
}

const Form: React.FC<FormProps> = ({ parentNodeObjective,isCategoryOfImprovementNode,setIsShowTaskModal,setIsShowKeyResultModal,e,setIsShowObjectiveModal, setIsShowMoonshotModal,timingData, setTimingData,nodeToBeEdited,setIsShowMissionModal,setNodeToBeEdited }) => {
  const dispatch:any = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const updateNodeBtnClickTime:any = useSelector((state: RootState) => state.globalStates.updateNodeBtnClickTime);
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const [activities, setActivities] = useState<any>(null);
  const [isShowPurpose, setIsShowPurpose] = useState(false);


  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === "Enter") {
      submitBtnRef.current?.click();
    }
  };


  const handleSetMission = () => {
    if(e.values.type === NODE_TYPES[0]){
      setIsShowMissionModal(true);
    }
    if(e.values.type === NODE_TYPES[1]){
      setIsShowMoonshotModal(true);
    }
    if(e.values.type === NODE_TYPES[2]){
      setIsShowObjectiveModal(true);
    }
    if(e.values.type === NODE_TYPES[3]){
      setIsShowKeyResultModal(true);
    }
    if(e.values.type === NODE_TYPES[4]){
      setIsShowTaskModal(true);
    }
  }


  const handleNodeTypeChange = (ev:any) => {
    e.handleChange(ev);
    setNodeToBeEdited((prev:any) => {
      return {
        ...prev,
        type: ev.target.value
      }
    });
  }

  const handleActivityChange = (ev:any) => {
    e.handleChange(ev);
    setNodeToBeEdited((prev:any) => {
      return {
        ...prev,
        activity: ev.target.value
      }
    });
  }


  const handleTitleChange = (ev:any) => {
    e.handleChange(ev);
    setNodeToBeEdited((prev:any) => {
      return {
        ...prev,
        objectiveDynamicProperties: {
          ...prev.objectiveDynamicProperties,
          title: ev.target.value
        }
      }
    })
  }


  useEffect(() => {
    (async() => {
      if(isCategoryOfImprovementNode){
        console.log(parentNodeObjective);
        
        const res = await getActivityOfUser(parentNodeObjective);
        if(res.success){
          setActivities(res.activities);
        }
      }
    })()
  }, [loggedInUser.email,isCategoryOfImprovementNode,parentNodeObjective]);


  if(isShowPurpose){
      return <Purpose isUpdate={true} setFieldValue={e.setFieldValue} purposeArr={e.values.purpose} objective={e.values.objective} setIsShowPurpose={setIsShowPurpose}/>
  }

  return (
    <>
      <NewButton label={`Update ${e.values.type.split("_").map((n:string) => capitalized(n)).join(" ")}`} onClick={handleSetMission}/>
      
      <form tabIndex={0} onKeyDown={handleKeyDown} className="w-full">
        {e.values.depth === 2 && <InputField name="role" value={e.values.role} handleChange={e.handleChange} label="Role" type="text" />}
        
        <InputField
          name="objectiveDynamicProperties.title"
          value={e.values?.objectiveDynamicProperties?.title || ""}
          handleChange={handleTitleChange}
          label={`${e.values.type.split("_").map((n:string) => capitalized(n)).join(" ")} Title`}
          type="text"
        />

        {
          activities && <>
              <div className="md:flex md:items-center  mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
                          htmlFor={"type"}
                        >
                          Activity
                        </label>
                      </div>
                      <div className="md:w-2/3">
                          <select className='border w-full p-2 rounded-md' value={e.values.activity} name='activity' id='activity' onChange={handleActivityChange}>
                                <option value={""}>Select Activity</option>
                                {
                                  activities.map((act:any) => <option key={act._id} value={act._id}>{capitalized(act.name)} {capitalized(act.category)}</option>)
                                }
                          </select>
                      </div>
              </div>
          </>
        }
        

        <div className="md:flex md:items-center  mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
                    htmlFor={"type"}
                  >
                    Node Type
                  </label>
                </div>
                <div className="md:w-2/3">
                    <select className='border w-full p-2 rounded-md' value={e.values.type} name='type' id='type' onChange={handleNodeTypeChange}>
                          {
                            NODE_TYPES.map((nt) => <option key={nt} value={nt}>{nt.split("_").map(n => capitalized(n)).join(" ")}</option>)
                          }
                    </select>
                </div>
        </div>

        <TextArea
          name="objective"
          isHeigLighted={true}
          value={e.values.objective}
          handleChange={e.handleChange}
          label={e.values.type.split("_").map((n:string) => capitalized(n)).join(" ")}
        />
       
       {
        isCategoryOfImprovementNode && <>
            <TextArea
              name="purpose"
              isHeigLighted={false}
              value={(e.values.purpose && e.values.purpose.length > 0)? formatPurpose(arrayToObject(e.values.purpose || [])):"click here to set purpose"}
              handleChange={()=>{}}
              label={"Purpose"}
              onTextAreaClick={() => {setIsShowPurpose(true)}}
            />
        </>
       }
       

        {/* <InputField name="result" value={e.values.result} handleChange={e.handleChange} label="Key Results" type="text" /> */}
        {/* <InputField name="body" value={e.values.body} handleChange={e.handleChange} label="Notes" type="text" /> */}
        <InputField name="deadline" value={e.values.deadline || ""} handleChange={e.handleChange} label="Deadline" type="datetime-local" />
        {/* <InputField name="legtime" value={e.values.legtime} handleChange={e.handleChange} label="Lag Time" type="text" />
        <InputField name="leadtime" value={e.values.leadtime} handleChange={e.handleChange} label="Lead Time" type="text" /> */}
        <InputField name="taskOrder" value={e.values.taskOrder} handleChange={e.handleChange} label="Task Order" type="number" />
        <InputField name="estimatedTaskDuration" value={e.values.estimatedTaskDuration === 0 ? 1 : e.values.estimatedTaskDuration} handleChange={e.handleChange} label="Est. Duration" type="number" />
        <InputField name="color" value={e.values.color || "#000000"} handleChange={e.handleChange} label="Color" type="color" />
        
        {["isCompleted", "isDeleted", "isCapturePark", "isImmediateTask","isAutoCognipulseEnabled"].map((field) => (
          <div className="flex items-center mb-6" key={field}>
            <input
              checked={e.values[field as keyof typeof e.values]}
              id={field}
              type="checkbox"
              onChange={e.handleChange}
              value={e.values[field as keyof typeof e.values]}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor={field} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.replace(/is/, "").replace(/([A-Z])/g, ' $1')}</label>
          </div>
        ))}

        <h6 className='p-2 flex items-center justify-center gap-2'>Recurring Details 
          <input type="checkbox" checked={e.values.isRecurringTask} onChange={e.handleChange} name='isRecurringTask' id='isRecurringTask' />
        </h6>
        {e.values.isRecurringTask && <RecurringForm timingData={timingData} setTimingData={setTimingData} />}
        
        <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <NewButton
            variant="gray"
            label="Cancel"
            onClick={async (e) => {
              e.preventDefault(); 
              dispatch(setUpdateNodeModalOpen(false));
              await createPlanningDuration({startTime: updateNodeBtnClickTime,endTime: new Date(),node: nodeToBeEdited?._id}); 
            }} 
          />
          <NewButton
            label="Update"
            ref={submitBtnRef} 
            onClick={e.handleSubmit}
          />
        </div>
      </form>
    </>
  );
};