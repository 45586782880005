import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {Modal} from "./Index";
import {  createGoalProgress, createSurvey, getSurveyTitle, setIsCreateImpulseAfterTaskCompleteModalOpen, updateNode } from "../../store/actions";
import { SurveyUI } from "../SurveyUI";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLabelFromDepth } from "../../utils/getLabelFromDepth";
import { RatingUI } from "../ImpulseRating";
import { NewButton } from "@components/Buttons/NewButton";
import { rightArrow } from "@assets/index";
import { ProgressBar } from "@components/ProgressBar";
import { createTaskProgress } from "@store/actions/taskProgress";



export function CreateImpulseAfterTaskComplete() {
  const dispatch:AppDispatch = useDispatch();
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const cognipulseGoals = useSelector((state:RootState) => state.globalStates.cognipulseGoals);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState<any>(null);
  const [minute] = useState(1);
  const [isShowImpulseRating, setIsShowImpulseRating] = useState(false);
  const [impactSurveyId, setImpactSurveyId] = useState("");
  const [isShowInstructions, setIsShowInstructions] = useState(true);
 
  const handleSubmit = async (lastAns:any,lastFactor:any,starttime:any) => {
        setIsSubmiting(true);
        const preparFinalSurveyAnswers = () => {
            const myAnswers = [...answers,{
              starttime,
              endtime: new Date().getTime(),
              rating: lastAns,
              XLFactor: lastFactor
            }];

            let data:any = {};

            myAnswers.forEach((answer:any) => {
              let temp = {
                starttime: answer.starttime,
                endtime: answer.endtime,
                rating: answer.rating,
              };
              data[answer.questiontype || answer.XLFactor] = temp;
            });


            data.isconsentformseen = true;
            data.isbriefingformseen = true;
            data.userIdIn10X = loggedInUser._id;
            data.surveyTitleId = surveyTitle._id;
            data.link = window.location.href;
            data.surveyName = surveyTitle.title;
            data.isNewSurvey = true;
            data.surveyPeriod = minute;
            data.activity = cognipulseGoals.categoryOfImprovementGoal?.activity;
            data.dialGoal = cognipulseGoals.currentGoal?._id;
            data.headerGoal = cognipulseGoals.parentGoal?._id;
            data.isCreatedFrom10X = true;

            return data;
        };
      
        const data = preparFinalSurveyAnswers();
  
        if(!cognipulseGoals.categoryOfImprovementGoal?.activity){
          toast.error("Please Add Activity At Category of Improvement Lavel");
          setIsSubmiting(false);
          return;
        }
        
        const res = await createSurvey({...data,email: loggedInUser.email});
        // console.log(res);
        setIsSubmiting(false);
        if(res.success){
          setIsShowImpulseRating(true);
          setImpactSurveyId(res.survey._id);
          return true;
        }
        else {
          toast.error(res.message);
          return false;
        }
  }

  useEffect(() => {
    (async() => {
      const res = await getSurveyTitle(true);

      if(res.success){
          let shuffled = res.surveyTitle.questions.map((a:any) => ({sort: Math.random(), value: a}))
                                                  .sort((a:any, b:any) => a.sort - b.sort)
                                                  .map((a:any) => a.value);
                                                  
          shuffled = shuffled.map((ques:any) => {
            let goal = `<div style="max-height: 90px;overflow: hidden;margin-top: 8px; box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.1), 0px 8px 24px rgba(17, 17, 26, 0.1), 0px 16px 26px rgba(17, 17, 26, 0.1); border-radius: 4px; padding: 4px;" title="${cognipulseGoals.currentGoal?.objective}">${cognipulseGoals.currentGoal?.objective.split(" ").length > 17? `${cognipulseGoals.currentGoal?.objective.split(" ").filter((item:any,i:number) => i < 17).join(" ")}...`:`${cognipulseGoals.currentGoal?.objective}`}</div>`;
            let question = `${ques.question}`;
            question = question?.replace("study", "Studies");
            question = question?.replace("[insert survey subject]", "");
            question = question?.replace("[insert user input name/title]", "[thisismydialgoal]");
            question = question?.replace("[thisismydialgoal]", goal);
            return {...ques,question};
          })                                             
          setQuestions(shuffled);
          setSurveyTitle(res.surveyTitle);
      }
      else {
        toast.error("Something Went Wrong!");
      }
    })()
  }, [loggedInUser.email,cognipulseGoals.currentGoal]);
  

  if(!surveyTitle){
    return <Modal 
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(bool))}}
      hideBtns={true}
      onEscape={()=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(false))}}
    >
      <div className="h-[632px] flex justify-center items-center">Loading...</div>
    </Modal>
  }

  if(isShowInstructions){
    return <Modal 
              saveBtnText="Create"
              onSubmit={()=>{}}
              isOpen={true}
              setIsOpen={(bool)=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(bool))}}
              hideBtns={true}
              onEscape={()=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(false))}}
              header
            >
              <div className="">
                  <p className="text-gray-500 font-bold text-center mb-2">Your Completion ImPulse for</p>
                  <p className="text-gray-200 bg-gray-500 text-center rounded-md p-2 mb-7">{cognipulseGoals.currentGoal.objective}</p>

                  <h1 className="text-gray-500 text-center mb-2">Track. Learn. Optimise.</h1>
                  <h1 className="text-gray-500 text-center mb-2">ImPulse helps you reflect on your focus, flow, and execution.</h1>
                  <h1 className="text-gray-500 text-center mb-2">Track your performance to unlock deeper insights and optimise your productivity.</h1>

                  <div className="mt-8">
                    <NewButton
                      label=""
                      onClick={()=>{setIsShowInstructions(false)}}
                      icon={<img src={rightArrow} alt='right-arrow'/>}
                      variant="gray"
                      full
                    />
                  </div>
              </div>
            </Modal>
  }

  if(isShowImpulseRating){
    return  <Modal
              saveBtnText="Create"
              onSubmit={()=>{}}
              isOpen={true}
              setIsOpen={(bool)=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(bool))}}
              hideBtns={true}
              onEscape={()=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(false))}}
              header
            >
                <ImpulseRating 
                  inThePastMinutes={minute}
                  impactSurveyId={impactSurveyId}
                  setIsShowImpulseRating={setIsShowImpulseRating}
                />
            </Modal>
  }


  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(bool))}}
      hideBtns={true}
      onEscape={()=>{dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(false))}}
      header
    >

      <div>
          <p className="mb-3 text-center text-gray-400 font-bold select-none">Completion ImPulse</p>
       
          <SurveyUI 
            isShowFinish={true} 
            dayOrWeekGoal={"this is day goal"} 
            label={""}  
            handleSubmit={handleSubmit} 
            surveyQuestionInstruction={""} 
            isSubmiting={isSubmiting} 
            questions={questions} 
            answers={answers} 
            setAnswers={setAnswers}
            goalToDisplayInHeader={cognipulseGoals.parentGoal?.objective}
            goalToDisplayInHeaderTitle={getLabelFromDepth(cognipulseGoals.parentGoal?.depth)}
            hideHeader
          />
      </div>
    </Modal>
  );
}



interface IImpulseRating {
  inThePastMinutes: any;
  impactSurveyId: string;
  setIsShowImpulseRating:any;
}

const ImpulseRating: React.FC<IImpulseRating> = ({setIsShowImpulseRating,inThePastMinutes,impactSurveyId}) => {
      const cognipulseGoals = useSelector((state:RootState) => state.globalStates.cognipulseGoals);
      const dispatch: AppDispatch = useDispatch();
      const [sliderValues, setSliderValues] = React.useState([0]);

      const handleOk = async (e:React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          const progress: number = Math.floor(Number(sliderValues[0]));

          const data:any = {
            percentage: progress,
            survey: impactSurveyId,
            activity: cognipulseGoals.categoryOfImprovementGoal?.activity,
            dialGoal: cognipulseGoals.currentGoal?._id,
            headerGoal: cognipulseGoals.parentGoal?._id
          }

          const res = await createGoalProgress(data);
          
          if(res.success){
            await createTaskProgress({
              node: cognipulseGoals.currentGoal?._id,
              progress: progress,
              activity: cognipulseGoals.categoryOfImprovementGoal?.activity,
              createdAt: new Date(),
              inheritEndTimeFromTaskCompletionEndTime: true
            });
            dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(false));

            const node = {
                _id: cognipulseGoals.currentGoal?._id,
                completedPortion: progress/100,
            }
            dispatch(updateNode(node,()=>{},()=>{}));
          }
      }
      

      return (
          <>
              <p className="mb-3 text-center text-secondry-color font-bold select-none">Completion ImPulse</p>
              
              <div className="mx-auto max-w-[200px]">
                <ProgressBar progress={90}/>
              </div>
  
              <RatingUI 
                currentGoal={cognipulseGoals.currentGoal}
                sliderValues={sliderValues}
                setSliderValues={setSliderValues}
                currentTaskLabel="What level did you complete this to?"
              />

              <div className='gap-5 w-[300px] max-w-full mx-auto flex justify-center'>
                  <NewButton onClick={handleOk} label="Back to Focus" full/>
              </div>

              <p className="text-sm text-green-400 text-center">Awesome! Every reflection fine-tunes your performance. Stay on track</p>
          </>
      )
}








