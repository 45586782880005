import { useDispatch } from "react-redux";
import { Modal } from "./Index";
import { AppDispatch, RootState } from "../../store/store";
import { delegateTask, setIsDelegateNodeModalOpen } from "../../store/actions";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { NewButton } from "../Buttons/NewButton";



export function DelegateNodeModal() {
    const dispatch:AppDispatch = useDispatch();
    const nodeToDelegate:any = useSelector((state: RootState) => state.globalStates.nodeToDelegate);
    const [email, setEmail] = useState("");


    const handleDelegateTask = async (e:React.MouseEvent<HTMLButtonElement>) => {
      if(!email){
        toast.error("Please Enter Email");
        return
      }
      const res = await delegateTask(nodeToDelegate._id,{email});
      // console.log(res);
      if(res.success){
        toast.success("The task has been delegated successfully.")
        dispatch(setIsDelegateNodeModalOpen(false))
      }
    }

 
  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=> dispatch(setIsDelegateNodeModalOpen(bool))}
      // hideBtns={true}
      onEscape={()=>{}}
      header
    >
       <>
         
        <div className="">
            <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Task To Delegate</label>
            <div className="relative mb-6 bg-gray-200 p-2 rounded-md">
                {
                  nodeToDelegate?.objective
                }
            </div>
        </div>

        <div className="">
            <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Recipient Eamil</label>
            <div className="relative mb-6">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                </svg>
              </div>
              <input onChange={(e)=>setEmail(e.target.value)} value={email} type="text" id="input-group-1" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@gmail.com"/>
            </div>
        </div>

        <div className="flex justify-end">
            <NewButton label="Delegate Now" onClick={handleDelegateTask}/>
        </div>
       </>
    </Modal>
  )
}






