import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store/store'
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getTreeData } from '../../../pages/ICICLETree/Index';
import { getCurrentTask } from '../../../utils';
import { setUpcomingTask, setUpcomingTaskModalOpen, setUpcomingTasksParents } from '../../../store/actions';
import { confettiRise } from '../../../assets/sounds';

export function useNextTaskPopupAlert() {
    const events = useSelector((state:RootState) => state.events);
    const ct = useSelector((state:RootState) => state.globalStates.currentTask);
    const timeoutIdsRef = useRef<NodeJS.Timeout[]>([]);
    const dispatch:AppDispatch = useDispatch();
    
    

    useEffect(() => {
        // Clear previous timeouts before setting new ones
        timeoutIdsRef.current.forEach(clearTimeout);
        timeoutIdsRef.current = [];

        const futureEvents = events?.filter((event:any) => new Date(event.startTime).getDay() === new Date().getDay()).filter((event:any) => {
            const eventStartTime = new Date().setHours(
                new Date(event.startTime).getHours(),
                new Date(event.startTime).getMinutes(),
                new Date(event.startTime).getSeconds(),
                0);
            return new Date(eventStartTime) > new Date();
        });
        
        futureEvents?.forEach((event:any) => {
            const eventStartTime = new Date().setHours(
                                                new Date(event.startTime).getHours(),
                                                new Date(event.startTime).getMinutes(),
                                                new Date(event.startTime).getSeconds(),
                                                0);
                                                
        const delay = eventStartTime - new Date().getTime();

        if (delay > 0) {
            const timeoutId = setTimeout(() => {
                // display alert if new task is there
                const root = getTreeData();
                const categoryOfChange:any = root.descendants().find((d:any) => d.data._id === event?.node._id);
                const undeferedTask:any = categoryOfChange?.data.children?.find((c:any) => !c.isCompleted && !c.isDefered);

                if (undeferedTask) {
                    const result:any = getCurrentTask(undeferedTask, [categoryOfChange.data]);
                    const currentTask = result.currentTask;
                    const parentObjectiveNode = result.parentObjectives;

                    if(currentTask && currentTask?._id !== ct?._id){
                        confettiRise.play();
                        dispatch(setUpcomingTaskModalOpen(true));
                        dispatch(setUpcomingTask(currentTask));
                        dispatch(setUpcomingTasksParents(parentObjectiveNode));
                    }
                }
            }, delay);

            
            timeoutIdsRef.current.push(timeoutId);
        }
        });

        return () => {
            timeoutIdsRef.current.forEach(clearTimeout);
            timeoutIdsRef.current = [];
        };
    }, [events,ct,dispatch]);

    return []
}
