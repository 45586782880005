import { fetchRequest } from "../../utils";
import { ADD_PRIORITY, DOMAIN_NAME, REMOVE_PRIORITY, SET_PRIORITIES, UPDATE_PRIORITY } from "../../constants";
import { toast } from "react-toastify";

interface Priority {
    id?: string;
    name?: string;
    description?: string;
    node?:any
    // Add other properties as needed
}

interface Response<T = any> {
    success: boolean;
    message: string;
    priorities?: T[];
    priority?: T;
}

export const setPrioritysOfUser = () => async (dispatch: any) => {
    try {
        const res: Response<Priority> = await fetchRequest(`${DOMAIN_NAME}/priorities`, "GET", {});
        

        if (res.success) {
            dispatch({ type: SET_PRIORITIES, payload: res.priorities });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const addPriority = (routine: Priority) => async (dispatch: any) => {
    try {
        const res: Response<Priority> = await fetchRequest(`${DOMAIN_NAME}/priorities`, "POST", { ...routine });

        if (res.success) {
            dispatch({ type: ADD_PRIORITY, payload: res.priority });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const updatePriority = (priority: Priority) => async (dispatch: any) => {
    try {
        dispatch({ type: UPDATE_PRIORITY, payload: priority });
    } catch (error) {
        console.error(error);
    }
}

export const removePriority = (priorityId: string) => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/priorities/${priorityId}`, "DELETE", {});

        if (res.success) {
            dispatch({ type: REMOVE_PRIORITY, payload: { priorityId: priorityId } });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}
