import { 
    DOMAIN_NAME
} from "../../constants";
import {
    fetchRequest
} from "../../utils";
const rootURL = `${DOMAIN_NAME}/admin/nodes`


export const addTreeToANodetoOtherUserByAdmin = async (parentId: string,children: Node[],userId:string) => {
    try {

        const res: any = await fetchRequest(`${rootURL}/${userId}`, "POST", {parentId,nodes:children });
        
        return res;

    } catch (error) {
        console.error(error);
    }
}
