import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { completeTaskLocal, playTask, setAddNodeModalOpen, setCurrentTask, setIsCreateImpulseAfterTaskCompleteModalOpen, setIsCurrentTaskRunning, setIsDelegateNodeModalOpen, setIsPauseModalOpen, setIsRatingAfterCompleteModalOpen, setManuallyScheduledNodeId, setNavigatePointerNodeId, setNodeToDelegate, setParentIdOfNodeTobeCreated, setStartTime } from "../../../store/actions";
import { DiferDurations } from "./DiferDurations";
import { Icons } from "../../../components/Icons";
import { confettiRise } from "../../../assets/sounds";
import { toast } from "react-toastify";
import { SET_COGNIPULSE_GOALS, SET_CURRENT_TASK_TO_COC, SET_IS_PAUSED, UPDATE_NODE } from "../../../constants";
import { Spinner } from "./Spinner";
import { getTreeData } from "../../../pages/ICICLETree/Index";
import { NewButton } from "../../../components/Buttons/NewButton";
import { getCurrentTask } from "@utils/getCurrentTask";
import { arrayToObject, formatPurpose } from "@components/Modal/Purpose";

interface ActionButtonsProps {
}

const ActionButtons: React.FC<ActionButtonsProps> = () => {
    const [isDifferMenuOpen, setIsDifferMenuOpen] = useState(false);
    const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
    const dispatch:any = useDispatch();
    const treeData = useSelector((state: RootState) => state.tree);
    const [immediateTask, setImmediateTask] = useState<string | null>(null);
    const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
    const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
    const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
    const [isCompleteLoading, setIsCompleteLoading] = useState(false);

   
    const handlePlayTaskBtnClick = () => {
        if (!currentTask) return;
        
        const startTime = new Date();
        dispatch(setIsCurrentTaskRunning(true));
        dispatch(setStartTime(startTime));

        const funcs = {
            preRun: () => {
                setIsPlayPauseLoading(true);
            },
            runIfSuccess: () => {
                setIsPlayPauseLoading(false);
            },
            runIfFails: () => {
                setIsPlayPauseLoading(false);
                dispatch(setIsCurrentTaskRunning(false));
                dispatch(setStartTime(null));
            }
        }

        dispatch(playTask(currentTask._id, startTime.toString(),funcs));
    }

    const handlePauseTaskBtnClick = async () => {
            if (!currentTask) return;
            // const currentTaskId = currentTask._id;

            // const funcs = {
            //     preRun: () => {
            //         setIsPlayPauseLoading(true);
            //     },
            //     runIfSuccess: async () => {
            //         setIsPlayPauseLoading(false);
            //     },
            //     runIfFails: () => {
            //         setIsPlayPauseLoading(false);
            //     }
            // }

            // const endTime = moment().valueOf();
            // dispatch(setIsCurrentTaskRunning(false));
            // dispatch(setStartTime(null));
            // dispatch(pauseTask(currentTask._id, endTime.toString(), false,funcs));

            dispatch(setIsPauseModalOpen(true));
    }

    const handleCompletedBtnClick = async (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
		if (!currentTask) return;
        // const treeData = getTreeData();
      
        // function ifParentTaskHasOneChild(){
        //     const children = treeData.descendants().find((n:any) => n.data._id === currentTask.parent).children.filter((c:any) => !c.data.isCompleted && !c.data.isTempCompleted);
        //     if (children?.length === 1) {
        //         dispatch(setParentTaskCompleteAlertModalOpen(true));  
        //     }
        // }

        setIsCompleteLoading(true);

        const res:any = await completeTaskLocal(currentTask._id,currentTask.nodeProductivityValue,true,1);

        setIsCompleteLoading(false);
    
        if(res.success){
            const tempCurrentTask = {...currentTask};
            if(res.node.isAutoCognipulseEnabled){
                setValuesForImpulseAfterTaskComplete(tempCurrentTask);
            }
            else {
                dispatch(setIsRatingAfterCompleteModalOpen(true));
                return;
            }
            confettiRise.volume = .1;
            confettiRise.play();
            toast.success(res.message);
            // ifParentTaskHasOneChild();
            dispatch({ type: SET_IS_PAUSED, payload: false });
            dispatch({ type: UPDATE_NODE, payload: {...res.node,startTime: null,completedPortion:1}});
            const remainingIds = manuallyScheduleNodeId?.filter((_id:any) => _id !== res.node._id);
            
            if(res.node.isAutoCognipulseEnabled){
                dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(true));
            }

            dispatch(setManuallyScheduledNodeId(remainingIds));
            dispatch(setParentIdOfNodeTobeCreated(currentTask.parent));
            dispatch(setCurrentTask(null));
            dispatch(setNavigatePointerNodeId(currentTask.parent));
            // dispatch(impulseAndCognipulseAndPhySelfiePopup(currentTask._id,true,false,false,false));
        }
        else {
            toast.error(res.message);
        }
	};

    function setValuesForImpulseAfterTaskComplete(current:any){
        const root = getTreeData();
        const ct:any = getCurrentTask(current,[]);
        // const currentGoal = {...clickedTask.data,depth: clickedTask.depth};
        // const parentGoal = {...clickedTask.parent.data,depth: clickedTask.parent.depth};
        let categoryOfImprovementGoal = null;
        let currentTaskToCOC = [];

        const curTask = root?.descendants()?.find((n:any) => n.data._id === ct.currentTask._id);
        
        let temp:any = curTask;
        

        while (temp) {
            if(temp.depth !== 2){
                currentTaskToCOC.push({objective: temp.data.objective,_id: temp.data._id,depth: temp.depth});
            }
            
            if(temp.depth === 2){
                currentTaskToCOC.push({objective: (temp.data.purpose && temp.data.purpose.length > 0)? formatPurpose(arrayToObject(temp.data.purpose)):temp.data.objective,_id: temp.data._id,depth: temp.depth});
                categoryOfImprovementGoal = {...temp.data,depth: temp.depth};
                temp = null;
                break;
            }
            
            temp = temp.parent;

            if(temp.depth < 2){
                temp = null;
                break;
            }
        }

        dispatch({ type: SET_COGNIPULSE_GOALS, payload: {
            currentGoal: {...ct.currentTask},
            parentGoal: currentTaskToCOC[1],
            categoryOfImprovementGoal
        }});

        dispatch({type: SET_CURRENT_TASK_TO_COC,payload: currentTaskToCOC});
    }

    const loadImmediateTask = () => {
        treeData?.children.forEach(child => {
            if (child.isImmediateTask) {
                setImmediateTask(child._id);
                return;
            }
            child.children.forEach(child1 => {
                if (child1.isImmediateTask) {
                    setImmediateTask(child1._id);
                    return;
                }
            });
        });
    };

    const toggleIsDifferMenuOpen = () => {
        if (!currentTask) {
            return;
        }
        setIsDifferMenuOpen(prev => !prev);
    };

    const toggleIsAddMenuOpen = () => {
        if (!currentTask) {
            loadImmediateTask();
            dispatch(setAddNodeModalOpen(true));
            return;
        }
        setIsAddMenuOpen(prev => !prev);
    };

    const handleAddNodeToCurrentTask = (e: React.MouseEvent<HTMLParagraphElement>) => {
        if (e.currentTarget.id === "to-current") {
            dispatch(setAddNodeModalOpen(true));
            dispatch(setParentIdOfNodeTobeCreated(currentTask._id));
        } else {
            loadImmediateTask();
            dispatch(setAddNodeModalOpen(true));
        }
        setIsAddMenuOpen(prev => !prev);
    };

    const handleShare = () => {
        dispatch(setNodeToDelegate(currentTask));
        dispatch(setIsDelegateNodeModalOpen(true));
    }

    useEffect(() => {
        if (immediateTask) {
            dispatch(setParentIdOfNodeTobeCreated(immediateTask));
            dispatch(setManuallyScheduledNodeId([immediateTask]));
        }
    }, [immediateTask, dispatch]);


    return (
        <>
            {/* play pause buttons  */}
            {
                (currentTask?.startTime && !isPlayPauseLoading) && <NewButton onClick={handlePauseTaskBtnClick} rounded label="" icon={<Icons.PauseWhite/>}/>
            }
            {
                (!currentTask?.startTime && !isPlayPauseLoading) && <NewButton onClick={handlePlayTaskBtnClick} rounded label="" icon={<Icons.PlayWhite/>}/>
            }
            {
                isPlayPauseLoading && <NewButton onClick={()=>{}} rounded label="" icon={<Spinner/>} spinner/>
            }
    

            {/* share button  */}
            <NewButton onClick={handleShare} rounded label="" icon={<Icons.SahareWhite/>}/>


            {/* defer button  */}
            <div className="relative">
                <NewButton onClick={toggleIsDifferMenuOpen} rounded label="" icon={<Icons.NextDoubleWhite/>}/>
                {isDifferMenuOpen && (
                    <DiferDurations 
                        toggleIsDifferMenuOpen={() => setIsDifferMenuOpen(false)} 
                        id={currentTask?._id}
                    />
                )}
            </div>


            {/* add nde button  */}
            <div className="relative">
                            <NewButton onClick={toggleIsAddMenuOpen} rounded label="" icon={<Icons.PlausWhite/>}/>

                            {isAddMenuOpen && (
                                <div className={`absolute z-[1] top-[140%] -left-[70px] w-[150px] bg-white rounded-md shadow-md p-2`}>
                                    <p onClick={handleAddNodeToCurrentTask} id='to-current' className='mb-0 cursor-pointer px-2 rounded-md hover:bg-gray-300'>Current Task</p>
                                    <p onClick={handleAddNodeToCurrentTask} className='mb-0 cursor-pointer px-2 rounded-md hover:bg-gray-300'>Immediate Task</p>
                                </div>
                            )}
            </div>


            {/* complete task button  */}
            {
                isCompleteLoading? <NewButton label="" rounded spinner onClick={()=>{}} icon={<Spinner/>}/>:<NewButton onClick={handleCompletedBtnClick} icon={<Icons.CheckWhite/>} rounded label=""/>
            }
            
        </>
    );
};


export {ActionButtons};





