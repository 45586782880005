import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

  interface ITriangle {
    topAngle: number;
    text: string;
  }
  
  export const Triangle: React.FC<ITriangle> = ({topAngle,text}) => {
    const [parentWidth, setParentWidth] = useState<number>(0);
    // Reference to the SVG element
    const svgHeight = 50;
    const svgRef = useRef<SVGSVGElement>(null);
  
    function calculatePercentageValue(number: number, percentage: number): number {
      return (number * percentage) / 100;
    }
    
    function calculateTextWidth(text: string, font: string) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if(!context){
        return 10;
      }
      context.font = font; // Set the font style
      return context.measureText(text).width;
    }
  
    useEffect(() => {
      if (svgRef.current && parentWidth !== 0) {
        const svg = d3.select(svgRef.current);
  
        // Clear existing content (in case of re-renders)
        svg.selectAll("*").remove();
  
        // Define the triangle's points
        const points = [
          [0, svgHeight], // Bottom left
          [calculatePercentageValue(parentWidth,topAngle), 0], // Top middle
          [parentWidth, svgHeight], // Bottom right
        ];
  
        // Create a path string from the points
        const pathData = d3
          .line<number[]>()
          .x(d => d[0])
          .y(d => d[1])
          .curve(d3.curveLinear)(points) + "Z"; // "Z" closes the path
  
        // Append the path to the SVG
        svg.append("path")
          .attr("d", pathData)
          .attr("fill", "steelblue")
          // .attr("stroke", "black")
          // .attr("stroke-width", 2);
         
        const font = "16px Arial";  
        const textLen:number = calculateTextWidth(text,font);
        const textX = calculatePercentageValue(parentWidth,topAngle);
        const ajjustedX = topAngle > 90? textLen:topAngle <= 10? 0:textLen/2;
  
        svg.append("text")
        .text(text) 
        .style("font-family", font.split(" ")[1]) // Set font family
        .style("font-size", font.split(" ")[0]) // Set font size
        .attr("fill", "#ffffff") // Set font size
        .attr("x",textX-ajjustedX)
        .attr("y",svgHeight-10);
      }
    }, [topAngle,text,parentWidth]); // Run once when the component mounts
  
  
    useEffect(() => {
      if (svgRef.current) {
        const childElement = svgRef.current;
        
        const parentElement = childElement?.parentElement;
        if (parentElement) {
          setParentWidth(parentElement.offsetWidth);
        }
      }
    }, []);
  
  
    return <svg ref={svgRef} width={parentWidth} height={svgHeight}></svg>;
  };