import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Loading, ProgressBar} from '../../components';
import {
	setNavigatePointerNodeId,
	toggleIsCompletedNodesVisible,
	toggleIsDeletedNodesVisible,
	setPowerLaw,
	setIsFullViewOn,
	setNodeToDelegate,
	setIsDelegateNodeModalOpen
} from "../../store/actions";
import { RootState } from '../../store/store'; // adjust import path as necessary
import { RoutinesAndChallenges } from './components/RoutinesAndChallenges';
import { DiferDurations } from './components/DiferDurations';
import { CurrentTask } from './components/currentTask';
import { BreadCrumbs } from './components/BreadCrumbs';
import { Icons } from '../../components/Icons';
import { FeedBack } from './components/FeedBack';
import { NewButton } from '../../components/Buttons/NewButton';
import { getLabelFromDepth } from '@utils/getLabelFromDepth';
import { cn } from '@utils/cn';
import { useIsMobile } from '@hooks/useIsMobile';
import { DesktopFocusUI } from './components/DesktopFocusUI';


// Define the types for component props
interface SideBarProps {}

const SideBar: React.FC<SideBarProps> = () => {
	const dispatch:any = useDispatch();
	const [parentObjectiveIndex, setParentObjectiveIndex] = useState<number>(0);
	const isCompletedNodesVisible = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible);
	const isDeletedNodesVisible = useSelector((state: RootState) => state.globalStates.isDeletedNodesVisible);
	const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents);
	const currentView = useSelector((state: RootState) => state.globalStates.currentView);
	const [showPowerLaw] = useState<boolean>(false);
	const powerLaw = useSelector((state: RootState) => state.globalStates.powerLaw);
	const [isDifferMenuOpen, setIsDifferMenuOpen] = useState<boolean>(false);
	const treeData = useSelector((state: RootState) => state.tree);
	const isMobile = useIsMobile();

	const handlePrevOrNextClick = (isNext: boolean) => {
		setParentObjectiveIndex(prev => {
			if (isNext) {
				return prev === currentTasksParents.length - 1 ? 0 : prev + 1;
			}
			return prev === 0 ? currentTasksParents.length - 1 : prev - 1;
		});
	};

	const toggleIsDifferMenuOpen = () => {
		setIsDifferMenuOpen(prev => !prev);
	};

	const handleParentObjectiveClick = () => {
		dispatch(setIsFullViewOn(false));
		dispatch(setNavigatePointerNodeId(currentTasksParents[parentObjectiveIndex]?._id));
	};

	const handleShare = () => {
		dispatch(setNodeToDelegate(currentTasksParents[parentObjectiveIndex]));
		dispatch(setIsDelegateNodeModalOpen(true));
	}

	useEffect(() => {
		setParentObjectiveIndex(currentTasksParents?.length - 1);
	}, [currentTasksParents]);

	if(!isMobile && currentView === "focus"){
		return <DesktopFocusUI/>
	}

	return (
		<div 
			className={cn(
				"bg-gray-300 flex justify-between flex-col p-3",
				currentView === "focus" && "mx-auto w-[400px] min-h-[calc(100vh-64px)] max-w-full"
			)}>
			<div className='mb-2'>
				<div className='mb-4'>
					<p className='text-sm text-center font-bold text-gray-600'>{currentTasksParents && currentTasksParents.length !== 0 ? getLabelFromDepth(currentTasksParents[parentObjectiveIndex]?.depth):""}</p>
					{/* text area  */}
					<div className="w-full md:w-full mb-1 mt-2 flex items-center">
						<span onClick={() => handlePrevOrNextClick(false)} className='p-1 cursor-pointer'>{"<"}</span>
						<div onClick={handleParentObjectiveClick} className="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-auto py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white">
						    {currentTasksParents && currentTasksParents.length !== 0 ? currentTasksParents[parentObjectiveIndex]?.objective : "Please schedule a Category of Improvement"}
						</div>
						<span onClick={() => handlePrevOrNextClick(true)} className='p-1 cursor-pointer'>{">"}</span>
					</div>

					

					{/* defer button and progress bar  */}
					{
						currentTasksParents?.length > 0 && <>

							{/* progress  */}
							<div className="flex justify-between items-center mb-1">
								<p className='mb-1'>Progress</p>

								<div className="flex items-center gap-1 mt-2">
									{/* share button  */}
									<NewButton onClick={handleShare} icon={<Icons.SahareWhite/>} label='' rounded/>

									{/* defer btn  */}
									<div className='flex justify-end relative'>
										<NewButton onClick={toggleIsDifferMenuOpen} icon={<Icons.NextDoubleWhite/>} label='' rounded/>

										{isDifferMenuOpen && <DiferDurations toggleIsDifferMenuOpen={toggleIsDifferMenuOpen} left={false} x={-70} id={currentTasksParents[parentObjectiveIndex]?._id}/>}
									</div>
								</div>
							</div>
							<div className="h-4">
								<ProgressBar height={15} borderRadius={15} progress={currentTasksParents && currentTasksParents[parentObjectiveIndex]?.completedPortion * 100} />
							</div>
						</>
					}
				</div>

				<FeedBack/>

				{
					!treeData && <div className='bg-white rounded-md mb-4 py-2'>
						<Loading />
					</div>
				}

				{/* current task  */}
				<CurrentTask/>

				<hr/>
				
				<BreadCrumbs />
			</div>

			<hr/>

			<RoutinesAndChallenges treeData={treeData} />

			<div className="flex-1 flex flex-col justify-end w-full">
				<div className="mt-3 flex gap-1 flex-col">
					<NewButton label={`${isCompletedNodesVisible ? "Hide" : "Show"} Completed`} onClick={() => dispatch(toggleIsCompletedNodesVisible())}/>
					<NewButton label={`${isDeletedNodesVisible ? "Hide" : "Show"} Deleted`} onClick={() => dispatch(toggleIsDeletedNodesVisible())}/>


					<div className="flex-1">
						{
							showPowerLaw && <div className="w-full">
								<input value={powerLaw} onChange={(e) => dispatch(setPowerLaw(e.target.value))} type="number" className='w-full rounded-md px-2 py-1' />
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideBar;












// source https://tailwindcomponents.com/component/dashboard-12

