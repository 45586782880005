import React, { useEffect, useState } from "react";
import {Modal} from "./Index";
import { useSelector, useDispatch } from "react-redux";
import {
  updateNode,
  setIsRatingAfterCompleteModalOpen,
  setNavigatePointerNodeId,
  setCurrentTask,
  setManuallyScheduledNodeId,
} from "../../store/actions";
import { RootState } from "../../store/store";
import { RatingUI } from "@components/ImpulseRating";
import { NewButton } from "@components/Buttons/NewButton";
import { createTaskProgress, getLastProgressOfUser } from "@store/actions/taskProgress";
import { SET_IS_PAUSED, UPDATE_NODE } from "@constants/actions";




interface IndexProps {}

export const RatingAfterCompleteModal: React.FC<IndexProps> = () => {
    const dispatch:any = useDispatch();
    const isRatingAfterCompleteModalOpen = useSelector((state: RootState) => state.globalStates.isRatingAfterCompleteModalOpen); 
    const navigatePointerNodeId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId); 
    const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with your state type
    const [sliderValues, setSliderValues] = React.useState([0]);
    const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
    const selectedActivityId = useSelector((state:RootState) => state.globalStates.selectedActivityId);
    const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);

    const handleClose = () => {
      if(navigatePointerNodeId === currentTask._id){
        dispatch(setNavigatePointerNodeId(currentTask.parent));
      }
      dispatch({ type: SET_IS_PAUSED, payload: false });
      dispatch({ type: UPDATE_NODE, payload: {...currentTask,startTime: null,completedPortion:1,isCompleted: true}});
    }

    const handlePause = (e:React.MouseEvent<HTMLButtonElement>) => {
      const endTime = new Date();
      const currentTaskId = currentTask._id;

      const funcs = {
          preRun: () => {
              setIsPlayPauseLoading(true);
          },
          runIfSuccess: async () => {
              await createTaskProgress({node: currentTaskId,progress: Math.floor(Number(sliderValues[0])),activity: selectedActivityId,createdAt: endTime});
              await createTaskProgress({node: currentTaskId,progress: 0,activity: selectedActivityId,createdAt: endTime});
              setIsPlayPauseLoading(false);
              dispatch(setIsRatingAfterCompleteModalOpen(false));
          },
          runIfFails: () => {
              setIsPlayPauseLoading(false);
          }
      }

      dispatch({ type: SET_IS_PAUSED, payload: false });
      dispatch(setCurrentTask({...currentTask,startTime: null}));
      dispatch(updateNode({isCompleted: true,startTime: null,_id: currentTask._id,completedPortion: Math.floor(Number(sliderValues[0]))/100},funcs.runIfSuccess,funcs.runIfFails));
      const remainingIds = manuallyScheduleNodeId?.filter((_id:any) => _id !== currentTask?._id);
      dispatch(setManuallyScheduledNodeId(remainingIds));
    };

    useEffect(() => {
      (async ()=> {
        const res = await getLastProgressOfUser(currentTask._id);
  
        if(res && res.success && res.progress){
          setSliderValues([res.progress])
        }
      })()
    }, [currentTask._id]);



  return (
    <Modal
      saveBtnText=""
      onSubmit={()=>{}}
      isOpen={isRatingAfterCompleteModalOpen}
      setIsOpen={(bool) => { dispatch(setIsRatingAfterCompleteModalOpen(bool)); }}
      hideBtns={true}
      header
      handleClose={handleClose}
    >
      <>
              <div className="mt-8">
                <RatingUI 
                    currentGoal={currentTask}
                    sliderValues={sliderValues}
                    setSliderValues={setSliderValues}
                />
              </div>

              <div className="w-full max-w-[320px] mx-auto">
                  <NewButton isLoading={isPlayPauseLoading} full label="Complete Now" onClick={handlePause}/>
              </div>
      </>
    </Modal>
  );
};




