import React, { useEffect, useState } from "react";
import {Modal} from "./Index";
import { useSelector, useDispatch } from "react-redux";
import {
  setStartTime,
  setIsPauseModalOpen,
  setIsCurrentTaskRunning,
  pauseTask,
  updateNode,
} from "../../store/actions";
import { RootState } from "../../store/store";
import { RatingUI } from "@components/ImpulseRating";
import { NewButton } from "@components/Buttons/NewButton";
import { Icons } from "@components/Icons";
import { createTaskProgress, getLastProgressOfUser } from "@store/actions/taskProgress";




interface IndexProps {}

export const PauseModal: React.FC<IndexProps> = () => {
  const dispatch:any = useDispatch();
  const isPauseModalOpen = useSelector((state: RootState) => state.globalStates.isPauseModalOpen); // Replace `any` with your state type
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with your state type
  const [sliderValues, setSliderValues] = React.useState([0]);
  const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
  const selectedActivityId = useSelector((state:RootState) => state.globalStates.selectedActivityId);

  const handlePause = (e:React.MouseEvent<HTMLButtonElement>) => {
    const endTime = new Date();
    const currentTaskId = currentTask._id;

    const funcs = {
        preRun: () => {
            setIsPlayPauseLoading(true);
        },
        runIfSuccess: async () => {
            await createTaskProgress({node: currentTaskId,progress: Math.floor(Number(sliderValues[0])),activity: selectedActivityId,createdAt: endTime});
            await createTaskProgress({node: currentTaskId,progress: 0,activity: selectedActivityId,createdAt: endTime});
            setIsPlayPauseLoading(false);
            dispatch(setIsPauseModalOpen(false));
        },
        runIfFails: () => {
            setIsPlayPauseLoading(false);
        }
    }

    dispatch(setIsCurrentTaskRunning(false));
    dispatch(setStartTime(null));
    dispatch(pauseTask({
      nodeId: currentTask._id,
      endTime,
      isPause: false,
      funcs
    }));
    dispatch(updateNode({_id: currentTask._id,completedPortion: Math.floor(Number(sliderValues[0]))/100},()=>{},()=>{}));
  };


    useEffect(() => {
      (async ()=> {
        const res = await getLastProgressOfUser(currentTask._id);
  
        if(res && res.success && res.progress){
          setSliderValues([res.progress])
        }
      })()
    }, [currentTask._id]);


  return (
    <Modal
      saveBtnText=""
      onSubmit={()=>{}}
      isOpen={isPauseModalOpen}
      setIsOpen={(bool) => { dispatch(setIsPauseModalOpen(bool)); }}
      hideBtns={true}
      header
    >
      <>
              <div className="mt-8">
                <RatingUI 
                    currentGoal={currentTask}
                    sliderValues={sliderValues}
                    setSliderValues={setSliderValues}
                />
              </div>

              <div className="w-full max-w-[320px] mx-auto">
                  <NewButton isLoading={isPlayPauseLoading} full label="Pause Now" onClick={handlePause} icon={<Icons.PauseWhite/>}/>
              </div>
      </>
    </Modal>
  );
};




