
function hexToRgb(hex: string): { r: number, g: number, b: number } {
    let r = 0, g = 0, b = 0;
  
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
  
    return { r, g, b };
  }
  
  function luminance(r: number, g: number, b: number): number {
    // Normalize RGB values to the range [0, 1]
    const [rL, gL, bL] = [r, g, b].map(val => {
      val /= 255;
      return val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4);
    });
  
    // Calculate luminance
    return 0.2126 * rL + 0.7152 * gL + 0.0722 * bL;
  }
  
  function contrastRatio(luminance1: number, luminance2: number): number {
    // Ensure luminance1 is always the lighter color
    const light = Math.max(luminance1, luminance2);
    const dark = Math.min(luminance1, luminance2);
  
    return (light + 0.05) / (dark + 0.05);
  }
  
  export function getTextColorForBackground(hex: string): string {
    const { r, g, b } = hexToRgb(hex);
    const backgroundLuminance = luminance(r, g, b);
  
    // Luminance of white and black text
    const whiteLuminance = luminance(255, 255, 255);
    const blackLuminance = luminance(0, 0, 0);
  
    // Contrast ratio between the background and black/white
    const contrastWithWhite = contrastRatio(backgroundLuminance, whiteLuminance);
    const contrastWithBlack = contrastRatio(backgroundLuminance, blackLuminance);
  
    // Return the color with the highest contrast ratio
    return contrastWithWhite > contrastWithBlack ? '#FFFFFF' : '#000000';
  }