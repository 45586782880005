import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../store/store";
import { useSelector } from "react-redux";
import CircularSlider from "react-circular-slider-svg";
import { cogniPulse1, cogniPulse2, cogniPulse3 } from "../assets/sounds";
import { createGoalProgress, setIsCreateImpulseModalOpen, updateNode } from "../store/actions";
import { useDispatch } from "react-redux";
import { NewButton } from "./Buttons/NewButton";



  const BROWSER_NAME = getBorwserName();

  interface IImpulseRating {
      inThePastMinutes: any;
      impactSurveyId: string;
  }

  export const ImpulseRating: React.FC<IImpulseRating> = ({inThePastMinutes,impactSurveyId}) => {
      const cognipulseGoals = useSelector((state:RootState) => state.globalStates.cognipulseGoals);
      const dispatch: AppDispatch = useDispatch();
      const [sliderValues, setSliderValues] = React.useState([0]);

      const handleOk = async (e:React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          const progress: number = Math.floor(Number(sliderValues[0]));

          const data:any = {
            percentage: progress,
            survey: impactSurveyId,
            activity: cognipulseGoals.categoryOfImprovementGoal?.activity,
            dialGoal: cognipulseGoals.currentGoal?._id,
            headerGoal: cognipulseGoals.parentGoal?._id
          }

          const res = await createGoalProgress(data);
          
          if(res.success){
            dispatch(setIsCreateImpulseModalOpen(false));

            const node = {
                _id: cognipulseGoals.currentGoal?._id,
                completedPortion: progress/100,
            }
            dispatch(updateNode(node,()=>{},()=>{}));
          }
      }
      

      return (
          <>
              <p className="mb-3 text-center text-secondry-color font-bold select-none">{"impulse"}</p>
              {
                  inThePastMinutes && <h6 className="mb-3 text-center text-secondry-color font-bold">In the past {inThePastMinutes} minutes</h6>
              }
              <RatingUI 
                currentGoal={cognipulseGoals.currentGoal}
                sliderValues={sliderValues}
                setSliderValues={setSliderValues}
              />
              <div className='mt-10 gap-5 w-[300px] max-w-full mx-auto'>
                  <NewButton onClick={handleOk} label="Submit" full/>
              </div>
          </>
      )
  }


  interface ratingUIProps {
      currentGoal: any;
      sliderValues: number[];
      setSliderValues: any;
      currentTaskLabel?: string;
  }

    
  export const RatingUI: React.FC<ratingUIProps> = ({currentTaskLabel="What progress have you made on",setSliderValues,sliderValues,currentGoal}) => {
            const [tempSliderValues, setTempSliderValues] = React.useState([0]);

            return <>
                      <div className="max-w-xl mx-auto">
                          {
                              currentGoal && <>
                                  <div className='mx-auto max-w-xs text-center mt-5 flex flex-col justify-center items-center'>
                                  <h2 className='text-secondry-color text-sm mb-1 font-bold'>{currentTaskLabel}</h2>
                                  <p className='bg-gray-600/20 text-secondry-color text-sm px-4 py-1 rounded-md'>{currentGoal.objective}</p>
                                  </div>
                              </>
                          }

                          <div className="mx-auto max-w-6xl">
                              <div className="flex gap-10 items-center justify-center">
                                  <div className="relative w-[300px] h-[300px] flex justify-center items-center mobile-slider">
                                      <ArcSlider
                                          values={sliderValues}
                                          setValues={setSliderValues}
                                          tempValues={tempSliderValues}
                                          setTempValues={setTempSliderValues}
                                      />
                                      <div className="absolute w-[300px] max-w-[300px] top-[80%] flex justify-between">
                                            <h1 className='text-red-600 text-[16px] select-none'>No Progress</h1>
                                            <h1 className='text-green-600 text-[16px] select-none'>Completed</h1>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
            </>
  }


  interface IArcSlider {
    values: any;
    setValues: any;
    tempValues: any;
    setTempValues: any;
  }
  
  const  ArcSlider: React.FC<IArcSlider> = ({values,setValues,tempValues,setTempValues}) => {
    const percentages = [
        {
            min: 0,
            max: 0,
            text: "Alas, no progress has been made on this yet.",
        },
        {
            min: 1,
            max: 4,
            text: "A tiny bit of progress so far.",
        },
        {
            min: 5,
            max: 9,
            text: "Minimal— no real progress yet.",
        },
        {
            min: 10,
            max: 14,
            text: "A glimmer of progress, but far from complete.",
        },
        {
            min: 15,
            max: 19,
            text: "Some initial progress, but still in the early stages.",
        },
        {
            min: 20,
            max: 24,
            text: "Moving forward, but much more is needed.",
        },
        {
            min: 25,
            max: 29,
            text: "Some progress, but some way off completion.",
        },
        {
            min: 30,
            max: 34,
            text: "Moving forward, but still a way to go.",
        },
        {
            min: 35,
            max: 39,
            text: "Progressing, but completion is still out of reach.",
        },
        {
            min: 40,
            max: 44,
            text: "Decent progress, but still unfinished.",
        },
        {
            min: 45,
            max: 49,
            text: "Good Progress, almost halfway.",
        },
        {
            min: 50,
            max: 54,
            text: "Halfway there, more to go.",
        },
        {
            min: 55,
            max: 59,
            text: "Solid progress, but needs further completion.",
        },
        {
            min: 60,
            max: 64,
            text: "Significant progress still needs to be completed.",
        },
        {
            min: 65,
            max: 69,
            text: "Nearly completed.",
        },
        {
            min: 70,
            max: 74,
            text: "Completed, just okay enough to move on.",
        },
        {
            min: 75,
            max: 79,
            text: "Satisfactory completion—ready to move forward.",
        },
        {
            min: 80,
            max: 89,
            text: "Well done! Completed, with minor improvements possible.",
        },
        {
            min: 90,
            max: 94,
            text: "Completed to a high standard.",
        },
        {
            min: 95,
            max: 99,
            text: "Excellent completion!",
        },
        {
            min: 100,
            max: 110,
            text: "Outstanding! Task fully completed to the best standard.",
        },
    ]
  
    const handleChange = (v:any)=> {
      setValues(() => [Number(v)]);
      setTempValues(() => [Number(v)]);
    }
  
    
    useEffect(() => {
      let time:any = undefined;
      if(true){
        time = setTimeout(() => {
          const items = [cogniPulse1,cogniPulse2,cogniPulse3];
          const cogniPulse = items[Math.floor(Math.random() * items.length)];
          cogniPulse.volume = .1;
          if (cogniPulse.paused) {
            cogniPulse.play();
          }
          else{
            cogniPulse.pause();
              cogniPulse.currentTime = 0
          }
          cogniPulse.play();
        }, 500);
      }
  
      return () => {
        clearTimeout(time);
      }
    }, [values]);
  
  
    return (
      <>
        <div id='arc-slider-1' className='absolute w-[300px] h-[225px] flex justify-center items-center'>
          <MyCircularSlider values={values} handleChange={handleChange}/>
        </div>
  
        {/* {
          (BROWSER_NAME !== "Safari" && BROWSER_NAME !== "unknown") && <>
              <div id='arc-slider-2' className='absolute w-[300px] h-[225px] flex justify-center items-center'>
                <MyCircularSlider values={tempValues} handleChange={handleChange}/>
              </div>
          </>
        } */}

        <div id='question-container' className="select-none w-[230px] h-[230px] absolute top-[30px] rounded-full flex flex-col text-center text-[14px] p-3 justify-center items-center  text-secondry-color">
             {percentages.find(p => p.min <= Math.floor(values[0]) &&  Math.floor(values[0]) <= p.max)?.text}
        </div>
      </>
    )
  }
  

  interface IMyCircularSlider {
      values: any;
      handleChange: any;
      bg?: any;
  }
  
  const MyCircularSlider: React.FC<IMyCircularSlider> = ({values,handleChange}) => {
  
    return (
      <CircularSlider
            size={300}
            minValue={0}
            maxValue={100}
            startAngle={60}
            endAngle={300}
            angleType={{
              direction: "cw",
              axis: "-y"
            }}
            handle1={{
              value: values[0],
              onChange: handleChange
            }}
            arcColor="rgb(79 70 229)"
            arcBackgroundColor={"#CECECE"}
          />
    )
  }


  function getBorwserName() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera'
    } else if (navigator.userAgent.indexOf("Edg") !== -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return 'Firefox';
    } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!(document as any).documentMode === true)){
      return 'IE';
    } else {
      return 'unknown';
    }
  }


  