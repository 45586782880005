import React, { useEffect, useRef, useState } from 'react'
import { SVGSircularProgressBar } from './SVGSircularProgressBar'
import { deepFocus, leftArrow, rightArrow } from '@assets/index';
import { ProgressBar } from '@components/ProgressBar';
import { NewButton } from '@components/Buttons/NewButton';
import { Spinner } from './Spinner';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@store/store';
import { useDispatch } from 'react-redux';
import { completeTaskLocal, getNodeDashboardData, getRunningTask, getTaskById, playTask, removeChallenge, removeRoutine, setAddNodeModalOpen, setCurrentTask, setCurrentTasksParents, setIsCreateImpulseAfterTaskCompleteModalOpen, setIsCurrentTaskRunning, setIsDelegateNodeModalOpen, setIsFullViewOn, setIsPauseModalOpen, setIsRatingAfterCompleteModalOpen, setManuallyScheduledNodeId, setNavigatePointerNodeId, setNodeToDelegate, setParentIdOfNodeTobeCreated, setStartTime, setTimingAlertModalOpen, toggleIsCompletedNodesVisible } from '@store/actions';
import { confettiRise } from '@assets/sounds';
import { toast } from 'react-toastify';
import { SET_COGNIPULSE_GOALS, SET_CURRENT_TASK_TO_COC, SET_IS_PAUSED, UPDATE_NODE } from '@constants/actions';
import { getTreeData } from '@pages/ICICLETree/Index';
import { getCurrentTask } from '@utils/getCurrentTask';
import { arrayToObject, formatPurpose } from '@components/Modal/Purpose';
import moment from 'moment';
import { totalExploitationDuration } from '@utils/initialCalculations';
import { calculatePercentage } from '@utils/calculatePercentage';
import { DiferDurations } from './DiferDurations';
import { FlowScoreChart, XLScoreChart } from '@components/charts';
import { getCOINodeColor } from '@utils/getCOINodeColor';
import { removePriority } from '@store/actions/priority';
import { getBreadCrumbsOfLoggedInUser } from '@store/actions/exploitationDuration';
import "./test.css";
import { CheckActionButton, CognitivePotentialSVG, NextFastStepActionButton, PauseActionButton, PlayActionButton, PlusActionButton, ShareActionButton } from '@components/SVG';
import { ChallengeSVG } from '@components/SVG/ChallengeSVG';
import { PrioritiesSVG } from '@components/SVG/PrioritiesSVG';
import { RoutinesSVG } from '@components/SVG/RoutinesSVG';

interface Ishow {
  breadCrampsAndActionButtons: boolean;
  leftBar: boolean;
  rightBar: boolean;
  nextAndBackIcons: boolean;
}

export function DesktopFocusUI() {
  const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents);
  const [show, setShow] = useState<Ishow>({
    breadCrampsAndActionButtons: false,
    leftBar: false,
    rightBar: false,
    nextAndBackIcons: false
  });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);


  const handleMouseMove = () => {
    setShow((prev:Ishow) => {
      return {
        ...prev,
        breadCrampsAndActionButtons: true
      }
    });

    // Clear previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
 

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      setShow((prev:Ishow) => {
        return {
          ...prev,
          breadCrampsAndActionButtons: false
        }
      });
    }, 5000);
  }
  
  return (
    <div onMouseMove={handleMouseMove} style={{backgroundColor: currentTasksParents[0]?.color || ""}} className='bg-gray-100 w-full min-h-[calc(100vh-64px)]'>
      <div className="mx-auto max-w-7xl  min-h-[calc(100vh-64px)] flex">
        <Left show={show} setShow={setShow}/>
        <Middle show={show}/>
        <Right show={show} setShow={setShow}/>
      </div>
    </div>
  )
}


interface LeftProps {
  show: Ishow;
  setShow: any;
}

const Left:React.FC<LeftProps> = ({show,setShow}) => {


  const handleMouseEnter = () => {
    setShow((prev:Ishow) => {
      return {
        ...prev,
        leftBar: true
      }
    });
  }
  const handleMouseLeave = () => {
    setShow((prev:Ishow) => {
      return {
        ...prev,
        leftBar: false
      }
    });
  }

  return <>
        <div className="flex-1 flex flex-col gap-4 justify-center items-center max-[1000px]:hidden">
          <div 
          style={{visibility: show.leftBar? "visible":"hidden"}} 
          className="w-[250px] h-[60%] bg-white/40 rounded-md p-2">
            <div className="flex justify-center my-5">
              <CognitivePotentialSVG/>
            </div>
            <CognitiveAndFlowScore/>
          </div>
          <div onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
              <CognitivePotentialSVG/>
          </div>
        </div>
  </>
}


const CognitiveAndFlowScore = () => {
    const XLScoreRef = useRef(null);
    const flowScoreRef = useRef(null);
    const [sideBarDashboardData, setSideBarDashboardData] = useState<any>(null);
    const [currentTaskActivityId, setCurrentTaskActivityId] = useState(null);
    const currentTask = useSelector((state:RootState) => state.globalStates.currentTask);
    const nodeDashboardData = useSelector((state: RootState) => state.globalStates.nodeDeshboardData);
    const root = getTreeData();


    useEffect(() => {
            if(currentTask){
                const cn = root.descendants().find((n:any) => n.data._id === currentTask._id);
            
                if(cn){
                    const cateOfImp = cn.ancestors()[cn.ancestors().length-3];
       
                    if(cateOfImp?.data?.activity){
                        setCurrentTaskActivityId(cateOfImp?.data?.activity)
                    }
                }
            }
    }, [root,currentTask]);
    
    
    useEffect(() => {
            (async () => {
              if(currentTaskActivityId){
                const res = await getNodeDashboardData(currentTaskActivityId || "");
                if(res && res.success){
                    const data = {...res};
                    delete data.success;
                    delete data.message;
                    setSideBarDashboardData(data)
                }
                else {
                  setSideBarDashboardData(null)
                }
              }
            })()
    }, [currentTaskActivityId,nodeDashboardData]);
    
        
    if(!sideBarDashboardData){
        return null;
    }

    return <div className='w-full h-full'>
        <h2 className='text-center font-bold text-gray-600'>Cognitive</h2>
        <div className="w-[70%] mx-auto min-w-[105px]">
                                {/* <h2 className="text-gray-400 font-bold text-center">Cognitive</h2> */}
                                <div ref={XLScoreRef} className="w-full" id='xl-score-in-side-bar'>
                                    <XLScoreChart containerRef={XLScoreRef} score={sideBarDashboardData.XLScore} change={sideBarDashboardData.XLChange}/>
                                </div>
        </div>
        <h2 className='text-center font-bold text-gray-600'>Flow</h2>
        <div className="w-[70%] mx-auto min-w-[105px]">
                                {/* <h2 className="text-gray-400 font-bold text-center">Flow</h2> */}
                                <div ref={flowScoreRef} className="w-full" id='flow-score-in-side-bar'>
                                    <FlowScoreChart containerRef={flowScoreRef} score={sideBarDashboardData.flowScore} change={sideBarDashboardData.flowChange}/>
                                </div>
        </div>
    </div>
}

interface MiddleProps {
  show: Ishow
}

const Middle:React.FC<MiddleProps> = ({show}) => {


  return <>
        <div className="min-[1000px]:w-[40%] max-[1000px]:w-full flex flex-col">
            {/* middle top  */}
            <div className="flex-1">
                <h1 className='text-center text-xl font-bold py-4 text-gray-600'>Deep Focus Mode</h1>
                <img src={deepFocus} alt="deep-focus" className='w-[250px] h-[150px] -mt-[50px] mx-auto'/>
            </div>

            {/* middle middle  */}
            <div className="flex-1 flex gap-2 items-center justify-center">
                <Progress show={show}/>
            </div>
            
            {/* middle bottom  */}
            <div style={{visibility: show.breadCrampsAndActionButtons? "visible":"hidden"}} className="flex-1 flex flex-col items-center">
                <ActionButtons/>
                <BreadCramps/>
            </div>
        </div>
  </>
}

const ActionButtons = () => {
  interface ButtonType {
    id: string;
    icon: any;
    isLoading: boolean;
  }
  const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents);
  const [buttons, setButtons] = useState<ButtonType[]>(
                                         [
                                            {
                                              icon: <PauseActionButton color={currentTasksParents[0]?.color}/>,
                                              id: "pause",
                                              isLoading: false
                                            },
                                            {
                                              icon: <PlayActionButton color={currentTasksParents[0]?.color}/>,
                                              id: "play",
                                              isLoading: false
                                            },
                                            {
                                              icon: <ShareActionButton color={currentTasksParents[0]?.color}/>,
                                              id: "share",
                                              isLoading: false
                                            },
                                            {
                                              icon: <NextFastStepActionButton color={currentTasksParents[0]?.color}/>,
                                              id: "defer",
                                              isLoading: false
                                            },
                                            {
                                              icon: <PlusActionButton color={currentTasksParents[0]?.color}/>,
                                              id: "add",
                                              isLoading: false
                                            },
                                            {
                                              icon: <CheckActionButton color={currentTasksParents[0]?.color}/>,
                                              id: "complete",
                                              isLoading: false
                                            }
                                         ]
                                         );
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const dispatch:AppDispatch = useDispatch();
  const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
  const [isShowDeferMenu, setIsShowDeferMenu] = useState(false);
  
 

  const setLoading = (id: string,state:boolean) => {
    setButtons(prev => {
      return prev.map(b => b.id === id? {...b,isLoading: state}:b);
    });
  }

  const play = async () => {
      if (!currentTask) return;
            
      const startTime = new Date();
      dispatch(setIsCurrentTaskRunning(true));
      dispatch(setStartTime(startTime));
    
      const funcs = {
                preRun: () => {
                  setLoading("play",true);
                },
                runIfSuccess: () => {
                  setLoading("play",false);
                },
                runIfFails: () => {
                  setLoading("play",false);
                    dispatch(setIsCurrentTaskRunning(false));
                    dispatch(setStartTime(null));
                }
      }
    
      dispatch(playTask(currentTask._id, startTime.toString(),funcs));
  }

  const pause = async () => {
    dispatch(setIsPauseModalOpen(true));
  }

  const share = async () => {
      dispatch(setNodeToDelegate(currentTask));
      dispatch(setIsDelegateNodeModalOpen(true));
  }

  const defer = async () => {
    setIsShowDeferMenu(!isShowDeferMenu);
  }

  const add = async () => {
      dispatch(setAddNodeModalOpen(true));
      dispatch(setParentIdOfNodeTobeCreated(currentTask._id));
  }

  const complete = async () => {
    if (!currentTask) return;
   
        setLoading("complete",true);
        
        const res:any = await completeTaskLocal(currentTask._id,currentTask.nodeProductivityValue,true,1);
        
        setLoading("complete",false);
    
        if(res.success){
            const tempCurrentTask = {...currentTask};
            if(res.node.isAutoCognipulseEnabled){
                setValuesForImpulseAfterTaskComplete(tempCurrentTask);
            }
            else {
                dispatch(setIsRatingAfterCompleteModalOpen(true));
                return;
            }
            confettiRise.volume = .1;
            confettiRise.play();
            toast.success(res.message);
            // ifParentTaskHasOneChild();
            dispatch({ type: SET_IS_PAUSED, payload: false });
            dispatch({ type: UPDATE_NODE, payload: {...res.node,startTime: null,completedPortion:1}});
            const remainingIds = manuallyScheduleNodeId?.filter((_id:any) => _id !== res.node._id);
            
            if(res.node.isAutoCognipulseEnabled){
                dispatch(setIsCreateImpulseAfterTaskCompleteModalOpen(true));
            }

            dispatch(setManuallyScheduledNodeId(remainingIds));
            dispatch(setParentIdOfNodeTobeCreated(currentTask.parent));
            dispatch(setCurrentTask(null));
            dispatch(setNavigatePointerNodeId(currentTask.parent));
            // dispatch(impulseAndCognipulseAndPhySelfiePopup(currentTask._id,true,false,false,false));
        }
        else {
            toast.error(res.message);
        }


      function setValuesForImpulseAfterTaskComplete(current:any){
          const root = getTreeData();
          const ct:any = getCurrentTask(current,[]);
          // const currentGoal = {...clickedTask.data,depth: clickedTask.depth};
          // const parentGoal = {...clickedTask.parent.data,depth: clickedTask.parent.depth};
          let categoryOfImprovementGoal = null;
          let currentTaskToCOC = [];
  
          const curTask = root?.descendants()?.find((n:any) => n.data._id === ct.currentTask._id);
          
          let temp:any = curTask;
          
  
          while (temp) {
              if(temp.depth !== 2){
                  currentTaskToCOC.push({objective: temp.data.objective,_id: temp.data._id,depth: temp.depth});
              }
              
              if(temp.depth === 2){
                  currentTaskToCOC.push({objective: (temp.data.purpose && temp.data.purpose.length > 0)? formatPurpose(arrayToObject(temp.data.purpose)):temp.data.objective,_id: temp.data._id,depth: temp.depth});
                  categoryOfImprovementGoal = {...temp.data,depth: temp.depth};
                  temp = null;
                  break;
              }
              
              temp = temp.parent;
  
              if(temp.depth < 2){
                  temp = null;
                  break;
              }
          }
  
          dispatch({ type: SET_COGNIPULSE_GOALS, payload: {
              currentGoal: {...ct.currentTask},
              parentGoal: currentTaskToCOC[1],
              categoryOfImprovementGoal
          }});
  
          dispatch({type: SET_CURRENT_TASK_TO_COC,payload: currentTaskToCOC});
      }
  }

  const handleActionButtonClicked = async (e:React.MouseEvent<HTMLButtonElement>,item:ButtonType) => {
    e.preventDefault();

    if(item.id === "play"){
      await play();
    }
    if(item.id === "pause"){
      await pause()
    }
    if(item.id === "share"){
      await share();
    }
    if(item.id === "defer"){
      await defer();
    }
    if(item.id === "add"){
      await add();
    }
    if(item.id === "complete"){
      await complete();
    }
  }


  return <>
          <div className="max-w-md mt-10">
              <div className='flex justify-center gap-3'>
                  {
                    buttons.filter(btn => {
                      if(btn.id !== "play" && btn.id !== "pause"){
                        return true;
                      }
                      if (!currentTask?.startTime) {
                        return btn.id === "play"; // Keep only "play" if startTime is present
                      } 
                      else {
                        return btn.id === "pause"; // Keep only "pause" if startTime is null/undefined
                      }
                    })
                    .map((_:ButtonType,idx:number)=> {

                      if(_.isLoading){
                        return <NewButton 
                                  spinner 
                                  key={idx} 
                                  style={{width: "50px",height: "50px",backgroundColor: "#fff"}} 
                                  onClick={(e)=>{handleActionButtonClicked(e,_)}} 
                                  rounded 
                                  label="" 
                                  icon={<Spinner/>}
                                />
                      }

                      if(_.id === "defer"){
                        return <div className='relative'>
                            <NewButton  key={idx} style={{width: "50px",height: "50px",backgroundColor: "#fff"}} onClick={(e)=>{handleActionButtonClicked(e,_)}} rounded label="" icon={_.icon}/>
                            {
                              isShowDeferMenu && <>
                                  <DiferDurations 
                                      toggleIsDifferMenuOpen={() => setIsShowDeferMenu(false)} 
                                      id={currentTask?._id}
                                  />
                              </>
                            }
                        </div>
                      }

                      return <NewButton key={idx} style={{width: "50px",height: "50px",backgroundColor: "#fff"}} onClick={(e)=>{handleActionButtonClicked(e,_)}} rounded label="" icon={_.icon}/>
                    
                    })
                  }
              </div>
          </div>
  </>
}


const BreadCramps = () => {
  const isCompleted = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible);
    const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
    const [exploitationDurations, setExploitationDurations] = useState<any[] | null>(null);
    const dispatch:any = useDispatch();
    const tree = useSelector((state: RootState) => state.tree);
    const [showTooltip, setShowTooltip] = useState("");
    
    const handleClick = (breadCrumb: any) => {
      const nodeId = breadCrumb.nodesTillLifeDivisions.length === 0? "":breadCrumb.nodesTillLifeDivisions[breadCrumb.nodesTillLifeDivisions.length-1]._id;
  
      if(nodeId){
        dispatch(setIsFullViewOn(false));
        if (!isCompleted) {
          dispatch(toggleIsCompletedNodesVisible());
        }
        dispatch(setNavigatePointerNodeId(nodeId));
      }
  
      setShowTooltip(breadCrumb._id);
      setTimeout(() => {
        setShowTooltip("");
      }, 2000);
    };
  
    useEffect(() => {
      (async () => {
        const res = await getBreadCrumbsOfLoggedInUser();
        // console.log(res);
        if(res?.success){
          setExploitationDurations(res.exploitationDurations)
        }
      })()
    }, [tree]);
  
    return (
      <div className="flex justify-center items-center gap-2 pt-2 flex-wrap max-w-md mt-4">
        {exploitationDurations && exploitationDurations.map((breadCrumb, idx) => {
          const tooltip = `${breadCrumb.nodesTillLifeDivisions[breadCrumb.nodesTillLifeDivisions.length-1].delegatedTo?.find((d:any) => d.user === loggedInUser._id)? "Delegated - ":""} ${breadCrumb.nodesTillLifeDivisions.length === 0? "Unknown":breadCrumb.nodesTillLifeDivisions[breadCrumb.nodesTillLifeDivisions.length-1].objective}`;
          
          return <div 
                onClick={() => handleClick(breadCrumb)} 
                key={idx} 
                title={tooltip} 
                style={{ backgroundColor: breadCrumb.nodesTillLifeDivisions[1].color || "green" }} 
                className="relative cursor-pointer border rounded-full w-8 h-8 text-md font-bold text-white flex justify-center items-center"
              >
                {exploitationDurations.length - idx}
  
                {
                  showTooltip === breadCrumb._id && <span className="absolute max-w-[100vw] left-0 bottom-[calc(100%+10px)] bg-white text-black rounded-md p-2">{tooltip}</span>
                }
              </div>
              })}
      </div>
    );
}

interface RightProps {
  show: Ishow;
  setShow: any;
}

const Right:React.FC<RightProps> = ({show,setShow}) => {

  const handleMouseEnter = () => {
    setShow((prev:Ishow) => {
      return {
        ...prev,
        rightBar: true
      }
    });
  }
  const handleMouseLeave = () => {
    setShow((prev:Ishow) => {
      return {
        ...prev,
        rightBar: false
      }
    });
  }

  return <>
        <div className="flex-1 flex flex-col gap-4 justify-center items-center max-[1000px]:hidden">
          <div 
          style={{visibility: show.rightBar? "visible":"hidden"}} 
          className="w-[250px] h-[60%] bg-white/40 rounded-md p-2">
              <ChallengesRoutinesAndPR/>
          </div>
          <div className="flex gap-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <ChallengeSVG/>
            <PrioritiesSVG/>
            <RoutinesSVG/>
          </div>
        </div>
  </>
}

const ChallengesRoutinesAndPR = () => {
  const dispatch:any = useDispatch();
      const [chalengeType, setChallengeType] = useState<"day" | "week">("day");
      const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
      const mytree = useSelector((state: RootState) => state.tree);
      const Gchallenges = useSelector((state: RootState) => state.challenges);
      const Groutines = useSelector((state: RootState) => state.routines);
      const Gpriorities = useSelector((state: RootState) => state.priorities);
      const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
      const [routines, setRoutines] = useState<any>([]);
      const [challenges, setChallenges] = useState<any>([]);
      const [priorities, setPriorities] = useState<any>([]);
      const [activeTab, setActiveTab] = useState("PRIORITIES");
      const treeData = useSelector((state: RootState) => state.tree);
      
  
      const handleRemoveChallenge = (_id: string) => {
          dispatch(removeChallenge(_id));
      };
  
      const handleLoadTask = (item: any) => {
          dispatch(setIsFullViewOn(false));
          dispatch(setNavigatePointerNodeId(item.node._id));
          
          let node:any = undefined;
  
          // find node from tree 
          if (treeData) {
              const data = getTreeData(); // Ensure getTreeData is defined and returns the correct type
  
              test(data);
              function test(d: any) {
                  if(d.data._id === item.node._id){
                      node = d;
                      return 
                  }
  
                  if (d.children) {
                      d.children.forEach((child:any) => test(child));
                  }
              }
          }
          else {
              return;
          }
  
          if(!node){
              dispatch(toggleIsCompletedNodesVisible())
              return;
          }
          
          if(currentTask?.startTime){
              return;
          }
  
          // create arry of parent objective from item to project 
          let parentObjectives = [];
          let tempNode = node.parent;
  
          while (tempNode.depth > 2) {
              parentObjectives.push(tempNode.data);
              tempNode = tempNode.parent;
          }
  
          // find current task and paretn objective 
          const result = getCurrentTask(node.data,parentObjectives);
          
          dispatch({ type: SET_IS_PAUSED, payload: true });
          dispatch(setCurrentTask(result.currentTask));
          dispatch(setCurrentTasksParents(result.parentObjectives));
  
          if(!manuallyScheduleNodeId || manuallyScheduleNodeId[manuallyScheduleNodeId.length-1] !== item.node._id){
        if(manuallyScheduleNodeId){
          dispatch(setManuallyScheduledNodeId([...manuallyScheduleNodeId,item.node._id]));
        }
        else {
          dispatch(setManuallyScheduledNodeId([item.node._id]));
        }
      }
      };
  
      const handleRemoveRoutine = (routineId: string) => {
          dispatch(removeRoutine(routineId));
      };
      
      const handleRemovePriority = (priorityId: string) => {
          dispatch(removePriority(priorityId));
      };
  
      useEffect(() => {
          const tree = getTreeData();
          const rs:any = [];
          const cs:any = [];
          const pr:any = [];
  
          if(tree){
              tree.descendants().map((node:any) => {
                  
                  const c = Gchallenges.find(c => c.node?._id === node.data._id);
                  if(c){
                      const coiNodeColor = getCOINodeColor(node);
                      cs.push({...c,node: {...c.node,completedPortion: node.data.completedPortion,coiNodeColor}});
                  }
                  const r = Groutines.find(c => c.node?._id === node.data._id);
                  if(r){
                      const coiNodeColor = getCOINodeColor(node);
                      rs.push({...r,node: {...r.node,completedPortion: node.data.completedPortion,coiNodeColor}});
                  }
                  const p = Gpriorities.find(c => c.node?._id === node.data._id);
                  if(p){
                      const coiNodeColor = getCOINodeColor(node);
                      pr.push({...p,node: {...p.node,completedPortion: node.data.completedPortion,coiNodeColor}});
                  }
                  return null;
              })
          }
  
          Gchallenges.forEach(ch => {
              const c = cs.find((c:any) => c._id === ch._id);
              if(!c){
                  cs.push({...ch,node: {...ch.node,completedPortion: 1}});
              }
          });
          Groutines.forEach(ch => {
              const r = rs.find((c:any) => c._id === ch._id);
              if(!r){
                  rs.push({...ch,node: {...ch.node,completedPortion: 1}});
              }
          });
          Gpriorities.forEach(ch => {
              const p = pr.find((c:any) => c._id === ch._id);
              if(!p){
                  pr.push({...ch,node: {...ch.node,completedPortion: 1}});
              }
          });
  
  
          setRoutines(rs.sort((a:any,b:any) =>  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
          setChallenges(cs.sort((a:any,b:any) =>  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
          setPriorities(pr.sort((a:any,b:any) =>  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
      }, [Gchallenges,Groutines,Gpriorities,mytree]);
      
  
      return (
          <>
              <div className='flex border'>
                  <a href="/" onClick={(e) => {e.preventDefault();setActiveTab("PRIORITIES")}} className={`${activeTab === "PRIORITIES"? "bg-white":""} text-center px-2 text-center flex-1`}>
                      PR
                  </a>
                  <a href="/" onClick={(e) => {e.preventDefault();setActiveTab("CHALLENGES")}} className={`${activeTab === "CHALLENGES"? "bg-white":""} border-r text-center px-2 text-center flex-1`}>
                      Challenges
                  </a>
                  <a href="/" onClick={(e) => {e.preventDefault();setActiveTab("ROUTINES")}} className={`${activeTab === "ROUTINES"? "bg-white":""} border-r text-center px-2 text-center flex-1`}>
                      Routines
                  </a>
              </div>

              <div className="h-[calc(100%-26px)] overflow-auto">
                  {activeTab === "CHALLENGES" && (
                      <div className='mt-3 p-2 bg-white rounded-md'>
                          <div className='flex items-center justify-between border-b pb-2'>
                              <NewButton full label={`${chalengeType === "day" ? "Day" : "Week"} Challenges`} onClick={() => setChallengeType(p => p === "day" ? "week" : "day")}/>
                          </div>
                          <div className='flex flex-col p-1'>
                              {challenges.filter((ch:any) => Gchallenges.find(c => c._id === ch._id)).filter((ch:any) => ch.type === chalengeType).map((item:any, idx:number) => (
                                  <div key={idx} style={{ backgroundColor: `${item.node?.coiNodeColor}` }} className={`border-b p-2`}>
                                      {/* <div className='bg-gray-200 h-[5px] rounded-md'>
                                          <div style={{ width: `${item.node?.completedPortion*100}%` }} className='h-full rounded-md bg-blue-600' />
                                      </div> */}
                                      <ProgressBar progress={item.node?.completedPortion*100}/>
                                      <div className='flex justify-between items-center'>
                                          <a href="/" onClick={(e) =>{e.preventDefault();handleLoadTask(item)}} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1'>{item.node?.objective}</a>
                                          <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemoveChallenge(item._id)}  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      </div>
                  )}
      
                  {activeTab === "ROUTINES" && (
                      <div className='mt-3 p-2 bg-white rounded-md'>
                          <div className='flex items-center justify-between border-b pb-2'>
                              <h6 className='mb-0'>Routines</h6>
                          </div>
                          <div className='flex flex-col p-1'>
                              {routines.filter((ch:any) => Groutines.find(c => c._id === ch._id)).map((item:any, idx:number) => (
                                  <div key={idx} style={{ backgroundColor: `${item.node.coiNodeColor}` }} className={`border-b p-2`}>
                                      <ProgressBar progress={item.node?.completedPortion*100}/>
                                      <div className='flex justify-between items-center'>
                                          <a href="/" onClick={(e) => {e.preventDefault();handleLoadTask(item)}} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1'>{item.node?.objective}</a>
                                          <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemoveRoutine(item._id)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      </div>
                  )}
      
                  {activeTab === "PRIORITIES" && (
                      <div className='mt-3 p-2 bg-white rounded-md'>
                      <div className='flex items-center justify-between border-b pb-2'>
                          <h6 className='mb-0'>Priorities</h6>
                      </div>
                      <div className='flex flex-col p-1'>
                          {priorities.filter((ch:any) => Gpriorities.find(c => c._id === ch._id)).map((item:any, idx:number) => (
                              <div key={idx} style={{ backgroundColor: `${item.node.coiNodeColor}` }} className={`border-b p-2`}>
                                  <ProgressBar progress={item.node?.completedPortion*100}/>
                                  <div className='flex justify-between items-center'>
                                      <a href="/" onClick={(e) =>{e.preventDefault();handleLoadTask(item)}} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1 break-all'>{item.node?.objective}</a>
                                      <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemovePriority(item._id)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                                  </div>
                              </div>
                          ))}
                      </div>
                  </div>
                  )}
              </div>
          </>
      );
}


const ParentObjective = () => {
  const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents);
  const [parentObjectiveIndex, setParentObjectiveIndex] = useState<number>(0);
 
  
  const handleParentObjectivePrevOrNextClick = (isNext: boolean) => {
		setParentObjectiveIndex(prev => {
			if (isNext) {
				return prev === currentTasksParents.length - 1 ? 0 : prev + 1;
			}
			return prev === 0 ? currentTasksParents.length - 1 : prev - 1;
		});
	};

  return <>
        <div className="max-w-md mx-auto">
            <div className="flex items-center gap-2">
              <img onClick={()=>handleParentObjectivePrevOrNextClick(false)} src={leftArrow} alt="left-arrow" className='w-4 cursor-pointer'/>
              <p className='border p-2 rounded-md flex-1'>{currentTasksParents && currentTasksParents.length !== 0 ? currentTasksParents[parentObjectiveIndex]?.objective : "Please schedule a Category of Improvement"}</p>
              <img onClick={()=>handleParentObjectivePrevOrNextClick(true)} src={rightArrow} alt="right-arrow" className='w-4 cursor-pointer'/>
            </div>
            <div className="m-2">
              <ProgressBar progress={currentTasksParents && currentTasksParents[parentObjectiveIndex]?.completedPortion * 100}/>
            </div>
        </div>
  </>
}


interface ProgressProps {
  show: Ishow
}

const Progress:React.FC<ProgressProps> = ({show}) => {
  const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents);
  const dispatch:AppDispatch = useDispatch();
  const {remainingTimeToDisplay,remainingTime,currentTask} = useRemaningTime();

 
  const handlePrevOrNextClick = (isNext:boolean) => {
		const currentTaskParent = currentTasksParents && currentTasksParents[currentTasksParents.length-1];

		if(!currentTaskParent){
			return;
		}
		if(currentTask?.isSomeOneWorkingOnThis || currentTask?.startTime){
			toast.error("You can't move the running task!");
			return;
		}

		const siblings = currentTaskParent.children.filter((child:any) => child.children?.length === 0);
		const index = siblings.findIndex((child:any) => child._id === currentTask._id);

		if(isNext){
			if(siblings[index+1]){
				dispatch(setCurrentTask(siblings[index+1]))
			}
			else {
				toast.error("This is the last sibling.");
			}
			return;
		}
		if(siblings[index-1]){
			dispatch(setCurrentTask(siblings[index-1]));
		}
		else {
			toast.error("This is the first sibling.");
		}
	}

  return <>   
              <img style={{visibility: show.nextAndBackIcons? "visible":"hidden"}} src={leftArrow} onClick={()=>handlePrevOrNextClick} alt="left-arrow" className='w-7 cursor-pointer'/>
              <div className="w-[400px] relative">
                  <SVGSircularProgressBar 
                    hideLabel 
                    percentage={calculatePercentage(remainingTime,(currentTask?.estimatedTaskDuration+currentTask?.extraTaskDuration?.duration))} 
                    size={400} 
                    strokeWidth={5}
                    strokeColor='#fff'
                    trackColor='transparent' 
                  />
                  <div className="absolute w-[95%] h-[95%] rounded-full top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
                      <div id='my-box' className='rounded-full'>
                          <i></i> 
                          <p className='text-xl font-bold text-center text-white' title={currentTask?.objective || "N/A"}>{formetText(`${currentTask?.objective || "N/A"}`)}</p>
                      </div>
                      <div className="text-gray-600 -translate-y-[100%] text-center h-[20%] flex items-center justify-center">
                          <h1 className='font-bold text-lg text-white'>{remainingTimeToDisplay}m</h1>
                      </div>
                  </div>
              </div>
              <img style={{visibility: show.nextAndBackIcons? "visible":"hidden"}} src={rightArrow} alt="right-arrow" className='w-7 cursor-pointer'/>
  </>
}

function useRemaningTime(){
  const dispatch: AppDispatch = useDispatch();
    const ct = useSelector((state: RootState) => state.globalStates.currentTask);
    const [currentTask, setSurrentTask] = useState<any>(null);
    const [remainingTime, setRemaningTime] = useState(0);
    const [remainingTimeToDisplay, setRemaningTimeToDisplay] = useState(0);
    const [isFetchingData, setIsFetchingData] = useState(false);
    useTA(currentTask);	
  
    
    useEffect(() => {
      function formatTime(seconds:number) {
        const minutes = Math.floor(seconds / 60); // Get the number of minutes
        const remainingSeconds = Math.abs(seconds % 60);   // Get the remaining seconds
        return `${minutes}:${remainingSeconds.toFixed(0).toString().padStart(2, '0')}`;
      }
  
      if(!currentTask?.extraTaskDuration){
        return;
      }
  
      const interval = setInterval(() => {
        let estimatedTaskDuration = currentTask?.estimatedTaskDuration
  
        if(currentTask?.extraTaskDuration && currentTask.extraTaskDuration.duration && moment(currentTask.extraTaskDuration.date).startOf("day").isSame(moment().startOf("day"))){
            estimatedTaskDuration = currentTask.estimatedTaskDuration+currentTask.extraTaskDuration.duration;
        }
  
        let remTime:any = undefined;
        if (!currentTask?.startTime) {
          remTime = (estimatedTaskDuration - currentTask?.taskExploitationDuration);
          clearInterval(interval);
        } 
        else {
          const currMinutes = moment().diff(moment(currentTask?.startTime), "seconds") / 60;
          remTime = ((estimatedTaskDuration - currentTask?.taskExploitationDuration) - currMinutes);
        }
        
        let rt1:any = undefined;
        if(typeof(remTime) === "number"){
          rt1 = formatTime(remTime*60);
        }
        setRemaningTime(remTime);
        setRemaningTimeToDisplay(rt1);
      }, 1000);
  
      return () => {
        return clearInterval(interval);
      };
    }, [currentTask?.extraTaskDuration,currentTask?.estimatedTaskDuration,currentTask?.startTime,currentTask?.taskExploitationDuration]);
    
    useEffect(() => {
      (async () => {
        if(ct){
          setIsFetchingData(true);
          const res = await getRunningTask();
          const res1:any = await getTaskById(ct._id);
          setIsFetchingData(false);
    
          // console.log(res1);
          if(res?.success && res.node){
            const node:any = res.node;
            const isRecurring = Boolean(node.recurring?.frequency);
            node.taskExploitationDuration = totalExploitationDuration(node.taskExploitationDuration, isRecurring);
            setSurrentTask(node);
          }
          if(res1?.success && res1.node && res1.exploitationDurations){
            const node:any = res1.node;
            const isRecurring = Boolean(node.recurring?.frequency);
            node.taskExploitationDuration = totalExploitationDuration(res1.exploitationDurations, isRecurring);
            setSurrentTask(node);
            if(!ct.startTime && node.startTime){
              dispatch(setCurrentTask({...ct,...node}));
            }
          }
        }
        else {
          setSurrentTask(null);
        }
      })()
    }, [ct,dispatch]);

    return {remainingTime,remainingTimeToDisplay,isFetchingData,currentTask};
}

function useTA(currentTask:any){
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      let remTime:any = undefined;

      let estimatedTaskDuration = currentTask?.estimatedTaskDuration

      if(currentTask?.extraTaskDuration && currentTask.extraTaskDuration.duration && moment(currentTask.extraTaskDuration.date).startOf("day").isSame(moment().startOf("day"))){
          estimatedTaskDuration = currentTask.estimatedTaskDuration+currentTask.extraTaskDuration.duration;
      }

      if (!currentTask?.startTime) {
        remTime = (estimatedTaskDuration - currentTask?.taskExploitationDuration);
      } 
      else {
        const currMinutes = moment().diff(moment(currentTask?.startTime), "seconds") / 60;
        remTime = ((estimatedTaskDuration - currentTask?.taskExploitationDuration) - currMinutes);
      }

      if (Number(remTime) < 0 && currentTask?.startTime) {
        confettiRise.play();
        dispatch(setTimingAlertModalOpen(true));
        clearInterval(interval);
      } 
      else {
        dispatch(setTimingAlertModalOpen(false));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [currentTask,dispatch]);
}

const formetText = (text:string) => {
  if(text.length > 250){
    return text.substring(0,250) + "..."
  }
  return text;
}